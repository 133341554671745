import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';

export const getCourseEnrolments = ({
  courseId
}: {
  courseId: string;
}): Promise<{ data: Array<any> }> => {
  return api.get(`/course-new/${courseId}/enrolments`);
};

export const getCourseEnrolmentsQueryOptions = (courseId: string) => {
  return queryOptions({
    queryKey: ['course', 'enrolments', courseId],
    queryFn: () => getCourseEnrolments({ courseId })
  });
};

type UseCourseEnrolmentsOptions = {
  courseId: string;
  queryConfig?: QueryConfig<typeof getCourseEnrolmentsQueryOptions>;
};

export const useCourseEnrolments = ({
  courseId,
  queryConfig
}: UseCourseEnrolmentsOptions) => {
  return useQuery({
    ...getCourseEnrolmentsQueryOptions(courseId),
    ...queryConfig
  });
};
