import React from 'react';
import { TextInput, Group, Button, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { F2FTaskDto, CreateF2FTaskDto, UpdateF2FTaskDto } from 'types/api/face-to-face';
import { SurveyDto } from 'types/api/survey';
import { F2FTaskType } from 'enums/training';

type Props = {
  task: F2FTaskDto;
  surveys: SurveyDto[];
  create: (dto: CreateF2FTaskDto) => void;
  update: (dto: UpdateF2FTaskDto) => void;
  remove: (id: string) => void;
};

export const SurveyTaskEditor: React.FC<Props> = ({ task, surveys, create, update, remove }) => {
  const form = useForm({
    initialValues: {
      title: task?.title || '',
      surveyId: task?.surveyId || null
    },
    validate: {
      title: value => (value.length === 0 ? 'A short title is required' : null),
      surveyId: value => (value ? null : 'Please select a survey to add')
    },
    transformValues: values => ({
      id: task.id,
      title: values.title,
      type: 'SURVEY' as keyof typeof F2FTaskType,
      when: task.when,
      surveyId: values.surveyId
    })
  });
  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (task.id) update(values);
        else create(values);
      })}
    >
      <TextInput mb="lg" label="Short Title" {...form.getInputProps('title')} />

      <Select my="lg" label="Which Survey?" searchable data={surveys.map(survey => ({ value: survey.id, label: survey.title }))} {...form.getInputProps('surveyId')} />

      <Group position="right" mt="lg">
        {task?.id && (
          <Button color="red" onClick={() => remove(task.id)}>
            Delete Task
          </Button>
        )}
        <Button type="submit">{task?.id ? 'Save' : 'Create'}</Button>
      </Group>
    </form>
  );
};
