import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { Scorm } from 'types/backend';
import { QueryConfig } from 'lib/react-query';

export const getScorms = (): Promise<{ data: Scorm[] }> => {
  return api.get('/scorm');
};

export const getScormsQueryOptions = () => {
  return queryOptions({
    queryKey: ['scorms'],
    queryFn: getScorms
  });
};

type UseScormsOptions = {
  queryConfig?: QueryConfig<typeof getScormsQueryOptions>;
};

export const useScorms = ({ queryConfig }: UseScormsOptions = {}) => {
  return useQuery({
    ...getScormsQueryOptions(),
    ...queryConfig
  });
};
