import React, { useCallback } from 'react';
import { UserIndex } from 'types/state';
import { Table, Grid, TextInput, Button, Text, Alert, Skeleton, Box } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useForm, isEmail } from '@mantine/form';
import { UserRoles } from 'enums';

type Props = {
  users: UserIndex;
  isLoading: boolean;
  error?: string;
  createUser: (arg0: { email: string; role: keyof typeof UserRoles }) => void;
};

export const AdminTable: React.FC<Props> = ({ users, isLoading, error, createUser }) => {
  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: isEmail('Please enter a valid email address')
    },
    transformValues: values => ({
      email: values.email,
      role: 'IOI_ADMIN' as keyof typeof UserRoles
    })
  });

  const handleFormSubmit = useCallback((arg0: {
    email: string;
    role: keyof typeof UserRoles;
  }) => {
    createUser(arg0);
    form.reset();
  }, [form, createUser]);

  return (
    <>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Grid my="xl">
          <Grid.Col span={10}>
            <TextInput placeholder="Administrator email" {...form.getInputProps('email')} />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button fullWidth type="submit" loading={isLoading} leftIcon={<IconPlus />}>
              Create New Administrator
            </Button>
          </Grid.Col>
        </Grid>
      </form>

      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(users)
            .sort((a, b) => (a.email < b.email ? -1 : 1))
            .map(user => (
              <tr key={user.id}>
                <td>
                  <Box td={user.isActive ? 'none' : 'line-through'}>{user.email}</Box>
                </td>
              </tr>
            ))}
          {isLoading && (
            <tr>
              <td>
                <Skeleton h="2rem" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {Object.values(users).length === 0 && <Text>No users found.</Text>}
    </>
  );
};
