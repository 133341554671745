export enum Language {
  ENGLISH = 'No, I only speak English',
  MANDARIN = 'Mandarin',
  ARABIC = 'Arabic',
  CANTONESE = 'Cantonese',
  VIETNAMESE = 'Vietnamese',
  ITALIAN = 'Italian',
  GREEK = 'Greek',
  HINDI = 'Hindi',
  SPANISH = 'Spanish',
  PUNJABI = 'Punjabi',
  OTHER = 'Other (please specify)'
}
