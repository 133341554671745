import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `data-export/${opName}`;

export const getCourseExport = createRoutine(getPrefix('get-course-export'));

export const getF2FExport = createRoutine(getPrefix('get-f2f-export'));

export const getScormExport = createRoutine(getPrefix('get-scorm-export'));

export const getUserExport = createRoutine(getPrefix('get-user-export'));

export const getEventParticipantsExport = createRoutine(getPrefix('get-event-participants-export'));

export const getEventPreferencesExport = createRoutine(getPrefix('get-event-preferences-export'));
