/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { UserRoles } from 'enums';
import { Routine } from 'redux-saga-routines';
import { UserDto } from 'types/api/users';
import { UserIndex, UsersState } from 'types/state';
import { fetchUsers, fetchUsersByRole, createUser } from './routines';
import { addParticipant } from 'store/event-enrolment/routines';

const initialState: UsersState = {
  [UserRoles.LHD_COORDINATOR]: {},
  [UserRoles.IOI_ADMIN]: {},
  [UserRoles.LEARNER]: {},
  [UserRoles.F2F_FACILITATOR]: {},
  isLoading: false,
  error: undefined
};

export const usersReducer = produce((state: UsersState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case fetchUsers.TRIGGER:
    case fetchUsersByRole.TRIGGER:
    case createUser.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchUsers.SUCCESS:
      state.isLoading = false;
      state[UserRoles.LHD_COORDINATOR] = {};
      state[UserRoles.IOI_ADMIN] = {};
      state[UserRoles.LEARNER] = {};
      state[UserRoles.F2F_FACILITATOR] = {};
      payload.forEach((user: UserDto) => {
        state[user.role][user.id] = user;
      });
      break;
    case fetchUsersByRole.SUCCESS:
      state.isLoading = false;
      state[payload.role] = payload.data.reduce(
        (acc: UserIndex, user: UserDto) => ({
          ...acc,
          [user.id]: user
        }),
        {}
      );
      break;
    case createUser.SUCCESS:
      state.isLoading = false;
      state[payload.role][payload.id] = payload;
      break;
    case fetchUsers.FAILURE:
    case fetchUsersByRole.FAILURE:
    case createUser.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    case addParticipant.SUCCESS:
      if (!state.LEARNER[payload.enrolment.userId]) {
        state.LEARNER[payload.enrolment.userId] = payload.enrolment.user;
      }
      break;
    // no default
  }
}, initialState);
