import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { CourseEmailDto } from 'types/api/course/course.email.dto';
import { CreateCourseEmailDto } from 'types/api/course/create.course.email.dto';
import { UpdateCourseEmailDto } from 'types/api/course/update.course.email.dto';

class CourseEmailService {
  constructor() {
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  async create(dto: CreateCourseEmailDto): Promise<CourseEmailDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<CourseEmailDto>>({
      url: '/course-email',
      payload: dto
    });
    return data.data;
  }

  async update(id: string, dto: UpdateCourseEmailDto): Promise<CourseEmailDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<CourseEmailDto>>({
      url: `/course-email/${id}`,
      payload: dto
    });
    return data.data;
  }

  async delete(id: string) {
    await axiosAdapter.doDelete({
      url: `/course-email/${id}`
    });
  }
}

export const courseEmailService = new CourseEmailService();
