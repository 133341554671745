import React, { useCallback } from 'react';
import { UserIndex } from 'types/state';
import { Table, Grid, Badge, Button, TextInput, Select, Text, Alert, Skeleton, Box } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { NSWHealthLHD } from 'enums/user-info';
import { useForm, isEmail, isNotEmpty } from '@mantine/form';
import { UserRoles } from 'enums';

type Props = {
  users: UserIndex;
  isLoading: boolean;
  error?: string;
  createUser: (arg0: { email: string; role: keyof typeof UserRoles; coordinatorFor: keyof typeof NSWHealthLHD }) => void;
};

export const CoordinatorTable: React.FC<Props> = ({ users, isLoading, error, createUser }) => {
  const form = useForm({
    initialValues: {
      email: '',
      coordinatorFor: undefined as keyof typeof NSWHealthLHD
    },
    validate: {
      email: isEmail('Please enter a valid email address'),
      coordinatorFor: isNotEmpty('This field is required.')
    },
    transformValues: values => ({
      email: values.email,
      coordinatorFor: values.coordinatorFor,
      role: 'LHD_COORDINATOR' as keyof typeof UserRoles
    })
  });

  const handleFormSubmit = useCallback((arg0: {
    email: string;
    role: keyof typeof UserRoles;
    coordinatorFor: keyof typeof NSWHealthLHD;
  }) => {
    createUser(arg0);
    form.reset();
  }, [form, createUser]);

  return (
    <>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Grid my="xl">
          <Grid.Col span={7}>
            <TextInput placeholder="Coordinator email" {...form.getInputProps('email')} />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select data={Object.entries(NSWHealthLHD).map(([value, label]) => ({ label, value }))} placeholder="Coordinator LHD" {...form.getInputProps('coordinatorFor')} />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button fullWidth type="submit" loading={isLoading} leftIcon={<IconPlus />}>
              Create New Coordinator
            </Button>
          </Grid.Col>
        </Grid>
      </form>

      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Local Health District</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(users)
            .sort((a, b) => (a.email < b.email ? -1 : 1))
            .map(user => (
              <tr key={user.id}>
                <td>
                  <Box td={user.isActive ? 'none' : 'line-through'}>{user.email}</Box>
                </td>
                <td>
                  <Badge radius="md">{NSWHealthLHD[user.coordinatorFor]}</Badge>
                </td>
              </tr>
            ))}
          {isLoading && (
            <tr>
              <td>
                <Skeleton h="2rem" />
              </td>
              <td>
                <Skeleton h="2rem" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {Object.values(users).length === 0 && !isLoading && <Text>No users found.</Text>}
    </>
  );
};
