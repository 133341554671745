import { Anchor, Stack, Title } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/Table';
import { SortableHeader } from 'components/Table/SortableHeader';
import { format } from 'date-fns';
import { AdminRoutes } from 'enums';
import { useCouponRedemptions } from 'features/coupons/api/get-coupon-redemptions';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { CouponRedemption } from 'types/backend';

const columns: ColumnDef<CouponRedemption>[] = [
  {
    header: 'User',
    accessorFn: row => row.user?.email ?? row.user_id
  },
  {
    header: 'Course',
    cell: ({ row }) => (
      <Anchor
        component={Link}
        to={`${AdminRoutes.courseView}/${row.original.course_id}`}
      >
        {row.original.course?.title ?? row.original.course_id}
      </Anchor>
    )
  },
  {
    id: 'created_at',
    header: ({ column }) => {
      return (
        <SortableHeader
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          order={column.getIsSorted()}
        >
          Redeemed At
        </SortableHeader>
      );
    },
    accessorKey: 'created_at',
    cell: ({ row }) =>
      format(new Date(row.original.created_at), 'd MMM yyyy, h:mm aa'),
    sortingFn: 'datetime'
  }
];

export const CouponRedemptionPage = () => {
  const { id: couponId } = useParams();
  const couponRedemptionsQuery = useCouponRedemptions({
    couponId,
    expand: ['user', 'course'],
    queryConfig: { enabled: !!couponId }
  });

  if (couponRedemptionsQuery.isLoading) return null;

  return (
    <Stack>
      <Title order={1}>Redemptions</Title>
      <DataTable
        id="coupon-redemptions"
        columns={columns}
        data={couponRedemptionsQuery.data.data}
        sortable
      />
    </Stack>
  );
};
