import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `course/${opName}`;

export const createCourse = createRoutine(getPrefix('create'));

export const updateCourse = createRoutine(getPrefix('update'));

export const fetchCourses = createRoutine(getPrefix('fetch-all'));

export const fetchCourse = createRoutine(getPrefix('fetch-one'));

export const enrolUserInCourse = createRoutine(getPrefix('enrol-user'));

export const createTask = createRoutine(getPrefix('create-task'));

export const updateTask = createRoutine(getPrefix('update-task'));

export const reorderTask = createRoutine(getPrefix('reorder-task'));

export const removeTask = createRoutine(getPrefix('remove-task'));

export const uploadSplash = createRoutine(getPrefix('upload-splash'));

export const createCourseEmail = createRoutine(getPrefix('create-email'));

export const updateCourseEmail = createRoutine(getPrefix('update-email'));

export const deleteCourseEmail = createRoutine(getPrefix('delete-email'));

export const dismissCongratulations = createRoutine(getPrefix('dismiss-congratulations'));
