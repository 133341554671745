import {
  IconChevronDown,
  IconChevronUp,
  IconSelect,
  IconSelector
} from '@tabler/icons-react';
import { SortDirection } from '@tanstack/react-table';
import React from 'react';

interface SortableHeaderProps {
  onClick: () => void;
  order: false | SortDirection;
  children?: React.ReactNode | Array<React.ReactNode>;
}
export const SortableHeader = ({
  onClick,
  order,
  children
}: SortableHeaderProps) => {
  return (
    <div
      role="button"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={() => {}}
      style={{ display: 'flex', cursor: 'pointer', userSelect: 'none' }}
    >
      {children}
      <span style={{ marginLeft: '6px' }}>
        {order === false && <IconSelector size="20" />}
        {order === 'asc' && <IconChevronDown size="20" />}
        {order === 'desc' && <IconChevronUp size="20" />}
      </span>
    </div>
  );
};
