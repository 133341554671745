import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Title,
  Container,
  Alert,
  LoadingOverlay,
  Anchor,
  Text,
  List,
  Box,
  Card,
  SimpleGrid,
  CardSection
} from '@mantine/core';
import { IconArrowLeft, IconFileText } from '@tabler/icons-react';
import { EventIndex, F2FTypeIndex, RootState } from 'types/state';
import { fetchF2FTypes as fetchF2FTypesRoutine } from 'store/face-to-face/routines';
import { Routes } from 'enums';

type Props = {
  f2fs: F2FTypeIndex;
  events: EventIndex;
  fetchF2Fs: () => void;
  error?: string;
  isLoading: boolean;
};

const F2FResourcesComponent: React.FC<Props> = ({
  f2fs,
  events,
  fetchF2Fs,
  error,
  isLoading
}) => {
  const { id } = useParams();

  useEffect(() => {
    fetchF2Fs();
  }, []);

  const event = events[id];
  const f2f = event ? f2fs[event.f2fId] : null;

  if (!event || !f2f || isLoading) return <LoadingOverlay visible />;

  return (
    <Container size="lg" mt="4rem">
      <Anchor
        component={Link}
        to={`${Routes.Trainings}/${event.id}`}
        display="flex"
      >
        <IconArrowLeft />
        <Text ml="md">Back to Event</Text>
      </Anchor>

      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      {f2f.resources.map(page => (
        <Box key={page.id}>
          <Title mt="xl" order={4}>
            {page.title}
          </Title>
          <SimpleGrid
            cols={3}
            breakpoints={[
              { maxWidth: '62rem', cols: 3, spacing: 'md' },
              { maxWidth: '48rem', cols: 2, spacing: 'sm' },
              { maxWidth: '36rem', cols: 1, spacing: 'sm' }
            ]}
          >
            {page.resources.map(resource => (
              <Card
                color="red"
                mt="sm"
                withBorder
                key={resource.id}
                component="a"
                href={resource.link}
                target="_blank"
              >
                <IconFileText />
                <Text mt="md" size="md">
                  {resource.text}
                </Text>
              </Card>
            ))}
          </SimpleGrid>
        </Box>
      ))}
      {f2f.resources.length === 0 && (
        <Text>This course has no associated resources.</Text>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  f2fs: state.faceToFace.types,
  events: state.event.events,
  error: state.faceToFace.error || state.event.error,
  isLoading: state.faceToFace.isLoading || state.event.isLoading
});

const mapDispatchToProps = {
  fetchF2Fs: fetchF2FTypesRoutine
};

export const F2FResources = connect(
  mapStateToProps,
  mapDispatchToProps
)(F2FResourcesComponent);
