import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { RootState } from 'types/state';
import { activate as activateRoutine, checkToken as checkTokenRoutine } from 'store/user/routines';
import { Alert, Container, Text, PasswordInput, Button, LoadingOverlay, Card } from '@mantine/core';
import { useForm, matchesField } from '@mantine/form';
import { isStrongPassword } from 'utilities/isStrongPassword';

type PasswordForm = {
  password: string;
  confirmPassword: string;
};

type Props = {
  isActive: boolean;
  error?: string;
  isLoading: boolean;
  activate: (arg0: { token: string; password: string }) => void;
  checkToken: (token: string) => void;
};

const ActivateAccountComponent: React.FC<Props> = ({ isActive, error, isLoading, activate, checkToken }) => {
  const form = useForm<PasswordForm>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validate: {
      password: isStrongPassword,
      confirmPassword: matchesField('password', 'Passwords must match')
    }
  });
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const target = searchParams.get('target');

  useEffect(() => {
    checkToken(token);
  }, [token]);

  if (isActive) return <Navigate to={target || '/'} />;

  return (
    <Container size="xs">
      <Card withBorder my="xl">
        {!error && (
          <form onSubmit={form.onSubmit(values => activate({ token, password: values.password }))}>
            <Text my="lg">Create your password.</Text>

            <PasswordInput my="md" label="Password" {...form.getInputProps('password')} />

            <PasswordInput my="md" label="Confirm password" {...form.getInputProps('confirmPassword')} />

            <Button type="submit" my="lg">
              Submit
            </Button>
          </form>
        )}
        {error && (
          <Alert color="red" title="Something went wrong activating your account">
            {error}
          </Alert>
        )}
        <LoadingOverlay visible={isLoading} />
      </Card>
    </Container>
  );
};

const mapStateToProps = (rootState: RootState) => ({
  isActive: rootState.user.isActive,
  error: rootState.user.error,
  isLoading: rootState.user.isLoading
});

const mapDispatchToProps = {
  activate: activateRoutine,
  checkToken: checkTokenRoutine
};

export const ActivateAccount = connect(mapStateToProps, mapDispatchToProps)(ActivateAccountComponent);
