import React from 'react';
import { Text, Group, Container } from '@mantine/core';
import { IconX, IconUpload, IconFileZip } from '@tabler/icons-react';
import { Dropzone } from '@mantine/dropzone';

type Props = {
  id: string;
  reuploadScorm: (arg0: { id: string; file: File }) => void;
  triggerError: (err: string) => void;
  isLoading: boolean;
};

export const ReuploadScormZip: React.FC<Props> = ({ id, isLoading, reuploadScorm, triggerError }) => {
  return (
    <Container size="xs">
      <Text my="xl">
        Drag and drop a new scorm zip here to{' '}
        <Text span italic>
          completely replace
        </Text>{' '}
        the content for this module.
      </Text>

      <Dropzone onDrop={files => reuploadScorm({ id, file: files[0] })} onReject={files => triggerError(files[0].errors[0].message)} maxSize={1024 ** 3} multiple={false} loading={isLoading}>
        <Group position="center">
          <Dropzone.Accept>
            <IconUpload size="3rem" />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size="3rem" />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileZip size="3rem" />
          </Dropzone.Idle>
          <div>
            <Text fw="bold">Drag Scorm ZIP to Upload</Text>
            <Text size="sm">Maximum file size of 300 mb</Text>
          </div>
        </Group>
      </Dropzone>
    </Container>
  );
};
