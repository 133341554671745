import { UserRoles } from 'enums';
import React from 'react';
import { connect } from 'react-redux';
import { Title, Text, Container, Card, Box } from '@mantine/core';
import { RootState, UserState } from 'types/state';
import { OnboardingBasic } from './OnboardingBasic';
import { OnboardingNSW } from './OnboardingNSW';
import { OnboardingVic } from './OnboardingVic';
import { OnboardingSA } from './OnboardingSA';
import { OnboardingWA } from './OnboardingWA';
import { OnboardingQld } from './OnboardingQld';
import { OnboardingTas } from './OnboardingTas';
import { OnboardingNT } from './OnboardingNT';
import { OnboardingACT } from './OnboardingACT';
import { OnboardingEmployment } from './OnboardingEmployment';
import { OnboardingDiscipline } from './OnboardingDiscipline';
import { OnboardingExperience } from './OnboardingExperience';
import { OnboardingPersonal } from './OnboardingPersonal';

type Props = {
  user: UserState;
};

const OnboardingComponent: React.FC<React.PropsWithChildren<Props>> = ({ user, children }) => {
  if (user?.role === UserRoles.LEARNER) {
    let onboardingPrompt: React.ReactNode = null;
    const australia = user?.basic?.country === 'Australia';

    if (!user.basic)
      onboardingPrompt = (
        <>
          <Title order={2}>Welcome to the InsideOut eLearning Hub</Title>
          <Text mb="lg">In order to finish setting up your account we need to ask you some questions.</Text>
          <OnboardingBasic />
        </>
      );
    else if (australia && user.basic.state === 'NSW' && !user.nsw)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingNSW />
        </>
      );
    else if (australia && user.basic.state === 'VIC' && !user.vic)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingVic />
        </>
      );
    else if (australia && user.basic.state === 'QLD' && !user.qld)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingQld />
        </>
      );
    else if (australia && user.basic.state === 'WA' && !user.wa)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingWA />
        </>
      );
    else if (australia && user.basic.state === 'SA' && !user.sa)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingSA />
        </>
      );
    else if (australia && user.basic.state === 'TAS' && !user.tas)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingTas />
        </>
      );
    else if (australia && user.basic.state === 'ACT' && !user.act)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingACT />
        </>
      );
    else if (australia && user.basic.state === 'NT' && !user.nt)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingNT />
        </>
      );
    else if (!user.primaryEmployment)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingEmployment primary state={user.basic.state} />
        </>
      );
    else if (!user.secondaryEmployment && user.primaryEmployment.hasSecondary)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingEmployment state={user.basic.state} />
        </>
      );
    else if (!user.discipline)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingDiscipline />
        </>
      );
    else if (!user.experience)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingExperience />
        </>
      );
    else if (!user.personal)
      onboardingPrompt = (
        <>
          <Title order={2}>Account Details</Title>
          <OnboardingPersonal />
        </>
      );

    if (onboardingPrompt) {
      return (
        <Box bg="gray.0" py="4rem" pos="absolute" top={0} left={0} right={0} bottom={0}>
          <Container size="sm">
            <Card withBorder my="xl" pos="relative">
              {onboardingPrompt}
            </Card>
          </Container>
        </Box>
      );
    }
  }

  return <div>{children}</div>;
};

const mapStateToProps = (state: RootState) => ({
  user: state.user
});

export const Onboarding = connect(mapStateToProps, null)(OnboardingComponent);
