import { EventEnrolmentStatus } from 'enums/event/event.enrolment.status.enum';
import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { eventService } from 'service/event';
import { eventEnrolmentService } from 'service/event-enrolment';
import { addParticipant, checkEnrolmentForEvent, fetchEnrolmentsForEvent, markAttendance, setEnrolmentStatus } from './routines';

function* fetchEnrolmentsForEventHandler({ payload: id }: Action<string>) {
  try {
    const data = yield call(eventEnrolmentService.getAllForEvent, id);
    yield put(fetchEnrolmentsForEvent.success({ id, data }));
  } catch (e: any) {
    if (e.message) yield put(fetchEnrolmentsForEvent.failure(e.message));
    else yield put(fetchEnrolmentsForEvent.failure(JSON.stringify(e)));
  }
}

function* checkEnrolmentForEventHandler({ payload: { eventId, email } }: Action<{ eventId: string; email: string }>) {
  try {
    // console.log('PAYLOAD', { eventId, email });
    const data = yield call(eventEnrolmentService.checkEnrolmentForEvent, eventId, email);
    yield put(checkEnrolmentForEvent.success({ eventId, data }));
  } catch (e: any) {
    if (e.message) yield put(checkEnrolmentForEvent.failure(e.message));
    else yield put(checkEnrolmentForEvent.failure(JSON.stringify(e)));
  }
}

function* setEnrolmentStatusHandler({
  payload: { enrolmentId, status }
}: Action<{
  enrolmentId: string;
  status: keyof typeof EventEnrolmentStatus;
}>) {
  try {
    const data = yield call(eventService.setEnrolmentStatus, enrolmentId, status);
    yield put(setEnrolmentStatus.success(data));
  } catch (e: any) {
    if (e.message) yield put(setEnrolmentStatus.failure(e.message));
    else yield put(setEnrolmentStatus.failure(JSON.stringify(e)));
  }
}

function* addParticipantHandler({
  payload: { eventId, email }
}: Action<{
  eventId: string;
  email: string;
}>) {
  try {
    const enrolment = yield call(eventService.addParticipant, eventId, email);
    yield put(addParticipant.success({ eventId, enrolment }));
  } catch (e: any) {
    if (e.message) yield put(addParticipant.failure(e.message));
    else yield put(addParticipant.failure(JSON.stringify(e)));
  }
}

function* markAttendanceHandler({
  payload: { eventId, instanceId, values }
}: Action<{
  eventId: string;
  instanceId: string;
  values: { [id: string]: boolean };
}>) {
  try {
    const enrolments = yield call(eventEnrolmentService.markAttendance, eventId, instanceId, values);
    yield put(markAttendance.success(enrolments));
  } catch (e: any) {
    if (e.message) yield put(markAttendance.failure(e.message));
    else yield put(markAttendance.failure(JSON.stringify(e)));
  }
}

export function* eventEnrolmentSaga() {
  yield all([
    takeEvery(fetchEnrolmentsForEvent.TRIGGER, fetchEnrolmentsForEventHandler),
    takeEvery(checkEnrolmentForEvent.TRIGGER, checkEnrolmentForEventHandler),
    takeEvery(setEnrolmentStatus.TRIGGER, setEnrolmentStatusHandler),
    takeEvery(addParticipant.TRIGGER, addParticipantHandler),
    takeEvery(markAttendance.TRIGGER, markAttendanceHandler)
  ]);
}
