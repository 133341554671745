/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { Routine } from 'redux-saga-routines';
import { EventDto } from 'types/api/event';
import { EventState } from 'types/state';
import { createEnrolment, createEvent, createInstance, fetchEvent, fetchEventForUser, fetchEvents, removeInstance, reorderEnrolment, signoffEvent, updateEvent, updateInstance } from './routines';

const initialState: EventState = {
  isLoading: false,
  error: undefined,
  events: {}
};

export const eventReducer = produce((state: EventState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case fetchEvents.TRIGGER:
    case fetchEvent.TRIGGER:
    case fetchEventForUser.TRIGGER:
    case createEvent.TRIGGER:
    case updateEvent.TRIGGER:
    case createInstance.TRIGGER:
    case updateInstance.TRIGGER:
    case removeInstance.TRIGGER:
    case createEnrolment.TRIGGER:
    case reorderEnrolment.TRIGGER:
    case signoffEvent.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchEvents.SUCCESS:
      state.isLoading = false;
      state.events = {};
      payload.forEach((event: EventDto) => {
        state.events[event.id] = event;
      });
      break;
    case fetchEvent.SUCCESS:
    case fetchEventForUser.SUCCESS:
    case createEvent.SUCCESS:
    case updateEvent.SUCCESS:
    case createInstance.SUCCESS:
    case updateInstance.SUCCESS:
    case removeInstance.SUCCESS:
    case reorderEnrolment.SUCCESS:
    case signoffEvent.SUCCESS:
      state.isLoading = false;
      state.events[payload.id] = payload;
      break;
    case createEnrolment.SUCCESS:
      state.isLoading = false;
      state.events[payload.eventId].enrolment = payload;
      break;
    case fetchEvents.FAILURE:
    case fetchEvent.FAILURE:
    case fetchEventForUser.FAILURE:
    case createEvent.FAILURE:
    case updateEvent.FAILURE:
    case createInstance.FAILURE:
    case updateInstance.FAILURE:
    case removeInstance.FAILURE:
    case createEnrolment.FAILURE:
    case reorderEnrolment.FAILURE:
    case signoffEvent.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
