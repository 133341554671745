import React from 'react';
import { connect } from 'react-redux';
import { useForm } from '@mantine/form';
import { Button, Group, Radio, Collapse, Alert } from '@mantine/core';
import { setNT as setNTRoutine } from 'store/user/routines';
import { RootState, UserNT } from 'types/state';
import { NTHealthType, NTHealthDistrict } from 'enums/user-info';

type NTForm = {
  isNTHealth: 'YES' | 'NO';
  type: keyof typeof NTHealthType;
  district: keyof typeof NTHealthDistrict;
};

type Props = {
  isLoading: boolean;
  error?: string;
  nt: UserNT;
  setNT: (dto: UserNT) => void;
};

const OnboardingNTComponent: React.FC<Props> = ({ isLoading, error, nt, setNT }) => {
  const form = useForm<NTForm, (arg0: NTForm) => UserNT>({
    initialValues: nt
      ? {
          isNTHealth: nt.isNTHealth ? 'YES' : 'NO',
          type: nt.type,
          district: nt.district
        }
      : {
          isNTHealth: undefined,
          type: undefined,
          district: undefined
        },
    validate: {
      isNTHealth: value => (!value ? 'This field is required' : null),
      type: (value, values) => (!value && values.isNTHealth === 'YES' ? 'This field is required' : null),
      district: (value, values) => (!value && values.isNTHealth === 'YES' && values.type === 'DISTRICT' ? 'This field is required' : null)
    },
    transformValues: values => ({
      isNTHealth: values.isNTHealth === 'YES',
      type: values.type,
      district: values.district
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setNT(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Do you work for NT Health?" {...form.getInputProps('isNTHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Collapse in={form.values.isNTHealth === 'YES'} my="lg">
        <Radio.Group label="Where in NT Health?" {...form.getInputProps('type')}>
          {Object.entries(NTHealthType).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.isNTHealth === 'YES' && form.values.type === 'DISTRICT'} my="lg">
        <Radio.Group label="Health Districts" {...form.getInputProps('district')}>
          {Object.entries(NTHealthDistrict).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update NT Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  nt: state.user.nt
});

const mapDispatchToProps = {
  setNT: setNTRoutine
};

export const OnboardingNT = connect(mapStateToProps, mapDispatchToProps)(OnboardingNTComponent);
