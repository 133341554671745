import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'enums/routing/routes';
import { Footer, Group, Container, createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%'
  },
  link: {
    color: theme.colors.gray[7],
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  copyright: {
    color: theme.colors.gray[5]
  },
  separator: {
    fontSize: '24px',
    color: theme.colors.gray[7],
    '&:after': {
      content: '"\\2022"'
    }
  }
}));

export const PageFooter = () => {
  const { classes } = useStyles();

  return (
    <Footer height={42}>
      <Container className={classes.container} size="xl" display={{ base: 'none', sm: 'flex' }}>
        <Group noWrap>
          <a className={classes.link} href="https://insideout-institute.helpscoutdocs.com/">
            Help &amp; Support
          </a>
          <div className={classes.separator} />
          <Link className={classes.link} to={Routes.TermsAndConditions}>
            Terms &amp; Conditions
          </Link>
          <div className={classes.separator} />
          <Link className={classes.link} to={Routes.PrivacyPolicy}>
            Privacy Policy
          </Link>
        </Group>
        <div className={classes.copyright}>&copy; 2023 InsideOut </div>
      </Container>
    </Footer>
  );
};
