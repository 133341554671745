import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';

export const updateEventEnrolments = ({
  eventId,
  data
}: {
  eventId: string;
  data: {
    enrolments: Array<{
      id: string;
      priority: number | null;
      archived: boolean;
    }>;
  };
}): Promise<{ data: any }> => {
  return api.patch(`/event/${eventId}/enrolments`, data);
};

type UseUpdateEventEnrolmentOptions = {
  mutationConfig?: MutationConfig<typeof updateEventEnrolments>;
};

export const useUpdateEventEnrolment = ({
  mutationConfig
}: UseUpdateEventEnrolmentOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: updateEventEnrolments
  });
};
