import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { EventEnrolmentDto } from 'types/api/event';

class EventEnrolmentService {
  async checkEnrolmentForEvent(eventId: string, email: string): Promise<EventEnrolmentDto | null> {
    const { data } = await axiosAdapter.doGet<AppResponse<EventEnrolmentDto | null>>({
      url: `/event-enrolment/${eventId}/check-enrolment`,
      queryParams: {
        email
      }
    });

    return data.data;
  }

  async getAllForEvent(eventId: string): Promise<EventEnrolmentDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<EventEnrolmentDto[]>>({
      url: `/event-enrolment/${eventId}`
    });
    return data.data;
  }

  async markAttendance(eventId: string, instanceId: string, values: { [id: string]: boolean }): Promise<EventEnrolmentDto[]> {
    const { data } = await axiosAdapter.doPut<AppResponse<EventEnrolmentDto[]>>({
      url: `/event-enrolment/attendance/${eventId}`,
      payload: {
        instanceId,
        values
      }
    });
    return data.data;
  }
}

export const eventEnrolmentService = new EventEnrolmentService();
