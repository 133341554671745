import { AuthInfoManager, Tokens } from '../types/api/auth';

class SessionStorageService implements AuthInfoManager {
  #accessToken = 'accessToken';

  #refreshToken = 'refreshToken';

  get(key: string) {
    return sessionStorage.getItem(key);
  }

  set(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  setAccessToken(accessToken: string) {
    this.set(this.#accessToken, accessToken);
  }

  setRefreshToken(refreshToken: string) {
    this.set(this.#refreshToken, refreshToken);
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
  }

  getTokens(): Tokens {
    const accessToken = this.get(this.#accessToken);
    const refreshToken = this.get(this.#refreshToken);
    return {
      accessToken,
      refreshToken
    };
  }

  logout(): void {
    this.remove(this.#accessToken);
    this.remove(this.#refreshToken);
  }

  setTokens(tokens: Tokens): void {
    this.setAccessToken(tokens.accessToken);
    this.setRefreshToken(tokens.refreshToken);
  }

  isAuthenticated(): boolean {
    const tokens = this.getTokens();
    return Boolean(tokens.accessToken && tokens.refreshToken);
  }
}

export const sessionStorageService = new SessionStorageService();
