import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserSA } from 'types/state';
import { SAHealthLHN, SAHealthType } from 'enums/user-info';

type Props = {
  sa: UserSA;
};

export const DisplaySA: React.FC<Props> = ({ sa }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {sa.isSAHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for SA Health.
      </Text>
      {sa.isSAHealth && (
        <Text>
          <Text span fw="bold">
            Where in SA Health:{' '}
          </Text>
          {SAHealthType[sa.type]}
        </Text>
      )}
      {sa.type === 'LHN' && (
        <Text>
          <Text span fw="bold">
            Which LHN:{' '}
          </Text>
          {SAHealthLHN[sa.lhn]}
        </Text>
      )}
    </Box>
  );
};
