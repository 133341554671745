/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { Routine } from 'redux-saga-routines';
import { CourseEnrolmentState } from 'types/state';
import {
  editEnrolment,
  fetchCourseEnrolment,
  fetchUserEnrolments,
  progressCourseTask
} from './routines';

const initialState: CourseEnrolmentState = {
  enrolments: {},
  userEnrolments: {},
  isLoading: false,
  error: undefined
};

export const courseEnrolmentReducer = produce(
  (state: CourseEnrolmentState, { type, payload }: Routine<any> = {}) => {
    switch (type) {
      case fetchCourseEnrolment.TRIGGER:
      case progressCourseTask.TRIGGER:
      case editEnrolment.TRIGGER:
        state.isLoading = true;
        state.error = undefined;
        break;
      case fetchCourseEnrolment.SUCCESS:
        state.isLoading = false;
        state.enrolments[payload.id] = payload;
        break;
      case fetchUserEnrolments.SUCCESS:
        const userEnrolmentIds: string[] = [];

        payload.data.forEach(enrolment => {
          state.enrolments[enrolment.id] = enrolment;
          userEnrolmentIds.push(enrolment.id);
        });

        state.userEnrolments[payload.id] = userEnrolmentIds;
        break;
      case progressCourseTask.SUCCESS: {
        const { enrolmentId, taskId } = payload;
        state.isLoading = false;
        const task = state.enrolments[enrolmentId].tasks.find(
          t => t.id === taskId
        );
        task.status = 'COMPLETE';
        break;
      }
      case editEnrolment.SUCCESS:
        state.isLoading = false;
        state.enrolments[payload.id].expiresAt = payload.data.expiresAt;
        state.enrolments[payload.id].completed = payload.data.completed;
        break;
      case fetchCourseEnrolment.FAILURE:
      case progressCourseTask.FAILURE:
      case editEnrolment.FAILURE:
        state.isLoading = false;
        state.error = payload;
      // no default
    }
  },
  initialState
);
