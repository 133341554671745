import { ActionIcon, Anchor, Group, Tooltip } from '@mantine/core';
import {
  IconArchive,
  IconClipboardText,
  IconNumbers
} from '@tabler/icons-react';
import { Routes } from 'enums';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EventEnrolmentsPriorityContext } from '../contexts/event-enrolments-priority';

interface UnprioritisedEOIActionProps {
  enrolmentId: string;
  userId: string;
}

export const UnprioritisedEOIAction = ({
  enrolmentId,
  userId
}: UnprioritisedEOIActionProps) => {
  const { handleArchive, handlePrioritise } = useContext(
    EventEnrolmentsPriorityContext
  );

  return (
    <Group spacing="xs">
      <Tooltip label="Prioritise" withArrow>
        <ActionIcon
          variant="light"
          color="green"
          title="Prioritise"
          onClick={() => handlePrioritise(enrolmentId)}
        >
          <IconNumbers />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Archive" withArrow>
        <ActionIcon
          variant="light"
          color="red"
          title="Archive"
          onClick={() => handleArchive('unprioritised', enrolmentId)}
        >
          <IconArchive />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="EOI Form" withArrow>
        <Anchor
          component={Link}
          to={`${Routes.ExpressionOfInterestViewer}/${userId}`}
        >
          <ActionIcon variant="light" color="blue" title="EOI Form">
            <IconClipboardText />
          </ActionIcon>
        </Anchor>
      </Tooltip>
    </Group>
  );
};
