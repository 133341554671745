import { Grid, Stack, Title } from '@mantine/core';
import React from 'react';
import { useEventEnrolmentCompletions } from '../api/get-event-enrolment-completions';
import { EventEnrolmentTask } from './enrolment-task';

interface EventEnrolmentCompletionDetailProps {
  enrolmentId: string;
}

export const EventEnrolmentCompletionDetail = ({
  enrolmentId
}: EventEnrolmentCompletionDetailProps) => {
  const eventEnrolmentCompletionsQuery = useEventEnrolmentCompletions({
    enrolmentId,
    queryConfig: {
      enabled: !!enrolmentId
    }
  });

  if (
    eventEnrolmentCompletionsQuery.isLoading ||
    !eventEnrolmentCompletionsQuery.data
  )
    return null;

  return (
    <Stack spacing="2.5rem">
      <Stack>
        <Title order={3}>Pre-Requisite Learning</Title>
        {eventEnrolmentCompletionsQuery.data.data.BEFORE.map(task => (
          <EventEnrolmentTask
            type={task.type}
            title={task.title}
            completed={task.completed}
          />
        ))}
      </Stack>
      <Stack>
        <Title order={3}>Pre-Event Surveys</Title>
        {eventEnrolmentCompletionsQuery.data.data.IMMEDIATELY_BEFORE.map(
          task => (
            <EventEnrolmentTask
              type={task.type}
              title={task.title}
              completed={task.completed}
            />
          )
        )}
      </Stack>
      <Stack>
        <Title order={3}>Post-Event Surveys</Title>
        {eventEnrolmentCompletionsQuery.data.data.AFTER.map(task => (
          <EventEnrolmentTask
            type={task.type}
            title={task.title}
            completed={task.completed}
          />
        ))}
      </Stack>
    </Stack>
  );
};
