/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { Routine } from 'redux-saga-routines';
import { EventEnrolmentDto } from 'types/api/event';
import { EventEnrolmentState } from 'types/state';
import { addParticipant, checkEnrolmentForEvent, fetchEnrolmentsForEvent, markAttendance, setEnrolmentStatus } from './routines';

const initialState: EventEnrolmentState = {
  enrolments: {},
  enrolmentLists: {},
  isLoading: false,
  error: undefined
};

export const eventEnrolmentReducer = produce((state: EventEnrolmentState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case fetchEnrolmentsForEvent.TRIGGER:
    case checkEnrolmentForEvent.TRIGGER:
    case setEnrolmentStatus.TRIGGER:
    case addParticipant.TRIGGER:
    case markAttendance.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchEnrolmentsForEvent.SUCCESS:
      state.isLoading = false;
      state.enrolmentLists[payload.id] = payload.data.map((e: EventEnrolmentDto) => e.id);
      payload.data.forEach((e: EventEnrolmentDto) => {
        state.enrolments[e.id] = e;
      });
      break;
    case checkEnrolmentForEvent.SUCCESS:
      state.isLoading = false;

      if (payload.data) {
        const eventEnrolmentList = state.enrolmentLists[payload.eventId] ?? [];
        state.enrolmentLists[payload.eventId] = [...eventEnrolmentList, payload.data.id];
        state.enrolments[payload.data.id] = payload.data;
      }

      break;
    case setEnrolmentStatus.SUCCESS:
      state.isLoading = false;
      state.enrolments[payload.id] = payload;
      break;
    case addParticipant.SUCCESS: {
      state.isLoading = false;
      state.enrolmentLists[payload.eventId].push(payload.enrolment.id);
      state.enrolments[payload.enrolment.id] = payload.enrolment;
      break;
    }
    case markAttendance.SUCCESS:
      state.isLoading = false;
      payload.forEach((e: EventEnrolmentDto) => {
        state.enrolments[e.id].attendance = e.attendance;
      });
      break;
    case fetchEnrolmentsForEvent.FAILURE:
    case checkEnrolmentForEvent.FAILURE:
    case setEnrolmentStatus.FAILURE:
    case addParticipant.FAILURE:
    case markAttendance.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
