import { AxiosError } from 'axios';
import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { certificateService } from 'service/certificate';
import { getEventCertificate, getCourseCertificate } from './routines';

function* getEventCertificateHandler({ payload: id }: Action<string>) {
  try {
    const blob = yield call(certificateService.eventCertificate, id);
    yield put(getEventCertificate.success(id));
    window.open(URL.createObjectURL(blob));
  } catch (e) {
    if (e instanceof AxiosError) yield put(getEventCertificate.failure({ id, error: e.message }));
    else yield put(getEventCertificate.failure({ id, error: 'Unknown error occurred.' }));
  }
}

function* getCourseCertificateHandler({ payload: id }: Action<string>) {
  try {
    const blob = yield call(certificateService.courseCertificate, id);
    yield put(getCourseCertificate.success(id));
    window.open(URL.createObjectURL(blob));
  } catch (e) {
    if (e instanceof AxiosError) yield put(getCourseCertificate.failure({ id, error: e.message }));
    else yield put(getCourseCertificate.failure({ id, error: 'Unknown error occurred.' }));
  }
}

export function* certificateSaga() {
  yield all([takeEvery(getEventCertificate.TRIGGER, getEventCertificateHandler), takeEvery(getCourseCertificate.TRIGGER, getCourseCertificateHandler)]);
}
