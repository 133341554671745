import React from 'react';
import { Container, Text, Title, List } from '@mantine/core';

export const TermsAndConditions: React.FC = () => {
  return (
    <Container size="md">
      <Title order={1} mb="lg">
        Terms of Use
      </Title>
      <Text>
        The InsideOut Institute eLearning Platform (&ldquo;the Platform&rdquo;) is owned and operated by InsideOut Institute on behalf of Sydney Local Health District and the University of Sydney (&ldquo;we,&rdquo;
        &ldquo;us,&rdquo; or &ldquo;our&rdquo;). These Terms of Use (&ldquo;Terms&rdquo;) govern your access to and use of the Platform. Your use of this website is deemed to be your acceptance of these Terms of Use and
        our Privacy Policy.
      </Text>
      <Text>
        We may change these Terms of Use at any time, with or without notice to you. Your continued use of the website constitutes your binding acceptance of such changes. You should consider any changes that we notify
        to you and regularly check these Terms of Use for any changes. This document was last updated on 12 July 2023.
      </Text>

      <Title order={3} my="lg">
        1. Permitted Use and Restrictions
      </Title>
      <List type="ordered">
        <List.Item>
          You must:
          <List type="ordered">
            <List.Item>Use the website in accordance with these Terms of Use, our reasonable directions, our content requirements, and all applicable laws; and</List.Item>
            <List.Item>
              Ensure that no data, information, or other content posted, entered, or uploaded onto the website is inappropriate or may cause offense, distress, menace, or harassment (including profanities or indecent,
              obscene, racist, sexist, defamatory, or other discriminatory content).
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          You must not:
          <List type="ordered">
            <List.Item>Use the website, including any information contained on the website, for any commercial purpose;</List.Item>
            <List.Item>
              Copy, reproduce, store, transmit, publish, modify, translate into any language, disassemble, reverse engineer, reverse assemble, or otherwise create derivative works from any part of the website or its
              contents, except and only to the extent that such activity is expressly permitted by these Terms of Use or applicable laws;
            </List.Item>
            <List.Item>Access or use the website in a manner that would expose us to the risk of any claims or other legal or administrative actions;</List.Item>
            <List.Item>Use the website to distribute any form of malicious, destructive, or harmful code (including viruses, Trojan horses, and worms) or any instructions activating such codes;</List.Item>
            <List.Item>
              Use the website in a manner that interferes, or threatens to interfere, with the efficiency or security of the website (or the servers or networks connected to the website) or the provision of the website
              by us to another person; or
            </List.Item>
            <List.Item>Use the website in a manner that infringes any other person&apos;s intellectual property rights, rights under applicable privacy laws, or confidential information.</List.Item>
            <List.Item>
              We may investigate your use of the website to determine if a violation of these Terms of Use has occurred or to comply with applicable laws. If our investigation reveals that your use of the website has
              violated these Terms of Use, we may take any action we reasonably consider appropriate.
            </List.Item>
          </List>
        </List.Item>
      </List>

      <Title order={3} my="lg">
        2. Intellectual Property
      </Title>
      <List type="ordered">
        <List.Item>
          All intellectual property rights, including copyrights, trademarks, trade secrets, and any other proprietary rights, in the eLearning courses, modules, materials, and content available on the Platform
          (&ldquo;Content&rdquo;) belong to us, unless otherwise indicated.
        </List.Item>
        <List.Item>We reserve all rights that are not expressly granted to you under these Terms of Use.</List.Item>
        <List.Item>You agree not to copy, modify, distribute, display, perform, or create derivative works of the Content unless expressly permitted by InsideOut Institute.</List.Item>
      </List>

      <Title order={3} my="lg">
        3. Warranties
      </Title>
      <Text>
        The website is provided to you on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. You agree that your use of the website is at your sole risk. Without limiting clause 11 below, and only to the extent
        legally permissible, we do not warrant that:
      </Text>
      <List type="ordered">
        <List.Item>The website or its contents will meet your specific needs or requirements;</List.Item>
        <List.Item>The website or its contents will be uninterrupted, timely, secure, or error-free;</List.Item>
        <List.Item>The website or its contents will be of merchantable quality or fit for any purpose;</List.Item>
        <List.Item>The quality of any products, services, information, or other material you obtain or purchase through the website will meet your expectations; or</List.Item>
        <List.Item>Errors in the website or its contents will be corrected.</List.Item>
      </List>

      <Title order={3} my="lg">
        4. Privacy
      </Title>
      <List type="ordered">
        <List.Item>
          InsideOut collects information on this website for the purpose of providing information and resources in relation to the management of eating disorders to the public and health professionals.
        </List.Item>
        <List.Item>
          InsideOut will keep information collected on this website strictly confidential and will not disclose it to anyone else (except, in the unlikely event, that it is required to disclose it by law).
        </List.Item>
        <List.Item>We may aggregate deidentified personal information collected through the website for research and reporting purposes.</List.Item>
        <List.Item>
          For more information about how InsideOut handles personal information about you, including your rights to access and correct your personal information, and privacy complaint handling procedures, please see our
          Privacy Policy.
        </List.Item>
        <List.Item>By accessing and using the website, you consent to us handling your personal information in the manner set out in these Terms of Use.</List.Item>
      </List>

      <Title order={3} my="lg">
        5. User Responsibilities
      </Title>
      <List type="ordered">
        <List.Item>
          Registration: In order to access certain features of the Platform, you will be required to create an account and provide accurate and complete information. You are responsible for maintaining the
          confidentiality of your account credentials and for any activities conducted through your account.
        </List.Item>
        <List.Item>
          User Content: You may have the opportunity to submit or contribute content to the Platform, including comments, feedback, and suggestions (&ldquo;User Content&rdquo;). By submitting User Content, you grant
          InsideOut Institute a non-exclusive, worldwide, royalty-free, and perpetual license to use, modify, reproduce, distribute, and display the User Content in connection with the Platform.
        </List.Item>
      </List>

      <Title order={3} my="lg">
        6. Third Party Content
      </Title>
      <List type="ordered">
        <List.Item>
          We take reasonable care in compiling the website and its contents. However, the contents may not always reflect the most recent developments in medical knowledge or science. We encourage you to consult other
          sources to confirm the information you receive from the website.
        </List.Item>
        <List.Item>
          Links to other websites are provided for your convenience only. We neither endorse nor accept responsibility for the accuracy or completeness of the information you receive from any other websites. If you use
          or rely on a third-party website and its content, you do so solely at your own risk. You acknowledge that those third-party websites will be governed by separate terms of use and privacy policies.
        </List.Item>
      </List>

      <Title order={3} my="lg">
        7. Modifications and Termination
      </Title>
      <List type="ordered">
        <List.Item>
          Platform Changes: We may modify, change, or update the website at any time, with or without notice. In the event of any such modification, change, or update, we do not represent that any features or
          functionalities of the website will remain.
        </List.Item>
        <List.Item>
          Termination: Without limiting our rights under these Terms of Use or at law, we may discontinue the website (including ceasing to provide administrative and technical support for the website) in our sole
          discretion and at any time. In this event, we may direct you to delete all copies of the website and related documentation, which are in your possession or under your control.
        </List.Item>
      </List>

      <Title order={3} my="lg">
        8. Liability
      </Title>
      <List type="ordered">
        <List.Item>
          {' '}
          For all matters connected to:
          <List type="ordered">
            <List.Item>Your use or inability to use the website;</List.Item>
            <List.Item>The contents of the website or any part of the website; or</List.Item>
            <List.Item>Injury to you or a third party as a result of your use of the website,</List.Item>
          </List>
          to the maximum extent permitted by law, we exclude all warranties, rights, remedies, and liability to you or a third party, whether arising under the law (including rules of common law, principles of equity,
          statutes, and regulations), tort (including negligence), breach of contract, or otherwise.
        </List.Item>
        <List.Item>
          Despite the foregoing, nothing in these Terms of Use excludes, restricts, or modifies any consumer guarantee, right, or remedy conferred on you by the Australian Consumer Law (being Schedule 2 of the
          Competition and Consumer Act 2010 (Cth)) or any other applicable law that cannot be excluded, restricted, or modified by agreement (Non-Excludable Guarantee).
        </List.Item>
        <List.Item>
          To the maximum extent permitted by law, our liability for breach of a Non-Excludable Guarantee is limited, at our option, to either resupplying the program to you or paying the cost of re-supplying the program
          to you.
        </List.Item>
        <List.Item>You are responsible for any information or User Content you post, enter, or upload onto the website, or which is posted, entered, or uploaded by another person using your Login Details.</List.Item>
      </List>

      <Title order={3} my="lg">
        9. Release
      </Title>
      <List type="ordered">
        <List.Item>
          You hereby release us, our personnel, and subcontractors from any loss (including legal costs and expenses on a full indemnity basis) or liability you suffer arising out of, or in connection with, your use or
          inability to use the website or with respect to the contents of the website or any part of the website (including errors or omissions contained in the website).
        </List.Item>
      </List>

      <Title order={3} my="lg">
        10. Denial of Access
      </Title>
      <List type="ordered">
        <List.Item>
          We may at any time deny all or part of your access to the website where, in our reasonable opinion, you fail to comply with these Terms of Use or otherwise suspend or terminate your access to the website
          without being obliged to give you a reason or prior notice.
        </List.Item>
      </List>

      <Title order={3} my="lg">
        11. General
      </Title>
      <List type="ordered">
        <List.Item>
          These Terms of Use are governed by and must be construed in accordance with the laws of New South Wales, Australia, and you irrevocably and unconditionally submit to the non-exclusive jurisdiction of the courts
          of New South Wales, Australia, and all courts which have jurisdiction to hear appeals from those courts.
        </List.Item>
        <List.Item>
          We make no representation that the website (including any content on the website) complies with the laws (including intellectual property laws) of any jurisdiction outside of the State of New South Wales,
          Australia. You are solely responsible for complying with the law of the jurisdiction in which you access or use the website.
        </List.Item>
        <List.Item>
          If a provision in these Terms of Use is wholly or partly void, illegal, or unenforceable in any relevant jurisdiction, that provision or part must, to that extent, be treated as deleted from these Terms of Use
          for the purposes of that jurisdiction. This does not affect the validity or enforceability of the remainder of the provision or any other provision of these Terms of Use.
        </List.Item>
      </List>
    </Container>
  );
};
