import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, TextInput, Textarea, NumberInput, Group, Button, Text, FileButton, SimpleGrid, Switch } from '@mantine/core';
import { IconCurrencyDollar } from '@tabler/icons-react';
import { useForm, isNotEmpty } from '@mantine/form';
import { updateCourse as updateCourseRoutine, uploadSplash as uploadSplashRoutine } from 'store/course/routines';
import { CourseDto } from 'types/api/course';

type Props = {
  course: CourseDto;
  updateCourse: (dto: Partial<CourseDto>) => void;
  uploadSplash: (arg0: { courseId: string; file: File }) => void;
  isLoading: boolean;
};

const MetadataComponent: React.FC<Props> = ({ course, updateCourse, uploadSplash, isLoading }) => {
  const [file, setFile] = useState<File>(null);

  const form = useForm<Partial<CourseDto>>({
    initialValues: {
      title: course.title,
      desc: course.desc,
      hours: course.hours,
      duration: course.duration,
      purchasePrice: course.purchasePrice,
      renewalDuration: course.renewalDuration,
      renewalPrice: course.renewalPrice,
      hasCertificate: course.hasCertificate
    },
    validate: {
      title: isNotEmpty('This field is required.'),
      desc: isNotEmpty('This field is required.'),
      hours: isNotEmpty('This field is required.'),
      duration: isNotEmpty('This field is required.'),
      purchasePrice: isNotEmpty('This field is required.'),
      renewalDuration: isNotEmpty('This field is required.'),
      renewalPrice: isNotEmpty('This field is required.')
    },
    transformValues: values => ({
      ...values,
      id: course.id
    })
  });

  return (
    <Container size="md" my="xl">
      <form onSubmit={form.onSubmit(values => updateCourse(values))}>
        <TextInput my="md" label="Course title" {...form.getInputProps('title')} />

        <Textarea my="md" label="Course description" {...form.getInputProps('desc')} />

        <NumberInput my="md" rightSection={<Text>Hours</Text>} rightSectionWidth={50} label="Approximate time to complete" hideControls {...form.getInputProps('hours')} />

        <SimpleGrid cols={2} mb="md">
          <NumberInput label="Purchase price" icon={<IconCurrencyDollar />} hideControls {...form.getInputProps('purchasePrice')} />

          <NumberInput label="Access expires after" rightSection={<Text>Days</Text>} rightSectionWidth={50} hideControls {...form.getInputProps('duration')} />
        </SimpleGrid>

        <SimpleGrid cols={2} mb="md">
          <NumberInput label="Renewal price" icon={<IconCurrencyDollar />} hideControls {...form.getInputProps('renewalPrice')} />

          <NumberInput label="Renewal lasts for" rightSection={<Text>Days</Text>} rightSectionWidth={50} hideControls {...form.getInputProps('renewalDuration')} />
        </SimpleGrid>

        <Switch my="xl" label="Course has certificate" labelPosition='left' {...form.getInputProps('hasCertificate', { type: 'checkbox' })} />

        <Group position="right" mt="xl">
          <Button type="submit" loading={isLoading}>
            Save Changes
          </Button>
        </Group>
      </form>

      <Group my="xl">
        <Text sx={{ flex: '1 !important' }}>{file ? file.name : 'Select a file to upload'}</Text>
        <FileButton onChange={setFile} accept="image/png,image/jpeg">
          {props => <Button {...props}>Choose New Splash Image</Button>}
        </FileButton>
        <Button
          disabled={file === null}
          onClick={() => {
            uploadSplash({ courseId: course.id, file });
            setFile(null);
          }}
        >
          Upload
        </Button>
      </Group>
    </Container>
  );
};

const mapDispatchToProps = {
  updateCourse: updateCourseRoutine,
  uploadSplash: uploadSplashRoutine
};

export const Metadata = connect(null, mapDispatchToProps)(MetadataComponent);
