import { Alert, Button, Group, LoadingOverlay, Table, Tabs, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { AdminRoutes, UserRoles } from 'enums';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchEvents as fetchEventsRoutine } from 'store/event/routines';
import { EventIndex, RootState } from 'types/state';

type Props = {
  role: UserRoles;
  events: EventIndex;
  isLoading: boolean;
  error?: string;
  fetchEvents: () => void;
};

const F2FEventListComponent: React.FC<Props> = ({ role, events, isLoading, error, fetchEvents }) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string | null>('past');

  const eventList = useMemo(() => {
    return Object.values(events).filter((event) => {
      const index = event.instances.length - 1; 

      if (index < 0) return true;

      if (activeTab === 'past') {
        return dayjs(event.instances[index].startsAt).unix() <= dayjs().unix();
      }

      if (activeTab === 'upcoming') {
        return dayjs(event.instances[index].startsAt).unix() >= dayjs().unix();
      }

      return true;
    }).sort((a, b) => {
      return dayjs(a.instances[0].startsAt).unix() - dayjs(b.instances[0].startsAt).unix()
    })
  }, [events, activeTab]);

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <Group position="right" my="xl">
        <Title sx={{ flex: '1 !important' }} order={2}>
          Events
        </Title>
        {role === UserRoles.IOI_ADMIN && (
          <Button component={Link} to={AdminRoutes.eventNew} leftIcon={<IconPlus />}>
            New Event
          </Button>
        )}
      </Group>

      {error && (
        <Alert title="Error fetching events" color="red" my="lg">
          {error}
        </Alert>
      )}
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List mb="sm">
          <Tabs.Tab value='past'>Past</Tabs.Tab>
          <Tabs.Tab value='upcoming'>Upcoming</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='past'>
          <Table>
            <thead>
              <tr>
                <th>Event Name</th>
              </tr>
            </thead>
            <tbody>
              {eventList.map(event => (
                <tr key={event.id} onClick={() => navigate(`${AdminRoutes.eventView}/${event.id}`)}>
                  <td>{event.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabs.Panel>
        <Tabs.Panel value='upcoming'>
          <Table>
            <thead>
              <tr>
                <th>Event Name</th>
              </tr>
            </thead>
            <tbody>
              {eventList.map(event => (
                <tr key={event.id} onClick={() => navigate(`${AdminRoutes.eventView}/${event.id}`)}>
                  <td>{event.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabs.Panel>
      </Tabs>

      {/* <Table>
        <thead>
          <tr>
            <th>Event Name</th>
          </tr>
        </thead>
        <tbody>
          {eventList.map(event => (
            <tr key={event.id} onClick={() => navigate(`${AdminRoutes.eventView}/${event.id}`)}>
              <td>{event.name}</td>
            </tr>
          ))}
        </tbody>
      </Table> */}

      <LoadingOverlay visible={isLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  role: state.user.role,
  events: state.event.events,
  isLoading: state.event.isLoading,
  error: state.event.error
});

const mapDispatchToProps = {
  fetchEvents: fetchEventsRoutine
};

export const F2FEventList = connect(mapStateToProps, mapDispatchToProps)(F2FEventListComponent);
