import React from 'react';
import { Alert, Stack, TextInput, NumberInput, Radio, Checkbox, Group, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { SurveyDto } from 'types/api/survey';

type Props = {
  survey: SurveyDto;
};

export const SurveyPreview: React.FC<Props> = ({ survey }) => {
  const initialValues = {};
  const validate = {};
  survey.questions.forEach(question => {
    initialValues[question.id] = '';
    switch (question.type) {
      case 'TEXT':
      case 'RADIO':
      case 'LIKERT':
        validate[question.id] = (value: string) => (value.length > 0 ? null : 'This value is required.');
        break;
      case 'CHECKBOX':
        validate[question.id] = (value: string[]) => (value.length > 0 ? null : 'This value is required.');
        break;
      case 'NUMBER':
        validate[question.id] = (value: number) => (typeof value === 'number' ? null : 'This value is required.');
        break;
      default:
        break;
    }
  });

  const form = useForm({
    initialValues,
    validate
  });

  return (
    <form onSubmit={form.onSubmit(values => console.log(values))}>
      <Stack>
        {survey.questions.map(question => {
          switch (question.type) {
            case 'TEXT':
              return <TextInput key={question.id} label={question.text} {...form.getInputProps(question.id)} />;
            case 'NUMBER':
              return <NumberInput key={question.id} label={question.text} {...form.getInputProps(question.id)} />;
            case 'RADIO':
              return (
                <div key={question.id}>
                  <Radio.Group label={question.text} {...form.getInputProps(question.id)}>
                    {question.options.map(option => (
                      <Radio label={option.text} value={option.id} key={option.id} />
                    ))}
                    {question.isOther && <Radio label="Other" value="OTHER" />}
                  </Radio.Group>
                  {form.values[question.id] === 'OTHER' && <TextInput label="Please specify" />}
                </div>
              );
            case 'CHECKBOX':
              return (
                <div key={question.id}>
                  <Checkbox.Group label={question.text} {...form.getInputProps(question.id)}>
                    {question.options.map(option => (
                      <Checkbox label={option.text} value={option.id} key={option.id} />
                    ))}
                    {question.isOther && <Checkbox label="Other" value="OTHER" />}
                  </Checkbox.Group>
                  {form.values[question.id].includes('OTHER') && <TextInput label="Please Specify" />}
                </div>
              );
            case 'LIKERT':
              return (
                <Radio.Group key={question.id} label={question.text} {...form.getInputProps(question.id)}>
                  <Group>
                    {question.options.map(option => (
                      <Radio label={option.text} value={option.id} key={option.id} />
                    ))}
                  </Group>
                </Radio.Group>
              );
            case 'COMMENT':
              return <div key={question.id} dangerouslySetInnerHTML={{ __html: question.text }} />;
            default:
              return (
                <Alert color="red" title="Error" key={question.id}>
                  Unknown question type.
                </Alert>
              );
          }
        })}
        <Group position="right">
          <Button type="submit">Submit</Button>
        </Group>
      </Stack>
    </form>
  );
};
