import { AppResponse } from 'types/api';
import { CourseDto, CreateCourseTaskDto, UpdateCourseTaskDto, ReorderCourseTaskDto } from 'types/api/course';
import { axiosAdapter } from 'service/axios-adapter';
import { CreateCourseDto } from 'types/api/course/create.course.dto';

class CourseService {
  async getAll(): Promise<CourseDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<CourseDto[]>>({
      url: '/course'
    });
    return data.data;
  }

  async getOne(id: string): Promise<CourseDto> {
    const { data } = await axiosAdapter.doGet<AppResponse<CourseDto>>({
      url: `/course/${id}`
    });
    return data.data;
  }

  async create(dto: CreateCourseDto): Promise<CourseDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<CourseDto>>({
      url: '/course',
      payload: dto
    });
    return data.data;
  }

  async update(dto: CourseDto): Promise<CourseDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<CourseDto>>({
      url: `/course/${dto.id}`,
      payload: dto
    });
    return data.data;
  }

  async createTask(
    courseId: string,
    dto: CreateCourseTaskDto
  ): Promise<CourseDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<CourseDto>>({
      url: `/course/${courseId}/tasks`,
      payload: dto
    });
    return data.data;
  }

  async updateTask(
    courseId: string,
    dto: UpdateCourseTaskDto
  ): Promise<CourseDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<CourseDto>>({
      url: `/course/${courseId}/tasks/${dto.id}`,
      payload: dto
    });
    return data.data;
  }

  async reorderTask(
    courseId: string,
    dto: ReorderCourseTaskDto
  ): Promise<CourseDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<CourseDto>>({
      url: `/course/${courseId}/tasks/reorder`,
      payload: dto
    });
    return data.data;
  }

  async removeTask(courseId: string, taskId: string): Promise<CourseDto> {
    const { data } = await axiosAdapter.doDelete<AppResponse<CourseDto>>({
      url: `/course/${courseId}/tasks/${taskId}`
    });
    return data.data;
  }

  async uploadSplash(id: string, file: File): Promise<CourseDto> {
    const payload = new FormData();
    payload.append('file', file, file.name);
    const { data } = await axiosAdapter.doPut<AppResponse<CourseDto>>({
      url: `/course/${id}/splash`,
      payload
    });
    return data.data;
  }
}

export const courseService = new CourseService();
