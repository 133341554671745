import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Group,
  Modal,
  Skeleton,
  Text,
  Title
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import {
  IconCalendar,
  IconCheck,
  IconCircle,
  IconLock,
  IconX
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { UserRoles } from 'enums';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  editEnrolment as editEnrolmentRoutine,
  fetchCourseEnrolment as fetchCourseEnrolmentRoutine,
  progressCourseTask as progressCourseTaskRoutine
} from 'store/course-enrolment/routines';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { CourseEnrolmentDto, CourseTaskDto } from 'types/api/course';
import { CourseIndex, RootState, UserIndex } from 'types/state';

type Props = {
  enrolments: Record<string, CourseEnrolmentDto>;
  courses: CourseIndex;
  learners: UserIndex;
  fetchCourses: () => void;
  fetchCourseEnrolment: (id: string) => void;
  editEnrolment: (arg0: {
    id: string;
    data: Partial<CourseEnrolmentDto>;
  }) => void;
  fetchUsersByRole: (role: UserRoles) => void;
  progressTask: (arg0: {
    enrolmentId: string;
    userId: string;
    taskId: string;
  }) => void;
};

const AdminCourseEnrolmentViewComponent: React.FC<Props> = ({
  enrolments,
  courses,
  learners,
  fetchCourseEnrolment,
  fetchCourses,
  editEnrolment,
  fetchUsersByRole,
  progressTask
}) => {
  const { id } = useParams();

  const [enrolmentModal, setEnrolmentModal] = useState<boolean>(false);
  const [expiryModal, setExpiryModal] = useState<boolean>(false);
  const [taskModal, setTaskModal] = useState<CourseTaskDto>(null);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);

  const enrolment = enrolments[id];
  const course = courses[enrolment?.courseId];
  const user = learners[enrolment?.userId];

  useEffect(() => {
    fetchCourseEnrolment(id);
  }, [id]);

  useEffect(() => {
    fetchUsersByRole(UserRoles.LEARNER);
    fetchCourses();
  }, []);

  useEffect(() => {
    if (enrolment?.expiresAt) setExpiryDate(new Date(enrolment.expiresAt));
  }, [enrolment?.expiresAt]);

  if (!enrolment) return <Skeleton h="8rem" />;

  return (
    <Container size="md">
      <Title order={2} my="lg">
        Course Enrolment Details
      </Title>
      {course ? <Text>{course.title}</Text> : <Skeleton h="2rem" />}
      {user ? (
        <Text mb="lg">
          {user.basic?.firstName} {user.basic?.lastName} ({user.email})
        </Text>
      ) : (
        <Skeleton h="2rem" />
      )}

      <Divider />

      <Group my="md">
        <ActionIcon
          color={enrolment.completed ? 'green' : 'red'}
          radius="lg"
          variant="filled"
          onClick={() => setEnrolmentModal(true)}
        >
          {enrolment.completed ? <IconCheck /> : <IconX />}
        </ActionIcon>
        <Text>
          Course is {enrolment.completed ? 'complete' : 'incomplete'}.
        </Text>
      </Group>
      <Modal
        opened={enrolmentModal}
        centered
        title="Override Course Completion?"
        onClose={() => setEnrolmentModal(false)}
      >
        <Group>
          <Button
            disabled={Boolean(enrolment.completed)}
            onClick={() => {
              editEnrolment({ id, data: { completed: new Date() } });
              setEnrolmentModal(false);
            }}
          >
            Mark as Completed
          </Button>
          <Button
            disabled={!enrolment.completed}
            onClick={() => {
              editEnrolment({ id, data: { completed: null } });
              setEnrolmentModal(false);
            }}
          >
            Mark is incomplete
          </Button>
        </Group>
      </Modal>

      <Group my="md">
        <ActionIcon
          color={enrolment.isActive ? 'green' : 'red'}
          radius="lg"
          variant="filled"
          onClick={() => setExpiryModal(true)}
        >
          <IconCalendar />
        </ActionIcon>
        <Text>
          Enrolment {enrolment.isActive ? 'expires' : 'expired'} on{' '}
          {dayjs(enrolment.expiresAt).format('DD MMM YYYY')}
        </Text>
      </Group>
      <Modal
        centered
        title="Override expiry date"
        opened={expiryModal}
        onClose={() => setExpiryModal(false)}
      >
        <DatePicker value={expiryDate} onChange={setExpiryDate} />
        <Button
          onClick={() => {
            editEnrolment({ id, data: { expiresAt: expiryDate } });
            setExpiryModal(false);
          }}
        >
          Save
        </Button>
      </Modal>

      <Divider />

      {enrolment.tasks.map(task => (
        <Group key={task.id} my="sm">
          {task.status === 'COMPLETE' && (
            <ActionIcon
              variant="filled"
              radius="lg"
              color="green"
              onClick={() => setTaskModal(task)}
            >
              <IconCheck />
            </ActionIcon>
          )}
          {task.status === 'INCOMPLETE' && (
            <ActionIcon
              variant="filled"
              radius="lg"
              color="yellow"
              onClick={() =>
                progressTask({
                  enrolmentId: id,
                  userId: user.id,
                  taskId: task.id
                })
              }
            >
              <IconCircle />
            </ActionIcon>
          )}
          {task.status === 'LOCKED' && (
            <ActionIcon
              variant="filled"
              radius="lg"
              color="gray"
              onClick={() =>
                progressTask({
                  enrolmentId: id,
                  userId: user.id,
                  taskId: task.id
                })
              }
            >
              <IconLock />
            </ActionIcon>
          )}
          <Text>{task.title}</Text>
        </Group>
      ))}
      <Modal
        title="Mark task as complete?"
        centered
        opened={Boolean(taskModal)}
        onClose={() => setTaskModal(null)}
      >
        <Group>
          <Button color="gray" onClick={() => setTaskModal(null)}>
            Cancel
          </Button>
          <Button
            color="red"
            disabled={taskModal?.status === 'COMPLETE'}
            onClick={() => {
              progressTask({
                enrolmentId: id,
                userId: user.id,
                taskId: taskModal.id
              });
              setTaskModal(null);
            }}
          >
            Mark as Complete
          </Button>
        </Group>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  enrolments: state.courseEnrolment.enrolments,
  courses: state.course.courses,
  learners: state.users.LEARNER
});

const mapDispatchToProps = {
  fetchCourseEnrolment: fetchCourseEnrolmentRoutine,
  fetchCourses: fetchCoursesRoutine,
  editEnrolment: editEnrolmentRoutine,
  fetchUsersByRole: fetchUsersByRoleRoutine,
  progressTask: progressCourseTaskRoutine
};

export const AdminCourseEnrolmentView = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCourseEnrolmentViewComponent);
