/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { UserUploadState } from 'types/state';
import { Routine } from 'redux-saga-routines';
import { fetchUserUploads, createUserUpload } from './routines';
import { UserUploadDto } from 'types/api/user-upload';

const initialState: UserUploadState = {
  isLoading: false,
  error: undefined,
  uploads: {}
};

export const userUploadReducer = produce((state: UserUploadState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case fetchUserUploads.TRIGGER:
    case createUserUpload.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchUserUploads.SUCCESS:
      state.isLoading = false;
      state.uploads = {};
      payload.forEach((upload: UserUploadDto) => {
        state.uploads[upload.taskId] = upload;
      });
      break;
    case createUserUpload.SUCCESS:
      state.isLoading = false;
      state.uploads[payload.taskId] = payload;
      break;
    case fetchUserUploads.FAILURE:
    case createUserUpload.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
