import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Text, Card, Group, Skeleton } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';
import { CourseTaskDto } from 'types/api/course';
import { SurveyDto } from 'types/api/survey';
import { Scorm } from 'types/backend';

type Props = {
  task: CourseTaskDto;
  onClick: () => void;
  survey: SurveyDto;
  scorm: Scorm;
};

export const TaskCard: React.FC<Props> = ({ task, onClick, survey, scorm }) => {
  return (
    <Draggable draggableId={task.id} index={task.sortIndex}>
      {(provided: DraggableProvided) => (
        <Card
          withBorder
          {...provided.draggableProps}
          ref={provided.innerRef}
          onClick={onClick}
        >
          <Group>
            <div {...provided.dragHandleProps}>
              <IconGripVertical />
            </div>
            <div>
              <Text>{task.title}</Text>
              {scorm && <Text>Module: {scorm.title}</Text>}

              {survey && <Text>Survey: {survey.title}</Text>}
            </div>
          </Group>
        </Card>
      )}
    </Draggable>
  );
};
