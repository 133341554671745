import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `scorm/${opName}`;

export const createScorm = createRoutine(getPrefix('create'));

export const reuploadScorm = createRoutine(getPrefix('reupload'));

export const fetchScorms = createRoutine(getPrefix('fetch-all'));

export const fetchScorm = createRoutine(getPrefix('fetch-one'));

export const openScorm = createRoutine(getPrefix('open'));

export const setScormData = createRoutine(getPrefix('set-data'));

export const uploadSplash = createRoutine(getPrefix('upload-splash'));

export const updateScormMetadata = createRoutine(getPrefix('update-metadata'));
