import React from 'react';
import { connect } from 'react-redux';
import { PasswordInput, Button, Alert } from '@mantine/core';
import { useForm, matchesField } from '@mantine/form';
import { RootState } from 'types/state';
import { updatePassword as updatePasswordRoutine } from 'store/user/routines';
import { isStrongPassword } from 'utilities/isStrongPassword';

type PasswordForm = {
  password: string;
  confirmPassword: string;
};

type Props = {
  isLoading: boolean;
  error?: string;
  updatePassword: (password: string) => void;
};

const SettingsComponent: React.FC<Props> = ({ isLoading, error, updatePassword }) => {
  const passwordForm = useForm<PasswordForm>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validate: {
      password: isStrongPassword,
      confirmPassword: matchesField('password', 'Passwords must match')
    }
  });

  return (
    <form onSubmit={passwordForm.onSubmit(values => updatePassword(values.password))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      <PasswordInput mb="md" label="New password" {...passwordForm.getInputProps('password')} />

      <PasswordInput mb="md" label="Confirm password" {...passwordForm.getInputProps('confirmPassword')} />

      <Button type="submit" loading={isLoading} disabled={!passwordForm.isDirty()}>
        Change Password
      </Button>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error
});

const mapDispatchToProps = {
  updatePassword: updatePasswordRoutine
};

export const Settings = connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
