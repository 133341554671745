import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { ReorderQuestionDto, SurveyDto, SurveyQuestionDto, UpdateSurveyDto } from 'types/api/survey';

class SurveyService {
  constructor() {
    this.create = this.create.bind(this);
    this.getAll = this.getAll.bind(this);
    this.getOne = this.getOne.bind(this);
  }

  async create(dto: SurveyDto): Promise<SurveyDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<SurveyDto>>({
      url: '/surveys',
      payload: dto
    });
    return data.data;
  }

  async update(dto: UpdateSurveyDto): Promise<SurveyDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<SurveyDto>>({
      url: `/surveys/${dto.id}`,
      payload: dto
    });
    return data.data;
  }

  async getAll(): Promise<SurveyDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<SurveyDto[]>>({
      url: '/surveys'
    });
    return data.data;
  }

  async getForEvent(eventId: string, userId?: string): Promise<SurveyDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<SurveyDto[]>>({
      url: `/surveys/event/${eventId}`,
      queryParams: {
        userId: userId ?? ''
      }
    });
    return data.data;
  }

  async getOne(id: string): Promise<SurveyDto> {
    const { data } = await axiosAdapter.doGet<AppResponse<SurveyDto>>({
      url: `/surveys/${id}`
    });
    return data.data;
  }

  async createQuestion(surveyId: string, dto: SurveyQuestionDto): Promise<SurveyDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<SurveyDto>>({
      url: `/surveys/${surveyId}/questions`,
      payload: dto
    });
    return data.data;
  }

  async updateQuestion(surveyId: string, dto: SurveyQuestionDto): Promise<SurveyDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<SurveyDto>>({
      url: `/surveys/${surveyId}/questions/${dto.id}`,
      payload: dto
    });
    return data.data;
  }

  async reorderQuestion(id: string, dto: ReorderQuestionDto): Promise<SurveyDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<SurveyDto>>({
      url: `/surveys/${id}/reorder`,
      payload: dto
    });
    return data.data;
  }

  async removeQuestion(surveyId: string, questionId: string): Promise<SurveyDto> {
    const { data } = await axiosAdapter.doDelete<AppResponse<SurveyDto>>({
      url: `/surveys/${surveyId}/questions/${questionId}`
    });
    return data.data;
  }
}

export const surveyService = new SurveyService();
