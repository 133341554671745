import { PrimaryHealthNetwork } from './primary.health.network.enum';
import { State } from './state.enum';

export const phnsByState: Record<
  keyof typeof State,
  (keyof typeof PrimaryHealthNetwork)[]
> = {
  NSW: [
    'NORTH_COAST',
    'HUNTER_NEW_ENGLAND_AND_CENTRAL_COAST',
    'WESTERN_NSW',
    'MURRUMBIDGEE',
    'SOUTH_EASTERN_NSW',
    'NEPEAN_BLUE_MOUNTAINS',
    'NORTHERN_SYDNEY',
    'WESTERN_SYDNEY',
    'SOUTH_WESTERN_SYDNEY',
    'CENTRAL_AND_EASTERN_SYDNEY',
    'SOUTH_EASTERN_SYDNEY'
  ],
  QLD: [],
  SA: [],
  TAS: [],
  VIC: [],
  WA: [],
  ACT: [],
  NT: []
};
