import React from 'react';
import { TextInput, Stack, Select, Group, Radio, Collapse, Grid, Divider } from '@mantine/core';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { IconClock, IconCalendar } from '@tabler/icons-react';
import { CreateEventDto, CreateEventInstanceDto, EventFormType, EventInstanceFormType } from 'types/api/event';
import { UseFormReturnType } from '@mantine/form';
import { EventType } from 'enums/event/event-type';
import { UserIndex } from 'types/state';

type Props<T extends EventFormType | EventInstanceFormType, J extends CreateEventDto | CreateEventInstanceDto> = {
  form: UseFormReturnType<T, (arg0: T) => J>;
  prefix?: string;
  values: EventInstanceFormType;
  trainers: UserIndex;
};

// Pardon the typescript generic nonsense. I want this component to work for
// both editing events and editing instances and this seems the easiest way.
// If you have a better idea please go ahead and improve it. ~ Patrick
export const InstanceForm = <T extends EventFormType | EventInstanceFormType, J extends CreateEventDto | CreateEventInstanceDto>({ form, prefix = '', values, trainers }: Props<T, J>) => {
  return (
    <Stack>
      <Grid align="center">
        <Grid.Col span={6}>
          <Radio.Group label="In person or online?" {...form.getInputProps(`${prefix}type`)}>
            <Group>
              {Object.entries(EventType).map(([value, label]) => (
                <Radio key={value} value={value} label={label} />
              ))}
            </Group>
          </Radio.Group>
        </Grid.Col>

        <Grid.Col span={6}>
          <Select label="Trainer" data={Object.values(trainers).map(trainer => ({ value: trainer.id, label: trainer.email }))} {...form.getInputProps(`${prefix}trainerId`)} />
        </Grid.Col>

        <Grid.Col span={4}>
          <DatePickerInput icon={<IconCalendar />} label="Date" {...form.getInputProps(`${prefix}date`)} />
        </Grid.Col>

        <Grid.Col span={4}>
          <TimeInput icon={<IconClock />} label="Start time" {...form.getInputProps(`${prefix}start`)} />
        </Grid.Col>

        <Grid.Col span={4}>
          <TimeInput icon={<IconClock />} label="Finish time" {...form.getInputProps(`${prefix}end`)} />
        </Grid.Col>
      </Grid>

      <Collapse in={values.type === 'ONLINE'}>
        <TextInput label="Meeting link" {...form.getInputProps(`${prefix}link`)} />
      </Collapse>

      <Collapse in={values.type === 'IN_PERSON'}>
        <Grid>
          <Grid.Col span={12}>
            <TextInput label="Address" {...form.getInputProps(`${prefix}address`)} />
          </Grid.Col>

          <Grid.Col span={9}>
            <TextInput label="City" {...form.getInputProps(`${prefix}city`)} />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput label="State" {...form.getInputProps(`${prefix}state`)} />
          </Grid.Col>
        </Grid>
      </Collapse>

      <Divider />
    </Stack>
  );
};
