import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `face-to-face/${opName}`;

export const fetchF2FTypes = createRoutine(getPrefix('fetch-all'));

export const fetchF2FType = createRoutine(getPrefix('fetch-one'));

export const createF2FType = createRoutine(getPrefix('create'));

export const updateF2FType = createRoutine(getPrefix('update'));

export const upsertEmail = createRoutine(getPrefix('create-email'));

export const deleteEmail = createRoutine(getPrefix('delete-email'));

export const createTask = createRoutine(getPrefix('create-task'));

export const updateTask = createRoutine(getPrefix('update-task'));

export const reorderTask = createRoutine(getPrefix('reorder-task'));

export const deleteTask = createRoutine(getPrefix('delete-task'));

export const uploadSplash = createRoutine(getPrefix('upload-splash'));
