import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { z } from 'zod';

export const exportF2FReportSchema = z.object({
  f2fs: z.string().array().optional(),
  events: z.string().array().optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional()
});

export type ExportF2FReportBody = z.infer<typeof exportF2FReportSchema>;

export const exportF2FReport = ({
  data
}: {
  data: ExportF2FReportBody;
}): Promise<Blob> => {
  return api.post('/report/f2f', data, {
    headers: { 'Accept': 'text/csv' },
    responseType: 'blob'
  });
};

type UseExportF2FReportOptions = {
  mutationConfig?: MutationConfig<typeof exportF2FReport>;
};

export const useExportF2FReport = ({
  mutationConfig
}: UseExportF2FReportOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: exportF2FReport
  });
};
