import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import { CreateSurveyQuestionAnswerIndex, SurveyQuestionDto } from 'types/api/survey';
import { Title, NumberInput } from '@mantine/core';

type Props = {
  form: UseFormReturnType<CreateSurveyQuestionAnswerIndex>;
  question: SurveyQuestionDto;
};

export const QuestionNumber: React.FC<Props> = ({ form, question }) => {
  return (
    <>
      <Title order={4} mt="lg" mb="md">
        {question.text}
      </Title>
      <NumberInput mb="4rem" maw="12rem" {...form.getInputProps(`${question.id}.valueNumber`)} />
    </>
  );
};
