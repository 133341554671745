import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { RootState, CourseIndex } from 'types/state';
import { Text, SimpleGrid, Card, Badge, Group, Progress, Image, Alert, Skeleton, Title } from '@mantine/core';
import { IconCircleCheck, IconCircle, IconX } from '@tabler/icons-react';
import { Routes } from 'enums/routing';

type Props = {
  courses: CourseIndex;
  isLoading: boolean;
  error?: string;
  fetchCourses: () => void;
};

export const EnrolmentsComponent: React.FC<Props> = ({ courses, isLoading, error, fetchCourses }) => {
  useEffect(() => {
    fetchCourses();
  }, []);

  if (error) {
    return (
      <Alert color="red" title="Error loading your events.">
        {error}
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <SimpleGrid cols={4} mt="md" mb="xl">
        <Skeleton height={150} />
        <Skeleton height={150} />
        <Skeleton height={150} />
        <Skeleton height={150} />
      </SimpleGrid>
    );
  }

  return (
    <>
      {!Object.values(courses).every(course => !course.enrolment) && (
        <Title order={2} my="md">
          Online Courses
        </Title>
      )}

      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: '62rem', cols: 3, spacing: 'md' },
          { maxWidth: '48rem', cols: 2, spacing: 'sm' },
          { maxWidth: '28rem', cols: 1, spacing: 'sm' }
        ]}
        mb="xl"
      >
        {Object.values(courses)
          .filter(course => course.enrolment)
          .map(course => {
            let taskCount = 0;
            let taskSum = 0;
            course.taskPages.forEach(page => {
              page.tasks.forEach(task => {
                taskCount += 1;
                if (task.status === 'COMPLETE') taskSum += 1;
              });
            });
            const percent = 100 * (taskSum / taskCount);

            return (
              <Card key={course.id} withBorder shadow="sm" component={Link} to={`${Routes.courseEnrolment}/${course.id}`}>
                <Card.Section>
                  <Image src={course.splash} height={160} />
                </Card.Section>

                <Group position="right" my="sm">
                  {course.enrolment.completed && (
                    <Badge color="green" leftSection={<IconCircleCheck size="1rem" />}>
                      Complete
                    </Badge>
                  )}
                  {!course.enrolment.completed && course.enrolment.isActive && (
                    <Badge color="blue" leftSection={<IconCircle size="1rem" />}>
                      In Progress
                    </Badge>
                  )}
                  {!course.enrolment.completed && !course.enrolment.isActive && (
                    <Badge color="yellow" leftSection={<IconX size="1rem" />}>
                      Access Expired
                    </Badge>
                  )}
                </Group>

                <Text fw="bold">{course.title}</Text>
                <Progress value={percent} />
                <Text>{percent.toFixed(0)}%</Text>
              </Card>
            );
          })}
      </SimpleGrid>
    </>
  );
};

const mapStateToProps = (root: RootState) => ({
  courses: root.course.courses,
  isLoading: root.course.isLoading,
  error: root.course.error
});

const mapDispatchToProps = {
  fetchCourses: fetchCoursesRoutine
};

export const Enrolments = connect(mapStateToProps, mapDispatchToProps)(EnrolmentsComponent);
