import React from 'react';
import { AppShell } from '@mantine/core';
import { Header } from 'components/Header';
import { PageFooter } from 'components/PageFooter';

type Props = {
  styles: any;
};

export const GuestLayout: React.FC<React.PropsWithChildren<Props>> = ({ styles, children }) => {
  return (
    <AppShell padding={0} header={<Header />} footer={<PageFooter />} styles={styles}>
      {children}
    </AppShell>
  );
};
