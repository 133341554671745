import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserNSW } from 'types/state';
import { NSWHealthLHD, NSWHealthType } from 'enums/user-info';

type Props = {
  nsw: UserNSW;
};

export const DisplayNSW: React.FC<Props> = ({ nsw }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {nsw.isNSWHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for NSW Health.
      </Text>
      {nsw.isNSWHealth && (
        <Text>
          <Text span fw="bold">
            Where in NSW Health:{' '}
          </Text>
          {NSWHealthType[nsw.type]}
        </Text>
      )}
      {nsw.type === 'LHD' && (
        <Text>
          <Text span fw="bold">
            Which LHD:{' '}
          </Text>
          {NSWHealthLHD[nsw.lhd]}
        </Text>
      )}
    </Box>
  );
};
