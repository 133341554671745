import { axiosAdapter } from 'service/axios-adapter';

class DataExportService {
  async getCourseExport(): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: '/data-export/course'
    });
    return res.data;
  }

  async getF2FExport(): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: '/data-export/f2f'
    });
    return res.data;
  }

  async getScormExport(): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: '/data-export/scorm'
    });
    return res.data;
  }

  async getUserExport(): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: '/data-export/user'
    });
    return res.data;
  }

  async getEventParticipantsExport(id: string): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: `/data-export/event/${id}/participants`
    });
    return res.data;
  }

  async getEventPreferencesExport(id: string): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: `/data-export/event/${id}/preferences`
    });
    return res.data;
  }
}

export const dataExportService = new DataExportService();
