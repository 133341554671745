import React from 'react';
import { connect } from 'react-redux';
import { useForm } from '@mantine/form';
import { Button, Group, Radio, Collapse, Alert } from '@mantine/core';
import { setSA as setSARoutine } from 'store/user/routines';
import { RootState, UserSA } from 'types/state';
import { SAHealthType, SAHealthLHN } from 'enums/user-info';

type SAForm = {
  isSAHealth: 'YES' | 'NO';
  type: keyof typeof SAHealthType;
  lhn: keyof typeof SAHealthLHN;
};

type Props = {
  isLoading: boolean;
  error?: string;
  sa: UserSA;
  setSA: (dto: UserSA) => void;
};

const OnboardingSAComponent: React.FC<Props> = ({ isLoading, error, sa, setSA }) => {
  const form = useForm<SAForm, (arg0: SAForm) => UserSA>({
    initialValues: sa
      ? {
          isSAHealth: sa.isSAHealth ? 'YES' : 'NO',
          type: sa.type,
          lhn: sa.lhn
        }
      : {
          isSAHealth: undefined,
          type: undefined,
          lhn: undefined
        },
    validate: {
      isSAHealth: value => (!value ? 'This field is required' : null),
      type: (value, values) => (!value && values.isSAHealth === 'YES' ? 'This field is required' : null),
      lhn: (value, values) => (!value && values.isSAHealth === 'YES' && values.type === 'LHN' ? 'This field is required' : null)
    },
    transformValues: values => ({
      isSAHealth: values.isSAHealth === 'YES',
      type: values.type,
      lhn: values.lhn
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setSA(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Do you work for SA Health?" {...form.getInputProps('isSAHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Collapse in={form.values.isSAHealth === 'YES'} my="lg">
        <Radio.Group label="Where in SA Health?" {...form.getInputProps('type')}>
          {Object.entries(SAHealthType).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.isSAHealth === 'YES' && form.values.type === 'LHN'} my="lg">
        <Radio.Group label="Local Health Networks" {...form.getInputProps('lhn')}>
          {Object.entries(SAHealthLHN).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update South Australia Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  sa: state.user.sa
});

const mapDispatchToProps = {
  setSA: setSARoutine
};

export const OnboardingSA = connect(mapStateToProps, mapDispatchToProps)(OnboardingSAComponent);
