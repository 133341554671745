import { ActionIcon, Anchor, Group, Tooltip } from '@mantine/core';
import { IconClipboardText, IconSquareArrowUp } from '@tabler/icons-react';
import { Routes } from 'enums';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EventEnrolmentsPriorityContext } from '../contexts/event-enrolments-priority';

interface ArchivedEOIActionProps {
  enrolmentId: string;
  userId: string;
}

export const ArchivedEOIAction = ({
  enrolmentId,
  userId
}: ArchivedEOIActionProps) => {
  const { handleUnarchive } = useContext(EventEnrolmentsPriorityContext);

  return (
    <Group spacing="xs">
      <Tooltip label="Unarchive" withArrow>
        <ActionIcon
          variant="light"
          color="green"
          title="Unarchive"
          onClick={() => handleUnarchive(enrolmentId)}
        >
          <IconSquareArrowUp />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="EOI Form" withArrow>
        <Anchor
          component={Link}
          to={`${Routes.ExpressionOfInterestViewer}/${userId}`}
        >
          <ActionIcon variant="light" color="blue" title="EOI Form">
            <IconClipboardText />
          </ActionIcon>
        </Anchor>
      </Tooltip>
    </Group>
  );
};
