import React from 'react';
import { CreateSurveyAnswerDto, CreateSurveyQuestionAnswerIndex, SurveyDto } from 'types/api/survey';
import { Title, Group, Button, Box } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { QuestionText } from './QuestionText';
import { QuestionNumber } from './QuestionNumber';
import { QuestionRadio } from './QuestionRadio';
import { QuestionCheckbox } from './QuestionCheckbox';
import { QuestionLikert } from './QuestionLikert';

type Props = {
  title: string;
  survey: SurveyDto;
  taskId: string;
  parentId: string;
  taskType: 'F2F' | 'COURSE';
  onSubmit: (arg0: CreateSurveyAnswerDto) => void;
};

export const SurveyViewer: React.FC<Props> = ({ title, survey, parentId, taskId, taskType, onSubmit }) => {
  const form = useForm<CreateSurveyQuestionAnswerIndex>({
    initialValues: survey.questions.reduce((obj, question) => {
      switch (question.type) {
        case 'TEXT':
          return { ...obj, [question.id]: { valueString: '' } };
        case 'NUMBER':
          return { ...obj, [question.id]: { valueNumber: undefined } };
        case 'LIKERT':
          return { ...obj, [question.id]: { valueId: '' } };
        case 'RADIO':
          return { ...obj, [question.id]: { valueString: undefined, valueId: undefined } };
        case 'CHECKBOX':
          return { ...obj, [question.id]: { valueString: undefined, isOther: false, valueArray: [] } };
        default:
          return obj;
      }
    }, {}),
    validate: survey.questions.reduce((obj, question) => {
      switch (question.type) {
        case 'TEXT':
          return {
            ...obj,
            [question.id]: {
              valueString: isNotEmpty('This field is required')
            }
          };
        case 'NUMBER':
          return {
            ...obj,
            [question.id]: {
              valueNumber: isNotEmpty('This field is required')
            }
          };
        case 'LIKERT':
          return {
            ...obj,
            [question.id]: {
              valueId: isNotEmpty('This field is required')
            }
          };
        case 'RADIO':
          return {
            ...obj,
            [question.id]: {
              valueId: isNotEmpty('This field is required'),
              valueString: (value: string, values: CreateSurveyQuestionAnswerIndex) => (value || values[question.id].valueId !== 'OTHER' ? null : 'This field is required')
            }
          };
        case 'CHECKBOX':
          return {
            ...obj,
            [question.id]: {
              valueArray: (value: string[], values: CreateSurveyQuestionAnswerIndex) => (value.length > 0 || values[question.id].isOther ? null : 'This field is required'),
              valueString: (value: string, values: CreateSurveyQuestionAnswerIndex) => (value || !values[question.id].isOther ? null : 'This field is required')
            }
          };
        default:
          return obj;
      }
    }, {})
  });

  return (
    <form
      onSubmit={form.onSubmit(values =>
        onSubmit({
          surveyId: survey.id,
          taskId,
          parentId,
          taskType,
          answers: values
        })
      )}
    >
      <Title order={1} mb="4rem">
        {title}
      </Title>

      {survey.questions.map(question => {
        switch (question.type) {
          case 'TEXT':
            return <QuestionText key={question.id} form={form} question={question} />;
          case 'NUMBER':
            return <QuestionNumber key={question.id} form={form} question={question} />;
          case 'RADIO':
            return <QuestionRadio key={question.id} form={form} question={question} />;
          case 'CHECKBOX':
            return <QuestionCheckbox key={question.id} form={form} question={question} />;
          case 'LIKERT':
            return <QuestionLikert key={question.id} form={form} question={question} />;
          case 'COMMENT':
            return <Box key={question.id} mt="lg" mb="4rem" dangerouslySetInnerHTML={{ __html: question.text }} />;
          default:
            return false;
        }
      })}

      <Group position="left">
        <Button type="submit" color="red" uppercase>
          Submit
        </Button>
      </Group>
    </form>
  );
};
