import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `survey/${opName}`;

export const createSurvey = createRoutine(getPrefix('create'));

export const updateSurvey = createRoutine(getPrefix('update'));

export const fetchSurveys = createRoutine(getPrefix('fetch-all'));

export const fetchSurveysForEvent = createRoutine(getPrefix('fetch-for-event'));

export const fetchSurvey = createRoutine(getPrefix('fetch-one'));

export const createQuestion = createRoutine(getPrefix('create-question'));

export const updateQuestion = createRoutine(getPrefix('update-question'));

export const reorderQuestion = createRoutine(getPrefix('reorder-question'));

export const removeQuestion = createRoutine(getPrefix('remove-question'));

export const answerSurvey = createRoutine(getPrefix('answer'));

export const answerPublicSurvey = createRoutine(getPrefix('answer-public'));
