import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { Coupon } from 'types/backend';

export const getCoupons = (): Promise<{ data: Coupon[] }> => {
  return api.get('/coupon');
};

export const getCouponsQueryOptions = () => {
  return queryOptions({
    queryKey: ['coupon'],
    queryFn: getCoupons
  });
};

type UseCouponsOptions = {
  queryConfig?: QueryConfig<typeof getCouponsQueryOptions>;
};

export const useCoupons = ({ queryConfig }: UseCouponsOptions = {}) => {
  return useQuery({
    ...getCouponsQueryOptions(),
    ...queryConfig
  });
};
