import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Title, Group, Button, Alert, Text } from '@mantine/core';
import { IconBackhoe } from '@tabler/icons-react';
import { Routes } from 'enums';

type Props = {};

const CalendarPageComponent: React.FC<Props> = () => {
  return (
    <Container size="lg">
      <Group position="right" my="xl">
        <Button component={Link} to={Routes.LHD} uppercase variant="default" color="gray">
          Dashboard
        </Button>
        <Button component={Link} to={Routes.CalendarPage} uppercase variant="filled" color="gray">
          Calendar
        </Button>
      </Group>

      <Title order={2}>Calendar View</Title>
      <Alert my="lg">
        <Group>
          <IconBackhoe size="4rem" />
          <Text size="lg">Page is under construction, check back later!</Text>
        </Group>
      </Alert>
    </Container>
  );
};

export const CalendarPage = connect(null, null)(CalendarPageComponent);
