import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import { CreateSurveyQuestionAnswerIndex, SurveyQuestionDto } from 'types/api/survey';
import { Title, Box, Radio, TextInput } from '@mantine/core';

type Props = {
  form: UseFormReturnType<CreateSurveyQuestionAnswerIndex>;
  question: SurveyQuestionDto;
};

export const QuestionRadio: React.FC<Props> = ({ form, question }) => {
  return (
    <Box mt="lg" mb="4rem">
      <Title order={4} mt="lg" mb="md">
        {question.text}
      </Title>

      <Radio.Group {...form.getInputProps(`${question.id}.valueId`)}>
        {question.options.map(option => (
          <Radio fw="bold" label={option.text} value={option.id} key={option.id} my="md" />
        ))}
        {question.isOther && <Radio fw="bold" label="Other" value="OTHER" my="md" />}
      </Radio.Group>
      {form.values[question.id].valueId === 'OTHER' && <TextInput maw="80%" placeholder="Please Specify" {...form.getInputProps(`${question.id}.valueString`)} />}
    </Box>
  );
};
