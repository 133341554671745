import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CourseIndex, EventIndex, RootState, UserState } from 'types/state';
import { Container, Box, Title, Text, Anchor } from '@mantine/core';
import { Trainings } from './Trainings';
import { Enrolments } from './Enrolments';
import { fetchUserInfo as fetchUserInfoRoutine } from 'store/user/routines';
import { Routes } from 'enums';

type Props = {
  user: UserState;
  fetchUserInfo: () => void;
  courses: CourseIndex;
  events: EventIndex;
};

const DashboardComponent: React.FC<Props> = ({ user, fetchUserInfo, courses, events }) => {
  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <>
      <Box bg="cyan">
        <Container size="lg" c="white" py="4rem">
          <Title order={1}>Welcome, {user.basic?.firstName}</Title>
        </Container>
      </Box>

      <Container size="lg">
        <Trainings />
        <Enrolments />

        {Object.values(events).length === 0 && Object.values(courses).every(course => !course.enrolment) && (
          <Text>
            You are not currently enrolled in any courses.{' '}
            <Anchor component={Link} to={Routes.CourseStore}>
              Click here
            </Anchor>{' '}
            to view our available courses.
          </Text>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (root: RootState) => ({
  user: root.user,
  courses: root.course.courses,
  events: root.event.events
});

const mapDispatchToProps = {
  fetchUserInfo: fetchUserInfoRoutine
};

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
