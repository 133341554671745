import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserTas } from 'types/state';

type Props = {
  tas: UserTas;
};

export const DisplayTas: React.FC<Props> = ({ tas }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {tas.isTasHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for Tas Health.
      </Text>
    </Box>
  );
};
