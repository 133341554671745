export enum Age {
  FIFTEEN_NINETEEN = '15-19',
  TWENTY_TWENTY_FOUR = '20-24',
  TWENTY_FIVE_TWENTY_NINE = '25-29',
  THIRTY_THIRTY_FOUR = '30-34',
  THIRTY_FIVE_THIRTY_NINE = '35-39',
  FORTY_FORTY_FOUR = '40-44',
  FORTY_FIVE_FORTY_NINE = '45-49',
  FIFTY_FIFTY_FOUR = '50-54',
  FIFTY_FIVE_FIFTY_NINE = '55-59',
  SIXTY_SIXTY_FOUR = '60-64',
  SIXTY_FIVE_SIXTY_NINE = '65-69',
  SEVENTY_SEVENTY_FOUR = '70-74',
  SEVENTY_SEVENTY_NINE = '75-79',
  EIGHTY_EIGHTY_FOUR = '80-84',
  EIGHTY_FIVE_EIGHTY_NINE = '85-89',
  NINETY_AND_MORE = '90+'
}
