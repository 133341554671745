import {
  Discipline,
  EmploymentType,
  ExperienceWithEatingDisorder,
  NSWHealthLHD
} from 'enums/user-info';
import { useEventEnrolments } from 'features/events/api/get-event-enrolments';
import { EOIList } from 'features/events/components/eoi-list';
import React, { useEffect, useMemo, useState } from 'react';
import type { EOI } from 'features/events/types/eoi';
import { useUpdateEventEnrolment } from 'features/events/api/update-event-enrolment';
import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Title
} from '@mantine/core';
import { format } from 'date-fns';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { EventDto } from 'types/api/event';
import { PreferencesTableAdmin } from 'pages/LHDCoordinator/View/PreferencesTableAdmin';
import { UserRoles } from 'enums';
import { IconDownload } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { StaticEOIList } from 'features/events/components/static-eoi-list';
import { UnprioritisedEOIList } from 'features/events/components/unprioritised-eoi-list';
import { ArchivedEOIList } from 'features/events/components/archived-eoi-list';
import { EventEnrolmentsPriorityContext } from 'features/events/contexts/event-enrolments-priority';

interface PrioritiseEOIsProps {
  event: EventDto;
  eventId: string;
  role: UserRoles;
  coordinatorFor: keyof typeof NSWHealthLHD;
  signoffEvent: (eventId: string) => void;
  getEventPreferencesExport: (id: string) => void;
}

export const PrioritiseEOIs = ({
  event,
  eventId,
  role,
  coordinatorFor,
  signoffEvent,
  getEventPreferencesExport
}: PrioritiseEOIsProps) => {
  const eventEnrolmentsQuery = useEventEnrolments({ eventId });

  const [prioritisedEOIs, setPrioritisedEOIs] = useState<Array<EOI>>([]);
  const [unprioritisedEOIs, setUnprioritisedEOIs] = useState<Array<EOI>>([]);
  const [archivedEOIs, setArchivedEOIs] = useState<Array<EOI>>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [autosaveTimer, setAutosaveTimer] = useState(null);
  const [lastSaved, setLastSaved] = useState<Date>(null);
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const updateEventEnrolmentMutation = useUpdateEventEnrolment({
    mutationConfig: {
      retry: 5,
      onSuccess: data => {
        eventEnrolmentsQuery.refetch();
        setUnsavedChanges(false);
        setLastSaved(new Date());
      }
    }
  });

  const lastSavedTimestamp = useMemo(
    () => (lastSaved ? format(lastSaved, "'last saved at' h:mm:ss b") : ''),
    [lastSaved]
  );

  const handleSave = () => {
    const enrolments = prioritisedEOIs
      .map(eoi => ({
        id: eoi.enrolment_id,
        priority: eoi.priority,
        archived: false
      }))
      .concat(
        unprioritisedEOIs.map(eoi => ({
          id: eoi.enrolment_id,
          priority: null,
          archived: false
        }))
      )
      .concat(
        archivedEOIs.map(eoi => ({
          id: eoi.enrolment_id,
          priority: null,
          archived: true
        }))
      );

    updateEventEnrolmentMutation.mutate({
      eventId,
      data: {
        enrolments
      }
    });

    if (autosaveTimer) {
      clearTimeout(autosaveTimer);
      setAutosaveTimer(null);
    }
  };

  const handleArchive = (
    table: 'prioritised' | 'unprioritised',
    id: string
  ) => {
    const index =
      table === 'prioritised'
        ? prioritisedEOIs.findIndex(e => e.enrolment_id === id)
        : unprioritisedEOIs.findIndex(e => e.enrolment_id === id);

    if (index === -1) return;

    if (table === 'prioritised') {
      const newList = [...prioritisedEOIs];
      const eoi = newList.splice(index, 1)[0];

      // Strting at index because we removed archived EOI from list
      for (let i = index; i < newList.length; i += 1) {
        newList[i].priority -= 1;
      }

      setPrioritisedEOIs(newList);
      setArchivedEOIs([...archivedEOIs, { ...eoi, priority: null }]);
    } else {
      const newList = [...unprioritisedEOIs];
      const eoi = newList.splice(index, 1)[0];

      setUnprioritisedEOIs(newList);
      setArchivedEOIs([...archivedEOIs, { ...eoi }]);
    }

    setUnsavedChanges(true);

    if (autosaveTimer) {
      clearTimeout(autosaveTimer);
    }

    setAutosaveTimer(setTimeout(handleSave, 10000));
  };

  const handleUnarchive = (id: string) => {
    const index = archivedEOIs.findIndex(e => e.enrolment_id === id);

    if (index === -1) return;

    const newList = [...archivedEOIs];
    const eoi = newList.splice(index, 1)[0];

    setArchivedEOIs(newList);
    setUnprioritisedEOIs([...unprioritisedEOIs, { ...eoi }]);

    setUnsavedChanges(true);

    if (autosaveTimer) {
      clearTimeout(autosaveTimer);
    }

    setAutosaveTimer(setTimeout(handleSave, 10000));
  };

  const handlePrioritise = (id: string) => {
    const index = unprioritisedEOIs.findIndex(e => e.enrolment_id === id);

    if (index === -1) return;

    const newList = [...unprioritisedEOIs];
    const eoi = newList.splice(index, 1)[0];

    setUnprioritisedEOIs(newList);
    setPrioritisedEOIs([
      ...prioritisedEOIs,
      { ...eoi, priority: prioritisedEOIs.length }
    ]);

    setUnsavedChanges(true);

    if (autosaveTimer) {
      clearTimeout(autosaveTimer);
    }

    setAutosaveTimer(setTimeout(handleSave, 10000));
  };

  const handleDownloadPreferences = () => {
    getEventPreferencesExport(eventId);
  };

  const handleDragStart = () => {
    if (autosaveTimer) {
      clearTimeout(autosaveTimer);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (result.reason === 'CANCEL') {
      if (unsavedChanges) {
        setAutosaveTimer(setTimeout(handleSave, 10000));
      }

      return;
    }
    if (!result.destination) return;
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    if (result.destination.droppableId === 'archived') {
      const table =
        result.source.droppableId === 'prioritised'
          ? 'prioritised'
          : 'unprioritised';

      handleArchive(table, result.draggableId);
      return;
    }

    const source = result.source.index;
    const destination = result.destination.index;

    const sourceTable = result.source.droppableId;
    const destinationTable = result.destination.droppableId;

    if (sourceTable === destinationTable && sourceTable === 'prioritised') {
      const newPrioritisedEOIs: Array<EOI> = [...prioritisedEOIs];

      if (destination < source) {
        for (let i = destination; i < source; i += 1) {
          newPrioritisedEOIs[i].priority += 1;
        }
      } else if (destination > source) {
        for (let i = destination; i > source; i -= 1) {
          newPrioritisedEOIs[i].priority -= 1;
        }
      }

      newPrioritisedEOIs[source].priority = destination;

      newPrioritisedEOIs.sort((a, b) => a.priority - b.priority);
      setPrioritisedEOIs(newPrioritisedEOIs);
    }

    if (sourceTable !== destinationTable) {
      const sourceLength =
        sourceTable === 'prioritised'
          ? prioritisedEOIs.length
          : unprioritisedEOIs.length;
      const destinationLength =
        destinationTable === 'prioritised'
          ? prioritisedEOIs.length
          : unprioritisedEOIs.length;
      let newPrioritisedEOIs: Array<EOI> = [...prioritisedEOIs];
      let newUnprioritisedEOIs: Array<EOI> = [...unprioritisedEOIs];

      if (sourceTable === 'prioritised') {
        for (let i = source + 1; i < sourceLength; i += 1) {
          newPrioritisedEOIs[i].priority -= 1;
        }
      }

      if (destinationTable === 'prioritised') {
        for (let i = destination; i < destinationLength; i += 1) {
          newPrioritisedEOIs[i].priority += 1;
        }
      }

      const activeEOI =
        sourceTable === 'prioritised'
          ? prioritisedEOIs.find(eoi => eoi.enrolment_id === result.draggableId)
          : unprioritisedEOIs.find(
              eoi => eoi.enrolment_id === result.draggableId
            );

      if (sourceTable === 'prioritised') {
        newPrioritisedEOIs = newPrioritisedEOIs.filter(
          eoi => eoi.enrolment_id !== result.draggableId
        );

        activeEOI.priority = null;
      } else {
        newUnprioritisedEOIs = newUnprioritisedEOIs.filter(
          eoi => eoi.enrolment_id !== result.draggableId
        );

        activeEOI.priority = destination;
      }

      if (destinationTable === 'prioritised') {
        newPrioritisedEOIs.splice(destination, 0, activeEOI);
      } else {
        newUnprioritisedEOIs.splice(destination, 0, activeEOI);
      }

      setPrioritisedEOIs(newPrioritisedEOIs);
      setUnprioritisedEOIs(newUnprioritisedEOIs);
    }

    setUnsavedChanges(true);

    if (autosaveTimer) {
      clearTimeout(autosaveTimer);
    }

    setAutosaveTimer(setTimeout(handleSave, 10000));
  };

  const eventEnrolmentsPriorityContextValue = useMemo(
    () => ({
      handleArchive,
      handleUnarchive,
      handlePrioritise
    }),
    [handleArchive, handleUnarchive]
  );

  useEffect(() => {
    if (eventEnrolmentsQuery.data?.data) {
      const prioritised: Array<EOI> = [];
      const unprioritised: Array<EOI> = [];
      const archived: Array<EOI> = [];

      const enrolments =
        role === UserRoles.LHD_COORDINATOR
          ? eventEnrolmentsQuery.data.data.filter(
              eoi => eoi.lhd === coordinatorFor
            )
          : eventEnrolmentsQuery.data.data;

      enrolments.forEach(enrolment => {
        const eoi = {
          enrolment_id: enrolment.id,
          event_id: eventId,
          priority: enrolment.priority,
          user_id: enrolment.user_id,
          email: enrolment.email,
          name: enrolment.first_name
            ? `${enrolment.first_name} ${enrolment.last_name}`
            : undefined,
          location: enrolment.city ?? '',
          lhd: enrolment.lhd ? NSWHealthLHD[enrolment.lhd] : undefined,
          discipline: enrolment.discipline
            ? Discipline[enrolment.discipline]
            : undefined,
          work_setting: enrolment.employment_type
            ? EmploymentType[enrolment.employment_type]
            : undefined,
          service_name: '',
          clinical_experience: enrolment.clinical_experience
            ? ExperienceWithEatingDisorder[enrolment.clinical_experience]
            : undefined,
          ed_experience: enrolment.ed_experience
            ? ExperienceWithEatingDisorder[enrolment.ed_experience]
            : undefined
        };

        if (enrolment.archived) {
          archived.push(eoi);
        } else if (eoi.priority !== null) {
          prioritised.push(eoi);
        } else {
          unprioritised.push(eoi);
        }
      });

      setPrioritisedEOIs(prioritised);
      setUnprioritisedEOIs(unprioritised);
      setArchivedEOIs(archived);
    }
  }, [eventEnrolmentsQuery.data, eventId]);

  if (eventEnrolmentsQuery.isLoading || eventEnrolmentsQuery.isError) {
    return null;
  }

  if (role === UserRoles.IOI_ADMIN || role === UserRoles.F2F_FACILITATOR)
    return <PreferencesTableAdmin event={event} />;

  if (
    !event.coordinatorSignoff[coordinatorFor] &&
    (event.status === 'OPEN' || event.status === 'WITH_COORDINATOR')
  ) {
    return (
      <EventEnrolmentsPriorityContext.Provider
        value={eventEnrolmentsPriorityContextValue}
      >
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <Group my="36px" position="right">
            <Button
              color="yellow"
              size="md"
              mr="auto"
              leftIcon={<IconDownload size={20} />}
              onClick={handleDownloadPreferences}
            >
              Download EOI Sheet
            </Button>
            <div>
              <Button
                mb={4}
                size="md"
                color="teal"
                onClick={handleSave}
                disabled={!unsavedChanges}
              >
                Save My Preferences
              </Button>
              <Text size="md" color="gray" opacity={0.8}>
                {lastSavedTimestamp}
              </Text>
            </div>
            {event.status === 'WITH_COORDINATOR' && (
              <>
                {event.coordinatorSignoff.SYDNEY ? (
                  <Text>
                    Your preferences were submitted on{' '}
                    {dayjs(event.coordinatorSignoff.SYDNEY.when).format(
                      'DD MMM YYYY'
                    )}
                  </Text>
                ) : (
                  <Button
                    variant="outline"
                    color="red"
                    size="md"
                    onClick={() => setConfirmSubmit(true)}
                  >
                    Submit My Preferences
                  </Button>
                )}

                <Modal
                  opened={confirmSubmit}
                  title="Confirm Preference Submission"
                  onClose={() => setConfirmSubmit(false)}
                  centered
                >
                  <Text>
                    You are about to lock in your preferences, this action
                    cannot be undone.
                  </Text>
                  <Text my="md" fw="bold">
                    Are you sure?
                  </Text>
                  <Group position="center">
                    <Button
                      color="red"
                      onClick={() => {
                        signoffEvent(event.id);
                        setConfirmSubmit(false);
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      color="gray"
                      onClick={() => setConfirmSubmit(false)}
                    >
                      Cancel
                    </Button>
                  </Group>
                </Modal>
              </>
            )}
          </Group>
          <Stack spacing={72}>
            <EOIList
              heading="Prioritised EOIs"
              eois={prioritisedEOIs}
              type="prioritised"
            />
            <UnprioritisedEOIList
              heading="Unprioritised EOIs"
              eois={unprioritisedEOIs}
              type="unprioritised"
            />
            <div>
              <Title order={1}>Archived EOIs</Title>
              <ArchivedEOIList eois={archivedEOIs} />
            </div>
            <LoadingOverlay
              visible={updateEventEnrolmentMutation.isPending}
              overlayBlur={2}
            />
          </Stack>
        </DragDropContext>
      </EventEnrolmentsPriorityContext.Provider>
    );
  }

  return (
    <>
      {event.coordinatorSignoff[coordinatorFor] && (
        <Alert color="blue" my="lg">
          These preferences were submitted on{' '}
          {dayjs(event.coordinatorSignoff[coordinatorFor].when).format(
            'DD MMM YYYY'
          )}
          . They can no longer be modified. InsideOut will review and finalize
          participant list by{' '}
          {dayjs(event.preferencesDue).add(1, 'week').format('DD MMM YYYY')}.
        </Alert>
      )}
      {!event.coordinatorSignoff[coordinatorFor] && (
        <Alert color="yellow" my="lg">
          Preferences were not finalised before the cutoff date of{' '}
          {dayjs(event.preferencesDue).format('DD MMM YYYY')}. All EOIs from
          your LHD have been passed on to InsideOut for finalisation of
          participant list. Any draft prioritisations were also passed on.
        </Alert>
      )}
      <StaticEOIList eois={prioritisedEOIs} />
    </>
  );
};
