import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ScormDto } from 'types/api/scorm';
import { Container, Title, Table, Skeleton, Anchor } from '@mantine/core';
import { CourseIndex, F2FTypeIndex, RootState } from 'types/state';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { fetchF2FTypes as fetchF2FTypesRoutine } from 'store/face-to-face/routines';
import { AdminRoutes } from 'enums';

type Props = {
  scorm: ScormDto;
  f2fs: F2FTypeIndex;
  courses: CourseIndex;
  fetchCourses: () => void;
  fetchF2FTypes: () => void;
};

const ScormUsedInComponent: React.FC<Props> = ({
  scorm,
  f2fs,
  courses,
  fetchCourses,
  fetchF2FTypes
}) => {
  useEffect(() => {
    fetchCourses();
    fetchF2FTypes();
  }, []);

  return (
    <Container size="md">
      <Title order={3}>Scorm Used In</Title>
      <Table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Task Name</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {scorm?.courses?.map(each => (
            <tr key={each.id}>
              <td>Course</td>
              <td>
                {courses[each.id] ? (
                  courses[each.id].title
                ) : (
                  <Skeleton h="1rem" />
                )}
              </td>
              <td>{each.text}</td>
              <td>
                <Anchor
                  component={Link}
                  to={`${AdminRoutes.courseView}/${each.id}`}
                >
                  Link
                </Anchor>
              </td>
            </tr>
          ))}
          {scorm?.f2fs?.map(each => (
            <tr key={each.id}>
              <td>Face-to-Face</td>
              <td>
                {f2fs[each.id] ? f2fs[each.id].name : <Skeleton h="1rem" />}
              </td>
              <td>{each.text}</td>
              <td>
                <Anchor
                  component={Link}
                  to={`${AdminRoutes.faceToFaceView}/${each.id}`}
                >
                  Link
                </Anchor>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  f2fs: state.faceToFace.types,
  courses: state.course.courses
});

const mapDispatchToProps = {
  fetchCourses: fetchCoursesRoutine,
  fetchF2FTypes: fetchF2FTypesRoutine
};

export const ScormUsedIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScormUsedInComponent);
