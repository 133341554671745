import React from 'react';
import { connect } from 'react-redux';
import {
  Stack,
  Switch,
  NumberInput,
  Collapse,
  Group,
  Button,
  Text,
  Input,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { F2FTypeDto, UpdateF2FTypeDto } from 'types/api/face-to-face';
import { updateF2FType as updateF2FTypeRoutine } from 'store/face-to-face/routines';

type Props = {
  type: F2FTypeDto;
  updateF2FType: (dto: UpdateF2FTypeDto) => void;
};

const CertificateComponent: React.FC<Props> = ({ type, updateF2FType }) => {
  // FIXME there is a bug, the switch does not correctly display initial value
  const form = useForm({
    initialValues: {
      automaticUnlock: type.certificateDelay !== -1,
      certificateDelay:
        type.certificateDelay === -1 ? 0 : type.certificateDelay,
      hours: type.hours,
      certificateDescription: type.certificateDescription,
      customLogo1: type.customLogo1,
      customLogo2: type.customLogo2,
      customLogo3: type.customLogo3
    },
    validate: {
      certificateDelay: (value, values) =>
        values.automaticUnlock && value < 0 ? 'Days must be positive' : null
    },
    transformValues: values => ({
      id: type.id,
      certificateDelay: values.automaticUnlock ? values.certificateDelay : -1,
      hours: values.hours,
      certificateDescription: values.certificateDescription,
      customLogo1: values.customLogo1,
      customLogo2: values.customLogo2,
      customLogo3: values.customLogo3
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => updateF2FType(values))}>
      <Stack>
        <Switch
          label="Automatically unlock certificate"
          {...form.getInputProps('automaticUnlock', { type: 'checkbox' })}
        />

        <Collapse in={form.values.automaticUnlock}>
          <NumberInput
            label="Days before automatically unlocking certificate"
            {...form.getInputProps('certificateDelay')}
          />
        </Collapse>
        <NumberInput
          my="md"
          rightSection={<Text>Hours</Text>}
          rightSectionWidth={50}
          label="Hours displayed on certificate"
          hideControls
          {...form.getInputProps('hours')}
        />
        <TextInput
          rightSectionWidth={50}
          label="Certificate Description (keep short)"
          {...form.getInputProps('certificateDescription')}
        />
        <TextInput
          rightSectionWidth={50}
          label="Custom Logo 1 link"
          {...form.getInputProps('customLogo1')}
        />
        <TextInput
          rightSectionWidth={50}
          label="Custom Logo 2 link"
          {...form.getInputProps('customLogo2')}
        />
        <TextInput
          rightSectionWidth={50}
          label="Custom Logo 3 link"
          {...form.getInputProps('customLogo3')}
        />

        <Group position="right">
          <Button type="submit">Save Changes</Button>
        </Group>
      </Stack>
    </form>
  );
};

const mapDispatchToProps = {
  updateF2FType: updateF2FTypeRoutine
};

export const Certificate = connect(
  null,
  mapDispatchToProps
)(CertificateComponent);
