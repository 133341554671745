import {
  Button,
  Center,
  Checkbox,
  Grid,
  Group,
  MultiSelect,
  Select,
  Stack,
  Text,
  Tooltip
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconHelpCircleFilled } from '@tabler/icons-react';
import { format, sub } from 'date-fns';
import { useCourses } from 'features/courses/api/get-courses';
import {
  ExportCourseReportBody,
  useExportCourseReport
} from 'features/report/api/export-course-report-csv';
import { useCurrentUser } from 'features/user/api/get-current-user';
import React from 'react';

interface FormData extends ExportCourseReportBody {
  timeframe: 'month' | 'quarter' | 'semi' | 'year' | 'custom';
}

export const CourseReportExportPanel = () => {
  const coursesQuery = useCourses();
  const currentUserQuery = useCurrentUser();

  const form = useForm<FormData>();

  const exportCourseReportDataMutation = useExportCourseReport({
    mutationConfig: {
      onSuccess: data => {
        if (data.type === 'text/csv') {
          const url = URL.createObjectURL(data);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `eLearning_report_${format(
            new Date(),
            'dd-MM-yyyy'
          )}`;

          anchor.click();
          anchor.remove();
        }
      }
    }
  });

  const timeframes = [
    {
      value: 'month',
      label: 'Last 30 days'
    },
    {
      value: 'quarter',
      label: 'Last 90 days'
    },
    {
      value: 'semi',
      label: 'Last 6 months'
    },
    {
      value: 'year',
      label: 'Last year'
    },
    {
      value: 'custom',
      label: 'Custom'
    }
  ];

  const coordinatorExcludeCourses = [
    '‘Red Flags’: Decision Making and Communication for at-risk Clients in the Fitness Industry',
    'EducatED',
    'Headspace Q&A Topic 1: About eating disorders, screening and identification',
    'Eating Disorders and Community Treatment Approaches (USyd Masters of Clinical Psychology)'
  ];

  // TODO: Make this into a database column flag
  const courses =
    currentUserQuery.data?.data?.role === 'IOI_ADMIN'
      ? coursesQuery.data?.data?.map(course => ({
          value: course.id,
          label: course.title
        })) ?? []
      : coursesQuery.data?.data
          ?.filter(course => !coordinatorExcludeCourses.includes(course.title))
          .map(course => ({
            value: course.id,
            label: course.title
          })) ?? [];

  return (
    <Center maw={960} mx="auto" mt="2rem">
      <form
        onSubmit={form.onSubmit(values => {
          const { timeframe } = values;
          let { startDate, endDate } = values;

          if (timeframe && timeframe !== 'custom') {
            const today = new Date();
            endDate = new Date();

            if (timeframe === 'month') {
              startDate = sub(today, {
                days: 30
              });
            } else if (timeframe === 'quarter') {
              startDate = sub(today, {
                days: 90
              });
            } else if (timeframe === 'semi') {
              startDate = sub(today, {
                months: 6
              });
            } else if (timeframe === 'year') {
              startDate = sub(today, {
                days: 365
              });
            }
          }

          exportCourseReportDataMutation.mutate({
            data: {
              courses: values.courses?.length ? values.courses : undefined,
              startDate,
              endDate,
              completionPerModule: !!values.completionPerModule
            }
          });
        })}
      >
        <Stack w={480}>
          <MultiSelect
            label="Choose eLearning (optional)"
            placeholder="Pick as many as you like"
            data={courses}
            searchable
            nothingFound="No eLearning"
            {...form.getInputProps('courses')}
          />
          <Select
            label="Choose timeframe (optional)"
            placeholder="Pick one"
            data={timeframes}
            {...form.getInputProps('timeframe')}
          />
          {form.values.timeframe === 'custom' && (
            <Grid grow>
              <Grid.Col span="auto">
                <DatePickerInput
                  label="Start date"
                  {...form.getInputProps('startDate')}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <DatePickerInput
                  label="End date"
                  {...form.getInputProps('endDate')}
                />
              </Grid.Col>
            </Grid>
          )}
          <Stack spacing="0.5rem">
            <Tooltip
              label="Expand eLearning to show user progress for each module"
              position="top-start"
            >
              <Group spacing="0.25rem" align="flex-end" w="fit-content">
                <Text size="sm" td="underline">
                  What is this?
                </Text>
                <IconHelpCircleFilled size="20" />
              </Group>
            </Tooltip>
            <Checkbox
              color="dark"
              label="Show completion per module"
              {...form.getInputProps('completionPerModule')}
            />
          </Stack>

          <Button
            mt="sm"
            type="submit"
            color="dark"
            loading={exportCourseReportDataMutation.isPending}
          >
            Download
          </Button>
        </Stack>
      </form>
    </Center>
  );
};
