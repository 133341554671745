export enum VicHealthRegion {
  BARWON_SOUTH_WESTERN = 'Barwon South Western',
  GIPPSLAND = 'Gippsland',
  GRAMPIANS = 'Grampians',
  HUME = 'Hume',
  LODDON_MALLEE = 'Loddon Mallee',
  NORTHERN_AND_WESTERN_METROPOLITAN = 'Northern and Western Metropolitan',
  EASTERN_METROPOLITAN = 'Eastern Metropolitan',
  SOUTHERN_METROPOLITAN = 'Southern Metropolitan'
}
