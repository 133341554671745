export enum UserRoles {
  F2F_FACILITATOR = 'F2F_FACILITATOR',
  LHD_COORDINATOR = 'LHD_COORDINATOR',
  LEARNER = 'LEARNER',
  IOI_ADMIN = 'IOI_ADMIN',
  GUEST = 'GUEST'
}

export const getAllRoles = (): UserRoles[] => {
  return Object.values(UserRoles);
};

export const getAllAdminRoles = (): UserRoles[] => {
  return [UserRoles.IOI_ADMIN, UserRoles.LHD_COORDINATOR, UserRoles.F2F_FACILITATOR];
};
