import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { CouponValidationRule } from 'types/backend';
import { getCouponValidationRuleQueryOptions } from './get-coupon-validation-rule';
import { getCouponValidationRulesQueryOptions } from './get-coupon-validation-rules';

export const updateCouponValidationRule = ({
  id,
  stripeCouponId,
  domains
}: {
  id: string;
  stripeCouponId?: string;
  domains?: Array<string>;
}): Promise<AppResponse<CouponValidationRule>> => {
  return api.patch(`/coupon-validation-rule/${id}`, {
    stripeCouponId,
    domains
  });
};

type UseUpdateCouponValidationRuleOptions = {
  mutationConfig?: MutationConfig<typeof updateCouponValidationRule>;
};

export const useUpdateCouponValidationRule = ({
  mutationConfig
}: UseUpdateCouponValidationRuleOptions = {}) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (...args) => {
      const [data, variables] = args;

      queryClient.setQueryData(
        getCouponValidationRulesQueryOptions().queryKey,
        ({ data: rules }) => {
          if (!data.data) return { data: rules };

          const index = rules.findIndex(rule => rule.id === data.data?.id);

          if (index === -1) return { data: rules };

          const updatedRules = [...rules];

          updatedRules[index] = data.data;

          return { data: updatedRules };
        }
      );

      queryClient.invalidateQueries({
        queryKey: getCouponValidationRuleQueryOptions(variables.id).queryKey
      });

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: updateCouponValidationRule
  });
};
