/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { Routine } from 'redux-saga-routines';
import { CertificateState } from 'types/state/certificate';
import { getEventCertificate, getCourseCertificate } from './routines';

const initialState: CertificateState = {
  loading: {},
  error: {}
};

export const certificateReducer = produce((state: CertificateState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case getEventCertificate.TRIGGER:
    case getCourseCertificate.TRIGGER:
      state.loading[payload] = true;
      break;
    case getEventCertificate.SUCCESS:
    case getCourseCertificate.SUCCESS:
      state.loading[payload] = false;
      break;
    case getEventCertificate.FAILURE:
    case getCourseCertificate.FAILURE:
      state.loading[payload.id] = false;
      state.error[payload.id] = payload.error;
      break;
    // no default
  }
}, initialState);
