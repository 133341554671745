import { AxiosError } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchSurveyAnswersForF2FTask, linkUserToSurveyAnswer } from './routines';
import { surveyAnswerService } from 'service/survey-answer';
import { Action } from 'redux-actions';

function* fetchSurveyAnswersForF2FTaskHandler({ payload: f2fTaskId }: Action<string>) {
  try {
    const data = yield call(surveyAnswerService.getTaskAnswers, f2fTaskId);
    yield put(fetchSurveyAnswersForF2FTask.success({ id: f2fTaskId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchSurveyAnswersForF2FTask.failure(e.message));
    else yield put(fetchSurveyAnswersForF2FTask.failure('Unknown error.'));
  }
}

function* linkUserToSurveyAnswerHandler({ payload }: Action<{ id: string, userId: string}>) {
  try {
    const data = yield call(surveyAnswerService.linkUser, payload.id, payload.userId);
    yield put(linkUserToSurveyAnswer.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(linkUserToSurveyAnswer.failure(e.message));
    else yield put(linkUserToSurveyAnswer.failure('Unknown error.'));
  }
}

export function* surveyAnswerSaga() {
  yield all([
    takeEvery(fetchSurveyAnswersForF2FTask.TRIGGER, fetchSurveyAnswersForF2FTaskHandler),
    takeEvery(linkUserToSurveyAnswer.TRIGGER, linkUserToSurveyAnswerHandler)
  ]);
}