import React from 'react';
import { SurveyQuestionDto } from 'types/api/survey';

import { Stack, Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { QuestionForm } from '../components/QuestionForm';

type Props = {
  question: SurveyQuestionDto;
  create: (dto: SurveyQuestionDto) => void;
  update: (dto: SurveyQuestionDto) => void;
  remove: (id: string) => void;
};

export const QuestionEditor: React.FC<Props> = ({
  question,
  create,
  update,
  remove
}) => {
  const form = useForm<SurveyQuestionDto>({
    initialValues: {
      ...question,
      options: question?.options.map(each => ({ ...each })) // Can't edit state! Must clone.
    },
    transformValues: values => ({
      ...values,
      id: question.id
    })
  });
  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (values.id) update(values);
        else create(values);
      })}
    >
      <Stack>
        <QuestionForm form={form} values={form.values} />
        <Group>
          {question?.id && (
            <Button color="red" onClick={() => remove(question.id)}>
              Delete
            </Button>
          )}
          <Button sx={{ flex: 1 }} type="submit">
            {question?.id ? 'Save Changes' : 'Create Question'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
