import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import { CreateSurveyQuestionAnswerIndex, SurveyQuestionDto } from 'types/api/survey';
import { Title, Textarea } from '@mantine/core';

type Props = {
  form: UseFormReturnType<CreateSurveyQuestionAnswerIndex>;
  question: SurveyQuestionDto;
};

export const QuestionText: React.FC<Props> = ({ form, question }) => {
  return (
    <>
      <Title order={4} mt="lg" mb="md">
        {question.text}
      </Title>
      <Textarea mb="4rem" maw="80%" autosize minRows={2} maxRows={4} {...form.getInputProps(`${question.id}.valueString`)} />
    </>
  );
};
