import React from 'react';
import { connect } from 'react-redux';
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, Radio, Alert } from '@mantine/core';
import { setACT as setACTRoutine } from 'store/user/routines';
import { RootState, UserACT } from 'types/state';

type ACTForm = {
  isACTHealth: 'YES' | 'NO';
};

type Props = {
  isLoading: boolean;
  error?: string;
  act: UserACT;
  setACT: (dto: UserACT) => void;
};

const OnboardingACTComponent: React.FC<Props> = ({ isLoading, error, act, setACT }) => {
  const form = useForm<ACTForm, (arg0: ACTForm) => UserACT>({
    initialValues: act
      ? {
          isACTHealth: act.isACTHealth ? 'YES' : 'NO'
        }
      : {
          isACTHealth: undefined
        },
    transformValues: values => ({
      isACTHealth: values.isACTHealth === 'YES'
    }),
    validate: {
      isACTHealth: isNotEmpty('This question is required.')
    }
  });

  return (
    <form onSubmit={form.onSubmit(values => setACT(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Do you work for ACT Health?" {...form.getInputProps('isACTHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update ACT Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  act: state.user.act
});

const mapDispatchToProps = {
  setACT: setACTRoutine
};

export const OnboardingACT = connect(mapStateToProps, mapDispatchToProps)(OnboardingACTComponent);
