import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import { CreateSurveyQuestionAnswerIndex, SurveyQuestionDto } from 'types/api/survey';
import { Radio, Title, Flex, Box } from '@mantine/core';

type Props = {
  form: UseFormReturnType<CreateSurveyQuestionAnswerIndex>;
  question: SurveyQuestionDto;
};

export const QuestionLikert: React.FC<Props> = ({ form, question }) => {
  return (
    <Box mt="lg" mb="4rem">
      <Title order={4} mt="lg" mb="md">
        {question.text}
      </Title>
      <Radio.Group {...form.getInputProps(`${question.id}.valueId`)} my="md">
        <Flex gap={{ base: 'sm', md: 'md' }} direction={{ base: 'column', sm: 'row' }}>
          {question.options.map(option => (
            <Radio sx={{ flex: 1 }} bg={form.values[question.id].valueId === option.id ? 'blue.3' : 'gray.2'} p="1rem" fw="bold" label={option.text} value={option.id} key={option.id} />
          ))}
        </Flex>
      </Radio.Group>
    </Box>
  );
};
