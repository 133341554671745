import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Button, Container, Checkbox, TextInput, Card, PasswordInput, Title, Group, Anchor } from '@mantine/core';
import { useForm, isEmail, isNotEmpty } from '@mantine/form';
import { RootState, UserState } from 'types/state';
import { LoginDto } from 'types/api/auth';
import { login as loginRoutine } from 'store/user/routines';
import { Routes } from 'enums';

type Props = {
  user: UserState;
  login: (payload: LoginDto) => void;
};

const LoginComponent: React.FC<Props> = ({ user, login }) => {
  const [searchParams] = useSearchParams();
  const target = searchParams.get('target');
  const msg = searchParams.get('msg');
  const email = searchParams.get('email');

  const form = useForm({
    initialValues: {
      email: email || '',
      password: '',
      rememberMe: false
    },
    validate: {
      email: isEmail('Please enter a valid email address'),
      password: isNotEmpty('Please enter a valid password')
    }
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.isAuthenticated) navigate(target || '/');
  }, [user]);

  return (
    <Container size="xs">
      <Card withBorder my="xl">
        <form onSubmit={form.onSubmit(values => login(values))}>
          <Title order={1} mb="lg">
            Welcome back to the InsideOut eLearning Hub
          </Title>
          {msg && <Alert color="green">{msg}</Alert>}
          {user.error && (
            <Alert title="Error logging in" color="red" my="md">
              {user.error}
            </Alert>
          )}
          <TextInput my="md" label="Email address" {...form.getInputProps('email')} />
          <PasswordInput my="md" label="Password" {...form.getInputProps('password')} />
          <Checkbox my="md" label="Remember me" {...form.getInputProps('rememberMe')} />
          <Anchor component={Link} to={Routes.ResetPasswordRequest}>
            Forgot your password? Click here to reset it.
          </Anchor>
          <Group position="center" my="md">
            <Button type="submit" uppercase loading={user.isLoading}>
              Log in
            </Button>
          </Group>
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (rootState: RootState) => ({
  user: rootState.user
});

const mapDispatchToProps = {
  login: loginRoutine
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
