import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Text, Title, Alert } from '@mantine/core';
import { EventIndex, RootState, UserIndex } from 'types/state';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { fetchEvent as fetchEventRoutine } from 'store/event/routines';
import { fetchEnrolmentsForEvent as fetchEnrolmentsForEventRoutine } from 'store/event-enrolment/routines';
import { UserRoles } from 'enums';
import { NSWHealthLHD } from 'enums/user-info';
import { EventEnrolmentDto } from 'types/api/event';

type Props = {
  events: EventIndex;
  users: UserIndex;
  fetchEvent: (id: string) => void;
  fetchUsersByRole: (role: UserRoles) => void;
  isLoading: boolean;
  error?: string;
  fetchEnrolmentsForEvent: (id: string) => void;
  allEnrolments: Record<string, EventEnrolmentDto>;
  enrolmentLists: Record<string, string[]>;
};

const AttendanceSheetComponent: React.FC<Props> = ({
  events,
  users,
  fetchEvent,
  fetchUsersByRole,
  isLoading,
  error,
  fetchEnrolmentsForEvent,
  allEnrolments,
  enrolmentLists
}) => {
  const { id } = useParams();

  useEffect(() => {
    fetchEvent(id);
    fetchEnrolmentsForEvent(id);
  }, [id]);

  useEffect(() => {
    fetchUsersByRole(UserRoles.LEARNER);
  }, []);

  const event = events[id];

  useEffect(() => {
    if (event && !isLoading) {
      const helpScoutEl = document.getElementById('beacon-container');

      if (helpScoutEl) helpScoutEl.remove();
      window.print();
    }
  }, [event, isLoading]);

  if (error)
    return (
      <Alert color="red" title="Something went wrong">
        {error}
      </Alert>
    );

  if (!event) return <Text>No event found with id {id}.</Text>;

  const enrolments = enrolmentLists[event.id]
    ? enrolmentLists[event.id].map(i => allEnrolments[i])
    : [];
  const sortedEnrolments = enrolments
    .filter(
      enrolment =>
        enrolment.status === 'APPROVED' || enrolment.status === 'COMPLETE'
    )
    .sort((one, two) => {
      const userOne = users[one.userId];
      const userTwo = users[two.userId];

      if (userOne && userTwo) {
        if (!userTwo.basic) return -1;
        if (!userOne.basic) return 1;

        const lastNameOne = userOne.basic.lastName.trim();
        const lastNameTwo = userTwo.basic.lastName.trim();

        if (lastNameOne < lastNameTwo) {
          return -1;
        }

        if (lastNameOne > lastNameTwo) {
          return 1;
        }
      }

      return 0;
    });

  return (
    <>
      <Title order={2} mb="xl">
        {event.name}
      </Title>
      <Table>
        <thead>
          <tr>
            <th>Count</th>
            <th>User</th>
            <th>LHD</th>
            <th
              style={{
                width: '128px',
                borderLeft: '0.0625rem solid #dee2e6',
                borderRight: '0.0625rem solid #dee2e6'
              }}
            >
              Employee Number
            </th>
            {event.instances.map((instance, index) => (
              <th
                key={instance.id}
                style={
                  index < event.instances.length - 1
                    ? { borderRight: '0.0625rem solid #dee2e6' }
                    : {}
                }
              >
                {dayjs(instance.startsAt).format('DD MMM')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedEnrolments.map((enrolment, index) => {
            const user = users[enrolment.userId];

            return (
              <tr key={enrolment.id}>
                <td width={56}>{index + 1}</td>
                <td>
                  {user?.basic
                    ? `${user.basic.lastName}, ${user.basic.firstName}`
                    : user?.email}
                </td>
                <td>{user?.nsw?.lhd ? NSWHealthLHD[user.nsw.lhd] : 'None'}</td>
                <td
                  width={128}
                  style={{
                    borderLeft: '0.0625rem solid #dee2e6',
                    borderRight: '0.0625rem solid #dee2e6'
                  }}
                />
                {event.instances.map((instance, ei) => (
                  <td
                    width={140}
                    key={instance.id}
                    style={
                      ei < event.instances.length - 1
                        ? { borderRight: '0.0625rem solid #dee2e6' }
                        : {}
                    }
                  />
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  users: state.users.LEARNER,
  isLoading:
    state.event.isLoading ||
    state.users.isLoading ||
    state.eventEnrolment.isLoading,
  error: state.event.error || state.users.error || state.eventEnrolment.error,
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists
});

const mapDispatchToProps = {
  fetchUsersByRole: fetchUsersByRoleRoutine,
  fetchEvent: fetchEventRoutine,
  fetchEnrolmentsForEvent: fetchEnrolmentsForEventRoutine
};

export const AttendanceSheet = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceSheetComponent);
