import React from 'react';
import { connect } from 'react-redux';
import { EventEnrolmentDto } from 'types/api/event';
import { RootState, UserIndex } from 'types/state';
import { PreferencesTableStatic } from './PreferencesTableStatic';
import {
  CommunityMentalHealthCentre,
  Discipline,
  EmploymentType,
  ExperienceWithEatingDisorder,
  Headspace,
  HeadToHealth,
  NSWHealthLHD,
  PrimaryHealthNetwork
} from 'enums/user-info';
import { AdminPreferenceTable } from './AdminPreferenceTable';
import { UserDto } from 'types/api/users';

type UnprioritisedPreferencesProps = {
  eventId: string;
  learners: UserIndex;
  allEnrolments: Record<string, EventEnrolmentDto>;
  enrolmentLists: Record<string, string[]>;
  coordinatoreFor: keyof typeof NSWHealthLHD;
};

const getServiceName = (user: UserDto): string => {
  if (user.primaryEmployment?.companyName)
    return user.primaryEmployment.companyName;

  if (user.primaryEmployment?.type === 'HEAD_TO_HEALTH')
    return HeadToHealth[user.primaryEmployment.headToHealth];

  if (user.primaryEmployment?.type === 'HEADSPACE')
    return Headspace[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'HOSPITAL_SETTING')
    return 'Hospital Setting';

  if (user.primaryEmployment?.type === 'PRIMARY_HEALTH_NETWORK')
    return PrimaryHealthNetwork[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'COMMUNITY_MENTAL_HEALTH_CENTRE')
    return CommunityMentalHealthCentre[
      user.primaryEmployment.communityMentalHealthCentre
    ];

  return '';
};

const UnprioritisedPreferencesComponent: React.FC<
  UnprioritisedPreferencesProps
> = ({ eventId, learners, allEnrolments, enrolmentLists, coordinatoreFor }) => {
  const enrolments = enrolmentLists[eventId]
    ? enrolmentLists[eventId]
        .map(i => allEnrolments[i])
        .filter(e => {
          if (!coordinatoreFor) {
            return e.priority === null;
          }

          const user = learners[e.userId];

          if (!user?.nsw?.lhd) {
            return e.priority === null;
          }

          return e.priority === null && coordinatoreFor !== user.nsw.lhd;
        })
    : [];

  const preferences =
    Object.values(learners).length > 0
      ? enrolments.map(enrolment => {
          const user = learners[enrolment.userId];

          return {
            user_id: user.id,
            name: user.basic
              ? `${user.basic.firstName} ${user.basic.lastName}`
              : '',
            email: user.email,
            lhd: user.nsw?.lhd ? NSWHealthLHD[user.nsw.lhd] : '',
            discipline: user.discipline?.discipline
              ? Discipline[user.discipline.discipline]
              : '',
            work_setting: user.primaryEmployment?.type
              ? EmploymentType[user.primaryEmployment.type]
              : '',
            service_name: getServiceName(user),
            clinical_experience: user.experience?.yearsInPractice
              ? ExperienceWithEatingDisorder[user.experience.yearsInPractice]
              : '',
            ed_experience: user.experience?.experienceWithEatingDisorder
              ? ExperienceWithEatingDisorder[
                  user.experience.experienceWithEatingDisorder
                ]
              : '',
            location: user.basic?.city ?? ''
          };
        })
      : [];

  // return <PreferencesTableStatic enrolments={enrolments} users={learners} />;
  return <AdminPreferenceTable preferences={preferences} />;
};

const mapStateToProps = (state: RootState) => ({
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists,
  coordinatoreFor: state.user.coordinatorFor
});

const mapDispatchToProps = {};

export const UnprioritisedPreferences = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnprioritisedPreferencesComponent);
