import React, { useState } from 'react';
import { CreateEventInstanceDto, EventDto, EventInstanceDto } from 'types/api/event';
import dayjs from 'dayjs';
import { Table, Drawer, Group, Button } from '@mantine/core';
import { InstanceEditor } from './InstanceEditor';
import { UserIndex } from 'types/state';

type Props = {
  event: EventDto;
  trainers: UserIndex;
  createInstance: (arg0: { eventId: string; dto: CreateEventInstanceDto }) => void;
  updateInstance: (arg0: { eventId: string; dto: CreateEventInstanceDto }) => void;
  removeInstance: (arg0: { eventId: string; instanceId: string }) => void;
};

export const InstanceTable: React.FC<Props> = ({ event, trainers, createInstance, updateInstance, removeInstance }) => {
  const [editInstance, setEditInstance] = useState<EventInstanceDto>(null);

  const create = (dto: CreateEventInstanceDto) => {
    createInstance({ eventId: event.id, dto });
    setEditInstance(null);
  };

  const update = (dto: CreateEventInstanceDto) => {
    updateInstance({ eventId: event.id, dto });
    setEditInstance(null);
  };

  const remove = (instanceId: string) => {
    removeInstance({ eventId: event.id, instanceId });
    setEditInstance(null);
  };

  return (
    <>
      <Group position="right">
        <Button
          onClick={() =>
            setEditInstance({
              id: undefined,
              type: 'IN_PERSON',
              startsAt: undefined,
              endsAt: undefined,
              trainerId: undefined
            })
          }
        >
          Add Date
        </Button>
      </Group>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Location</th>
            <th>Trainer</th>
          </tr>
        </thead>
        <tbody>
          {event.instances.map(instance => {
            const startsAt = dayjs(instance.startsAt);
            const endsAt = dayjs(instance.endsAt);
            return (
              <tr key={instance.id} onClick={() => setEditInstance(instance)}>
                <td>{startsAt.format('DD MMM YYYY')}</td>
                <td>{startsAt.format('hh:mm a')}</td>
                <td>{endsAt.format('hh:mm a')}</td>
                <td>{instance.type === 'ONLINE' ? instance.link : `${instance.address}, ${instance.city} (${instance.state})`}</td>
                <td>{instance.trainerId}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Drawer title={`${editInstance?.id ? 'Edit' : 'Add'} Date`} size="lg" position="right" opened={Boolean(editInstance)} onClose={() => setEditInstance(null)}>
        <InstanceEditor instance={editInstance} create={create} update={update} remove={remove} trainers={trainers} />
      </Drawer>
    </>
  );
};
