import { AxiosError } from 'axios';
import { AppError } from '../types/api';

export const rethrowApiError = (error: any): never => {
  if (error instanceof AxiosError<any>) {
    const respError: AppError = error.response.data.error;
    const {
      message,
      validationErrors
    }: {
      message: string;
      validationErrors?: {
        [x: string]: string[];
      };
    } = respError.data;
    if (validationErrors) {
      throw new Error(JSON.stringify(validationErrors, null, 2));
    }
    throw new Error(message);
  }
  throw error;
};
