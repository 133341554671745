import { Select } from '@mantine/core';
import React from 'react';
import { useF2Fs } from '../api/get-f2fs';

export const F2FSelect = React.forwardRef<
  React.ElementRef<typeof Select>,
  Omit<React.ComponentPropsWithoutRef<typeof Select>, 'data'>
>((props, ref) => {
  const f2fsQuery = useF2Fs();

  if (f2fsQuery.isLoading) {
    return null;
  }

  const data = f2fsQuery.data.data?.map(f2f => ({
    value: f2f.id,
    label: f2f.name
  }));

  return <Select ref={ref} data={data} {...props} />;
});
