import { Action } from 'redux-actions';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import { courseService } from 'service/course';
import { courseEnrolmentService } from 'service/course-enrolment';
import {
  fetchCourse,
  fetchCourses,
  createCourse,
  updateCourse,
  enrolUserInCourse,
  createTask,
  updateTask,
  reorderTask,
  removeTask,
  uploadSplash,
  createCourseEmail,
  updateCourseEmail,
  deleteCourseEmail
} from './routines';
import { AdminRoutes } from 'enums/routing';
import { CourseDto, CreateCourseTaskDto, UpdateCourseTaskDto, ReorderCourseTaskDto } from 'types/api/course';
import { NavigateFunction } from 'react-router-dom';
import { AxiosError } from 'axios';
import { CreateCourseDto } from 'types/api/course/create.course.dto';
import { CreateCourseEmailDto } from 'types/api/course/create.course.email.dto';
import { UpdateCourseEmailDto } from 'types/api/course/update.course.email.dto';
import { courseEmailService } from 'service/course-email';

function* fetchCoursesHandler() {
  try {
    const data = yield call(courseService.getAll);
    yield put(fetchCourses.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchCourses.failure(e.message));
    else yield put(fetchCourses.failure('Unknown error.'));
  }
}

function* fetchCourseHandler({ payload: id }: Action<string>) {
  try {
    const data = yield call(courseService.getOne, id);
    yield put(fetchCourse.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchCourse.failure(e.message));
    else yield put(fetchCourse.failure('Unknown error.'));
  }
}

function* createCourseHandler({
  payload: { dto, navigate }
}: Action<{
  dto: CreateCourseDto;
  navigate: NavigateFunction;
}>) {
  try {
    const data = yield call(courseService.create, dto);
    navigate(`${AdminRoutes.courseView}/${data.id}`);
    yield put(createCourse.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createCourse.failure(e.message));
    else yield put(createCourse.failure('Unknown error.'));
  }
}

function* updateCourseHandler({ payload }: Action<CourseDto>) {
  try {
    const retData = yield call(courseService.update, payload);
    yield put(updateCourse.success(retData));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateCourse.failure(e.message));
    else yield put(updateCourse.failure('Unknown error.'));
  }
}

function* enrolUserInCourseHandler({
  payload: { courseId, userId }
}: Action<{
  courseId: string;
  userId: string;
}>) {
  try {
    const data = yield call(courseEnrolmentService.enrolUser, courseId, userId);
    yield put(enrolUserInCourse.success({ courseId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(enrolUserInCourse.failure(e.message));
    else yield put(enrolUserInCourse.failure('Unknown error.'));
  }
}

function* createTaskHandler({
  payload: { courseId, dto }
}: Action<{
  courseId: string;
  dto: CreateCourseTaskDto;
}>) {
  try {
    const data = yield call(courseService.createTask, courseId, dto);
    yield put(createTask.success({ id: courseId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createTask.failure(e.message));
    else yield put(createTask.failure('Unknown error.'));
  }
}

function* updateTaskHandler({
  payload: { courseId, dto }
}: Action<{
  courseId: string;
  dto: UpdateCourseTaskDto;
}>) {
  try {
    const data = yield call(courseService.updateTask, courseId, dto);
    yield put(updateTask.success({ id: courseId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateTask.failure(e.message));
    else yield put(updateTask.failure('Unknown error.'));
  }
}

function* reorderTaskHandler({
  payload: { courseId, dto }
}: Action<{
  courseId: string;
  dto: ReorderCourseTaskDto;
}>) {
  try {
    const data = yield call(courseService.reorderTask, courseId, dto);
    yield put(reorderTask.success({ id: courseId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(reorderTask.failure(e.message));
    else yield put(reorderTask.failure('Unknown error.'));
  }
}

function* removeTaskHandler({
  payload: { courseId, taskId }
}: Action<{
  courseId: string;
  taskId: string;
}>) {
  try {
    const data = yield call(courseService.removeTask, courseId, taskId);
    yield put(removeTask.success({ id: courseId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(removeTask.failure(e.message));
    else yield put(removeTask.failure('Unknown error.'));
  }
}

function* uploadSplashHandler({
  payload: { courseId, file }
}: Action<{
  courseId: string;
  file: File;
}>) {
  try {
    const data = yield call(courseService.uploadSplash, courseId, file);
    yield put(uploadSplash.success({ courseId, splash: data.splash }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(uploadSplash.failure(e.message));
    else yield put(uploadSplash.failure('Unknown error.'));
  }
}

function* createEmailHandler({ payload: dto }: Action<CreateCourseEmailDto>) {
  try {
    const data = yield call(courseEmailService.create, dto);
    yield put(createCourseEmail.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createCourseEmail.failure(e.message));
    else yield put(createCourseEmail.failure('Unknown error.'));
  }
}

function* updateEmailHandler({
  payload: { id, dto, courseId }
}: Action<{
  id: string;
  dto: UpdateCourseEmailDto;
  courseId: string;
}>) {
  try {
    const data = yield call(courseEmailService.update, id, dto);
    yield put(updateCourseEmail.success({ id, data, courseId }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateCourseEmail.failure(e.message));
    else yield put(updateCourseEmail.failure('Unknown error.'));
  }
}

function* deleteEmailHandler({
  payload: { courseId, emailId }
}: Action<{
  courseId: string;
  emailId: string;
}>) {
  try {
    yield call(courseEmailService.delete, emailId);
    yield put(deleteCourseEmail.success({ courseId, emailId }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(deleteCourseEmail.failure(e.message));
    else yield put(deleteCourseEmail.failure('Unknown error.'));
  }
}

export function* courseSaga() {
  yield all([
    takeEvery(fetchCourse.TRIGGER, fetchCourseHandler),
    takeEvery(fetchCourses.TRIGGER, fetchCoursesHandler),
    takeEvery(createCourse.TRIGGER, createCourseHandler),
    takeEvery(updateCourse.TRIGGER, updateCourseHandler),
    takeEvery(enrolUserInCourse.TRIGGER, enrolUserInCourseHandler),
    takeEvery(createTask.TRIGGER, createTaskHandler),
    takeEvery(updateTask.TRIGGER, updateTaskHandler),
    takeEvery(reorderTask.TRIGGER, reorderTaskHandler),
    takeEvery(removeTask.TRIGGER, removeTaskHandler),
    takeEvery(uploadSplash.TRIGGER, uploadSplashHandler),
    takeEvery(createCourseEmail.TRIGGER, createEmailHandler),
    takeEvery(updateCourseEmail.TRIGGER, updateEmailHandler),
    takeEvery(deleteCourseEmail.TRIGGER, deleteEmailHandler)
  ]);
}
