import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserEmployment } from 'types/state';
import { CommunityMentalHealthCentre, EatingDisorderTreatmentService, EducationEnvironment, EmploymentType, Headspace, HeadToHealth, HospitalSetting, PrimaryHealthNetwork } from 'enums/user-info';
import { employmentTypeNeedsName } from 'enums/user-info/employment.type.needs.name';

type Props = {
  employment: UserEmployment;
};

export const DisplayEmployment: React.FC<Props> = ({ employment }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {employment.isPrimary ? 'Primary' : 'Secondary'} workplace:{' '}
        </Text>
        {EmploymentType[employment.type]}
      </Text>
      {employment.type === 'HEAD_TO_HEALTH' && (
        <Text>
          <Text span fw="bold">
            Which head to healht:{' '}
          </Text>
          {HeadToHealth[employment.headToHealth]}
        </Text>
      )}
      {employment.type === 'PRIMARY_HEALTH_NETWORK' && (
        <Text>
          <Text span fw="bold">
            Which PHN:{' '}
          </Text>
          {PrimaryHealthNetwork[employment.phn]}
        </Text>
      )}
      {employmentTypeNeedsName.includes(employment.type) && (
        <Text>
          <Text span fw="bold">
            Team / Organisation / Company Name:{' '}
          </Text>
          {employment.companyName}
        </Text>
      )}
      {employment.type === 'HEADSPACE' && (
        <Text>
          <Text span fw="bold">
            Which Headspace:{' '}
          </Text>
          {Headspace[employment.headspace]}
        </Text>
      )}
      {employment.type === 'HOSPITAL_SETTING' && (
        <Text>
          <Text span fw="bold">
            Which Hospital Settings:{' '}
          </Text>
          {employment.hospitalSetting.map(each => HospitalSetting[each]).join(', ')}
        </Text>
      )}
      {employment.type === 'EDUCATION_TEACHING_ENVIRONMENT' && (
        <Text>
          <Text span fw="bold">
            Which Education Environment:{' '}
          </Text>
          {EducationEnvironment[employment.educationEnvironment]}
        </Text>
      )}
      {employment.type === 'COMMUNITY_MENTAL_HEALTH_CENTRE' && (
        <Text>
          <Text span fw="bold">
            Which Community Mental Health Centre:{' '}
          </Text>
          {CommunityMentalHealthCentre[employment.communityMentalHealthCentre]}
        </Text>
      )}
      {employment.type === 'EATING_DISORDER_TREATMENT_SERVICE' && (
        <Text>
          <Text span fw="bold">
            What type of Eating Disorder Treatment Service:{' '}
          </Text>
          {employment.eatingDisorderTreatmentService.map(each => EatingDisorderTreatmentService[each]).join(', ')}
        </Text>
      )}
    </Box>
  );
};
