import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Droppable,
  DragDropContext,
  DroppableProvided
} from 'react-beautiful-dnd';
import { fetchSurveys as fetchSurveysRoutine } from 'store/survey/routines';
import {
  createTask as createTaskRoutine,
  updateTask as updateTaskRoutine,
  reorderTask as reorderTaskRoutine,
  removeTask as removeTaskRoutine,
  updateCourse as updateCourseRoutine
} from 'store/course/routines';
import { Button, Group, Drawer, Card, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RootState, CourseIndex, SurveyIndex } from 'types/state';
import {
  CourseDto,
  CourseTaskDto,
  CreateCourseTaskDto,
  ReorderCourseTaskDto,
  UpdateCourseTaskDto
} from 'types/api/course';
import { CourseTaskEditor } from './CourseTaskEditor';
import { CourseTaskPage } from './CourseTaskPage';

type Props = {
  id: string;
  courses: CourseIndex;
  surveys: SurveyIndex;
  fetchSurveys: () => void;
  createTask: (arg0: { courseId: string; dto: CreateCourseTaskDto }) => void;
  updateTask: (arg0: { courseId: string; dto: UpdateCourseTaskDto }) => void;
  reorderTask: (arg0: { courseId: string; dto: ReorderCourseTaskDto }) => void;
  removeTask: (arg0: { courseId: string; taskId: string }) => void;
  updateCourse: (arg0: Partial<CourseDto>) => void;
};

const CourseTasksComponent: React.FC<Props> = ({
  id,
  courses,
  surveys,
  fetchSurveys,
  createTask,
  updateTask,
  reorderTask,
  removeTask,
  updateCourse
}) => {
  const [editTask, setEditTask] = useState<CourseTaskDto>(null);
  const course = courses[id];

  useEffect(() => {
    fetchSurveys();
  }, []);

  const onDragEnd = ({ reason, source, destination }) => {
    if (reason === 'CANCEL') return;
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;
    const moving = course.taskPages[source.droppableId].tasks.find(
      t => t.sortIndex === source.index
    );
    reorderTask({
      courseId: course.id,
      dto: {
        taskId: moving.id,
        targetIndex: destination.index,
        targetPage: destination.droppableId
      }
    });
  };

  const create = (dto: CreateCourseTaskDto) => {
    createTask({ courseId: id, dto });
    setEditTask(null);
  };

  const update = (dto: UpdateCourseTaskDto) => {
    updateTask({ courseId: id, dto });
    setEditTask(null);
  };

  const remove = (taskId: string) => {
    removeTask({ courseId: id, taskId });
    setEditTask(null);
  };

  const [editingTitle, setEditingTitle] = useState<string>(null);
  const form = useForm<Partial<CourseDto>>({
    initialValues: {
      pageTitles: course.taskPages.map((p, i) => course.pageTitles[i] || p.name)
    },
    transformValues: values => ({
      ...values,
      id: course.id
    })
  });

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} mt="lg">
        <form
          onSubmit={form.onSubmit(values => {
            updateCourse(values);
            setEditingTitle(null);
          })}
        >
          {course.taskPages.map(page => (
            <CourseTaskPage
              key={page.id}
              page={page}
              setEditTask={setEditTask}
              surveys={surveys}
              form={form}
              editingTitle={editingTitle}
              setEditingTitle={setEditingTitle}
            />
          ))}
        </form>
        <Droppable droppableId={course.taskPages.length.toString()}>
          {(provided: DroppableProvided) => (
            <Card
              withBorder
              shadow="sm"
              my="lg"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Text mb="lg">Create New Page</Text>
              {provided.placeholder}
              <Group position="right" mt="lg">
                <Button
                  onClick={() =>
                    setEditTask({
                      pageIndex: course.taskPages.length.toString(),
                      title: '',
                      type: 'SCORM'
                    })
                  }
                >
                  Add Module
                </Button>
                <Button
                  onClick={() =>
                    setEditTask({
                      pageIndex: course.taskPages.length.toString(),
                      title: '',
                      type: 'SURVEY'
                    })
                  }
                >
                  Add Survey
                </Button>
              </Group>
            </Card>
          )}
        </Droppable>
      </DragDropContext>

      <Drawer
        title={`${editTask?.id ? 'Edit' : 'Create'} Task`}
        opened={Boolean(editTask)}
        onClose={() => setEditTask(null)}
      >
        <CourseTaskEditor
          task={editTask}
          surveys={surveys}
          create={create}
          update={update}
          remove={remove}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses,
  surveys: state.survey.surveys
});

const mapDispatchToProps = {
  fetchSurveys: fetchSurveysRoutine,
  createTask: createTaskRoutine,
  updateTask: updateTaskRoutine,
  reorderTask: reorderTaskRoutine,
  removeTask: removeTaskRoutine,
  updateCourse: updateCourseRoutine
};

export const CourseTasks = connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseTasksComponent);
