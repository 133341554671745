import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserNT } from 'types/state';
import { NTHealthDistrict, NTHealthType } from 'enums/user-info';

type Props = {
  nt: UserNT;
};

export const DisplayNT: React.FC<Props> = ({ nt }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {nt.isNTHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for NT Health.
      </Text>
      {nt.isNTHealth && (
        <Text>
          <Text span fw="bold">
            Where in NT Health:{' '}
          </Text>
          {NTHealthType[nt.type]}
        </Text>
      )}
      {nt.type === 'DISTRICT' && (
        <Text>
          <Text span fw="bold">
            Which Region:{' '}
          </Text>
          {NTHealthDistrict[nt.district]}
        </Text>
      )}
    </Box>
  );
};
