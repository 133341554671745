import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { z } from 'zod';

export const exportSurveyBodySchema = z.object({
  events: z.string().array(),
  users: z.string().array(),
  f2fId: z.string().min(1, 'Required'),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  when: z.enum(['IMMEDIATELY_BEFORE', 'AFTER'])
});

export type ExportSurveyBody = z.infer<typeof exportSurveyBodySchema>;

export const exportSurvey = ({
  data
}: {
  data: ExportSurveyBody;
}): Promise<Blob> => {
  return api.post('/data-export/survey', data, { responseType: 'blob' });
};

type UseExportSurveyOptions = {
  mutationConfig?: MutationConfig<typeof exportSurvey>;
};

export const useExportSurvey = ({
  mutationConfig
}: UseExportSurveyOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: exportSurvey
  });
};
