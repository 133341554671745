import React, { useState } from 'react';
import { F2FTaskDto } from 'types/api/face-to-face';
import { Card, Title, FileButton, Button, Group, Text, ActionIcon } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { UserUploadDto } from 'types/api/user-upload';

type Props = {
  task: F2FTaskDto;
  upload: UserUploadDto | null;
  createUserUpload: (arg0: { taskId: string; file: File }) => void;
};

export const UploadCard: React.FC<Props> = ({ task, upload, createUserUpload }) => {
  const [file, setFile] = useState<File | null>(null);

  if (upload) {
    return (
      <Card withBorder my="md">
        {upload.originalFilename}
      </Card>
    );
  }

  return (
    <Card withBorder my="md">
      <Title order={2}>{task.title}</Title>

      <div dangerouslySetInnerHTML={{ __html: task.body }} />

      {file && (
        <Group>
          <ActionIcon onClick={() => setFile(null)}>
            <IconX />
          </ActionIcon>
          <Text>{file.name}</Text>
        </Group>
      )}

      <Group position="right">
        <FileButton onChange={setFile}>
          {props => (
            <Button {...props} disabled={task.status === 'LOCKED' || Boolean(file)}>
              Select File
            </Button>
          )}
        </FileButton>
        <Button disabled={file === null} onClick={() => createUserUpload({ taskId: task.id, file })}>
          Submit
        </Button>
      </Group>
    </Card>
  );
};
