export enum PrimaryHealthNetwork {
  NORTH_COAST = 'North Coast',
  HUNTER_NEW_ENGLAND_AND_CENTRAL_COAST = 'Hunter New England and Central Coast',
  WESTERN_NSW = 'Western NSW',
  MURRUMBIDGEE = 'Murrumbidgee',
  SOUTH_EASTERN_NSW = 'South Eastern NSW',
  NEPEAN_BLUE_MOUNTAINS = 'Nepean Blue Mountains',
  NORTHERN_SYDNEY = 'Northern Sydney',
  WESTERN_SYDNEY = 'Western Sydney',
  SOUTH_WESTERN_SYDNEY = 'South Western Sydney',
  CENTRAL_AND_EASTERN_SYDNEY = 'Central and Eastern Sydney',
  SOUTH_EASTERN_SYDNEY = 'South Eastern Sydney'
}
