import React from 'react';
import { connect } from 'react-redux';
import { Title, Alert, TextInput, Textarea, NumberInput, Group, Button, SimpleGrid, Container, Text } from '@mantine/core';
import { IconCurrencyDollar } from '@tabler/icons-react';
import { isNotEmpty, useForm } from '@mantine/form';
import { createCourse as createCourseRoutine } from 'store/course/routines';
import { RootState } from 'types/state';
import { CreateCourseDto } from 'types/api/course/create.course.dto';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type Props = {
  isLoading: boolean;
  error?: string;
  createCourse: (arg0: { dto: CreateCourseDto; navigate: NavigateFunction }) => void;
};

const CourseNewComponent: React.FC<Props> = ({ isLoading, error, createCourse }) => {
  const navigate = useNavigate();
  const form = useForm<CreateCourseDto>({
    initialValues: {
      title: '',
      desc: '',
      hours: 0,
      duration: 0,
      purchasePrice: 0,
      renewalDuration: 0,
      renewalPrice: 0
    },
    validate: {
      title: isNotEmpty('This field is required.'),
      desc: isNotEmpty('This field is required.'),
      hours: isNotEmpty('This field is required.'),
      duration: isNotEmpty('This field is required.'),
      purchasePrice: isNotEmpty('This field is required.'),
      renewalDuration: isNotEmpty('This field is required'),
      renewalPrice: isNotEmpty('This field is required.')
    }
  });

  return (
    <Container size="md" my="xl">
      <Title order={3} my="xl">
        Create New Course
      </Title>
      {error && (
        <Alert title="Error creating course" color="red" my="lg">
          {error}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(values => createCourse({ dto: values, navigate }))}>
        <TextInput my="md" label="Course title" {...form.getInputProps('title')} />

        <Textarea my="md" label="Course description" {...form.getInputProps('desc')} />

        <NumberInput my="md" rightSection={<Text>Hours</Text>} rightSectionWidth={50} label="Approximate time to complete" hideControls {...form.getInputProps('hours')} />

        <SimpleGrid cols={2} my="md">
          <NumberInput label="Purchase price" icon={<IconCurrencyDollar />} hideControls {...form.getInputProps('purchasePrice')} />

          <NumberInput label="Access expires after" rightSection={<Text>Days</Text>} rightSectionWidth={50} hideControls {...form.getInputProps('duration')} />
        </SimpleGrid>

        <SimpleGrid cols={2} my="md">
          <NumberInput label="Renewal price" icon={<IconCurrencyDollar />} hideControls {...form.getInputProps('renewalPrice')} />
          <NumberInput label="Renewal lasts for" rightSection={<Text>Days</Text>} rightSectionWidth={50} hideControls {...form.getInputProps('renewalDuration')} />
        </SimpleGrid>

        <Group position="right" mt="xl">
          <Button type="submit" loading={isLoading}>
            Create Course
          </Button>
        </Group>
      </form>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.course.isLoading,
  error: state.course.error
});

const mapDispatchToProps = {
  createCourse: createCourseRoutine
};

export const CourseNew = connect(mapStateToProps, mapDispatchToProps)(CourseNewComponent);
