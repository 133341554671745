import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { AxiosError } from 'axios';
import { userUploadService } from 'service/user-upload';
import { fetchUserUploads, createUserUpload } from './routines';

function* fetchUserUploadsHandler() {
  try {
    const uploads = yield call(userUploadService.getAll);
    yield put(fetchUserUploads.success(uploads));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchUserUploads.failure(e.message));
    else yield put(fetchUserUploads.failure('Unknown error.'));
  }
}

function* createUserUploadHandler({
  payload: { taskId, file }
}: Action<{
  taskId: string;
  file: File;
}>) {
  try {
    const upload = yield call(userUploadService.create, taskId, file);
    yield put(createUserUpload.success(upload));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createUserUpload.failure(e.message));
    else yield put(createUserUpload.failure('Unknown error.'));
  }
}

export function* userUploadSaga() {
  yield all([takeEvery(fetchUserUploads.TRIGGER, fetchUserUploadsHandler), takeEvery(createUserUpload.TRIGGER, createUserUploadHandler)]);
}
