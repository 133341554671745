import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `user/${opName}`;

export const assertTokensAreValid = createRoutine(getPrefix('assert-tokens-are-valid'));

export const login = createRoutine(getPrefix('login'));

export const logout = createRoutine(getPrefix('logout'));

export const activate = createRoutine(getPrefix('activate'));

export const restartAccountActivation = createRoutine(getPrefix('restartActivation'));

export const register = createRoutine(getPrefix('register'));

export const fetchUserInfo = createRoutine(getPrefix('get-user-info'));

export const checkToken = createRoutine(getPrefix('check-token'));

export const setBasic = createRoutine(getPrefix('set-basic'));

export const setNSW = createRoutine(getPrefix('set-nsw'));

export const setVic = createRoutine(getPrefix('set-vic'));

export const setQld = createRoutine(getPrefix('set-qld'));

export const setWA = createRoutine(getPrefix('set-wa'));

export const setSA = createRoutine(getPrefix('set-sa'));

export const setTas = createRoutine(getPrefix('set-tas'));

export const setACT = createRoutine(getPrefix('set-act'));

export const setNT = createRoutine(getPrefix('set-nt'));

export const setEmployment = createRoutine(getPrefix('set-employment'));

export const setDiscipline = createRoutine(getPrefix('set-discipline'));

export const setExperience = createRoutine(getPrefix('set-experience'));

export const setPersonal = createRoutine(getPrefix('set-personal'));

export const registerAndCheckout = createRoutine(getPrefix('register-and-checkout'));

export const registerAndEoI = createRoutine(getPrefix('register-and-eoi'));

export const updatePassword = createRoutine(getPrefix('update-password'));

export const resetPasswordRequest = createRoutine(getPrefix('reset-password-request'));

export const resetPassword = createRoutine(getPrefix('reset-password'));
