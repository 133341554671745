import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { CreateSurveyAnswerDto, SurveyAnswerDto } from 'types/api/survey';

class SurveyAnswerService {
  async getTaskAnswers(taskId: string): Promise<SurveyAnswerDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<SurveyAnswerDto[]>>({
      url: `/survey-answer/f2f-task/${taskId}`,
    });
    return data.data;
  }
  
  async create(dto: CreateSurveyAnswerDto): Promise<SurveyAnswerDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<SurveyAnswerDto>>({
      url: '/survey-answer',
      payload: dto
    });
    return data.data;
  }

  async createPublic(dto: CreateSurveyAnswerDto, d: { userId?: string, email?: string }): Promise<SurveyAnswerDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<SurveyAnswerDto>>({
      url: '/survey-answer/public',
      payload: dto,
      queryParams: {
        userId: d.userId ?? '',
        email: d.email ?? ''
      }
    });
    return data.data;
  }

  async linkUser(id: string, userId: string): Promise<SurveyAnswerDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<SurveyAnswerDto>>({
      url: `/survey-answer/${id}`,
      payload: { userId }
    });

    return data.data;
  }
}

export const surveyAnswerService = new SurveyAnswerService();
