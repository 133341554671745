import React from 'react';
import { Group, Button, TextInput } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { RichTextEditor } from 'components/RichTextEditor';
import { CreateF2FTaskDto, F2FTaskDto, UpdateF2FTaskDto } from 'types/api/face-to-face';

type Props = {
  task: F2FTaskDto;
  create: (dto: CreateF2FTaskDto) => void;
  update: (dto: UpdateF2FTaskDto) => void;
  remove: (id: string) => void;
};

export const UploadTaskEditor: React.FC<Props> = ({
  task,
  create,
  update,
  remove
}) => {
  const form = useForm<CreateF2FTaskDto & UpdateF2FTaskDto>({
    initialValues: task
      ? {
          id: task.id,
          type: 'UPLOAD',
          title: task.title || '',
          body: task.body || '',
          when: task.when
        }
      : {
          id: undefined,
          type: 'UPLOAD',
          title: '',
          body: '',
          when: 'AFTER'
        },
    validate: {
      title: isNotEmpty('A short title is required'),
      body: isNotEmpty('A task description is required')
    }
  });
  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (values.id) update(values);
        else create(values);
      })}
    >
      <TextInput my="md" label="Upload name" {...form.getInputProps('title')} />

      <RichTextEditor my="md" label="Task Text" field="body" form={form} />

      <Group position="right" my="md">
        {task?.id && (
          <Button color="red" onClick={() => remove(task.id)}>
            Delete Task
          </Button>
        )}
        <Button type="submit">{task?.id ? 'Save' : 'Create'}</Button>
      </Group>
    </form>
  );
};
