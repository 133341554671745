import React from 'react';
import { Link } from 'react-router-dom';
import { F2FTaskDto } from 'types/api/face-to-face';
import { Paper, Group, BackgroundImage, ThemeIcon, Text, Center, Button, Skeleton, Box } from '@mantine/core';
import { IconCircle, IconCircleCheck, IconLock } from '@tabler/icons-react';
import { CourseDto } from 'types/api/course';
import { Routes } from 'enums';

type Props = {
  task: F2FTaskDto;
  course: CourseDto;
};

export const CourseCard: React.FC<Props> = ({ task, course }) => {
  if (!course) return <Skeleton h={100} my="md" />;

  return (
    <Paper withBorder my="md">
      <Group>
        <BackgroundImage src={course.splash} w={300}>
          <Center w={300} h={150}>
            {task.status === 'LOCKED' && (
              <ThemeIcon variant="light" size="xl" color="gray">
                <IconLock />
              </ThemeIcon>
            )}
            {task.status === 'COMPLETE' && (
              <ThemeIcon variant="light" size="xl" color="green">
                <IconCircleCheck />
              </ThemeIcon>
            )}
            {task.status === 'INCOMPLETE' && (
              <ThemeIcon variant="light" size="xl" color="blue">
                <IconCircle />
              </ThemeIcon>
            )}
          </Center>
        </BackgroundImage>

        <Box sx={{ flex: 1 }}>
          <Text>{task.title}</Text>
          <Text>{course.title}</Text>
        </Box>

        <Button mr="lg" variant="light" component={Link} to={`${Routes.courseEnrolment}/${course.id}`} disabled={task.status === 'LOCKED'}>
          Go to Course
        </Button>
      </Group>
    </Paper>
  );
};
