import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState, CourseIndex } from 'types/state';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { AdminRoutes } from 'enums/routing/admin-routes';
import { Table, Title, Group, Button, Alert, LoadingOverlay } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

type Props = {
  courses: CourseIndex;
  isLoading: boolean;
  error?: string;
  fetchCourses?: () => void;
};

const CourseListComponent: React.FC<Props> = ({ courses, isLoading, error, fetchCourses }) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchCourses();
  }, []);

  const courseList = Object.values(courses).sort((a, b) => (a.title < b.title ? -1 : 1));

  return (
    <>
      <Group my="xl">
        <Title sx={{ flex: '1 !important' }} order={2}>
          eLearning Courses
        </Title>
        <Button component={Link} to={AdminRoutes.courseNew} leftIcon={<IconPlus />}>
          Create New
        </Button>
      </Group>

      {error && (
        <Alert title="Error Loading Courses" color="red" my="lg">
          {error}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>eLearning</th>
          </tr>
        </thead>
        <tbody>
          {courseList.map(course => (
            <tr key={course.id} onClick={() => navigate(`${AdminRoutes.courseView}/${course.id}`)}>
              <td>{course.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses,
  isLoading: state.course.isLoading,
  error: state.course.error
});

const mapDispatchToProps = {
  fetchCourses: fetchCoursesRoutine
};

export const CourseList = connect(mapStateToProps, mapDispatchToProps)(CourseListComponent);
