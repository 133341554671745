import React, { useEffect } from 'react';
import { Text, Container, Title, Card } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

export const AwaitingActivation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state?.email;

  useEffect(() => {
    if (!email) navigate('/');
  }, [email]);

  return (
    <Container size="xs">
      <Card withBorder my="xl">
        <Title order={4}>Activate your account</Title>
        <Text>An account activation email was sent to {email}. Please check your email for further instructions.</Text>
      </Card>
    </Container>
  );
};
