import { Action } from 'redux-actions';
import { AxiosError } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { uploadService } from 'service/upload';
import { fetchFiles, uploadFileForF2F } from './routines';

function* fetchFilesHandler() {
  try {
    const data = yield call(uploadService.getAll);
    yield put(fetchFiles.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchFiles.failure(e.message));
    else yield put(fetchFiles.failure('Unknown error.'));
  }
}

function* uploadFileForF2FHandler({
  payload: { file, typeId }
}: Action<{
  file: File;
  typeId: string;
}>) {
  try {
    const data = yield call(uploadService.uploadFileForF2F, typeId, file);
    yield put(uploadFileForF2F.success({ typeId, file: data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(uploadFileForF2F.failure(e.message));
    else yield put(uploadFileForF2F.failure('Unknown error.'));
  }
}

export function* fileSaga() {
  yield all([takeEvery(fetchFiles.TRIGGER, fetchFilesHandler), takeEvery(uploadFileForF2F.TRIGGER, uploadFileForF2FHandler)]);
}
