import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { EventEnrolmentExtended } from 'types/backend';

export const getEventEnrolments = ({
  eventId
}: {
  eventId: string;
}): Promise<{ data: Array<EventEnrolmentExtended> }> => {
  return api.get(`/event/${eventId}/enrolments`);
};

export const getEventEnrolmentsQueryOptions = (eventId: string) => {
  return queryOptions({
    queryKey: ['events', 'enrolments', eventId],
    queryFn: () => getEventEnrolments({ eventId })
  });
};

type UseEventEnrolmentsOptions = {
  eventId: string;
  queryConfig?: QueryConfig<typeof getEventEnrolmentsQueryOptions>;
};

export const useEventEnrolments = ({
  eventId,
  queryConfig
}: UseEventEnrolmentsOptions) => {
  return useQuery({
    ...getEventEnrolmentsQueryOptions(eventId),
    ...queryConfig
  });
};
