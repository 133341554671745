export enum Identity {
  LIVED_EXPERIENCE = 'Lived experience of an eating disorder',
  CARER = 'A carer for someone with a lived experience of an eating disorder',
  CALD = 'Culturally and Linguistically Diverse (CALD)',
  LGBTQIA = 'LGBTQIA+',
  REFUGEE = 'Refugee or Asylum Seeker',
  DISABILITY = 'Person with a disability',
  FINANCIAL = 'Financial / social disadvantage',
  NONE = 'None',
  PREFER_NOT_TO_SAY = 'I prefer not to say'
}
