import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Container, Text, Group, Button, Drawer } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { CourseDto } from 'types/api/course';
import { CourseEmailCondition } from 'enums/course/course.email.condition.enum';
import { CreateCourseEmailDto } from 'types/api/course/create.course.email.dto';
import { UpdateCourseEmailDto } from 'types/api/course/update.course.email.dto';
import { createCourseEmail as createCourseEmailRoutine, updateCourseEmail as updateCourseEmailRoutine, deleteCourseEmail as deleteCourseEmailRoutine } from 'store/course/routines';
import { CourseEmailEditor } from './CourseEmailEditor';

type Props = {
  course: CourseDto;
  createCourseEmail: (dto: CreateCourseEmailDto) => void;
  updateCourseEmail: (arg0: { id: string; dto: UpdateCourseEmailDto; courseId: string }) => void;
  deleteCourseEmail: (arg0: { courseId: string; emailId: string }) => void;
};

const CourseEmailsComponent: React.FC<Props> = ({ course, createCourseEmail, updateCourseEmail, deleteCourseEmail }) => {
  const [editingEmail, setEditingEmail] = useState<CreateCourseEmailDto | UpdateCourseEmailDto>(null);

  return (
    <Container size="md">
      {course.emails.map(email => (
        <Card
          key={email.id}
          withBorder
          my="md"
          onClick={() =>
            setEditingEmail({
              action: 'update',
              id: email.id,
              subject: email.subject,
              body: email.body,
              delay: email.delay,
              condition: email.condition
            })
          }
        >
          <Text fw="bold">{email.subject}</Text>
          <Text>
            Sent{' '}
            <Text fw="bold" span>
              {email.delay}
            </Text>{' '}
            days before expiry if
            <Text fw="bold" span>
              {' '}
              {CourseEmailCondition[email.condition]}
            </Text>
          </Text>
        </Card>
      ))}

      <Group position="right">
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setEditingEmail({
              action: 'create',
              subject: '',
              body: '',
              delay: 1,
              condition: 'ALWAYS',
              courseId: course.id
            })
          }
        >
          Add New Email
        </Button>
      </Group>
      <Drawer opened={Boolean(editingEmail)} onClose={() => setEditingEmail(null)} position="right">
        <CourseEmailEditor email={editingEmail} createCourseEmail={createCourseEmail} updateCourseEmail={updateCourseEmail} deleteCourseEmail={deleteCourseEmail} courseId={course.id} />
      </Drawer>
    </Container>
  );
};

const mapDispatchToProps = {
  createCourseEmail: createCourseEmailRoutine,
  updateCourseEmail: updateCourseEmailRoutine,
  deleteCourseEmail: deleteCourseEmailRoutine
};

export const CourseEmails = connect(null, mapDispatchToProps)(CourseEmailsComponent);
