import React from 'react';
import { connect } from 'react-redux';
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, Radio, Alert } from '@mantine/core';
import { setExperience as setExperienceRoutine } from 'store/user/routines';
import { RootState, UserExperience } from 'types/state';

import { YearsInPractice, ExperienceWithEatingDisorder } from 'enums/user-info';

type Props = {
  isLoading: boolean;
  error?: string;
  experience: UserExperience;
  setExperience: (dto: UserExperience) => void;
};

const OnboardingExperienceComponent: React.FC<Props> = ({ isLoading, error, experience, setExperience }) => {
  const form = useForm<UserExperience>({
    initialValues: experience
      ? {
          yearsInPractice: experience.yearsInPractice,
          experienceWithEatingDisorder: experience.experienceWithEatingDisorder
        }
      : {
          yearsInPractice: undefined,
          experienceWithEatingDisorder: undefined
        },
    validate: {
      yearsInPractice: isNotEmpty('This field is required'),
      experienceWithEatingDisorder: isNotEmpty('This field is required')
    }
  });

  return (
    <form onSubmit={form.onSubmit(values => setExperience(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="How many years have you been in professional practice?" {...form.getInputProps('yearsInPractice')}>
        {Object.entries(YearsInPractice).map(([value, label]) => (
          <Radio key={value} {...{ value, label }} />
        ))}
      </Radio.Group>

      <Radio.Group my="lg" label="How many years have you been working with people with an eating disorder?" {...form.getInputProps('experienceWithEatingDisorder')}>
        {Object.entries(ExperienceWithEatingDisorder).map(([value, label]) => (
          <Radio key={value} {...{ value, label }} />
        ))}
      </Radio.Group>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Experience Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  experience: state.user.experience
});

const mapDispatchToProps = {
  setExperience: setExperienceRoutine
};

export const OnboardingExperience = connect(mapStateToProps, mapDispatchToProps)(OnboardingExperienceComponent);
