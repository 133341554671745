import {
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Group,
  MultiSelect,
  Radio,
  Stack
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import { useExportSurvey } from 'features/data-export/api/export-survey';
import { useF2F } from 'features/f2fs/api/get-f2f';
import { F2FSelect } from 'features/f2fs/components/F2FSelect';
import React from 'react';

interface FormData {
  f2f: string;
  events: string[];
  users: string[];
  startDate?: Date;
  endDate?: Date;
  type: 'IMMEDIATELY_BEFORE' | 'AFTER';
}

export const SurveyExportPanel = () => {
  const form = useForm<FormData>({
    initialValues: {
      f2f: '',
      events: [],
      users: [],
      type: 'IMMEDIATELY_BEFORE'
    }
  });

  const f2fQuery = useF2F({
    f2fId: form.values.f2f,
    queryConfig: {
      enabled: !!form.values.f2f
    }
  });

  const exportSurveyMutation = useExportSurvey({
    mutationConfig: {
      onSuccess: data => {
        if (data.type === 'text/csv') {
          window.open(URL.createObjectURL(data));
        }

        if (data.type === 'application/json') {
          throw new Error('No data available for selected export.');
        }
      }
    }
  });

  const events =
    f2fQuery.data?.data?.events.map(event => ({
      value: event.id,
      label: event.name
    })) ?? [];

  const enrolments =
    f2fQuery.data?.data?.enrolments.map(enrolment => ({
      value: enrolment.user_id,
      label: enrolment.first_name
        ? `${enrolment.first_name} ${enrolment.last_name}`
        : enrolment.email
    })) ?? [];

  const onF2FChange = (value: string) => {
    form.reset();
    form.setFieldValue('f2f', value);
  };

  const onEventsChange = (value: string[]) => {
    form.setFieldValue('startDate', null);
    form.setFieldValue('endDate', null);
    form.setFieldValue('events', value);
  };

  const onStartDateChange = (value: Date) => {
    form.setFieldValue('events', []);
    form.setFieldValue('startDate', value);
  };

  const onEndDateChange = (value: Date) => {
    form.setFieldValue('events', []);
    form.setFieldValue('endDate', value);
  };

  return (
    <Center maw={960} mx="auto">
      <form
        onSubmit={form.onSubmit(values =>
          exportSurveyMutation.mutate({
            data: {
              f2fId: values.f2f,
              events: values.events,
              users: values.users,
              startDate: values.startDate,
              endDate: values.endDate,
              when: values.type
            }
          })
        )}
      >
        <Stack>
          {exportSurveyMutation.isError && (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Oops! Looks like we have a problem."
              variant="light"
              color="red"
            >
              {exportSurveyMutation.error.message}
            </Alert>
          )}
          <F2FSelect
            w={480}
            label="Choose a F2F"
            placeholder="Pick one"
            searchable
            nothingFound="No F2F"
            required
            {...form.getInputProps('f2f')}
            onChange={onF2FChange}
          />

          {form.values.f2f && (
            <>
              <Box p="md" sx={{ border: '0.065rem solid #ced4da' }}>
                <MultiSelect
                  label="Choose events"
                  placeholder="Pick as many as you like"
                  w={440}
                  data={events}
                  searchable
                  nothingFound="No events"
                  {...form.getInputProps('events')}
                  onChange={onEventsChange}
                />
                <Divider my="md" label="OR" labelPosition="center" />
                <Group grow>
                  <DatePickerInput
                    label="Start date"
                    {...form.getInputProps('startDate')}
                    onChange={onStartDateChange}
                  />
                  <DatePickerInput
                    label="End date"
                    {...form.getInputProps('endDate')}
                    onChange={onEndDateChange}
                  />
                </Group>
              </Box>
              <MultiSelect
                label="Choose participants (optional)"
                placeholder="Pick as many as you like"
                w={480}
                data={enrolments}
                searchable
                nothingFound="No user"
                {...form.getInputProps('users')}
              />
              <Radio.Group
                name="task-when"
                label="Select survey type"
                {...form.getInputProps('type')}
                required
              >
                <Stack mt="xs">
                  <Radio value="IMMEDIATELY_BEFORE" label="Pre-Event Surveys" />
                  <Radio value="AFTER" label="Post-Event Surveys" />
                </Stack>
              </Radio.Group>
            </>
          )}

          <Button type="submit" loading={exportSurveyMutation.isPending}>
            Download
          </Button>
        </Stack>
      </form>
    </Center>
  );
};
