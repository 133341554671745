import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { TextInput, Title, Stack, Select, MultiSelect, Group, Button, NumberInput, Grid, Divider, Textarea, ActionIcon, LoadingOverlay, Alert } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import { F2FTypeIndex, RootState, UserIndex } from 'types/state';
import { fetchF2FTypes as fetchF2FTypesRoutine } from 'store/face-to-face/routines';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { createEvent as createEventRoutine } from 'store/event/routines';
import { UserRoles } from 'enums';
import { NSWHealthLHD } from 'enums/user-info';
import { CreateEventDto, EventFormType } from 'types/api/event';
import { InstanceForm } from '../components/InstanceForm';

type Props = {
  types: F2FTypeIndex;
  trainers: UserIndex;
  isLoading: boolean;
  error?: string;
  fetchF2FTypes: () => void;
  fetchUsersByRole: (role: UserRoles) => void;
  createEvent: (arg0: { dto: CreateEventDto; navigate: NavigateFunction }) => void;
};

const F2FEventNewComponent: React.FC<Props> = ({ types, trainers, isLoading, error, fetchF2FTypes, fetchUsersByRole, createEvent }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const form = useForm<EventFormType, (arg0: EventFormType) => CreateEventDto>({
    initialValues: {
      name: '',
      details: '',
      f2fId: searchParams.get('for') || '',
      lhds: [],
      classSizeLimit: undefined,
      instances: [
        {
          id: randomId(),
          type: 'IN_PERSON',
          date: undefined,
          start: undefined,
          end: undefined,
          trainerId: ''
        }
      ]
    },
    validate: {
      name: isNotEmpty('Event must have name.'),
      f2fId: isNotEmpty('All events must belong to a type.'),
      classSizeLimit: isNotEmpty('This field is required'),
      instances: {
        trainerId: isNotEmpty('This field is required'),
        date: isNotEmpty('This field is required'),
        start: isNotEmpty('This field is required'),
        end: isNotEmpty('This field is required')
      }
    },
    transformValues: (values): CreateEventDto => ({
      ...values,
      instances: values.instances.map(instance => {
        const startHour = parseInt(instance.start.split(':')[0], 10);
        const startMinute = parseInt(instance.start.split(':')[1], 10);
        const endHour = parseInt(instance.end.split(':')[0], 10);
        const endMinute = parseInt(instance.end.split(':')[1], 10);
        return {
          ...instance,
          startsAt: dayjs(instance.date).hour(startHour).minute(startMinute).format(),
          endsAt: dayjs(instance.date).hour(endHour).minute(endMinute).format()
        };
      })
    })
  });

  useEffect(() => {
    fetchF2FTypes();
    fetchUsersByRole(UserRoles.F2F_FACILITATOR);
  }, []);

  return (
    <form onSubmit={form.onSubmit(values => createEvent({ dto: values, navigate }))}>
      <Stack>
        <Title order={2}>New Event</Title>

        {error && (
          <Alert title="Error creating event" color="red">
            {error}
          </Alert>
        )}

        <Grid>
          <Grid.Col span={6}>
            <Select label="Face to face event type" data={Object.values(types).map(f2f => ({ value: f2f.id, label: f2f.name }))} {...form.getInputProps('f2fId')} />
          </Grid.Col>
          <Grid.Col span={6}>
            <MultiSelect label="Local health districts" data={Object.entries(NSWHealthLHD).map(([value, label]) => ({ label, value }))} {...form.getInputProps('lhds')} />
          </Grid.Col>

          <Grid.Col span={9}>
            <TextInput label="Event name" {...form.getInputProps('name')} />
          </Grid.Col>
          <Grid.Col span={3}>
            <NumberInput label="Class size limit" {...form.getInputProps('classSizeLimit')} />
          </Grid.Col>
        </Grid>

        <Textarea label="Event details" {...form.getInputProps('details')} />

        <Divider />

        {form.values.instances.length === 0 && <Alert color="red">An event must have at least one instance.</Alert>}

        {form.values.instances.map((instance, index) => (
          <div key={instance.id}>
            <Group>
              <Title sx={{ flex: 1 }} order={5}>
                Event Date {index + 1}
              </Title>
              <ActionIcon size="lg" onClick={() => form.removeListItem('instances', index)}>
                <IconX />
              </ActionIcon>
            </Group>
            <InstanceForm prefix={`instances.${index}.`} values={instance} form={form} trainers={trainers} />
          </div>
        ))}

        <Group position="right">
          <Button
            onClick={() =>
              form.insertListItem('instances', {
                id: randomId(),
                type: 'IN_PERSON'
              })
            }
          >
            Add Day
          </Button>
          <Button type="submit">Create Event</Button>
        </Group>
      </Stack>
      <LoadingOverlay visible={isLoading} />
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.event.isLoading,
  error: state.event.error,
  types: state.faceToFace.types,
  trainers: state.users.F2F_FACILITATOR
});

const mapDispatchToProps = {
  fetchF2FTypes: fetchF2FTypesRoutine,
  fetchUsersByRole: fetchUsersByRoleRoutine,
  createEvent: createEventRoutine
};

export const F2FEventNew = connect(mapStateToProps, mapDispatchToProps)(F2FEventNewComponent);
