/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table,
  Skeleton,
  Anchor,
  Group,
  Button,
  ActionIcon,
  Badge,
  Modal,
  Text,
  Switch,
  Stack,
  UnstyledButton,
  Center
} from '@mantine/core';
import {
  IconCheck,
  IconX,
  IconQuestionMark,
  IconEye,
  IconSelector,
  IconChevronDown,
  IconChevronUp
} from '@tabler/icons-react';
import { EventDto, EventEnrolmentDto } from 'types/api/event';
import { RootState, UserIndex } from 'types/state';
import {
  NSWHealthLHD,
  Discipline,
  EmploymentType,
  ExperienceWithEatingDisorder,
  HeadToHealth,
  Headspace,
  PrimaryHealthNetwork,
  CommunityMentalHealthCentre
} from 'enums/user-info';
import { Routes } from 'enums';
import {
  EventEnrolmentStatus,
  EventEnrolmentStatusColors
} from 'enums/event/event.enrolment.status.enum';
import { signoffEvent as signoffEventRoutine } from 'store/event/routines';
import { setEnrolmentStatus as setEnrolmentStatusRoutine } from 'store/event-enrolment/routines';
import dayjs from 'dayjs';
import { UserDto } from 'types/api/users';

type Props = {
  event: EventDto;
  users: UserIndex;
  signoffEvent: (eventId: string) => void;
  setEnrolmentStatus: (arg0: {
    enrolmentId: string;
    status: keyof typeof EventEnrolmentStatus;
  }) => void;
  allEnrolments: Record<string, EventEnrolmentDto>;
  enrolmentLists: Record<string, string[]>;
};

interface ThProps {
  title: string;
  name: 'name' | 'priority' | 'lhd' | 'status';
  sortKey: 'name' | 'priority' | 'lhd' | 'status';
  sorOrder: 'asc' | 'desc';
  onClick: () => void;
}

const getServiceName = (user: UserDto): string => {
  if (user.primaryEmployment?.companyName)
    return user.primaryEmployment.companyName;

  if (user.primaryEmployment?.type === 'HEAD_TO_HEALTH')
    return HeadToHealth[user.primaryEmployment.headToHealth];

  if (user.primaryEmployment?.type === 'HEADSPACE')
    return Headspace[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'HOSPITAL_SETTING')
    return 'Hospital Setting';

  if (user.primaryEmployment?.type === 'PRIMARY_HEALTH_NETWORK')
    return PrimaryHealthNetwork[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'COMMUNITY_MENTAL_HEALTH_CENTRE')
    return CommunityMentalHealthCentre[
      user.primaryEmployment.communityMentalHealthCentre
    ];

  return '';
};

const Th = ({ title, name, sorOrder, sortKey, onClick }: ThProps) => {
  const icon = useMemo(() => {
    if (name === sortKey) {
      if (sorOrder === 'asc') return <IconChevronDown size="20" />;

      return <IconChevronUp size="20" />;
    }

    return <IconSelector size="20" />;
  }, [sorOrder, sortKey]);

  return (
    <th>
      <UnstyledButton onClick={onClick}>
        <Group>
          <Text fw={500} fz="sm">
            {title}
          </Text>
          <Center>{icon}</Center>
        </Group>
      </UnstyledButton>
    </th>
  );
};

const PreferencesTableAdminComponent: React.FC<Props> = ({
  event,
  users,
  signoffEvent,
  setEnrolmentStatus,
  allEnrolments,
  enrolmentLists
}) => {
  const enrolments = enrolmentLists[event.id]
    ? enrolmentLists[event.id].map(i => allEnrolments[i])
    : [];

  const [sortKey, setSortKey] = useState<
    'name' | 'priority' | 'lhd' | 'status'
  >('priority');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const sortedEnrolments = useMemo(() => {
    const lhdCount = new Map<string, number>();

    const sortedEnrolmentList: EventEnrolmentDto[] = [];

    enrolments.forEach(enrolment => {
      const user = users[enrolment.userId];

      if (user) {
        if (!user.nsw || !user.nsw.lhd || enrolment.priority === null)
          sortedEnrolmentList.push(enrolment);
        else {
          const { lhd } = user.nsw;

          if (lhdCount.has(lhd)) lhdCount.set(lhd, lhdCount.get(lhd) + 1);
          else lhdCount.set(lhd, 0);

          const count = lhdCount.get(lhd);

          sortedEnrolmentList.push({ ...enrolment, priority: count });
        }
      }
    });

    const sortMultiplier = sortOrder === 'asc' ? 1 : -1;

    if (sortKey === 'priority') {
      return sortedEnrolmentList.sort((one, two) => {
        if (one.priority === null) return 1;
        if (two.priority === null) return -1;

        if (one.priority < two.priority) return sortMultiplier * -1;
        if (one.priority > two.priority) return sortMultiplier * 1;

        return 0;
      });
    }
    if (sortKey === 'status') {
      return sortedEnrolmentList.sort((one, two) => {
        if (one.status < two.status) return sortMultiplier * -1;
        if (one.status > two.status) return sortMultiplier * 1;

        return 0;
      });
    }
    if (sortKey === 'lhd') {
      return sortedEnrolmentList.sort((one, two) => {
        const userOne = users[one.userId];
        const userTwo = users[two.userId];

        if (!userOne.nsw || !userOne.nsw.lhd) return sortMultiplier * 1;
        if (!userTwo.nsw || !userTwo.nsw.lhd) return sortMultiplier * -1;

        if (userOne.nsw.lhd < userTwo.nsw.lhd) return sortMultiplier * -1;
        if (userOne.nsw.lhd > userTwo.nsw.lhd) return sortMultiplier * 1;

        return 0;
      });
    }
    if (sortKey === 'name') {
      return sortedEnrolmentList.sort((one, two) => {
        const userOne = users[one.userId];
        const userTwo = users[two.userId];

        if (!userOne.basic) return sortMultiplier * 1;
        if (!userTwo.basic) return sortMultiplier * -1;

        if (userOne.basic.lastName < userTwo.basic.lastName)
          return sortMultiplier * -1;
        if (userOne.basic.lastName > userTwo.basic.lastName)
          return sortMultiplier * 1;

        return 0;
      });
    }

    return sortedEnrolmentList;
  }, [users, enrolments, sortKey, sortOrder]);

  const [confirm, setConfirm] = useState<boolean>(false);
  const [isTableSettingsVisible, setIsTableSettingsVisible] =
    useState<boolean>(false);

  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isDisciplineVisible, setIsDisciplineVisible] = useState(false);
  const [isEmploymentSettingVisible, setIsEmploymentSettingVisible] =
    useState(false);
  const [isServiceNameVisible, setIsServiceNameVisible] = useState(false);
  const [isClinicalExperienceVisible, setIsClinicalExperienceVisible] =
    useState(false);
  const [isEDExperienceVisible, setIsEDExperienceVisible] = useState(false);
  const [isLocationVisible, setIsLocationVisible] = useState(false);

  const handleShowHideBtnClick = useCallback(() => {
    setIsTableSettingsVisible(true);
  }, []);

  const handleTableSettingsModalClose = useCallback(() => {
    setIsTableSettingsVisible(false);
  }, []);

  const handleEmailSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsEmailVisible(e.currentTarget.checked);
    },
    []
  );
  const handleDisciplineSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsDisciplineVisible(e.currentTarget.checked);
    },
    []
  );
  const handleEmploymentSettingSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsEmploymentSettingVisible(e.currentTarget.checked);
    },
    []
  );
  const handleServiceNameSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsServiceNameVisible(e.currentTarget.checked);
    },
    []
  );
  const handleClinicalExperienceSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsClinicalExperienceVisible(e.currentTarget.checked);
    },
    []
  );
  const handleEDExperienceSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsEDExperienceVisible(e.currentTarget.checked);
    },
    []
  );
  const handleLocationSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsLocationVisible(e.currentTarget.checked);
    },
    []
  );

  const handleTableColumnClick = useCallback(
    (column: 'name' | 'priority' | 'lhd' | 'status') => {
      if (sortKey === column) {
        setSortOrder(order => (order === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortKey(column);
        setSortOrder('asc');
      }
    },
    [sortKey]
  );

  return (
    <>
      {event.ioiSignoff && (
        <Text my="lg">
          Admin Sign-off was sent on{' '}
          {dayjs(event.ioiSignoff.when).format('DD MMM YYYY')}
        </Text>
      )}
      {!event.ioiSignoff && (
        <Group my="lg" position="right">
          <Button
            color="blue"
            uppercase
            disabled={!enrolments.every(e => e.status !== 'PENDING')}
            onClick={() => setConfirm(true)}
          >
            Lock In Event, Send Acceptance and Rejection Emails
          </Button>
        </Group>
      )}

      <Modal
        opened={confirm}
        onClose={() => setConfirm(false)}
        title="Are you sure?"
        centered
      >
        <Text mb="md">
          Locking in final enrolments will send out acceptance and rejection
          notification emails. No further EOIs can be accepted.{' '}
          <b>This cannot be undone.</b> If you are concerned that, for example,
          there have not been many EOIs for this event, do not lock in event.
          Instead, confirm with coordinator that they are happy for us to close
          EOIs.
        </Text>
        <Group position="center">
          <Button color="gray" onClick={() => setConfirm(false)}>
            Cancel
          </Button>
          <Button
            color="green"
            onClick={() => {
              signoffEvent(event.id);
              setConfirm(false);
            }}
          >
            Confirm
          </Button>
        </Group>
      </Modal>

      <Modal
        opened={isTableSettingsVisible}
        onClose={handleTableSettingsModalClose}
        title="Show  / Hide Table Columns"
        centered
      >
        <Stack>
          <Switch
            label="Email"
            checked={isEmailVisible}
            onChange={handleEmailSwitchToggle}
          />
          <Switch
            label="Discipline"
            checked={isDisciplineVisible}
            onChange={handleDisciplineSwitchToggle}
          />
          <Switch
            label="Employment Setting"
            checked={isEmploymentSettingVisible}
            onChange={handleEmploymentSettingSwitchToggle}
          />
          <Switch
            label="Service Name"
            checked={isServiceNameVisible}
            onChange={handleServiceNameSwitchToggle}
          />
          <Switch
            label="Years of Clinical Experience"
            checked={isClinicalExperienceVisible}
            onChange={handleClinicalExperienceSwitchToggle}
          />
          <Switch
            label="Years of ED Experience"
            checked={isEDExperienceVisible}
            onChange={handleEDExperienceSwitchToggle}
          />
          <Switch
            label="Location"
            checked={isLocationVisible}
            onChange={handleLocationSwitchToggle}
          />
        </Stack>
      </Modal>

      <Stack spacing="xs">
        <Group position="right">
          <Button
            variant="outline"
            color="blue"
            onClick={handleShowHideBtnClick}
          >
            <IconEye style={{ marginRight: '0.25rem' }} />
            <span>Show / Hide</span>
          </Button>
        </Group>

        <Table>
          <thead>
            <tr>
              <th>Count</th>
              <th>Action</th>
              <Th
                title="Participant Name"
                name="name"
                sortKey={sortKey}
                sorOrder={sortOrder}
                onClick={() => handleTableColumnClick('name')}
              />
              <Th
                title="Priority"
                name="priority"
                sortKey={sortKey}
                sorOrder={sortOrder}
                onClick={() => handleTableColumnClick('priority')}
              />
              <Th
                title="LHD"
                name="lhd"
                sortKey={sortKey}
                sorOrder={sortOrder}
                onClick={() => handleTableColumnClick('lhd')}
              />
              <Th
                title="Status"
                name="status"
                sortKey={sortKey}
                sorOrder={sortOrder}
                onClick={() => handleTableColumnClick('status')}
              />
              {isEmailVisible && <th>Email</th>}
              {isDisciplineVisible && <th>Discipline</th>}
              {isEmploymentSettingVisible && <th>Employment Setting</th>}
              {isServiceNameVisible && <th>Service Name</th>}
              {isClinicalExperienceVisible && (
                <th>Years of Clinical Experience</th>
              )}
              {isEDExperienceVisible && <th>Years of ED Experience</th>}
              {isLocationVisible && <th>Location</th>}
            </tr>
          </thead>
          <tbody>
            {sortedEnrolments.map((enrolment, index) => {
              const user = users[enrolment.userId];
              return (
                <tr key={enrolment.id}>
                  <td>{index + 1}</td>
                  <td>
                    <Group>
                      {enrolment.status !== 'APPROVED' && (
                        <ActionIcon
                          size="sm"
                          variant="light"
                          color="green"
                          onClick={() =>
                            setEnrolmentStatus({
                              enrolmentId: enrolment.id,
                              status: 'APPROVED'
                            })
                          }
                        >
                          <IconCheck />
                        </ActionIcon>
                      )}
                      {enrolment.status !== 'PENDING' && (
                        <ActionIcon
                          size="sm"
                          variant="light"
                          color="yellow"
                          onClick={() =>
                            setEnrolmentStatus({
                              enrolmentId: enrolment.id,
                              status: 'PENDING'
                            })
                          }
                        >
                          <IconQuestionMark />
                        </ActionIcon>
                      )}
                      {enrolment.status !== 'REJECTED' && (
                        <ActionIcon
                          size="sm"
                          variant="light"
                          color="red"
                          onClick={() =>
                            setEnrolmentStatus({
                              enrolmentId: enrolment.id,
                              status: 'REJECTED'
                            })
                          }
                        >
                          <IconX />
                        </ActionIcon>
                      )}
                    </Group>
                  </td>
                  <td>
                    {!user && <Skeleton h="2rem" />}
                    {user && !user.basic && user.email}
                    {user && user.basic && (
                      <Anchor
                        component={Link}
                        to={`${Routes.ExpressionOfInterestViewer}/${user.id}`}
                      >
                        {user.basic.firstName} {user.basic.lastName}
                      </Anchor>
                    )}
                  </td>
                  <td>
                    {typeof enrolment.priority === 'number'
                      ? enrolment.priority + 1
                      : ''}
                  </td>
                  <td>
                    {user?.nsw?.lhd ? NSWHealthLHD[user.nsw.lhd] : 'None'}
                  </td>
                  <td>
                    <Badge color={EventEnrolmentStatusColors[enrolment.status]}>
                      {EventEnrolmentStatus[enrolment.status]}
                    </Badge>
                  </td>

                  {isEmailVisible && <td>{user.email}</td>}

                  {isDisciplineVisible && (
                    <td>
                      {user && user.discipline ? (
                        Discipline[user.discipline.discipline]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}

                  {isEmploymentSettingVisible && (
                    <td>
                      {user && user.primaryEmployment ? (
                        EmploymentType[user.primaryEmployment.type]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}

                  {isServiceNameVisible && (
                    <td>
                      {user ? getServiceName(user) : <Skeleton h="2rem" />}
                    </td>
                  )}

                  {isClinicalExperienceVisible && (
                    <td>
                      {user && user.experience ? (
                        ExperienceWithEatingDisorder[
                          user.experience.yearsInPractice
                        ]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}

                  {isEDExperienceVisible && (
                    <td>
                      {user && user.experience ? (
                        ExperienceWithEatingDisorder[
                          user.experience.experienceWithEatingDisorder
                        ]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}

                  {isLocationVisible && (
                    <td>
                      {user && user.basic ? (
                        [user.basic.city]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Stack>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.LEARNER,
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists
});

const mapDispatchToProps = {
  signoffEvent: signoffEventRoutine,
  setEnrolmentStatus: setEnrolmentStatusRoutine
};

export const PreferencesTableAdmin = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferencesTableAdminComponent);
