import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { EventCompletion } from 'types/backend';

export const getEventCompletion = ({
  eventId
}: {
  eventId: string;
}): Promise<{ data: EventCompletion }> => {
  return api.get(`/event/${eventId}/completion`);
};

export const getEventCompletionQueryOptions = (eventId: string) => {
  return queryOptions({
    queryKey: ['events', 'completion', eventId],
    queryFn: () => getEventCompletion({ eventId })
  });
};

type UseEventCompletionOptions = {
  eventId: string;
  queryConfig?: QueryConfig<typeof getEventCompletionQueryOptions>;
};

export const useEventCompletion = ({
  eventId,
  queryConfig
}: UseEventCompletionOptions) => {
  return useQuery({
    ...getEventCompletionQueryOptions(eventId),
    ...queryConfig
  });
};
