import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { getCouponValidationRulesQueryOptions } from './get-coupon-validation-rules';

export const deleteCouponValidatioRule = ({ id }: { id: string }) => {
  return api.delete(`/coupon-validation-rule/${id}`);
};

type UseDeleteCouponValidationRuleOptions = {
  mutationConfig?: MutationConfig<typeof deleteCouponValidatioRule>;
};

export const useDeleteCouponValidationRule = ({
  mutationConfig
}: UseDeleteCouponValidationRuleOptions = {}) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.refetchQueries({
        queryKey: getCouponValidationRulesQueryOptions().queryKey
      });

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: deleteCouponValidatioRule
  });
};
