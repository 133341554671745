import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Card, Text, Title } from '@mantine/core';
import { RootState, UserIndex } from 'types/state';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { UserRoles } from 'enums';
import { DisplayBasic } from './DisplayBasic';
import { DisplayNSW } from './DisplayNSW';
import { DisplayVic } from './DisplayVic';
import { DisplayQld } from './DisplayQld';
import { DisplayWA } from './DisplayWA';
import { DisplaySA } from './DisplaySA';
import { DisplayTas } from './DisplayTas';
import { DisplayACT } from './DisplayACT';
import { DisplayNT } from './DisplayNT';
import { DisplayEmployment } from './DisplayEmployment';
import { DisplayExperience } from './DisplayExperience';
import { DisplayDiscipline } from './DisplayDiscipline';

type Props = {
  users: UserIndex;
  fetchUsersByRole: (role: UserRoles) => void;
};

const ExpressionOfInterestViewerComponent: React.FC<Props> = ({ users, fetchUsersByRole }) => {
  const { id } = useParams();

  useEffect(() => {
    fetchUsersByRole(UserRoles.LEARNER);
  }, []);

  const user = users[id];
  if (!user) return null;

  return (
    <Container size="md">
      <Card my="md" withBorder>
        <Title>Expression of Interest Form</Title>
        <Text mb="lg">
          <Text span fw="bold">
            Email:{' '}
          </Text>
          {user.email}
        </Text>

        {user.basic && <DisplayBasic basic={user.basic} />}
        {user.basic?.state === 'NSW' && user.nsw && <DisplayNSW nsw={user.nsw} />}
        {user.basic?.state === 'VIC' && user.vic && <DisplayVic vic={user.vic} />}
        {user.basic?.state === 'QLD' && user.qld && <DisplayQld qld={user.qld} />}
        {user.basic?.state === 'WA' && user.wa && <DisplayWA wa={user.wa} />}
        {user.basic?.state === 'SA' && user.sa && <DisplaySA sa={user.sa} />}
        {user.basic?.state === 'TAS' && user.tas && <DisplayTas tas={user.tas} />}
        {user.basic?.state === 'ACT' && user.act && <DisplayACT act={user.act} />}
        {user.basic?.state === 'NT' && user.nt && <DisplayNT nt={user.nt} />}
        {user.primaryEmployment && <DisplayEmployment employment={user.primaryEmployment} />}
        {user.secondaryEmployment && <DisplayEmployment employment={user.secondaryEmployment} />}
        {user.discipline && <DisplayDiscipline discipline={user.discipline} />}
        {user.experience && <DisplayExperience experience={user.experience} />}
      </Card>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.LEARNER
});

const mapDispatchToProps = {
  fetchUsersByRole: fetchUsersByRoleRoutine
};

export const ExpressionOfInterestViewer = connect(mapStateToProps, mapDispatchToProps)(ExpressionOfInterestViewerComponent);
