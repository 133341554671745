import React from 'react';
import { Link } from 'react-router-dom';
import { CourseDto } from 'types/api/course';
import { Header, Container, BackgroundImage, Divider, Group, Text, Anchor, RingProgress, Center, Tooltip } from '@mantine/core';
import { IconTrophy, IconInfoCircle, IconArrowLeft } from '@tabler/icons-react';
import logo from 'assets/images/logo.svg';
import logoStaging from 'assets/images/logo-staging.svg';
import { Routes } from 'enums';
import dayjs from 'dayjs';

type Props = {
  course: CourseDto;
  staging?: boolean;
};

export const CourseHeader: React.FC<Props> = ({ course, staging }) => {
  const now = dayjs();
  const expiresAt = course?.enrolment && dayjs(course.enrolment.expiresAt);

  let taskCount = 0;
  let taskSum = 0;
  if (course.taskPages) {
    course.taskPages.forEach(page => {
      page.tasks.forEach(task => {
        taskCount += 1;
        if (task.status === 'COMPLETE') taskSum += 1;
      });
    });
  } else {
    taskCount = 1;
  }
  const percent = 100 * (taskSum / taskCount);

  return (
    <Header height={72}>
      <Container display="flex" size="xl" sx={{ height: '100%', alignItems: 'center' }}>
        <Group>
          <Anchor component={Link} to="/" td="none" display="flex" color="cyan">
            <IconArrowLeft size="2rem" />
          </Anchor>

          <BackgroundImage src={staging ? logoStaging : logo} sx={{ height: '30px', width: '150px' }} component={Link} to="/" mb="6px" />
        </Group>

        <Divider orientation="vertical" mx="xl" my="xl" />

        {course.enrolment && (
          <Group position="right" sx={{ flex: '1 !important' }}>
            <div>
              {now > expiresAt && <Text color="red">Your access is expired.</Text>}
              {now < expiresAt && (
                <Text lh="1">
                  Access Expires
                  <Text fw="bold" color={now < expiresAt.subtract(1, 'week') ? 'dimmed' : 'red'} span>
                    {expiresAt.format(' DD MMM YYYY')} ({expiresAt.diff(now, 'days')} days)
                  </Text>
                </Text>
              )}
              <Anchor lh="1" component={Link} to={`${Routes.CourseStore}/${course.id}`} display="block">
                Purchase Extension
              </Anchor>
            </div>

            {course.enrolment.completed && (
              <Link to={Routes.Certificates}>
                <RingProgress
                  size={72}
                  thickness={6}
                  sections={[{ value: 100, color: 'green' }]}
                  label={
                    <Center c="green">
                      <IconTrophy size="2rem" />
                    </Center>
                  }
                />
              </Link>
            )}

            {!course.enrolment.completed && (
              <RingProgress
                size={72}
                thickness={6}
                sections={[{ value: percent, color: 'gold' }]}
                label={
                  <Center c="gold">
                    <IconTrophy size="2rem" />
                  </Center>
                }
              />
            )}

            <Text fw="bold">Certificate Progress</Text>

            <Tooltip label="Your certificate will become available when you complete all modules of this eLearning">
              <IconInfoCircle />
            </Tooltip>
          </Group>
        )}
      </Container>
    </Header>
  );
};
