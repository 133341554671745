import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { Container, Card, Title, Text, PasswordInput, Alert, Button, Group, Anchor } from '@mantine/core';
import { useForm, matchesField } from '@mantine/form';
import { resetPassword as resetPasswordRoutine } from 'store/user/routines';
import { RootState } from 'types/state';
import { Routes } from 'enums';
import { isStrongPassword } from 'utilities/isStrongPassword';

type PasswordForm = {
  password: string;
  confirmPassword: string;
};

type Props = {
  isLoading: boolean;
  error?: string;
  resetPassword: (arg0: { token: string; password: string }) => void;
};

const ResetPasswordComponent: React.FC<Props> = ({ isLoading, error, resetPassword }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const form = useForm<PasswordForm>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validate: {
      password: isStrongPassword,
      confirmPassword: matchesField('password', 'Passwords must match')
    }
  });
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  return (
    <Container size="xs">
      <Card withBorder my="xl">
        <form
          onSubmit={form.onSubmit(values => {
            resetPassword({ token, password: values.password });
            setSubmitted(true);
          })}
        >
          <Title order={1}>Set a New Password</Title>
          <Text my="md">To request a password reset please enter your email below.</Text>

          {error && (
            <Alert title="Something went wrong" color="red" my="md">
              {error}
            </Alert>
          )}

          {(!submitted || isLoading) && (
            <>
              <PasswordInput my="md" label="New Password" {...form.getInputProps('password')} />

              <PasswordInput my="md" label="Confirm password" {...form.getInputProps('confirmPassword')} />

              <Group position="right" my="md">
                <Button type="submit" loading={isLoading}>
                  Submit
                </Button>
              </Group>
            </>
          )}
          {submitted && !isLoading && !error && (
            <Alert color="green" title="Password changed">
              Your password has been changed, you can{' '}
              <Anchor component={Link} to={Routes.Login}>
                Log In
              </Anchor>{' '}
              with your new password now.
            </Alert>
          )}
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error
});

const mapDispatchToProps = {
  resetPassword: resetPasswordRoutine
};

export const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent);
