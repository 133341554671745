import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `event-enrolment/${opName}`;

export const fetchEnrolmentsForEvent = createRoutine(getPrefix('fetch-for-event'));

export const checkEnrolmentForEvent = createRoutine(getPrefix('check-for-event'));

export const setEnrolmentStatus = createRoutine(getPrefix('set-enrolment-status'));

export const addParticipant = createRoutine(getPrefix('add-participant'));

export const markAttendance = createRoutine(getPrefix('mark-attendance'));
