import { AuthInfoManager } from '../types/api/auth';
import { localStorageService } from './local-storage-service';
import { sessionStorageService } from './session-storage-service';

/** Purpose of this holder is to ensure that different parts of application are
 * using the correct AuthInfoManager, based on users choice whether to remember them.
 *
 * You should only use the holder if you need to interact with low-level
 * layer of authentication, otherwise you MUST use AuthService
 */
export class AuthInfoManagerHolder {
  #manager: AuthInfoManager;

  constructor() {
    if (localStorageService.isAuthenticated()) {
      this.#manager = localStorageService;
    } else {
      this.#manager = sessionStorageService;
    }
  }

  getAuthInfoManager(): AuthInfoManager {
    return this.#manager;
  }

  setAuthInfoManager(newManager: AuthInfoManager) {
    this.#manager = newManager;
  }
}

export const authInfoManagerHolder = new AuthInfoManagerHolder();
