import React, { useEffect, useState } from 'react';
import {
  Box,
  Title,
  Text,
  Group,
  Card,
  Button,
  Image,
  TextInput,
  Loader
} from '@mantine/core';
import { useForm, isEmail } from '@mantine/form';
import { IconClock, IconCalendar } from '@tabler/icons-react';
import { CourseDto } from 'types/api/course';
import { env } from 'env';
import { UserRoles } from 'enums';
import { usePromoCodeValidity } from 'features/stripe/api/check-promo-code';
import { keepPreviousData } from '@tanstack/react-query';

type CheckoutForm = {
  courseId: string;
  email: string;
};

type PromoCodeForm = {
  courseId: string;
  code: string;
};

type Props = {
  userEmail: string;
  isAuthenticated: boolean;
  role: UserRoles;
  course: CourseDto;
  registerAndCheckout: (arg0: CheckoutForm) => void;
};

export const PurchaseCard: React.FC<Props> = ({
  userEmail,
  isAuthenticated,
  role,
  course,
  registerAndCheckout
}) => {
  const baseURL = env.urls.backendOrigin;
  const [emailFormOpen, setEmailFormOpen] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState('');

  const form = useForm<CheckoutForm>({
    initialValues: {
      courseId: course.id,
      email: ''
    },
    validate: {
      email: isEmail('Please enter a valid email address')
    }
  });

  const promoCodeForm = useForm<PromoCodeForm>({
    initialValues: {
      courseId: course.id,
      code: ''
    }
  });

  const isPromoCodeValid = usePromoCodeValidity({
    code: promoCode,
    courseId: promoCodeForm.values.courseId,
    queryConfig: {
      enabled: !!promoCode,
      placeholderData: keepPreviousData
    }
  });

  return (
    <Card shadow="sm" radius="lg" mb="-4rem">
      <Image src={course.splash} height={160} mb="md" />

      {(!isAuthenticated || !course.enrolment) && (
        <Title order={5} color="red">
          ${course.purchasePrice}
        </Title>
      )}
      {isAuthenticated && course.enrolment && (
        <Title order={5} color="red">
          <Text td="line-through" span>
            ${course.purchasePrice}
          </Text>{' '}
          ${course.renewalPrice}
        </Title>
      )}

      <Title order={5}>{course.title}</Title>
      <Group my="xl" color="dimmed">
        <IconClock />
        <Text>~{course.hours} hours</Text>
        <IconCalendar />
        {(!isAuthenticated || !course.enrolment) && (
          <Text>{course.duration} days access</Text>
        )}
        {isAuthenticated && course.enrolment && (
          <Text>
            <Text td="line-through" span>
              {course.duration}
            </Text>{' '}
            {course.renewalDuration} days access
          </Text>
        )}
      </Group>

      {isAuthenticated && (
        <form
          onSubmit={promoCodeForm.onSubmit(values => setPromoCode(values.code))}
        >
          {isPromoCodeValid.data?.data === true && (
            <Text size="sm" color="green" mb="sm">
              Promotion code applied!
            </Text>
          )}

          {isPromoCodeValid.data?.data === false && (
            <Text size="sm" color="red" mb="sm">
              Promotion code is invalid!
            </Text>
          )}

          <Group grow>
            <TextInput
              my="sm"
              placeholder="Add promotion code"
              {...promoCodeForm.getInputProps('code')}
              error=""
            />
            <Button
              type="submit"
              color="red"
              loading={isPromoCodeValid.isLoading}
            >
              Apply
            </Button>
          </Group>
        </form>
      )}

      {isAuthenticated && course.enrolment && (
        <Button
          component="a"
          color="red"
          fullWidth
          href={`${baseURL}/stripe/renew-course?enrolmentId=${course.enrolment.id}&promoCode=${promoCode}`}
          disabled={role !== UserRoles.LEARNER}
        >
          Purchase an Extension
        </Button>
      )}

      {isAuthenticated && !course.enrolment && (
        <Button
          component="a"
          color="red"
          fullWidth
          href={`${baseURL}/stripe/purchase-course/${
            course.id
          }?email=${encodeURIComponent(userEmail)}&promoCode=${promoCode}`}
          disabled={role !== UserRoles.LEARNER}
        >
          Enrol Now
        </Button>
      )}

      {!isAuthenticated && !emailFormOpen && (
        <>
          <Button color="red" fullWidth onClick={() => setEmailFormOpen(true)}>
            Get Started
          </Button>
          <Button
            my="xs"
            color="red"
            fullWidth
            styles={{
              label: { whiteSpace: 'normal' }
            }}
            component="a"
            href="https://insideout-elearning.myshopify.com/collections/headspace-head-to-health-free-training"
          >
            Headspace & Head to Health Q&A Sessions
          </Button>
        </>
      )}

      {!isAuthenticated && emailFormOpen && (
        <form onSubmit={form.onSubmit(registerAndCheckout)}>
          <TextInput
            my="sm"
            placeholder="Email Address"
            {...form.getInputProps('email')}
          />
          <Button color="red" fullWidth type="submit">
            Go to Checkout
          </Button>
        </form>
      )}
    </Card>
  );
};
