import React from 'react';

import { Alert, Group, Text } from '@mantine/core';
import { IconBackhoe } from '@tabler/icons-react';

type Props = {};

export const Supervision: React.FC<Props> = () => {
  return (
    <Alert>
      <Group>
        <IconBackhoe size="4rem" />
        <Text size="lg">Page is under construction, check back later!</Text>
      </Group>
    </Alert>
  );
};
