import {
  Alert,
  Anchor,
  Button,
  LoadingOverlay,
  Tabs,
  Text,
  Title
} from '@mantine/core';
import { AdminRoutes } from 'enums';
import { UserRoles } from 'enums/user-roles';
import QRCode from 'qrcode';
import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  createInstance as createInstanceRoutine,
  fetchEvent as fetchEventRoutine,
  removeInstance as removeInstanceRoutine,
  updateEvent as updateEventRoutine,
  updateInstance as updateInstanceRoutine
} from 'store/event/routines';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { CreateEventInstanceDto, UpdateEventDto } from 'types/api/event';
import { EventIndex, RootState, UserIndex } from 'types/state';
import { EventEnrolmentTable } from './EventEnrolmentTable';
import { EventMetadata } from './EventMetadata';
import { InstanceTable } from './InstanceTable';
import { sanitize } from 'dompurify';

type Props = {
  role: UserRoles;
  events: EventIndex;
  isLoading: boolean;
  error?: string;
  trainers: UserIndex;
  fetchUsersByRole: (role: UserRoles) => void;
  fetchEvent: (id: string) => void;
  updateEvent: (dto: UpdateEventDto) => void;
  createInstance: (arg0: {
    eventId: string;
    dto: CreateEventInstanceDto;
  }) => void;
  updateInstance: (arg0: {
    eventId: string;
    dto: CreateEventInstanceDto;
  }) => void;
  removeInstance: (arg0: { eventId: string; instanceId: string }) => void;
};

const F2FEventViewComponent: React.FC<Props> = ({
  role,
  events,
  isLoading,
  error,
  trainers,
  fetchUsersByRole,
  fetchEvent,
  updateEvent,
  createInstance,
  updateInstance,
  removeInstance
}) => {
  const { id } = useParams();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const event = useMemo(() => events[id], [events, id]);
  const directSurveyLink = useMemo(() => {
    if (event) {
      return `http://localhost:8080/direct-survey/${event.id}`;
    }

    return '';
  }, [event]);

  useEffect(() => {
    fetchEvent(id);
  }, [id]);

  useEffect(() => {
    if (role === UserRoles.IOI_ADMIN)
      fetchUsersByRole(UserRoles.F2F_FACILITATOR);
  }, [role]);

  useEffect(() => {
    if (!canvasRef.current && !directSurveyLink) return;

    QRCode.toCanvas(canvasRef.current, directSurveyLink, { scale: 10 });
  }, [canvasRef, directSurveyLink]);

  if (!event) return null;

  return (
    <div>
      <Title order={2}>{event.name}</Title>

      {error && (
        <Alert title="Error fetching event" color="red" my="lg">
          {error}
        </Alert>
      )}

      <Text dangerouslySetInnerHTML={{ __html: sanitize(event.details) }} />

      {role === UserRoles.IOI_ADMIN && (
        <>
          <Button
            variant="light"
            mt="sm"
            mb="lg"
            component={Link}
            to={`${AdminRoutes.faceToFaceView}/${event.f2fId}`}
          >
            Go to F2F Type
          </Button>

          <Tabs defaultValue="instances">
            <Tabs.List>
              <Tabs.Tab value="instances">Event Dates</Tabs.Tab>
              <Tabs.Tab value="metadata">Metadata</Tabs.Tab>
              <Tabs.Tab value="enrolment">Enrolments</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="instances" my="lg">
              <InstanceTable
                event={event}
                trainers={trainers}
                createInstance={createInstance}
                updateInstance={updateInstance}
                removeInstance={removeInstance}
              />
            </Tabs.Panel>
            <Tabs.Panel value="metadata" my="lg">
              <EventMetadata event={event} updateEvent={updateEvent} />
            </Tabs.Panel>
            <Tabs.Panel value="enrolment" my="lg">
              <EventEnrolmentTable event={event} />
            </Tabs.Panel>
          </Tabs>
        </>
      )}

      {role === UserRoles.F2F_FACILITATOR && (
        <Anchor href={directSurveyLink}>
          <canvas ref={canvasRef} />
        </Anchor>
      )}

      <LoadingOverlay visible={isLoading} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  role: state.user.role,
  events: state.event.events,
  trainers: state.users.F2F_FACILITATOR,
  isLoading: state.event.isLoading,
  error: state.event.error
});

const mapDispatchToProps = {
  fetchUsersByRole: fetchUsersByRoleRoutine,
  fetchEvent: fetchEventRoutine,
  updateEvent: updateEventRoutine,
  createInstance: createInstanceRoutine,
  updateInstance: updateInstanceRoutine,
  removeInstance: removeInstanceRoutine
};

export const F2FEventView = connect(
  mapStateToProps,
  mapDispatchToProps
)(F2FEventViewComponent);
