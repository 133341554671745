import { zxcvbn } from '@zxcvbn-ts/core';

export const isStrongPassword = (value: string): string | null => {
  if (!value) return 'Please specify a password.';
  if (value.length < 8) return 'Password must be at least 8 characters.';
  const zxc = zxcvbn(value);
  if (zxc.score < 3) {
    if (zxc.feedback.warning) return zxc.feedback.warning;
    if (zxc.feedback.suggestions) return zxc.feedback.suggestions.join(', ');
    return 'Password not strong enough.';
  }
  return null;
};
