import React from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { Draggable } from '@hello-pangea/dnd';

interface DraggableRowProps<TData> {
  row: Row<TData>;
}

export const DraggableRow = <TData,>({ row }: DraggableRowProps<TData>) => {
  return (
    <Draggable draggableId={row.id} index={row.index}>
      {provided => (
        <tr
          ref={provided.innerRef}
          key={row.id}
          data-state={row.getIsSelected() && 'selected'}
          {...provided.draggableProps}
        >
          {row.getVisibleCells().map(cell => (
            <td
              key={cell.id}
              {...(cell.column.id === 'handle' && provided.dragHandleProps)}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      )}
    </Draggable>
  );
};
