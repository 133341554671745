import React from 'react';

import { TextInput, Textarea, Stack, Switch, Button, SegmentedControl, Collapse, Text, Grid, NumberInput, ActionIcon } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import { SurveyDto, SurveyQuestionDto } from 'types/api/survey';
import { SurveyQuestionType } from 'enums/survey/f2f-survey-type';
import { RichTextEditor } from 'components/RichTextEditor';

type Props<T extends SurveyDto | SurveyQuestionDto> = {
  form: UseFormReturnType<T>;
  prefix?: string;
  values: SurveyQuestionDto;
};

// Pardon the typescript generic nonsense. I want this component to work for
// both editing questions and editing surveys and this seems the easiest way.
// If you have a better idea please go ahead and improve it. ~ Patrick
export const QuestionForm = <T extends SurveyDto | SurveyQuestionDto>({ form, prefix = '', values }: Props<T>) => {
  return (
    <>
      <SegmentedControl data={Object.entries(SurveyQuestionType).map(([value, label]) => ({ label, value }))} {...form.getInputProps(`${prefix}type`)} />

      <Collapse in={values.type !== 'COMMENT'}>
        <Textarea label="Question text" autosize minRows={1} maxRows={4} {...form.getInputProps(`${prefix}text`)} />
      </Collapse>

      <Collapse in={values.type === 'COMMENT'}>
        <RichTextEditor label="Comment" form={form} field={`${prefix}text`} />
      </Collapse>

      <Collapse in={['CHECKBOX', 'RADIO', 'LIKERT'].includes(values.type)}>
        <Stack>
          {values.options.map((option, index) => (
            <Grid key={option.id} align="end">
              <Grid.Col span={6}>
                <TextInput label={`Option ${index + 1} Text`} {...form.getInputProps(`${prefix}options.${index}.text`)} />
              </Grid.Col>

              <Grid.Col span={5}>
                <NumberInput label={`Option ${index + 1} Value`} {...form.getInputProps(`${prefix}options.${index}.value`)} />
              </Grid.Col>
              <Grid.Col span={1}>
                <ActionIcon size="lg" onClick={() => form.removeListItem(`${prefix}options`, index)}>
                  <IconX />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          ))}
          {values.options.length === 0 && <Text>No options</Text>}

          <Button
            onClick={() =>
              form.insertListItem(`${prefix}options`, {
                id: randomId(),
                text: ''
              })
            }
          >
            Add Option
          </Button>
        </Stack>
      </Collapse>

      <Collapse in={['CHECKBOX', 'RADIO'].includes(values.type)}>
        <Switch label='User can answer "Other (Please Specify)"' {...form.getInputProps(`${prefix}isOther`, { type: 'checkbox' })} />
      </Collapse>
    </>
  );
};
