import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { PredictionDto } from 'types/api/google-maps';

class GooglemapsService {
  async getPredictions(q: string): Promise<PredictionDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<PredictionDto[]>>({
      url: `googlemaps/predictions?q=${q}`
    });
    return data.data;
  }
}

export const googlemapsService = new GooglemapsService();
