import { AppResponse } from 'types/api';
import { ScormDto } from 'types/api/scorm';
import { axiosAdapter } from 'service/axios-adapter';
import { UpdateScormMetadataDto } from 'types/api/scorm/update.scorm.metadata.dto';

class ScormService {
  constructor() {
    this.getAll = this.getAll.bind(this);
    this.getOne = this.getOne.bind(this);
  }

  async getAll(): Promise<ScormDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<ScormDto[]>>({
      url: '/scorm'
    });
    return data.data;
  }

  async getOne(id: string): Promise<ScormDto> {
    const { data } = await axiosAdapter.doGet<AppResponse<ScormDto>>({
      url: `/scorm/${id}`
    });
    return data.data;
  }

  async updateMetadata(
    dto: UpdateScormMetadataDto
  ): Promise<UpdateScormMetadataDto> {
    const { data } = await axiosAdapter.doPut<
      AppResponse<UpdateScormMetadataDto>
    >({
      url: `/scorm/${dto.id}`,
      payload: dto
    });
    return data.data;
  }

  async setData(id: string, key: string, value: string): Promise<boolean> {
    const { data } = await axiosAdapter.doPut<AppResponse<boolean>>({
      url: `/scorm/${id}/data`,
      payload: { key, value }
    });
    return data.data;
  }

  async uploadSplash(id: string, file: File): Promise<ScormDto> {
    const payload = new FormData();
    payload.append('file', file, file.name);
    const { data } = await axiosAdapter.doPut<AppResponse<ScormDto>>({
      url: `/scorm/${id}/splash`,
      payload
    });
    return data.data;
  }
}

export const scormService = new ScormService();
