import { ActionIcon, Anchor, Badge, Group, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/Table';
import { AdminRoutes } from 'enums';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CouponValidationRule } from 'types/backend';
import { useDeleteCouponValidationRule } from '../api/delete-coupon-validation-rule';
import { useCouponValidationRules } from '../api/get-coupon-validation-rules';

const columns: ColumnDef<CouponValidationRule>[] = [
  {
    header: 'Coupon Name',
    accessorKey: 'stripe_coupon_name'
  },
  {
    header: 'Coupon Id',
    accessorKey: 'stripe_coupon_id'
  },
  {
    header: 'Valid Email Domains',
    cell: ({ row }) => {
      return (
        <Group spacing="xs">
          {row.original.email_domains.map(domain => (
            <Badge key={domain} color="dark">
              {domain}
            </Badge>
          ))}
        </Group>
      );
    }
  },
  {
    header: 'Num of Redemptions',
    cell: ({ row }) => (
      <Anchor
        component={Link}
        to={`${AdminRoutes.coupon}/${row.original.stripe_coupon_id}/redemption`}
      >
        {row.original.redemptions ?? 0}
      </Anchor>
    )
  },
  {
    id: 'actions',
    cell: ({ row, table }) => {
      return (
        <Group spacing="md" position="right">
          <Tooltip label="Edit" withArrow>
            <ActionIcon
              color="blue"
              variant="light"
              onClick={() =>
                // Hacky but best way to do this unfortunately
                (table.options.meta as any)?.onEditClick(row.original.id)
              }
            >
              <IconEdit size="20" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete" withArrow>
            <ActionIcon
              color="red"
              variant="light"
              onClick={() =>
                (table.options.meta as any)?.onDeleteClick(row.original.id)
              }
            >
              <IconTrash size="20" />
            </ActionIcon>
          </Tooltip>
        </Group>
      );
    }
  }
];

interface CouponValidationRuleListProps {
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
}

export const CouponValidationRuleList = ({
  onEditClick,
  onDeleteClick
}: CouponValidationRuleListProps) => {
  const couponValidationRulesQuery = useCouponValidationRules({
    expand: ['redemptions']
  });

  const meta = useMemo(
    () => ({
      onEditClick,
      onDeleteClick
    }),
    [onEditClick, onDeleteClick]
  );

  if (couponValidationRulesQuery.isLoading) return null;

  return (
    <DataTable
      id="coupon-validation-rule"
      columns={columns}
      data={couponValidationRulesQuery.data.data}
      meta={meta}
    />
  );
};
