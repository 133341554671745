import { flexRender, Row as TRow } from '@tanstack/react-table';
import React from 'react';

interface RowProps<TData> {
  row: TRow<TData>;
}

export const Row = <TData,>({ row }: RowProps<TData>) => {
  return (
    <tr key={row.id} data-state={row.getIsSelected() && 'selected'}>
      {row.getVisibleCells().map(cell => (
        <td key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};
