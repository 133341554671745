import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  assertTokensAreValid,
  login,
  logout,
  activate,
  register,
  restartAccountActivation,
  fetchUserInfo,
  checkToken,
  setBasic,
  setPersonal,
  setNSW,
  setVic,
  setQld,
  setWA,
  setSA,
  setTas,
  setACT,
  setNT,
  setEmployment,
  setDiscipline,
  setExperience,
  registerAndCheckout,
  registerAndEoI,
  resetPassword,
  resetPasswordRequest,
  updatePassword
} from './routines';
import { authService } from 'service/auth';
import { Action } from 'redux-actions';
import { LoginDto } from 'types/api/auth';
import {
  UserACT,
  UserBasic,
  UserDiscipline,
  UserEmployment,
  UserExperience,
  UserNSW,
  UserNT,
  UserPersonal,
  UserQld,
  UserSA,
  UserTas,
  UserVic,
  UserWA
} from 'types/state';
import { userService } from 'service/user';
import { AxiosError } from 'axios';

function* assertTokensAreValidHandler() {
  try {
    const user = yield call(authService.assertTokensAreValid);
    if (user) {
      const jwtPayload = yield call(authService.parseJwtPayload);
      yield put(assertTokensAreValid.success(jwtPayload));
      yield put(fetchUserInfo.success(user)); // XXX kinda dodgy
    } else {
      yield put(assertTokensAreValid.failure('Could not validate tokens.'));
    }
  } catch (e: any) {
    if (e.message) yield put(assertTokensAreValid.failure(e.message));
    else yield put(assertTokensAreValid.failure(JSON.stringify(e)));
  }
}

function* loginHandler({
  payload: { email, password, rememberMe }
}: Action<LoginDto>) {
  try {
    const user = yield call(authService.login, email, password, rememberMe);
    const jwtPayload = yield call(authService.parseJwtPayload);
    yield put(login.success(jwtPayload));
    yield put(fetchUserInfo.success(user)); // XXX kinda dodgy, as above
  } catch (e: any) {
    if (e.message) yield put(login.failure(e.message));
    else yield put(login.failure(JSON.stringify(e)));
  }
}

function* activateHandler({
  payload: { token, password }
}: Action<{ token: string; password: string }>) {
  try {
    yield call(authService.activateAccount, token, password);
    const jwtPayload = yield call(authService.parseJwtPayload);
    yield put(activate.success(jwtPayload));
  } catch (e: any) {
    yield put(activate.failure(e.message));
  }
}

function* restartAccountActivationHandler() {
  try {
    yield call(authService.restartAccountActivation);
    yield put(restartAccountActivation.success());
  } catch (e: any) {
    yield put(restartAccountActivation.failure(e.message));
  }
}

function* logoutHandler() {
  yield call(authService.logout);
  yield put(logout.success());
}

function* registerHandler({
  payload: { email, target }
}: Action<{
  email: string;
  target?: string;
}>) {
  try {
    const data = yield call(authService.register, email, target);
    yield put(register.success(data));
  } catch (e: any) {
    yield put(register.failure(e.message));
  }
}

function* fetchUserInfoHandler() {
  try {
    const user = yield call(userService.fetchUserInfo);
    yield put(fetchUserInfo.success(user));
  } catch (e: any) {
    yield put(fetchUserInfo.failure(e.message));
  }
}

function* checkTokenHandler(action: Action<string>) {
  try {
    const token = action.payload;
    yield call(authService.checkToken, token);
    yield put(checkToken.success());
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(checkToken.failure(e.response.data.error.data.message));
    } else {
      yield put(checkToken.failure('Unknown error'));
    }
  }
}

function* setBasicHandler({ payload }: Action<UserBasic>) {
  try {
    const data = yield call(userService.setBasic, payload);
    yield put(setBasic.success(data));
  } catch (e: any) {
    if (e.message) yield put(setBasic.failure(e.message));
    else yield put(setBasic.failure(JSON.stringify(e)));
  }
}

function* setNSWHandler({ payload }: Action<UserNSW>) {
  try {
    const data = yield call(userService.setNSW, payload);
    yield put(setNSW.success(data));
  } catch (e: any) {
    if (e.message) yield put(setNSW.failure(e.message));
    else yield put(setNSW.failure(JSON.stringify(e)));
  }
}

function* setVicHandler({ payload }: Action<UserVic>) {
  try {
    const data = yield call(userService.setVic, payload);
    yield put(setVic.success(data));
  } catch (e: any) {
    if (e.message) yield put(setVic.failure(e.message));
    else yield put(setVic.failure(JSON.stringify(e)));
  }
}

function* setQldHandler({ payload }: Action<UserQld>) {
  try {
    const data = yield call(userService.setQld, payload);
    yield put(setQld.success(data));
  } catch (e: any) {
    if (e.message) yield put(setQld.failure(e.message));
    else yield put(setQld.failure(JSON.stringify(e)));
  }
}

function* setWAHandler({ payload }: Action<UserWA>) {
  try {
    const data = yield call(userService.setWA, payload);
    yield put(setWA.success(data));
  } catch (e: any) {
    if (e.message) yield put(setWA.failure(e.message));
    else yield put(setWA.failure(JSON.stringify(e)));
  }
}

function* setSAHandler({ payload }: Action<UserSA>) {
  try {
    const data = yield call(userService.setSA, payload);
    yield put(setSA.success(data));
  } catch (e: any) {
    if (e.message) yield put(setSA.failure(e.message));
    else yield put(setSA.failure(JSON.stringify(e)));
  }
}

function* setTasHandler({ payload }: Action<UserTas>) {
  try {
    const data = yield call(userService.setTas, payload);
    yield put(setTas.success(data));
  } catch (e: any) {
    if (e.message) yield put(setTas.failure(e.message));
    else yield put(setTas.failure(JSON.stringify(e)));
  }
}

function* setACTHandler({ payload }: Action<UserACT>) {
  try {
    const data = yield call(userService.setACT, payload);
    yield put(setACT.success(data));
  } catch (e: any) {
    if (e.message) yield put(setACT.failure(e.message));
    else yield put(setACT.failure(JSON.stringify(e)));
  }
}

function* setNTHandler({ payload }: Action<UserNT>) {
  try {
    const data = yield call(userService.setNT, payload);
    yield put(setNT.success(data));
  } catch (e: any) {
    if (e.message) yield put(setNT.failure(e.message));
    else yield put(setNT.failure(JSON.stringify(e)));
  }
}

function* setEmploymentHandler({ payload }: Action<UserEmployment>) {
  try {
    const data = yield call(userService.setEmployment, payload);
    yield put(setEmployment.success(data));
  } catch (e: any) {
    if (e.message) yield put(setEmployment.failure(e.message));
    else yield put(setEmployment.failure(JSON.stringify(e)));
  }
}

function* setDisciplineHandler({ payload }: Action<UserDiscipline>) {
  try {
    const data = yield call(userService.setDiscipline, payload);
    yield put(setDiscipline.success(data));
  } catch (e: any) {
    if (e.message) yield put(setDiscipline.failure(e.message));
    else yield put(setDiscipline.failure(JSON.stringify(e)));
  }
}

function* setExperienceHandler({ payload }: Action<UserExperience>) {
  try {
    const data = yield call(userService.setExperience, payload);
    yield put(setExperience.success(data));
  } catch (e: any) {
    if (e.message) yield put(setExperience.failure(e.message));
    else yield put(setExperience.failure(JSON.stringify(e)));
  }
}

function* setPersonalHandler({ payload }: Action<UserPersonal>) {
  try {
    const data = yield call(userService.setPersonal, payload);
    yield put(setPersonal.success(data));
  } catch (e: any) {
    if (e.message) yield put(setPersonal.failure(e.message));
    else yield put(setPersonal.failure(JSON.stringify(e)));
  }
}

function* registerAndCheckoutHandler({
  payload: { courseId, email, promoCode }
}: Action<{
  courseId: string;
  email: string;
  promoCode: string;
}>) {
  try {
    const exists = yield call(userService.doesUserExist, email);
    if (exists) {
      const msg = 'You already have an account. Please log in to continue.';
      window.location.href = `/login?target=/store/${courseId}&email=${encodeURIComponent(
        email
      )}&msg=${msg}`;
    } else {
      const msg =
        'You need an account to purchase. Please create an account continue.';
      window.location.href = `/registration?email=${encodeURIComponent(
        email
      )}&msg=${msg}`;
    }
  } catch (e: any) {
    if (e.message) yield put(registerAndCheckout.failure(e.message));
    else yield put(registerAndCheckout.failure(JSON.stringify(e)));
  }
}

function* registerAndEoIHandler({
  payload: { eventId, email }
}: Action<{
  eventId: string;
  email: string;
}>) {
  try {
    const target = `/expression-of-interest/${eventId}`;
    const exists = yield call(userService.doesUserExist, email);
    if (exists) {
      const msg = 'You already have an account. Please log in to continue.';
      window.location.href = `/login?target=${target}&email=${email}&msg=${msg}`;
    } else {
      yield call(authService.register, email, target);
      yield put(registerAndEoI.success());
    }
  } catch (e: any) {
    if (e.message) yield put(registerAndEoI.failure(e.message));
    else yield put(registerAndEoI.failure(JSON.stringify(e)));
  }
}

function* resetPasswordRequestHandler({ payload: email }: Action<string>) {
  try {
    yield call(authService.resetPasswordRequest, email);
    yield put(resetPasswordRequest.success());
  } catch (e: any) {
    if (e.message) yield put(resetPasswordRequest.failure(e.message));
    else yield put(resetPasswordRequest.failure(JSON.stringify(e)));
  }
}

function* resetPasswordHandler({
  payload: { token, password }
}: Action<{
  token: string;
  password: string;
}>) {
  try {
    yield call(authService.resetPassword, token, password);
    yield put(resetPasswordRequest.success());
  } catch (e: any) {
    if (e.message) yield put(resetPassword.failure(e.message));
    else yield put(resetPassword.failure(JSON.stringify(e)));
  }
}

function* updatePasswordHandler({ payload: password }: Action<string>) {
  try {
    yield call(authService.updatePassword, password);
    yield put(updatePassword.success());
  } catch (e: any) {
    if (e.message) yield put(updatePassword.failure(e.message));
    else yield put(updatePassword.failure(JSON.stringify(e)));
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(assertTokensAreValid.TRIGGER, assertTokensAreValidHandler),
    takeEvery(login.TRIGGER, loginHandler),
    takeEvery(activate.TRIGGER, activateHandler),
    takeEvery(
      restartAccountActivation.TRIGGER,
      restartAccountActivationHandler
    ),
    takeEvery(logout.TRIGGER, logoutHandler),
    takeEvery(register.TRIGGER, registerHandler),
    takeEvery(fetchUserInfo.TRIGGER, fetchUserInfoHandler),
    takeEvery(checkToken.TRIGGER, checkTokenHandler),
    takeEvery(setBasic.TRIGGER, setBasicHandler),
    takeEvery(setNSW.TRIGGER, setNSWHandler),
    takeEvery(setVic.TRIGGER, setVicHandler),
    takeEvery(setQld.TRIGGER, setQldHandler),
    takeEvery(setWA.TRIGGER, setWAHandler),
    takeEvery(setSA.TRIGGER, setSAHandler),
    takeEvery(setTas.TRIGGER, setTasHandler),
    takeEvery(setACT.TRIGGER, setACTHandler),
    takeEvery(setNT.TRIGGER, setNTHandler),
    takeEvery(setEmployment.TRIGGER, setEmploymentHandler),
    takeEvery(setDiscipline.TRIGGER, setDisciplineHandler),
    takeEvery(setExperience.TRIGGER, setExperienceHandler),
    takeEvery(setPersonal.TRIGGER, setPersonalHandler),
    takeEvery(registerAndCheckout.TRIGGER, registerAndCheckoutHandler),
    takeEvery(registerAndEoI.TRIGGER, registerAndEoIHandler),
    takeEvery(resetPasswordRequest.TRIGGER, resetPasswordRequestHandler),
    takeEvery(resetPassword.TRIGGER, resetPasswordHandler),
    takeEvery(updatePassword.TRIGGER, updatePasswordHandler)
  ]);
}
