import React from 'react';
import { CreateEventInstanceDto, EventInstanceDto, EventInstanceFormType } from 'types/api/event';
import { Stack, Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { InstanceForm } from '../components/InstanceForm';
import dayjs from 'dayjs';
import { UserIndex } from 'types/state';

type Props = {
  instance: EventInstanceDto;
  create: (dto: CreateEventInstanceDto) => void;
  update: (dto: CreateEventInstanceDto) => void;
  remove: (id: string) => void;
  trainers: UserIndex;
};

export const InstanceEditor: React.FC<Props> = ({ instance, create, update, remove, trainers }) => {
  const form = useForm<EventInstanceFormType, (arg0: EventInstanceFormType) => CreateEventInstanceDto>({
    initialValues: {
      id: instance?.id,
      type: instance?.type || 'IN_PERSON',
      trainerId: instance?.trainerId,
      date: instance?.startsAt ? dayjs(instance.startsAt).startOf('day').toDate() : undefined,
      start: instance?.startsAt ? dayjs(instance.startsAt).format('HH:mm') : '',
      end: instance?.endsAt ? dayjs(instance.endsAt).format('HH:mm') : '',
      link: instance?.link || '',
      address: instance?.address || '',
      city: instance?.city || '',
      state: instance?.state || ''
    },
    transformValues: values => {
      const startHour = parseInt(values.start.split(':')[0], 10);
      const startMinute = parseInt(values.start.split(':')[1], 10);
      const endHour = parseInt(values.end.split(':')[0], 10);
      const endMinute = parseInt(values.end.split(':')[1], 10);
      return {
        ...values,
        startsAt: dayjs(values.date).hour(startHour).minute(startMinute).toISOString(),
        endsAt: dayjs(values.date).hour(endHour).minute(endMinute).toISOString()
      };
    }
  });
  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (values.id) update(values);
        else create(values);
      })}
    >
      <Stack>
        <InstanceForm form={form} values={form.values} trainers={trainers} />

        <Group>
          {instance?.id && (
            <Button color="red" onClick={() => remove(instance.id)}>
              Delete
            </Button>
          )}
          <Button sx={{ flex: 1 }} type="submit">
            {instance?.id ? 'Save Changes' : 'Create Instance'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
