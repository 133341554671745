import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { RichTextEditor as MantineRTE, Link } from '@mantine/tiptap';
import { Input, DefaultProps } from '@mantine/core';

type Token = {
  name: string;
  value: string;
};

type Props = {
  label: string;
  field: string;
  // XXX I can't work out the correct typescript expression to get this to clear. Good luck!
  form: UseFormReturnType<any>;
  tokens?: Token[];
};

export const RichTextEditor: React.FC<Props & DefaultProps> = ({ label, field, form, tokens = [], ...props }) => {
  const editor = useEditor({
    extensions: [StarterKit, Link],
    content: field.split('.').reduce((values, subfield) => values[subfield], form.values), // fancy form.values[field]
    onUpdate: ({ editor: e }) => form.setFieldValue(field, e.getHTML())
  });

  return (
    <Input.Wrapper label={label} {...props}>
      <MantineRTE editor={editor}>
        <MantineRTE.Toolbar>
          <MantineRTE.ControlsGroup>
            <MantineRTE.Bold />
            <MantineRTE.Italic />
            <MantineRTE.Underline />
            <MantineRTE.Strikethrough />
          </MantineRTE.ControlsGroup>

          <MantineRTE.ControlsGroup>
            <MantineRTE.BulletList />
            <MantineRTE.OrderedList />
          </MantineRTE.ControlsGroup>

          <MantineRTE.ControlsGroup>
            <MantineRTE.Link />
            <MantineRTE.Unlink />
          </MantineRTE.ControlsGroup>

          <MantineRTE.ControlsGroup>
            <MantineRTE.ClearFormatting />
          </MantineRTE.ControlsGroup>

          {tokens.length > 0 && (
            <MantineRTE.ControlsGroup style={{ flexWrap: 'wrap' }}>
              {tokens.map(({ name, value }) => (
                <MantineRTE.Control onClick={() => editor.commands.insertContent(value)} p="4px">
                  {name}
                </MantineRTE.Control>
              ))}
            </MantineRTE.ControlsGroup>
          )}
        </MantineRTE.Toolbar>

        <MantineRTE.Content />
      </MantineRTE>
    </Input.Wrapper>
  );
};
