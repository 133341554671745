export enum Discipline {
  COUNSELLOR = 'Counsellor',
  DIETITIAN = 'Dietitian',
  EXERCISE_PHYSIOLOGIST = 'Exercise Physiologist',
  EXERCISE_PROFESSIONAL = 'Exercise Professional',
  GENERAL_PRACTITIONER = 'General Practitioner',
  JUNIOR_MEDICAL_OFFICER = 'Junior Medical Officer (JMO)',
  MEDICAL_STAFF_SPECIALIST = 'Medical / Staff Specialist',
  MEDICAL_REGISTRAR = 'Medical Registrar',
  MENTAL_HEALTH_NURSE = 'Mental Health Nurse',
  NGO = 'NGO',
  NURSE = 'Nurse',
  OCCUPATIONAL_THERAPIST = 'Occupational Therapist',
  OTHER_EDUCATIONAL_STAFF = 'Other educational staff (primary and secondary)',
  PAEDIATRICIAN = 'Paediatrician',
  PARAMEDIC = 'Paramedic',
  PEER_WORKER = 'Peer Worker',
  PHYSICIAN = 'Physician',
  PHYSIOTHERAPIST = 'Physiotherapist',
  PSYCHIATRIST = 'Psychiatrist',
  PSYCHOLOGIST = 'Psychologist',
  PSYCHOTHERAPIST = 'Psychotherapist',
  RESEARCHER = 'Researcher',
  SCHOOL_COUNSELLOR = 'School Counsellor',
  SCHOOL_NURSE = 'School Nurse',
  SOCIAL_WORKER = 'Social Worker',
  SPEECH_PATHOLOGIST = 'Speech Pathologist',
  TEACHER = 'Teacher',
  OTHER = 'Other'
}
