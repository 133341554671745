import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserACT } from 'types/state';

type Props = {
  act: UserACT;
};

export const DisplayACT: React.FC<Props> = ({ act }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {act.isACTHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for ACT Health.
      </Text>
    </Box>
  );
};
