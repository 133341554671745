import { Accordion, Tabs, Text, Title } from '@mantine/core';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { fetchSurveyAnswersForF2FTask as fetchSurveyAnswersForF2FTaskRoutine } from 'store/survey-answer/routines';
import { F2FTaskDto } from 'types/api/face-to-face';
import { RootState, UserIndex } from 'types/state';
import { SurveyAnswersTable } from './SurveyAnswersTable';
import { EventDto } from 'types/api/event';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { UserRoles } from 'enums';

type Props = {
  events: EventDto[];
  learners: UserIndex;
  immediatelyBefore: F2FTaskDto[];
  after: F2FTaskDto[];
  isUsersLoading: boolean,
  fetchUsersByRole: (arg0: UserRoles) => void;
  fetchSurveyAnswersForF2FTask: (arg0: string) => void;
};

const SurveyAnswersComponent: React.FC<Props> = ({ events, learners, immediatelyBefore, after, isUsersLoading, fetchUsersByRole, fetchSurveyAnswersForF2FTask }) => {
  const immediatelyBeforeTaskIds = useMemo(() => immediatelyBefore.map((task) => task.id), [immediatelyBefore]);
  const afterTaskIds = useMemo(() => after.map((task) => task.id), [after]);


  useEffect(() => {
    immediatelyBeforeTaskIds.map((id) => fetchSurveyAnswersForF2FTask(id))
  }, [immediatelyBeforeTaskIds]);

  useEffect(() => {
    afterTaskIds.map((id) => fetchSurveyAnswersForF2FTask(id))
  }, [afterTaskIds]);

  useEffect(() => {
    if (!isUsersLoading && Object.values(learners).length === 0) fetchUsersByRole(UserRoles.LEARNER);
  }, [isUsersLoading, learners, fetchUsersByRole]);

  return (
    <Tabs defaultValue="pre-event" my="lg">
      <Tabs.List grow>
          <Tabs.Tab value="pre-event">Pre-Event</Tabs.Tab>
          <Tabs.Tab value="post-event">Post-Event</Tabs.Tab>
        </Tabs.List>


        <Tabs.Panel value="pre-event" my="lg">
          <Accordion>
            {immediatelyBefore.length === 0 && (
              <Text mt="xl" fw="700" align='center'>No surveys to display...</Text>
            )}


            {immediatelyBefore.map((task) => (
                <Accordion.Item value={task.id}>
                  <Accordion.Control>
                    <Title order={3}>{task.title}</Title>
                  </Accordion.Control>

                  <Accordion.Panel>
                    <SurveyAnswersTable key={task.id} f2fTaskId={task.id} events={events} />
                  </Accordion.Panel>
                </Accordion.Item>
            ))}
          </Accordion>
        </Tabs.Panel>
        <Tabs.Panel value="post-event" my="lg">
          <Accordion>
            {after.length === 0 && (
              <Text mt="xl" fw="700" align='center'>No surveys to display...</Text>
            )}

            {after.map((task) => (
                <Accordion.Item value={task.id}>
                  <Accordion.Control>
                    <Title order={3}>{task.title}</Title>
                  </Accordion.Control>

                  <Accordion.Panel>
                    <SurveyAnswersTable key={task.id} f2fTaskId={task.id} events={events} />
                  </Accordion.Panel>
                </Accordion.Item>
            ))}
          </Accordion>
        </Tabs.Panel>
    </Tabs>
  )
}

const mapStateToProps = (state: RootState) => ({
  learners: state.users.LEARNER,
  isUsersLoading: state.users.isLoading
});

const mapDispatchToProps = {
  fetchUsersByRole: fetchUsersByRoleRoutine,
  fetchSurveyAnswersForF2FTask: fetchSurveyAnswersForF2FTaskRoutine
};

export const SurveyAnswers = connect(mapStateToProps, mapDispatchToProps)(SurveyAnswersComponent);