/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { Routine } from 'redux-saga-routines';
import { produce } from 'immer';
import {
  fetchCourse,
  fetchCourses,
  createCourse,
  updateCourse,
  enrolUserInCourse,
  createTask,
  updateTask,
  reorderTask,
  removeTask,
  uploadSplash,
  createCourseEmail,
  deleteCourseEmail,
  updateCourseEmail,
  dismissCongratulations
} from './routines';
import { answerSurvey } from 'store/survey/routines';
import { CourseIndex, CourseState } from 'types/state';
import { CourseDto } from 'types/api/course';
import { setScormData } from 'store/scorm/routines';
import { LessonStatusType, ScormDataType } from 'enums/scorm';

const initialState: CourseState = {
  courses: {},
  isLoading: false,
  error: undefined,
  isCongratulations: false
};

export const courseReducer = produce((state: CourseState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case fetchCourses.TRIGGER:
    case fetchCourse.TRIGGER:
    case createCourse.TRIGGER:
    case updateCourse.TRIGGER:
    case enrolUserInCourse.TRIGGER:
    case createTask.TRIGGER:
    case updateTask.TRIGGER:
    case reorderTask.TRIGGER:
    case removeTask.TRIGGER:
    case uploadSplash.TRIGGER:
    case createCourseEmail.TRIGGER:
    case updateCourseEmail.TRIGGER:
    case deleteCourseEmail.TRIGGER:
      state.isLoading = true;
      state.isCongratulations = false;
      state.error = undefined;
      break;
    case dismissCongratulations.TRIGGER:
      state.isCongratulations = false;
      break;
    case fetchCourses.SUCCESS:
      state.isLoading = false;
      state.courses = payload.reduce(
        (acc: CourseIndex, course: CourseDto) => ({
          ...acc,
          [course.id]: course
        }),
        {}
      );
      break;
    case fetchCourse.SUCCESS:
    case createCourse.SUCCESS:
    case updateCourse.SUCCESS:
      state.isLoading = false;
      state.courses[payload.id] = payload;
      break;
    case createTask.SUCCESS:
    case updateTask.SUCCESS:
    case reorderTask.SUCCESS:
    case removeTask.SUCCESS:
      state.isLoading = false;
      state.courses[payload.id].taskPages = payload.data.taskPages;
      break;
    case enrolUserInCourse.SUCCESS: {
      state.isLoading = false;
      const idx = state.courses[payload.courseId].enrolments.findIndex(e => e.id === payload.data.id);
      if (idx === -1) state.courses[payload.courseId].enrolments.push(payload.data);
      else state.courses[payload.courseId].enrolments[idx] = payload.data;
      break;
    }
    case uploadSplash.SUCCESS:
      state.isLoading = false;
      state.courses[payload.courseId].splash = payload.splash;
      break;
    case createCourseEmail.SUCCESS:
      state.isLoading = false;
      state.courses[payload.courseId].emails.push(payload);
      break;
    case updateCourseEmail.SUCCESS: {
      state.isLoading = false;
      const idk = state.courses[payload.courseId].emails.findIndex(e => e.id === payload.id);
      state.courses[payload.courseId].emails[idk] = payload.data;
      break;
    }
    case deleteCourseEmail.SUCCESS:
      state.isLoading = false;
      state.courses[payload.courseId].emails = state.courses[payload.courseId].emails.filter(e => e.id !== payload.emailId);
      break;
    case fetchCourses.FAILURE:
    case fetchCourse.FAILURE:
    case createCourse.FAILURE:
    case updateCourse.FAILURE:
    case enrolUserInCourse.FAILURE:
    case createTask.FAILURE:
    case updateTask.FAILURE:
    case reorderTask.FAILURE:
    case removeTask.FAILURE:
    case uploadSplash.FAILURE:
    case createCourseEmail.FAILURE:
    case updateCourseEmail.FAILURE:
    case deleteCourseEmail.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    case answerSurvey.SUCCESS:
      if (payload.taskType === 'COURSE') {
        const course = state.courses[payload.parentId];
        if (course) {
          let canUnlockPage = false;
          course.taskPages.forEach(page => {
            let canUnlockTask = false;
            if (canUnlockPage) {
              page.status = 'INCOMPLETE';
              canUnlockPage = false;
              canUnlockTask = true;
            }
            page.tasks.forEach(task => {
              if (task.id === payload.data.courseTaskId) {
                task.status = 'COMPLETE';
                canUnlockTask = true;
              } else if (canUnlockTask && task.status === 'LOCKED') {
                task.status = 'INCOMPLETE';
                canUnlockTask = false;
              }
            });
            if (page.tasks.every(task => task.status === 'COMPLETE')) {
              page.status = 'COMPLETE';
              canUnlockPage = true;
            }
          });
          if (course.taskPages.every(page => page.status === 'COMPLETE')) {
            state.isCongratulations = true;
            course.enrolment.completed = new Date();
          }
        }
      }
      break;
    case setScormData.TRIGGER:
      // FIXME probably do this non-optimistically
      if (payload.key !== ScormDataType.LESSON_STATUS) break;
      if (payload.value !== LessonStatusType.COMPLETED && payload.value !== LessonStatusType.PASSED) break;
      Object.values(state.courses).forEach(course => {
        if (!course.enrolment) return;
        let canComplete = false;
        let canUnlockPage = false;
        course.taskPages.forEach(page => {
          let canUnlockTask = false;
          if (canUnlockPage) {
            page.status = 'INCOMPLETE';
            canUnlockPage = false;
            canUnlockTask = true;
          }
          page.tasks.forEach(task => {
            if (task.scormId === payload.id) {
              task.status = 'COMPLETE';
              canUnlockTask = true;
            } else if (canUnlockTask && task.status === 'LOCKED') {
              task.status = 'INCOMPLETE';
              canUnlockTask = false;
            }
          });
          if (page.tasks.every(task => task.status === 'COMPLETE')) {
            page.status = 'COMPLETE';
            canUnlockPage = true;
            canComplete = true;
          }
        });
        // FIXME edge case if you complete a course while working on a different course
        if (canComplete && course.taskPages.every(page => page.status === 'COMPLETE')) {
          state.isCongratulations = true;
          course.enrolment.completed = new Date();
        }
      });
      break;
    // no default
  }
}, initialState);
