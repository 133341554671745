import React from 'react';
import { Link } from 'react-router-dom';
import { EventDto } from 'types/api/event';
import { Routes } from 'enums';
import { EventEnrolmentStatus } from 'enums/event/event.enrolment.status.enum';
import { Card, BackgroundImage, Group, Badge, Text, Progress } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { F2FTaskDto, F2FTypeDto } from 'types/api/face-to-face';

type Props = {
  event: EventDto;
  f2f: F2FTypeDto;
};

export const ActiveCard: React.FC<Props> = ({ event, f2f }) => {
  if (!f2f) return null; // waiting on f2fs loading from server

  return (
    <Card withBorder shadow="sm" component={Link} to={`${Routes.Trainings}/${event.id}`}>
      <Card.Section>
        <BackgroundImage src={f2f.splash}>
          <Group position="right" mih="150px" align="flex-start" p="md">
            <Badge color="green" leftSection={<IconUsers size="1rem" />}>
              {EventEnrolmentStatus.APPROVED}
            </Badge>
          </Group>
        </BackgroundImage>
      </Card.Section>

      <Text fw="bold" mt="md">
        {event.name}
      </Text>

      <Text>
        {dayjs(event.instances[0].startsAt).format('DD MMM')}
        {event.instances.length > 0 && dayjs(event.instances[event.instances.length - 1].startsAt).format(' - DD MMM')}
      </Text>
    </Card>
  );
};
