import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserQld } from 'types/state';
import { QldHealthHSD, QldHealthType } from 'enums/user-info';

type Props = {
  qld: UserQld;
};

export const DisplayQld: React.FC<Props> = ({ qld }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {qld.isQldHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for Qld Health.
      </Text>
      {qld.isQldHealth && (
        <Text>
          <Text span fw="bold">
            Where in Qld Health:{' '}
          </Text>
          {QldHealthType[qld.type]}
        </Text>
      )}
      {qld.type === 'HSD' && (
        <Text>
          <Text span fw="bold">
            Which HSD:{' '}
          </Text>
          {QldHealthHSD[qld.hsd]}
        </Text>
      )}
    </Box>
  );
};
