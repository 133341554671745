import { Alert, LoadingOverlay, Tabs, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSurvey as fetchSurveyRoutine, updateSurvey as updateSurveyRoutine } from 'store/survey/routines';
import { UpdateSurveyDto } from 'types/api/survey';
import { RootState, SurveyIndex } from 'types/state';
import { Questions } from './Questions';
import { SurveyMetadata } from './SurveyMetadata';
import { SurveyPreview } from './SurveyPreview';
import { SurveyUsedIn } from './SurveyUsedIn';

type Props = {
  surveys: SurveyIndex;
  isLoading: boolean;
  error?: string;
  fetchSurvey: (id: string) => void;
  updateSurvey: (dto: UpdateSurveyDto) => void;
};

const SurveyViewComponent: React.FC<Props> = ({ surveys, isLoading, error, fetchSurvey, updateSurvey }) => {
  const { id } = useParams();

  useEffect(() => {
    fetchSurvey(id);
  }, [id]);

  const survey = surveys[id];
  if (!survey) return null;

  return (
    <div>
      <Title order={2} my="lg">
        {survey.title}
      </Title>
      {error && (
        <Alert title="Error loading survey" color="red" my="lg">
          {error}
        </Alert>
      )}
      <LoadingOverlay visible={isLoading} />

      <Tabs defaultValue="preview">
        <Tabs.List>
          <Tabs.Tab value="preview">Preview</Tabs.Tab>
          <Tabs.Tab value="questions">Questions</Tabs.Tab>
          <Tabs.Tab value="metadata">Metadata</Tabs.Tab>
          <Tabs.Tab value="used-in">Used In</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="preview" my="lg">
          <SurveyPreview survey={survey} />
        </Tabs.Panel>
        <Tabs.Panel value="questions" my="lg">
          <Questions survey={survey} />
        </Tabs.Panel>
        <Tabs.Panel value="metadata" my="lg">
          <SurveyMetadata survey={survey} updateSurvey={updateSurvey} />
        </Tabs.Panel>
        <Tabs.Panel value="used-in" my="lg">
          <SurveyUsedIn survey={survey} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  surveys: state.survey.surveys,
  isLoading: state.survey.isLoading,
  error: state.survey.error
});

const mapDispatchToProps = {
  fetchSurvey: fetchSurveyRoutine,
  updateSurvey: updateSurveyRoutine
};

export const SurveyView = connect(mapStateToProps, mapDispatchToProps)(SurveyViewComponent);
