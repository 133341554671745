import { Card, Skeleton, Text, ThemeIcon, Title } from '@mantine/core';
import { IconCalendar, IconLock } from '@tabler/icons-react';
import { SurveyViewer } from 'components/SurveyViewer';
import React from 'react';
import { CourseTaskDto } from 'types/api/course';
import { F2FTaskDto } from 'types/api/face-to-face';
import { CreateSurveyAnswerDto, SurveyDto } from 'types/api/survey';
import { SurveyAnswerCard } from './SurveyAnswerCard';

type Props = {
  task: CourseTaskDto | F2FTaskDto;
  taskType: 'F2F' | 'COURSE';
  parentId: string;
  survey: SurveyDto;
  onSubmit: (dto: CreateSurveyAnswerDto) => void;
};

export const SurveyCard: React.FC<Props> = ({ task, taskType, survey, parentId, onSubmit }) => {
  if (!survey || survey.isLoading) return <Skeleton h={100} my="md" />;

  const answer = survey.answers[task.id];

  if (answer) {
    return <SurveyAnswerCard title={task.title} survey={survey} answer={answer} />;
  }

  if (task.status === 'LOCKED') {
    return (
      <Card withBorder my="md" display="flex" sx={{ alignItems: 'center' }}>
        <ThemeIcon color="gray" radius="lg" size="xl" variant="light">
          <IconLock />
        </ThemeIcon>
        <Title order={2} ml="xl">
          {task.title}
        </Title>
      </Card>
    );
  }

  if (task.status === 'EXPIRED') {
    return (
      <Card withBorder my="md" display="flex" sx={{ alignItems: 'center' }}>
        <ThemeIcon color="yellow" radius="lg" size="xl" variant="light">
          <IconCalendar />
        </ThemeIcon>
        <Text>This survey should be completed before your training. As the training event has passed, this survey is now locked.</Text>
      </Card>
    );
  }

  return (
    <Card withBorder my={{ xs: 'sm', md: 'md', lg: 'lg' }} p={{ base: '1rem', sm: '4rem' }}>
      <SurveyViewer title={task.title} taskId={task.id} parentId={parentId} taskType={taskType} survey={survey} onSubmit={onSubmit} />
    </Card>
  );
};
