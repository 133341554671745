import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Header, Group, BackgroundImage, Divider, Container, Text, Button, Menu } from '@mantine/core';
import { IconChevronDown, IconSettings, IconLogout } from '@tabler/icons-react';
import logo from 'assets/images/logo.svg';
import logoStaging from 'assets/images/logo-staging.svg';
import { Routes } from 'enums';
import { UserBasic } from 'types/state';

type Props = {
  email: string;
  basic: UserBasic;
  staging?: boolean;
  logout: () => void;
};

export const LearnerHeader: React.FC<Props> = ({ email, basic, staging, logout }) => {
  const { pathname } = useLocation();

  return (
    <Header height={72}>
      <Container display="flex" size="xl" sx={{ height: '100%', alignItems: 'center' }}>
        <BackgroundImage src={staging ? logoStaging : logo} sx={{ height: '30px', width: '150px' }} display={{ base: 'none', sm: 'block' }} component={Link} to="/" mb="6px" />
        <Divider orientation="vertical" mx="xl" my="xl" display={{ base: 'none', sm: 'block' }} />

        <Text fw="bold" color="gray" size="lg" display={{ base: 'none', md: 'block' }}>
          eLearning
        </Text>

        <Group position="right" sx={{ flex: '1 !important' }} noWrap>
          <Button color="gray" variant={pathname === Routes.Dashboard ? 'light' : 'outline'} component={Link} to={Routes.Dashboard}>
            Learner Dashboard
          </Button>
          <Button color="gray" display={{ base: 'none', md: 'block' }} variant={pathname === Routes.CourseStore ? 'light' : 'outline'} component={Link} to={Routes.CourseStore}>
            Explore Courses
          </Button>
          <Button color="gray" display={{ base: 'none', md: 'block' }} variant={pathname === Routes.Certificates ? 'light' : 'outline'} component={Link} to={Routes.Certificates}>
            Certificates
          </Button>
          <Menu trigger="hover">
            <Menu.Target>
              <Group display={{ base: 'none', sm: 'flex' }} spacing="xs">
                <Text fw="bold">{basic ? `${basic.firstName} ${basic.lastName}` : email}</Text>
                <IconChevronDown />
              </Group>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item icon={<IconSettings />} component={Link} to={Routes.AccountSettings}>
                Account Settings
              </Menu.Item>
              <Menu.Item icon={<IconLogout />} onClick={() => logout()}>
                Log Out
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Container>
    </Header>
  );
};
