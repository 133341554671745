import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CourseIndex, RootState } from 'types/state';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { Routes } from 'enums/routing/routes';
import { Container, Title, Card, Text, SimpleGrid, Image } from '@mantine/core';
import { GuestLayout } from 'components/GuestLayout';
import backgroundStore from 'assets/images/background-courses.svg';

type Props = {
  courses: CourseIndex;
  fetchCourses: () => void;
};

const CourseStoreComponent: React.FC<Props> = ({ courses, fetchCourses }) => {
  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <GuestLayout
      styles={theme => ({
        root: {
          backgroundColor: theme.colors.gray[0]
        },
        main: {
          padding: 0,
          backgroundImage: `url(${backgroundStore})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 1000px',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'left center'
        }
      })}
    >
      <Container size="md" mt="lg">
        <Title order={1} py="4rem">
          eLearning Courses
        </Title>
        <SimpleGrid cols={3}>
          {Object.values(courses).map(course => (
            <Card key={course.id} withBorder shadow="sm" radius="md" component={Link} to={`${Routes.CourseStore}/${course.id}`}>
              <Card.Section>
                <Image src={course.splash} height={160} />
              </Card.Section>

              <Title order={4} mt="lg">
                {course.title}
              </Title>
              <Text color="gray">{course.desc}</Text>
            </Card>
          ))}
        </SimpleGrid>
      </Container>
    </GuestLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses
});

const mapDispatchToProps = {
  fetchCourses: fetchCoursesRoutine
};

export const CourseStore = connect(mapStateToProps, mapDispatchToProps)(CourseStoreComponent);
