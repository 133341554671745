import {
  Alert,
  Box,
  Button,
  Grid,
  Skeleton,
  Table,
  Text,
  TextInput
} from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { UserRoles } from 'enums';
import { UserDrawer } from 'pages/Administration/Users/UserDrawer';
import React, { useCallback, useMemo, useState } from 'react';
import { UserIndex } from 'types/state';

type Props = {
  users: UserIndex;
  isLoading: boolean;
  error?: string;
  createUser: (arg0: { email: string; role: keyof typeof UserRoles }) => void;
};

export const LearnerTable: React.FC<Props> = ({
  users,
  isLoading,
  error,
  createUser
}) => {
  const [selectedUserId, setSelectedUserId] = useState('');

  const [isDrawerOpen, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: isEmail('Please enter a valid email address')
    },
    transformValues: values => ({
      email: values.email,
      role: 'LEARNER' as keyof typeof UserRoles
    })
  });

  const sortedUsers = useMemo(
    () => Object.values(users).sort((a, b) => (a.email < b.email ? -1 : 1)),
    [users]
  );

  const handleUserClick = useCallback((userId: string) => {
    setSelectedUserId(userId);
    openDrawer();
  }, []);

  const handleFormSubmit = useCallback((arg0: {
    email: string;
    role: keyof typeof UserRoles;
  }) => {
    createUser(arg0);
    form.reset();
  }, [form, createUser]);

  return (
    <>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Grid my="xl">
          <Grid.Col span={10}>
            <TextInput
              placeholder="Learner email"
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              fullWidth
              type="submit"
              loading={isLoading}
              leftIcon={<IconPlus />}
            >
              Create New Learner
            </Button>
          </Grid.Col>
        </Grid>
      </form>

      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map(user => (
            <tr key={user.id} onClick={() => handleUserClick(user.id)}>
              <td>
                <Box td={user.isActive ? 'none' : 'line-through'}>
                  {user.email}
                </Box>
              </td>
            </tr>
          ))}
          {isLoading && (
            <tr>
              <td>
                <Skeleton h="2rem" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {selectedUserId && (
        <UserDrawer
          isOpen={isDrawerOpen}
          user={users[selectedUserId]}
          onClose={closeDrawer}
        />
      )}

      {Object.values(users).length === 0 && !isLoading && (
        <Text>No users found.</Text>
      )}
    </>
  );
};
