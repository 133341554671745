import { Alert, Anchor, Button, Card, Container, Group, LoadingOverlay, Notification, Select, Stack, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconInfoCircle } from '@tabler/icons-react';
import { SurveyCard } from 'components/TaskCards';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { checkEnrolmentForEvent as checkEnrolmentForEventRoutine } from 'store/event-enrolment/routines';
import { answerPublicSurvey as answerPublicSurveyRoutine, fetchSurveysForEvent as fetchSurveysForEventRoutine } from 'store/survey/routines';
import { CreateSurveyAnswerDto, SurveyDto } from 'types/api/survey';
import { EventEnrolmentIndex, EventEnrolmentListIndex, F2FTypeIndex, RootState, SurveyIndex } from 'types/state';
import { fetchF2FType as fetchF2FTypeRoutine } from 'store/face-to-face/routines';
import { subDays, addDays, isBefore, isAfter } from 'date-fns';
import { EventDto } from 'types/api/event';

type Props = {
  types: F2FTypeIndex;
  eventEnrolments: EventEnrolmentIndex;
  eventEnrolmentLists: EventEnrolmentListIndex;
  eventEnrolmentLoading: boolean;
  surveys: SurveyIndex;
  fetchF2FType: (arg0: { id: string }) => void;
  checkEnrolmentForEvent: (arg0: { eventId: string; email: string }) => void;
  fetchSurveysForEvent: (arg0: { eventId: string; userId?: string }) => void;
  answerPublicSurvey?: (arg0: { userId?: string; email?: string; dto: CreateSurveyAnswerDto }) => void;
}

const formatEventDate = (event: EventDto, group?: string) => {
  const { instances } = event;

  const today = new Date();
  const startDate = new Date(instances[0].startsAt);
  const endDate = new Date(instances[instances.length - 1].startsAt);

  const isPast = isBefore(endDate, today);

  let groupName = group;

  if (!groupName) {
    groupName = isPast ? 'Past event(s)' : 'Future event(s)';
  }

  if (instances.length === 1) {
    return {
      value: event.id,
      label: startDate.toLocaleDateString('en-au', { day: 'numeric', month: 'short' }),
      group: groupName
    };
  }

  return {
    value: event.id,
    label: `${startDate.toLocaleDateString('en-au', { day: 'numeric', month: 'short' })} - ${endDate.toLocaleDateString('en-au', { day: 'numeric', month: 'short' })}`,
    group: groupName
  };
}

const DirectSurveyComponent: React.FC<Props> = ({ types, eventEnrolments, eventEnrolmentLists, eventEnrolmentLoading, surveys, fetchF2FType, checkEnrolmentForEvent, fetchSurveysForEvent, answerPublicSurvey }) => {
  const { id, type: surveyType } = useParams();

  const [isPublic, setIsPublic] = useState(false);
  const [eventId, setEventId] = useState('');
  const [email, setEmail] = useState('');

  const form = useForm({
    initialValues: { event: '', email: '' },
    validate: {
      email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email')
    }
  });

  const type = useMemo(() => types[id], [types, id]);

  const events = useMemo(() => {
    if (!type) return [];

    const today = new Date();
    const twoWeeksPast = subDays(today, 14);
    const twoWeeksFuture = addDays(today, 14);

    const filteredEvents = type.events.filter((event) => {
      const { instances } = event;

      if (instances.length === 0) return false;

      const startDate = new Date(instances[0].startsAt);
      const endDate = new Date(instances[instances.length - 1].startsAt);  
      
      if (isBefore(endDate, twoWeeksPast) || isAfter(startDate, twoWeeksFuture)) {
        return false;
      }

      return true;
    });

    if (filteredEvents.length === 0) return [];

    const sortedEvents = filteredEvents.sort((eventOne, eventTwo) => {
      const eventOneStartDate = new Date(eventOne.instances[0].startsAt);
      const eventTwoStartDate = new Date(eventTwo.instances[0].startsAt);

      return +eventOneStartDate - +eventTwoStartDate;
    });

    // Sort by most recent
    const mostRecentEvent = [...sortedEvents].sort((eventOne, eventTwo) => {
      const distanceOne = Math.abs(+today - +new Date(eventOne.instances[0].startsAt));
      const distanceTwo = Math.abs(+today - +new Date(eventTwo.instances[0].startsAt));

      return distanceOne - distanceTwo;
    })[0];

    const filteredSortedEvents = sortedEvents.filter((event) => event.id !== mostRecentEvent.id);

    const formatedEventData = filteredSortedEvents.map((event) => formatEventDate(event));
    return [formatEventDate(mostRecentEvent, 'Recent event(s)'), ...formatedEventData];
  }, [type]);

  const enrolment = useMemo(() => {
    if (!eventId) return undefined;

    const eventEnrolmentList = eventEnrolmentLists[eventId] ?? [];

    if (eventEnrolmentList.length === 0) return undefined;

    return eventEnrolments[eventEnrolmentList[0]];
  }, [eventId, eventEnrolmentLists, eventEnrolments])

  const tasks = useMemo(() => {
    if (!type || !surveyType) return [];

    if (surveyType === 'pre') {
      return type.IMMEDIATELY_BEFORE.map((task) => ({ ...task, status: 'INCOMPLETE' as const }));  
    }

    return type.AFTER.map((task) => ({ ...task, status: 'INCOMPLETE' as const }));
  }, [type, surveyType]);

  const surveyTasks = useMemo(() => {
    const taskSurveys: SurveyDto[] = [];

    tasks.forEach((task) => {
      const survey = surveys[task.surveyId];

      if (survey) taskSurveys.push(survey);
    })

    return taskSurveys;
  }, [tasks, surveys]);

  const isComplete = useMemo(() => surveyTasks.length > 0 && surveyTasks.every(
    (survey, index) => survey.answers[tasks[index]?.id]
  ), [surveyTasks, tasks])


   const handleNoEmailClick = useCallback(() => {
    setIsPublic(true);
    fetchSurveysForEvent({ eventId: id });
  }, []);

  const handleFormSubmit = useCallback(
    (values: { event: string, email: string }) => {
      setEventId(values.event);
      setEmail(values.email.toLowerCase());
      checkEnrolmentForEvent({ eventId: values.event, email: values.email.toLowerCase() });
    },
    [id]
  );

  const handleSurveyAnswerSubmit = useCallback(
    (dto: CreateSurveyAnswerDto) => {
      answerPublicSurvey({ userId: enrolment?.userId, email, dto });
    },
    [answerPublicSurvey, enrolment, email]
  );

  useEffect(() => {
    fetchF2FType({ id });
  }, [id]);

  useEffect(() => {
    if (enrolment) fetchSurveysForEvent({ eventId: enrolment.eventId, userId: enrolment.userId });
  }, [enrolment]);

  useEffect(() => {
    if (enrolment || isPublic) {
      const helpScoutEl = document.getElementById('beacon-container');

      if (helpScoutEl) helpScoutEl.remove();
    }
  }, [enrolment, isPublic]);

  if (!type) return <LoadingOverlay visible />;

  if (enrolment || isPublic) {
    return (
      <Container mt="xl" size="lg">
        {surveyTasks.length === 0 && (
          <Card withBorder>
            <Title order={2} align='center'>No survey to display...</Title>
          </Card>
        )}
        
        {
          isComplete 
          ? (
            <Alert icon={<IconCheck size="1rem" />} color='green' mb="lg" py="md" fw="bold">
              Thank you for completing the surveys! Feel free to close this page, your responses have been saved.
            </Alert>
          ) : (
            <Stack>
              {surveyTasks.map((task, index) => <SurveyCard key={task.id} task={tasks[index]} taskType="F2F" parentId={id} survey={task} onSubmit={handleSurveyAnswerSubmit} />)}
            </Stack>
          )
        }

      </Container>
    )
  }

  return (
    <Container mt="xl" size="lg">
      <Stack spacing='lg'>
        <Card withBorder>
          <Stack spacing="sm">
            <Title order={1}>{type.name}</Title>
            <form onSubmit={form.onSubmit(handleFormSubmit)}>
              <Stack spacing="lg">
                <Stack spacing="xs">
                  <Text fw="600">Choose your event date</Text>
                  <Select 
                    placeholder='Pick one'
                    maxDropdownHeight={120}
                    data={events}
                    { ...form.getInputProps('event') }
                  />
                </Stack>
                <Stack spacing="xs">
                  <Text fw="600">Work email address</Text>
                  <TextInput {...form.getInputProps('email')} />
                </Stack>
              </Stack>
              <Group align="flex-end" mt="md">
                <Button type="submit">Submit</Button>
              </Group>
            </form>
          </Stack>
        </Card>
        {!eventEnrolmentLoading && eventId && (
          <Alert variant="light" color="yellow" icon={<IconInfoCircle />}>
            This email and event date do not match. Please check that you have selected the correct date and email address. If this is the correct email and date,&nbsp;
            <Anchor onClick={handleNoEmailClick} underline>
              continue regardless
            </Anchor>
          </Alert>
        )}
      </Stack>
    </Container>
  )

  // const { id } = useParams();

  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // const [isPublic, setIsPublic] = useState(false);

  // const form = useForm({
  //   initialValues: { email: '' },
  //   validate: {
  //     email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email')
  //   }
  // });

  // const eventEnrolmentList = useMemo(() => eventEnrolmentLists[id], [eventEnrolmentLists, id]);
  // const eventEnrolment = useMemo(() => (eventEnrolmentList && eventEnrolmentList.length > 0 ? eventEnrolments[eventEnrolmentList[0]] : undefined), [eventEnrolmentList, eventEnrolments]);
  // const userId = useMemo(() => (eventEnrolment ? eventEnrolment.userId : undefined), [eventEnrolment]);

  // const event = useMemo(() => events[id], [events, id]);
  // const surveyTasks = useMemo(() => {
  //   if (!event) return [];

  //   console.log(event);

  //   const taskSurrveys: SurveyDto[] = [];

  //   event.f2f?.AFTER.forEach(task => {
  //     const survey = surveys[task.surveyId];

  //     if (survey) taskSurrveys.push(survey);
  //   });

  //   return taskSurrveys;
  // }, [event, surveys]);

  // const handleFormSubmit = useCallback(
  //   (email: string) => {
  //     setIsFormSubmitted(true);
  //     checkEnrolmentForEvent({ eventId: id, email });
  //   },
  //   [id]
  // );

  // const handleSurveyAnswerSubmit = useCallback(
  //   (dto: CreateSurveyAnswerDto) => {
  //     answerPublicSurvey({ userId, dto });
  //   },
  //   [answerPublicSurvey, userId]
  // );

  // const handleNoEmailClick = useCallback(() => {
  //   setIsPublic(true);
  //   fetchSurveysForEvent({ eventId: id });
  // }, [id]);

  // useEffect(() => {
  //   if (id) fetchEvent(id);
  // }, [id]);

  // useEffect(() => {
  //   if (id && userId) fetchSurveysForEvent({ eventId: id, userId });
  // }, [id, userId]);

  // // useEffect(() => {
  // //   fetchSurveys();
  // // }, []);

  // // const event = events[id];
  // // if (!event) return null;

  // // console.log('EVENT:', event);

  // // const survey = surveys[event.f2f?.AFTER[0].surveyId];
  // // if (!survey) return null;

  // // console.log(events);
  // // console.log(event);
  // // const eventEnrolment = eventEnrolmentList && eventEnrolmentList.length > 0 ? eventEnrolments[eventEnrolmentList[0]] : undefined;

  // // console.log(event.f2f?.AFTER);

  // // console.log(survey);

  // return (
  //   <Container size="lg">
  //     <h1>Direct Survey</h1>
  //     {!eventEnrolment && !isPublic && (
  //       <Stack>
  //         <Card withBorder>
  //           <Stack spacing="sm">
  //             <Title order={3}>Work email address</Title>
  //             <form onSubmit={form.onSubmit(values => handleFormSubmit(values.email))}>
  //               <TextInput {...form.getInputProps('email')} />
  //               <Group align="flex-end" mt="md">
  //                 <Button type="submit">Submit</Button>
  //               </Group>
  //             </form>
  //           </Stack>
  //         </Card>
  //         {!eventEnrolmentLoading && isFormSubmitted && (
  //           <Alert variant="light" color="yellow" icon={<IconInfoCircle />}>
  //             The email you entered is not on our participant list for this event. Do you have another email? If so, enter it above.&nbsp;
  //             <Anchor onClick={handleNoEmailClick} underline>
  //               This is my email, continue regardless
  //             </Anchor>
  //           </Alert>
  //         )}
  //       </Stack>
  //     )}
  //     <Stack>
  //       {(eventEnrolment || isPublic) && surveyTasks.map((task, key) => <SurveyCard key={1} task={event.f2f?.AFTER[key]} taskType="F2F" parentId={event.f2fId} survey={task} onSubmit={handleSurveyAnswerSubmit} />)}
  //     </Stack>
  //   </Container>
  // );

  // if (!eventEnrolment) {
  //   return (
  //     <Container size="lg">
  //       <h1>Direct Survey Link</h1>
  //       <Stack>
  //         <Card withBorder>
  //           <Stack spacing="sm">
  //             <Title order={3}>Work email address</Title>
  //             <form onSubmit={form.onSubmit(values => handleFormSubmit(values.email))}>
  //               <TextInput {...form.getInputProps('email')} />
  //               <Group align="flex-end" mt="md">
  //                 <Button type="submit">Submit</Button>
  //               </Group>
  //             </form>
  //           </Stack>
  //         </Card>
  //         {!eventEnrolmentLoading && isFormSubmitted && (
  //           <Alert variant="light" color="yellow" icon={<IconInfoCircle />}>
  //             The email you entered is not on our participant list for this event. Do you have another email? If so, enter it above.
  //           </Alert>
  //         )}
  //       </Stack>
  //       {/* <canvas ref={canvasRef} /> */}
  //       {/* <SurveyCard key={1} task={event.f2f?.AFTER[0]} taskType="F2F" parentId={event.f2fId} survey={survey} onSubmit={() => {}} /> */}
  //     </Container>
  //   );
  // }
};

const mapStateToProps = (state: RootState) => ({
  types: state.faceToFace.types,
  eventEnrolmentLoading: state.eventEnrolment.isLoading,
  eventEnrolments: state.eventEnrolment.enrolments,
  eventEnrolmentLists: state.eventEnrolment.enrolmentLists,
  surveys: state.survey.surveys
});

const mapDispatchToProps = {
  fetchF2FType: fetchF2FTypeRoutine,
  checkEnrolmentForEvent: checkEnrolmentForEventRoutine,
  fetchSurveysForEvent: fetchSurveysForEventRoutine,
  answerPublicSurvey: answerPublicSurveyRoutine
};

export const DirectSurvey = connect(mapStateToProps, mapDispatchToProps)(DirectSurveyComponent);
