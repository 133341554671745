import React from 'react';
import { EventDto } from 'types/api/event';

import { Table, Button, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { AdminRoutes } from 'enums';

type Props = {
  typeId: string;
  events: EventDto[];
};

export const Events: React.FC<Props> = ({ typeId, events }) => {
  const navigate = useNavigate();

  if (!events) return null;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr key={event.id} onClick={() => navigate(`${AdminRoutes.eventView}/${event.id}`)}>
              <td>{event.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Group position="right">
        <Button onClick={() => navigate(`${AdminRoutes.eventNew}?for=${typeId}`)}>Add Event</Button>
      </Group>
    </>
  );
};
