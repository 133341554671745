import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserDiscipline } from 'types/state';
import { Discipline } from 'enums/user-info';

type Props = {
  discipline: UserDiscipline;
};

export const DisplayDiscipline: React.FC<Props> = ({ discipline }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          What is your primary professional discipline:{' '}
        </Text>
        {discipline.isStudent ? 'Student' : Discipline[discipline.discipline]}
      </Text>
      {discipline.isStudent && (
        <Text>
          <Text span fw="bold">
            What discipline are you studying for:{' '}
          </Text>
          {Discipline[discipline.discipline]}
        </Text>
      )}
      {discipline.discipline === 'OTHER' && (
        <Text>
          <Text span fw="bold">
            Please specify:{' '}
          </Text>
          {discipline.other}
        </Text>
      )}
    </Box>
  );
};
