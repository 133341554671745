import { EventEnrolmentStatus } from 'enums/event/event.enrolment.status.enum';
import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import {
  CreateEventDto,
  CreateEventInstanceDto,
  EventDto,
  EventEnrolmentDto,
  ReorderEventEnrolmentDto,
  UpdateEventDto
} from 'types/api/event';

class EventService {
  async getAll(): Promise<EventDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<EventDto[]>>({
      url: '/f2f-event'
    });
    return data.data;
  }

  async getOne(id: string): Promise<EventDto> {
    const { data } = await axiosAdapter.doGet<AppResponse<EventDto>>({
      url: `/f2f-event/${id}`
    });
    return data.data;
  }

  async getOneForUser(eventId: string, userId: string): Promise<EventDto> {
    const { data } = await axiosAdapter.doGet<AppResponse<EventDto>>({
      url: `/f2f-event/${eventId}/${userId}`
    });
    return data.data;
  }

  async create(payload: CreateEventDto): Promise<EventDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<EventDto>>({
      url: '/f2f-event',
      payload
    });
    return data.data;
  }

  async update(payload: UpdateEventDto): Promise<EventDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<EventDto>>({
      url: `/f2f-event/${payload.id}`,
      payload
    });
    return data.data;
  }

  async createInstance(
    eventId: string,
    dto: CreateEventInstanceDto
  ): Promise<EventDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<EventDto>>({
      url: `/f2f-event/${eventId}/instances`,
      payload: dto
    });
    return data.data;
  }

  async updateInstance(
    eventId: string,
    dto: CreateEventInstanceDto
  ): Promise<EventDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<EventDto>>({
      url: `/f2f-event/${eventId}/instances/${dto.id}`,
      payload: dto
    });
    return data.data;
  }

  async removeInstance(eventId: string, instanceId: string): Promise<EventDto> {
    const { data } = await axiosAdapter.doDelete<AppResponse<EventDto>>({
      url: `/f2f-event/${eventId}/instances/${instanceId}`
    });
    return data.data;
  }

  async createEnrolment(eventId: string): Promise<EventEnrolmentDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<EventEnrolmentDto>>({
      url: `/event-enrolment/for-event/${eventId}`
    });
    return data.data;
  }

  async reorderEnrolment(
    enrolmentId: string,
    dto: ReorderEventEnrolmentDto
  ): Promise<EventDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<EventDto>>({
      url: `/event-enrolment/reorder/${enrolmentId}`,
      payload: dto
    });
    return data.data;
  }

  async signoff(eventId: string): Promise<EventDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<EventDto>>({
      url: `/f2f-event/signoff/${eventId}`
    });
    return data.data;
  }

  async setEnrolmentStatus(
    enrolmentId: string,
    status: keyof typeof EventEnrolmentStatus
  ): Promise<EventEnrolmentDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<EventEnrolmentDto>>({
      url: `/event-enrolment/status/${enrolmentId}`,
      payload: { status }
    });
    return data.data;
  }

  async addParticipant(
    eventId: string,
    email: string
  ): Promise<EventEnrolmentDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<EventEnrolmentDto>>({
      url: `/event-enrolment/add-participant/${eventId}`,
      payload: {
        eventId,
        email
      }
    });
    return data.data;
  }
}

export const eventService = new EventService();
