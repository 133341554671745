import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AdminRoutes } from 'enums';
import {
  Table,
  Title,
  Group,
  Button,
  Alert,
  LoadingOverlay,
  Anchor
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useScorms } from 'features/scorms/api/get-scorms';

const ScormListComponent: React.FC = () => {
  const navigate = useNavigate();

  const scormsQuery = useScorms();
  const scorms = scormsQuery.data?.data;

  const scormList = scorms?.sort((a, b) => (a.title < b.title ? -1 : 1));

  return (
    <>
      <Group position="right" my="xl">
        <Title order={2} sx={{ flex: '1 !important' }}>
          Scorm Modules
        </Title>
        <Button
          component={Link}
          to={AdminRoutes.scormNew}
          leftIcon={<IconPlus />}
        >
          Upload New Module
        </Button>
      </Group>

      {scormsQuery.isError && (
        <Alert title="Error Loading Courses" color="red" my="lg">
          {scormsQuery.error.message}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>Module Title</th>
          </tr>
        </thead>
        <tbody>
          {scormList?.map(scorm => (
            <tr
              key={scorm.id}
              onClick={() => navigate(`${AdminRoutes.scormView}/${scorm.id}`)}
            >
              <td>{scorm.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LoadingOverlay visible={scormsQuery.isLoading} />
    </>
  );
};

export const ScormList = ScormListComponent;
