import React from 'react';
import { connect } from 'react-redux';
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, Radio, Alert } from '@mantine/core';
import { setTas as setTasRoutine } from 'store/user/routines';
import { RootState, UserTas } from 'types/state';

type TasForm = {
  isTasHealth: 'YES' | 'NO';
};

type Props = {
  isLoading: boolean;
  error?: string;
  tas: UserTas;
  setTas: (dto: UserTas) => void;
};

const OnboardingTasComponent: React.FC<Props> = ({ isLoading, error, tas, setTas }) => {
  const form = useForm<TasForm, (arg0: TasForm) => UserTas>({
    initialValues: tas
      ? {
          isTasHealth: tas.isTasHealth ? 'YES' : 'NO'
        }
      : {
          isTasHealth: undefined
        },
    validate: {
      isTasHealth: isNotEmpty('This field is required')
    },
    transformValues: values => ({
      isTasHealth: values.isTasHealth === 'YES'
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setTas(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Do you work for Tas Health?" {...form.getInputProps('isTasHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Tasmania Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  tas: state.user.tas
});

const mapDispatchToProps = {
  setTas: setTasRoutine
};

export const OnboardingTas = connect(mapStateToProps, mapDispatchToProps)(OnboardingTasComponent);
