import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { CouponValidationRule } from 'types/backend';
import { getCouponValidationRulesQueryOptions } from './get-coupon-validation-rules';

export const createCouponValidationRule = ({
  data
}: {
  data: { stripeCouponId: string; domains: Array<string> };
}): Promise<AppResponse<CouponValidationRule>> => {
  return api.post('/coupon-validation-rule', data);
};

type UseCreateCouponValidationRuleOptions = {
  mutationConfig?: MutationConfig<typeof createCouponValidationRule>;
};

export const useCreateCouponValidationRule = ({
  mutationConfig
}: UseCreateCouponValidationRuleOptions = {}) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.setQueryData(
        getCouponValidationRulesQueryOptions().queryKey,
        old => ({ data: [...old.data, args[0].data] })
      );

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: createCouponValidationRule
  });
};
