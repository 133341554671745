export enum SAHealthLHN {
  BAROSSA_HILLS_FLEURIEU = 'Barossa Hills Fleurieu',
  CENTRAL_ADELAIDE = 'Central Adelaide',
  EYRE_AND_FAR_NORTH = 'Eyre and Far North',
  FLINDERS_AND_UPPER_NORTH = 'Flinders and Upper North',
  LIMESTONE_COAST = 'Limestone Coast',
  NORTHERN_ADELAIDE = 'Northern Adelaide',
  RIVERLAND_MALLEE_COORONG = 'Riverland Mallee Coorong',
  SOUTHERN_ADELAIDE = 'Southern Adelaide',
  WOMENS_AND_CHILDRENS = "Women's and Children's",
  YORKE_AND_NORTHERN = 'Yorke and Northern'
}
