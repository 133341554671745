import React from 'react';
import { connect } from 'react-redux';
import { Text, Title, Button, Box, TextInput, Group, Textarea, Container, ActionIcon, Switch, Stack } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { RichTextEditor } from 'components/RichTextEditor';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { updateCourse as updateCourseRoutine } from 'store/course/routines';
import { CourseDto } from 'types/api/course';

type Props = {
  course: CourseDto;
  updateCourse: (dto: Partial<CourseDto>) => void;
};

const StorePageMetadataComponent: React.FC<Props> = ({ course, updateCourse }) => {
  const form = useForm<Partial<CourseDto>>({
    initialValues: {
      purchaseDescription: course.purchaseDescription,
      learningObjectives: course.learningObjectives,
      purchaseModules: course.purchaseModules ? course.purchaseModules.map(each => ({ ...each })) : [],
      accreditations: course.accreditations ? course.accreditations.map(each => ({ ...each })) : [],
      pricingDescription: course.pricingDescription,
      purchaseVideo: course.purchaseVideo,
      isVisible: course.isVisible ?? false,
      testimonials: course.testimonials ? course.testimonials.map(each => ({ ...each })) : [],
      papers: course.papers ? course.papers.map(each => ({ ...each })) : []
    },
    transformValues: values => ({
      ...values,
      id: course.id
    })
  });

  return (
    <Container size="md">
      <form onSubmit={form.onSubmit(values => updateCourse(values))}>
        <RichTextEditor mb="lg" label="Purchase Description" field="purchaseDescription" form={form} />

        <RichTextEditor mb="lg" label="Learning objectives" field="learningObjectives" form={form} />

        <RichTextEditor mb="lg" label="Pricing description" field="pricingDescription" form={form} />

        <TextInput mb="lg" label="Purchase video" {...form.getInputProps('purchaseVideo')} />

        <Stack my="lg" spacing='xs'>
          <Title order={3} sx={{ flex: '1 !important' }}>
            Store visibility
          </Title>
          <Switch label="Show on store page" labelPosition='left' {...form.getInputProps('isVisible', { type: 'checkbox' })} />
        </Stack>

        <Group>
          <Title order={3} sx={{ flex: '1 !important' }}>
            Purchase Page Modules
          </Title>
          <ActionIcon
            size="xl"
            radius="xl"
            variant="filled"
            color="blue"
            onClick={() =>
              form.insertListItem('purchaseModules', {
                id: randomId(),
                title: '',
                description: ''
              })
            }
          >
            <IconPlus />
          </ActionIcon>
        </Group>
        {form.values.purchaseModules.map((mod, index) => (
          <Box key={mod.id}>
            <Group align="end">
              <TextInput sx={{ flex: '1 !important' }} label="Title" {...form.getInputProps(`purchaseModules.${index}.title`)} />
              <Button color="red" variant="outline" onClick={() => form.removeListItem('purchaseModules', index)}>
                <IconTrash />
              </Button>
            </Group>
            <RichTextEditor label="Description" field={`purchaseModules.${index}.description`} form={form} />
          </Box>
        ))}
        {form.values.purchaseModules.length === 0 && <Text>No purchase modules.</Text>}

        <Group my="lg">
          <Title order={3} sx={{ flex: '1 !important' }}>
            Accreditations
          </Title>
          <ActionIcon
            size="xl"
            radius="xl"
            variant="filled"
            color="blue"
            onClick={() =>
              form.insertListItem('accreditations', {
                id: randomId(),
                accreditor: '',
                logo: '',
                points: ''
              })
            }
          >
            <IconPlus />
          </ActionIcon>
        </Group>
        {form.values.accreditations.map((acc, index) => (
          <Group key={acc.id} align="end">
            <TextInput sx={{ flex: '1 !important' }} label="Accreditor" {...form.getInputProps(`accreditations.${index}.accreditor`)} />
            <TextInput sx={{ flex: '1 !important' }} label="Logo" {...form.getInputProps(`accreditations.${index}.logo`)} />
            <TextInput sx={{ flex: '1 !important' }} label="Points" {...form.getInputProps(`accreditations.${index}.points`)} />
            <Button color="red" variant="outline" onClick={() => form.removeListItem('accreditations', index)}>
              <IconTrash />
            </Button>
          </Group>
        ))}
        {form.values.accreditations.length === 0 && <Text>No accreditations.</Text>}

        <Group my="lg">
          <Title order={3} sx={{ flex: '1 !important' }}>
            Testimonials
          </Title>
          <ActionIcon
            size="xl"
            radius="xl"
            variant="filled"
            color="blue"
            onClick={() =>
              form.insertListItem('testimonials', {
                id: randomId(),
                text: '',
                author: ''
              })
            }
          >
            <IconPlus />
          </ActionIcon>
        </Group>
        {form.values.testimonials.map((test, index) => (
          <Box key={test.id}>
            <Group align="end">
              <TextInput sx={{ flex: '1 !important' }} label="Author" {...form.getInputProps(`testimonials.${index}.author`)} />
              <Button color="red" variant="outline" onClick={() => form.removeListItem('testimonials', index)}>
                <IconTrash />
              </Button>
            </Group>

            <Textarea label="Text" {...form.getInputProps(`testimonials.${index}.text`)} />
          </Box>
        ))}
        {form.values.testimonials.length === 0 && <Text>No testimonials.</Text>}

        <Group my="lg">
          <Title order={3} sx={{ flex: '1 !important' }}>
            Papers
          </Title>
          <ActionIcon
            size="xl"
            radius="xl"
            variant="filled"
            color="blue"
            onClick={() =>
              form.insertListItem('papers', {
                id: randomId(),
                title: '',
                link: ''
              })
            }
          >
            <IconPlus />
          </ActionIcon>
        </Group>
        {form.values.papers.map((paper, index) => (
          <Group key={paper.id} align="end">
            <TextInput sx={{ flex: '1 !important' }} label="Title" {...form.getInputProps(`papers.${index}.title`)} />
            <TextInput sx={{ flex: '1 !important' }} label="Link" {...form.getInputProps(`papers.${index}.link`)} />
            <Button color="red" variant="outline" onClick={() => form.removeListItem('papers', index)}>
              <IconTrash />
            </Button>
          </Group>
        ))}
        {form.values.papers.length === 0 && <Text>No papers.</Text>}

        <Group position="right" my="xl">
          <Button type="submit">Save Changes</Button>
        </Group>
      </form>
    </Container>
  );
};

const mapDispatchToProps = {
  updateCourse: updateCourseRoutine
};

export const StorePageMetadata = connect(null, mapDispatchToProps)(StorePageMetadataComponent);
