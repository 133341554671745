import React from 'react';
import {
  Paper,
  Group,
  BackgroundImage,
  ThemeIcon,
  Title,
  Badge,
  Center,
  Skeleton,
  Box,
  LoadingOverlay
} from '@mantine/core';
import { IconCircle, IconCheck, IconLock } from '@tabler/icons-react';
import { CourseTaskDto } from 'types/api/course';
import { ScormDto } from 'types/api/scorm';
import { F2FTaskDto } from 'types/api/face-to-face';
import dayjs from 'dayjs';

type Props = {
  task: CourseTaskDto | F2FTaskDto;
  scorm: ScormDto;
  openScorm: (arg0: {
    scorm: ScormDto;
    setScormData: (arg1: { key: string; value: string; id: string }) => void;
  }) => void;
  setScormData: (arg0: { key: string; value: string; id: string }) => void;
};

export const ScormCard: React.FC<Props> = ({
  task,
  scorm,
  openScorm,
  setScormData
}) => {
  if (!scorm) return <Skeleton h={100} my="md" />;

  return (
    <Paper
      withBorder
      my="md"
      pos="relative"
      onClick={() => {
        if (task.status !== 'LOCKED') openScorm({ scorm, setScormData });
      }}
    >
      <Group noWrap>
        <BackgroundImage
          src={scorm.splash}
          w={300}
          sx={{ alignSelf: 'stretch' }}
        >
          <Center w={300} h="100%" mih={150} bg="#0004">
            {task.status === 'COMPLETE' && (
              <ThemeIcon variant="filled" size="xl" radius="xl" color="green">
                <IconCheck />
              </ThemeIcon>
            )}
            {task.status === 'INCOMPLETE' && (
              <ThemeIcon variant="filled" size="xl" radius="xl" color="cyan">
                <IconCircle />
              </ThemeIcon>
            )}
            {task.status === 'LOCKED' && (
              <ThemeIcon variant="light" size="xl" radius="xl" color="gray">
                <IconLock />
              </ThemeIcon>
            )}
          </Center>
        </BackgroundImage>

        <Box>
          <Title order={3} mb="xs" mt="xl">
            {scorm.title}
          </Title>
          {task.status === 'COMPLETE' && (
            <Badge radius="xs" color="green" leftSection={<IconCheck />}>
              COMPLETED
              {scorm.enrolment?.completed &&
                dayjs(scorm.enrolment.completed).format('DD MMM YYYY')}
            </Badge>
          )}
          {task.status === 'INCOMPLETE' && (
            <Badge radius="xs" color="cyan">
              IN PROGRESS
            </Badge>
          )}
          {task.status === 'LOCKED' && (
            <Badge radius="xs" color="gray">
              LOCKED
            </Badge>
          )}
          <Box
            my="lg"
            dangerouslySetInnerHTML={{
              __html: scorm.description
            }}
          />
        </Box>
      </Group>
    </Paper>
  );
};
