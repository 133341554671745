import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { EventEnrolmentCompletion } from 'types/backend';

export const getEventEnrolmentCompletions = ({
  enrolmentId
}: {
  enrolmentId: string;
}): Promise<{ data: EventEnrolmentCompletion }> => {
  return api.get(`/event-enrolment-new/${enrolmentId}/completions`);
};

export const getEventEnrolmentCompletionsQueryOptions = (
  enrolmentId: string
) => {
  return queryOptions({
    queryKey: ['event-enrolment', 'completions', enrolmentId],
    queryFn: () => getEventEnrolmentCompletions({ enrolmentId })
  });
};

type UseEventEnrolmentCompletionsOptions = {
  enrolmentId: string;
  queryConfig?: QueryConfig<typeof getEventEnrolmentCompletionsQueryOptions>;
};

export const useEventEnrolmentCompletions = ({
  enrolmentId,
  queryConfig
}: UseEventEnrolmentCompletionsOptions) => {
  return useQuery({
    ...getEventEnrolmentCompletionsQueryOptions(enrolmentId),
    ...queryConfig
  });
};
