import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { CouponValidationRule } from 'types/backend';

export const getCouponValidationRule = ({
  id
}: {
  id: string;
}): Promise<AppResponse<CouponValidationRule>> => {
  return api.get(`/coupon-validation-rule/${id}`);
};

export const getCouponValidationRuleQueryOptions = (id: string) => {
  return queryOptions({
    queryKey: ['coupon-validation-rule', id],
    queryFn: () => getCouponValidationRule({ id })
  });
};

type UseCouponValidationRuleOptions = {
  id: string;
  queryConfig?: QueryConfig<typeof getCouponValidationRuleQueryOptions>;
};

export const useCouponValidationRule = ({
  id,
  queryConfig
}: UseCouponValidationRuleOptions) => {
  return useQuery({
    ...getCouponValidationRuleQueryOptions(id),
    ...queryConfig
  });
};
