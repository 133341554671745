import React from 'react';
import { Container, Title, Text, List, Anchor, Box } from '@mantine/core';

export const PrivacyPolicy: React.FC = () => {
  return (
    <Container size="md">
      <Title order={1} mb="lg">
        Privacy Policy
      </Title>
      <Text>
        The InsideOut Institute eLearning Platform (&ldquo;the Platform&rdquo;) is owned and operated by InsideOut Institute on behalf of Sydney Local Health District and the University of Sydney (&ldquo;we,&rdquo;
        &ldquo;us,&rdquo; or &ldquo;our&rdquo;). This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you access and use our Platform. Please read this Privacy Policy
        carefully to understand our practices regarding your personal information. Your use of this website is deemed to be your acceptance of these Terms of Use and our Privacy Policy.
      </Text>
      <Title order={2} my="lg">
        Policy Statement
      </Title>
      <Text>
        InsideOut Institute (InsideOut, we, us, and our) is committed to managing and protecting the privacy of the personal information it collects and holds in accordance with the Privacy Act 1988 (Cth) and other
        applicable privacy laws (including the Privacy and Personal Information Protection Act 1998, and the Health Records and Information Privacy Act 2002 (NSW)) (Privacy Laws).
      </Text>
      <Text>
        The purpose of this Privacy Policy is to explain how we collect, hold, and manage your personal information, as well as how you can contact us about the personal information we hold about you. Personal
        information is information that identifies you or could reasonably identify you.
      </Text>

      <Title order={3} my="lg">
        1. Information We Collect
      </Title>
      <List type="ordered">
        <List.Item>
          <Text>Registration Information:</Text>
          <Text>When you create an account on the Platform, we collect your name, email address, and any other information you provide during the registration process.</Text>
        </List.Item>
        <List.Item>
          <Text>Learning Activity Data:</Text>
          <Text>
            We may collect information about your activity on the Platform, including the modules you access, your completion status, quiz scores, and any other interactions you have with the Platform&apos;s content.
          </Text>
        </List.Item>
        <List.Item>
          <Text>Surveys and Feedback:</Text>
          <Text>We may ask you to provide responses to surveys or feedback forms to help evaluate and improve our offerings and Platform.</Text>
        </List.Item>
      </List>

      <Title order={3} my="lg">
        2. Use of Collected Information
      </Title>
      <List type="ordered">
        <List.Item>
          <Text>Platform Operation and Improvement:</Text>
          <Text>We use your information to operate, maintain, and enhance the Platform&apos;s functionality, including personalizing your learning experience, tracking your progress, and providing support.</Text>
        </List.Item>
        <List.Item>
          <Text>Evaluation and Reporting:</Text>
          <Text>
            We may use your de-identified personal information, including your survey responses and learning activity data, to analyze and evaluate the effectiveness of our modules, to conduct internal research aimed at
            improving our offerings and communicate with related organizations, such as the University of Sydney and NSW Ministry of Health.
          </Text>
        </List.Item>
        <List.Item>
          <Text>Research:</Text>
          <Text>
            With your consent, we may aggregate and de-identify the personal information collected from users to generate statistical or research reports. This aggregated and de-identified data will not personally
            identify you and may be used for various purposes, including but not limited to academic publications, presentations, or communications with related organizations, such as the University of Sydney, and NSW
            Ministry of Health.
          </Text>
        </List.Item>
        <List.Item>
          <Text>Communication:</Text>
          <Text>
            We may use your email address to send you important updates, notifications, and information related to the Platform, such as changes to our terms or policies. We may also use your email address to respond to
            your inquiries or requests.
          </Text>
        </List.Item>
      </List>

      <Title order={3} my="lg">
        3. Disclosure of Personal Information
      </Title>
      <List type="ordered">
        <List.Item>
          <Text>Service Providers:</Text>
          <Text>
            We may engage trusted third-party service providers who assist us in operating the Platform or conducting our business. These service providers have access to your personal information only to perform
            specific tasks on our behalf and are obligated to maintain confidentiality.
          </Text>
        </List.Item>
        <List.Item>
          <Text>Legal Compliance:</Text>
          <Text>
            We may disclose your personal information if required to do so by law or in response to a valid legal request, such as a court order, government investigation, or as otherwise required by applicable law.
          </Text>
        </List.Item>
        <List.Item>
          <Text>Research Studies:</Text>
          <Text>
            In cases where separate consent opt-ins are applicable, we may seek your explicit consent to participate in research studies covered by an Ethics Review Board conducted on the Platform. Your participation in
            such studies will be subject to a separate consent process, and the handling of your personal information will be governed by specific research study protocols and applicable ethical standards.
          </Text>
        </List.Item>
      </List>

      <Title order={3} my="lg">
        4. Data Security
      </Title>
      <List type="ordered">
        <List.Item>
          We have implemented reasonable measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, no method of transmission over the internet or
          electronic storage is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.
        </List.Item>
      </List>

      <Title order={3} my="lg">
        5. Your Choices
      </Title>
      <List type="ordered">
        <List.Item>
          <Text>Access and Update:</Text>
          <Text>
            You may access, review, and update your account information by logging into your account settings on the Platform. If you need assistance with accessing or updating your information, please contact us using
            the information provided at the end of this Privacy Policy.
          </Text>
        </List.Item>
        <List.Item>
          <Text>Communications Preferences:</Text>
          <Text>
            You can opt out of receiving non-essential communications from us by following the unsubscribe instructions provided in those communications or by contacting us directly. However, please note that we may
            still send you important administrative messages related to your use of the Platform.
          </Text>
        </List.Item>
      </List>

      <Title order={3} my="lg">
        6. Changes to this Privacy Policy
      </Title>
      <List type="ordered">
        <List.Item>
          We may update this Privacy Policy from time to time in our sole discretion. Any changes we make will become effective when we post a revised version of the Privacy Policy on the Platform. We encourage you to
          review this Privacy Policy periodically for any updates.
        </List.Item>
      </List>

      <Title order={3} my="lg">
        7. Contact Us
      </Title>
      <Text>If you have any questions, comments, or concerns regarding this Privacy Policy or our privacy practices, please contact us at:</Text>

      <Box my="md" ml="xl">
        <Text>InsideOut Institute</Text>
        <Text>Level 2, The Charles Perkins Centre, D17 John Hopkins Dr,</Text>
        <Text>The University of Sydney</Text>
        <Text>Camperdown NSW 2006</Text>
        <Text>Phone: (02) 8627 5690</Text>
      </Box>

      <Title order={3} my="lg">
        8. Complaints
      </Title>
      <Text>
        If you have a complaint about InsideOut’s information handling practices or consider we have breached your privacy, you should first lodge a complaint with InsideOut (via the contact details in clause 7).
      </Text>
      <Text>
        InsideOut aims to deal with all complaints in a fair and efficient manner. After receiving a complaint, we may investigate the complaint by requesting further information from you and/or conducting internal
        discussions. We will notify you of the results of such investigation. If we conclude that our handling of your personal information is in breach of the Privacy Laws, we will remedy the breach as soon as
        reasonably practicable.
      </Text>
      <Text>If you are unsatisfied with our handling of your complaint, you may contact the Privacy Commissioner at:</Text>

      <Box my="md" ml="xl">
        <Text>Office of Australian Information Commissioner</Text>
        <Text>GPO Box 5218, Sydney NSW 2001</Text>
        <Text>Phone: 1300 363 992</Text>
        <Text>
          Email: <Anchor href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</Anchor>
        </Text>
      </Box>
    </Container>
  );
};
