import { ActionIcon, Anchor, Group, Tooltip } from '@mantine/core';
import { IconArchive, IconClipboardText } from '@tabler/icons-react';
import { Routes } from 'enums';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EventEnrolmentsPriorityContext } from '../contexts/event-enrolments-priority';

interface PrioritisedEOIActionProps {
  enrolmentId: string;
  userId: string;
}

export const PrioritisedEOIAction = ({
  enrolmentId,
  userId
}: PrioritisedEOIActionProps) => {
  const { handleArchive } = useContext(EventEnrolmentsPriorityContext);

  return (
    <Group spacing="xs">
      <Tooltip label="Archive" withArrow>
        <ActionIcon
          variant="light"
          color="red"
          title="Archive"
          onClick={() => handleArchive('prioritised', enrolmentId)}
        >
          <IconArchive />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="EOI Form" withArrow>
        <Anchor
          component={Link}
          to={`${Routes.ExpressionOfInterestViewer}/${userId}`}
        >
          <ActionIcon variant="light" color="blue" title="EOI Form">
            <IconClipboardText />
          </ActionIcon>
        </Anchor>
      </Tooltip>
    </Group>
  );
};
