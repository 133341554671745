/* eslint-disable no-param-reassign */

import { produce } from 'immer';
import { Routine } from 'redux-saga-routines';
import { SurveyAnswerState } from 'types/state/survey-answer';
import { fetchSurveyAnswersForF2FTask, linkUserToSurveyAnswer } from './routines';

const initialState: SurveyAnswerState = {
  surveyAnswers: {},
  isLoading: false,
  error: undefined
}

export const surveyAnswerReducer = produce((state: SurveyAnswerState, { type, payload }: Routine<any> = {}) => {
  switch(type) {
    case fetchSurveyAnswersForF2FTask.TRIGGER:
    case linkUserToSurveyAnswer.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchSurveyAnswersForF2FTask.SUCCESS:
      state.isLoading = false;
      state.surveyAnswers[payload.id] = payload.data;
      break;
    case linkUserToSurveyAnswer.SUCCESS:
      state.isLoading = false;
      state.surveyAnswers[payload.id] = payload;
      break;
    case fetchSurveyAnswersForF2FTask.FAILURE:
    case linkUserToSurveyAnswer.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);