import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { AppResponse } from 'types/api';
import { CouponRedemption } from 'types/backend';

export const getCouponRedemptions = ({
  couponId,
  expand
}: {
  couponId: string;
  expand?: Array<string>;
}): Promise<AppResponse<Array<CouponRedemption>>> => {
  return api.get(`/coupon/${couponId}/redemption`, {
    params: { 'expand[]': expand }
  });
};

export const getCouponRedemptionsQueryOptions = (
  couponId: string,
  expand?: Array<string>
) => {
  return queryOptions({
    queryKey: ['coupons', 'redemption', couponId],
    queryFn: () => getCouponRedemptions({ couponId, expand })
  });
};

type UseCouponRedemptionsOptions = {
  couponId: string;
  expand?: Array<string>;
  queryConfig?: QueryConfig<typeof getCouponRedemptionsQueryOptions>;
};

export const useCouponRedemptions = ({
  couponId,
  expand,
  queryConfig
}: UseCouponRedemptionsOptions) => {
  return useQuery({
    ...getCouponRedemptionsQueryOptions(couponId, expand),
    ...queryConfig
  });
};
