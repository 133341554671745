import { AxiosError } from 'axios';
import { AdminRoutes } from 'enums';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { surveyService } from 'service/survey';
import { surveyAnswerService } from 'service/survey-answer';
import { CreateSurveyAnswerDto, ReorderQuestionDto, SurveyDto, SurveyQuestionDto, UpdateSurveyDto } from 'types/api/survey';
import { answerPublicSurvey, answerSurvey, createQuestion, createSurvey, fetchSurvey, fetchSurveys, fetchSurveysForEvent, removeQuestion, reorderQuestion, updateQuestion, updateSurvey } from './routines';

function* createSurveyHandler({
  payload: { dto, navigate }
}: Action<{
  dto: SurveyDto;
  navigate: NavigateFunction;
}>) {
  try {
    const data = yield call(surveyService.create, dto);
    navigate(`${AdminRoutes.surveyView}/${data.id}`);
    yield put(createSurvey.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createSurvey.failure(e.message));
    else yield put(createSurvey.failure('Unknown error.'));
  }
}

function* updateSurveyHandler({ payload }: Action<UpdateSurveyDto>) {
  try {
    const data = yield call(surveyService.update, payload);
    yield put(updateSurvey.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateSurvey.failure(e.message));
    else yield put(updateSurvey.failure('Unknown error.'));
  }
}

function* fetchSurveysHandler() {
  try {
    const data = yield call(surveyService.getAll);
    yield put(fetchSurveys.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchSurveys.failure(e.message));
    else yield put(fetchSurveys.failure('Unknown error.'));
  }
}

function* fetchSurveysForEventHandler({ payload: { eventId, userId } }: Action<{ eventId: string; userId?: string }>) {
  try {
    const data = yield call(surveyService.getForEvent, eventId, userId);
    yield put(fetchSurveysForEvent.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchSurveys.failure(e.message));
    else yield put(fetchSurveys.failure('Unknown error.'));
  }
}

function* fetchSurveyHandler({ payload: id }: Action<string>) {
  try {
    const data = yield call(surveyService.getOne, id);
    yield put(fetchSurvey.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchSurvey.failure(e.message));
    else yield put(fetchSurvey.failure('Unknown error.'));
  }
}

function* createQuestionHandler({
  payload: { surveyId, dto }
}: Action<{
  surveyId: string;
  dto: SurveyQuestionDto;
}>) {
  try {
    const data = yield call(surveyService.createQuestion, surveyId, dto);
    yield put(createQuestion.success({ surveyId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createQuestion.failure(e.message));
    else yield put(createQuestion.failure('Unknown error.'));
  }
}

function* updateQuestionHandler({
  payload: { surveyId, dto }
}: Action<{
  surveyId: string;
  dto: SurveyQuestionDto;
}>) {
  try {
    const data = yield call(surveyService.updateQuestion, surveyId, dto);
    yield put(updateQuestion.success({ surveyId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateQuestion.failure(e.message));
    else yield put(updateQuestion.failure('Unknown error.'));
  }
}

function* reorderQuestionHandler({
  payload: { surveyId, dto }
}: Action<{
  surveyId: string;
  dto: ReorderQuestionDto;
}>) {
  try {
    const data = yield call(surveyService.reorderQuestion, surveyId, dto);
    yield put(reorderQuestion.success({ surveyId, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(reorderQuestion.failure(e.message));
    else yield put(reorderQuestion.failure('Unknown error.'));
  }
}

function* removeQuestionHandler({
  payload: { surveyId, questionId }
}: Action<{
  surveyId: string;
  questionId: string;
}>) {
  try {
    const data = yield call(surveyService.removeQuestion, surveyId, questionId);
    yield put(removeQuestion.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(removeQuestion.failure(e.message));
    else yield put(removeQuestion.failure('Unknown error.'));
  }
}

function* answerSurveyHandler({ payload }: Action<CreateSurveyAnswerDto>) {
  try {
    const data = yield call(surveyAnswerService.create, payload);
    yield put(
      answerSurvey.success({
        taskType: payload.taskType,
        surveyId: payload.surveyId,
        parentId: payload.parentId,
        data
      })
    );
  } catch (e) {
    if (e instanceof AxiosError) yield put(answerSurvey.failure(e.message));
    else yield put(answerSurvey.failure('Unknown error.'));
  }
}

function* answerPublicSurveyHandler({ payload: { userId, email, dto } }: Action<{ userId?: string; email: string, dto: CreateSurveyAnswerDto }>) {
  try {
    const data = yield call(surveyAnswerService.createPublic, dto, { userId, email });
    yield put(
      answerPublicSurvey.success({
        taskType: dto.taskType,
        surveyId: dto.surveyId,
        parentId: dto.parentId,
        data
      })
    );
  } catch (e) {
    if (e instanceof AxiosError) yield put(answerPublicSurvey.failure(e.message));
    else yield put(answerPublicSurvey.failure('Unknown error.'));
  }
}

export function* surveySaga() {
  yield all([
    takeEvery(createSurvey.TRIGGER, createSurveyHandler),
    takeEvery(updateSurvey.TRIGGER, updateSurveyHandler),
    takeEvery(fetchSurveys.TRIGGER, fetchSurveysHandler),
    takeEvery(fetchSurveysForEvent.TRIGGER, fetchSurveysForEventHandler),
    takeEvery(fetchSurvey.TRIGGER, fetchSurveyHandler),
    takeEvery(createQuestion.TRIGGER, createQuestionHandler),
    takeEvery(updateQuestion.TRIGGER, updateQuestionHandler),
    takeEvery(reorderQuestion.TRIGGER, reorderQuestionHandler),
    takeEvery(removeQuestion.TRIGGER, removeQuestionHandler),
    takeEvery(answerSurvey.TRIGGER, answerSurveyHandler),
    takeEvery(answerPublicSurvey.TRIGGER, answerPublicSurveyHandler)
  ]);
}
