import React, { useState } from 'react';
import styled from 'styled-components';
import { Drawer, Button, Card, Group, Space, Stack, Text } from '@mantine/core';
import { F2FEmailDto, F2FTypeDto } from 'types/api/face-to-face';
import { EmailEditor } from './EmailEditor';
import { F2FEmailCondition, F2FEmailWhen } from 'enums/training';

const Em = styled.span`
  font-weight: bold;
  color: #c33;
`;

type Props = {
  type: F2FTypeDto;
  upsertEmail: (arg0: { typeId: string; dto: F2FEmailDto }) => void;
  deleteEmail: (arg0: { typeId: string; emailId: string }) => void;
};

export const Emails: React.FC<Props> = ({ type, upsertEmail, deleteEmail }) => {
  const [editingEmail, setEditingEmail] = useState<F2FEmailDto>(null);

  if (!type.emails) return null;

  return (
    <>
      <Stack>
        {type.emails.map(email => (
          <Card key={email.id} withBorder shadow="sm" onClick={() => setEditingEmail(email)}>
            <Text fw="bold">{email.subject}</Text>
            <Text>
              Sent <Em>{email.delay}</Em> days <Em>{F2FEmailWhen[email.when]}</Em> if <Em>{F2FEmailCondition[email.condition]}</Em>
            </Text>
          </Card>
        ))}
      </Stack>
      <Space h="md" />
      <Group position="right">
        <Button
          onClick={() =>
            setEditingEmail({
              subject: '',
              when: 'PRE_EVENT',
              condition: 'ALWAYS',
              delay: 0,
              body: ''
            })
          }
        >
          New Email
        </Button>
      </Group>

      <Drawer title={editingEmail?.id ? 'Edit Email' : 'Create Email'} position="right" size="lg" opened={!!editingEmail} onClose={() => setEditingEmail(null)}>
        <EmailEditor typeId={type.id} email={editingEmail} close={() => setEditingEmail(null)} upsertEmail={upsertEmail} deleteEmail={deleteEmail} />
      </Drawer>
    </>
  );
};
