/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { Routine } from 'redux-saga-routines';
import { produce } from 'immer';
import { fetchFiles, uploadFileForF2F } from './routines';
import { FileState } from 'types/state';
import { FileDto } from 'types/api/file';

const initialState: FileState = {
  files: {},
  isLoading: false,
  error: undefined
};

export const fileReducer = produce((state: FileState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case fetchFiles.TRIGGER:
    case uploadFileForF2F.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchFiles.SUCCESS:
      state.isLoading = false;
      state.files = {};
      payload.forEach((file: FileDto) => {
        state.files[file.id] = file;
      });
      break;
    case uploadFileForF2F.SUCCESS:
      state.isLoading = false;
      break;
    case fetchFiles.FAILURE:
    case uploadFileForF2F.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
