import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  MultiSelect,
  Select,
  Stack
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { format, sub } from 'date-fns';
import { useF2FMultiple } from 'features/f2fs/api/get-f2f';
import { useF2Fs } from 'features/f2fs/api/get-f2fs';
import {
  ExportF2FReportBody,
  useExportF2FReport
} from 'features/report/api/export-f2f-report-csv';
import { useCurrentUser } from 'features/user/api/get-current-user';
import React from 'react';

interface FormData extends ExportF2FReportBody {
  timeframe?: 'month' | 'quarter' | 'semi' | 'year' | 'custom';
}

export const F2FReportExportPanel = () => {
  const form = useForm<FormData>({
    initialValues: {
      f2fs: []
    }
  });

  const f2fQuery = useF2Fs();
  const f2fDetailQuery = useF2FMultiple({
    f2fIds: form.values.f2fs,
    queryConfig: {
      enabled: !!form.values.f2fs.length
    }
  });
  const currentUserQuery = useCurrentUser();

  const exportF2FReportDataMutation = useExportF2FReport({
    mutationConfig: {
      onSuccess: data => {
        if (data.type === 'text/csv') {
          const url = URL.createObjectURL(data);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `f2f_report_${format(new Date(), 'dd-MM-yyyy')}`;

          anchor.click();
          anchor.remove();
        }
      }
    }
  });

  const timeframes = [
    {
      value: 'month',
      label: 'Last 30 days'
    },
    {
      value: 'quarter',
      label: 'Last 90 days'
    },
    {
      value: 'semi',
      label: 'Last 6 months'
    },
    {
      value: 'year',
      label: 'Last year'
    },
    {
      value: 'custom',
      label: 'Custom'
    }
  ];

  // TODO: Make this into a database column flag
  const f2fs =
    currentUserQuery.data?.data?.role === 'IOI_ADMIN'
      ? f2fQuery.data?.data?.map(f2f => ({
          value: f2f.id,
          label: f2f.name
        })) ?? []
      : f2fQuery.data?.data
          ?.filter(
            f2f =>
              !(
                f2f.name.includes('Headspace') ||
                f2f.name.includes('Head to Health') ||
                f2f.name.includes('BEeT')
              )
          )
          .map(f2f => ({
            value: f2f.id,
            label: f2f.name
          })) ?? [];

  const events = f2fDetailQuery.reduce((acc, curr) => {
    const selectEventData =
      curr.data?.data.events.map(event => ({
        value: event.id,
        label: event.name,
        group: curr.data.data.name
      })) ?? [];

    return [...acc, ...selectEventData];
  }, []);

  return (
    <Center maw={960} mx="auto" mt="2rem">
      <form
        onSubmit={form.onSubmit(values => {
          const { timeframe } = values;
          let { startDate, endDate } = values;

          if (timeframe && timeframe !== 'custom') {
            const today = new Date();
            endDate = new Date();

            if (timeframe === 'month') {
              startDate = sub(today, {
                days: 30
              });
            } else if (timeframe === 'quarter') {
              startDate = sub(today, {
                days: 90
              });
            } else if (timeframe === 'semi') {
              startDate = sub(today, {
                months: 6
              });
            } else if (timeframe === 'year') {
              startDate = sub(today, {
                days: 365
              });
            }
          }

          exportF2FReportDataMutation.mutate({
            data: {
              f2fs: values.f2fs?.length ? values.f2fs : undefined,
              startDate,
              endDate
            }
          });
        })}
      >
        <Stack w={480} spacing="lg">
          <MultiSelect
            label="Choose F2F Types (optional)"
            placeholder="Pick as many as you like"
            data={f2fs}
            searchable
            nothingFound="No F2Fs"
            {...form.getInputProps('f2fs')}
          />
          <Box p="md" sx={{ border: '0.065rem solid #ced4da' }}>
            <MultiSelect
              label="Choose events (optional)"
              placeholder="Pick as many as you like"
              w={440}
              data={events}
              searchable
              nothingFound="No events"
              {...form.getInputProps('events')}
            />
            <Divider my="md" label="OR" labelPosition="center" />
            <Stack>
              <Select
                label="Choose timeframe (optional)"
                placeholder="Pick one"
                data={timeframes}
                {...form.getInputProps('timeframe')}
              />
              {form.values.timeframe === 'custom' && (
                <Grid grow>
                  <Grid.Col span="auto">
                    <DatePickerInput
                      label="Start date"
                      {...form.getInputProps('startDate')}
                    />
                  </Grid.Col>
                  <Grid.Col span="auto">
                    <DatePickerInput
                      label="End date"
                      {...form.getInputProps('endDate')}
                    />
                  </Grid.Col>
                </Grid>
              )}
            </Stack>
          </Box>

          <Button
            mt="sm"
            type="submit"
            color="dark"
            loading={exportF2FReportDataMutation.isPending}
          >
            Download
          </Button>
        </Stack>
      </form>
    </Center>
  );
};
