import { Container, Tabs } from '@mantine/core';
import React from 'react';
import { CourseReportExportPanel } from './course-export';
import { F2FReportExportPanel } from './f2f-export';

export const ReportPage = () => {
  return (
    <Container size="lg" my="lg">
      <Tabs w="100%" defaultValue="course" color="dark" variant="pills">
        <Tabs.List position="center" grow>
          <Tabs.Tab value="course">eLearning</Tabs.Tab>
          <Tabs.Tab value="f2f">F2F</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="course">
          <CourseReportExportPanel />
        </Tabs.Panel>

        <Tabs.Panel value="f2f">
          <F2FReportExportPanel />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
