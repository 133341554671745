/* eslint-disable no-param-reassign */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosResponse
} from 'axios';
import { AxiosRequestDto, IAxiosAdapter } from 'types/api/axios-adapter';
import { env } from 'env';
import { AppResponse } from 'types/api';
import { ErrorCodes } from 'enums/error-codes';
import { Tokens } from 'types/api/auth';
import { Routes } from 'enums';
import { authInfoManagerHolder } from './auth-info-manager-holder';

class AxiosAdapter implements IAxiosAdapter {
  #adapter: AxiosInstance;

  constructor() {
    this.#adapter = axios.create({
      baseURL: env.urls.backendOrigin
    });

    this.#adapter.interceptors.request.use(
      config => {
        const authManager = authInfoManagerHolder.getAuthInfoManager();
        const { accessToken: token } = authManager.getTokens();
        if (token) {
          // @ts-ignore
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
          };
        }
        return config;
      },
      error => Promise.reject(error)
    );

    this.#adapter.interceptors.response.use(
      (response: AxiosResponse<AppResponse<any>, any>) => response,
      async (error: AxiosError<AppResponse<any>, any>) => {
        const code = error.response?.data?.error?.code;
        if (code !== ErrorCodes.UNAUTHORIZED) {
          return Promise.reject(error.response.data.error);
        }

        const authManager = authInfoManagerHolder.getAuthInfoManager();
        const tokens = authManager.getTokens();
        try {
          const { refreshToken } = tokens;
          const res: AxiosResponse<AppResponse<Tokens>> = await axios.get(
            '/auth/refresh',
            {
              baseURL: env.urls.backendOrigin,
              headers: {
                'X-Refresh-Token': refreshToken as string
              } as Record<string, string>
            }
          );

          if (res.data.data) {
            const dto: Tokens = res.data.data;
            authManager.setTokens(dto);

            const { config } = error;
            config.headers.Authorization = `Bearer ${dto.accessToken}`;

            return await new Promise((resolve, reject) => {
              axios
                .request(config)
                .then(response => {
                  resolve(response);
                })
                .catch(e => {
                  reject(e);
                });
            });
          }
          if (res.data.error.code === ErrorCodes.UNAUTHORIZED) {
            authManager.logout();
            window.location.href = `${window.location.origin}${Routes.Login}`;
          }
        } catch (err) {
          return Promise.reject(err);
        }
        return Promise.reject(error.response.data.error);
      }
    );
  }

  doDelete<T>(dto: AxiosRequestDto): AxiosPromise<T> {
    const { url, headers, queryParams, payload } = dto;
    return this.#adapter.delete(url, {
      headers,
      params: queryParams,
      data: payload
    });
  }

  doGet<T>(dto: AxiosRequestDto): AxiosPromise<T> {
    const { url, headers, queryParams } = dto;
    return this.#adapter.get(url, {
      headers,
      params: queryParams,
      withCredentials: true
    });
  }

  doPatch<T>(dto: AxiosRequestDto): AxiosPromise<T> {
    const { url, payload, headers, queryParams } = dto;
    return this.#adapter.patch(url, payload, {
      headers,
      params: queryParams
    });
  }

  doPost<T>(dto: AxiosRequestDto): AxiosPromise<T> {
    const { url, payload, headers, queryParams } = dto;
    return this.#adapter.post(url, payload, {
      headers,
      params: queryParams
    });
  }

  doPut<T>(dto: AxiosRequestDto): AxiosPromise<T> {
    const { url, payload, headers, queryParams } = dto;
    return this.#adapter.put(url, payload, {
      headers,
      params: queryParams
    });
  }

  doGetPDF(dto: AxiosRequestDto): AxiosPromise {
    const { url, headers, queryParams } = dto;
    return this.#adapter.get(url, {
      headers,
      params: queryParams,
      responseType: 'blob'
    });
  }
}

export const axiosAdapter = new AxiosAdapter();
