/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { Routine } from 'redux-saga-routines';
import { produce } from 'immer';
import { UserState } from 'types/state';
import { UserRoles } from 'enums';
import {
  activate,
  assertTokensAreValid,
  login,
  logout,
  register,
  restartAccountActivation,
  fetchUserInfo,
  checkToken,
  setBasic,
  setPersonal,
  setNSW,
  setVic,
  setQld,
  setWA,
  setSA,
  setTas,
  setACT,
  setNT,
  setEmployment,
  setDiscipline,
  setExperience,
  registerAndCheckout,
  resetPassword,
  resetPasswordRequest,
  updatePassword,
  registerAndEoI
} from './routines';
import { authService } from 'service/auth';

const initialState: UserState = {
  id: authService.parseJwtPayload()?.sub,
  email: authService.parseJwtPayload()?.email,
  role: authService.parseJwtPayload()?.role || UserRoles.GUEST,
  isAuthenticated: authService.isAuthenticated(),
  isActive: authService.parseJwtPayload()?.isActive || false,
  coordinatorFor: undefined,
  isLoading: false,
  verificationIsInProgress: true,
  error: undefined
};

export const userReducer = produce((state: UserState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case activate.TRIGGER:
    case restartAccountActivation.TRIGGER:
    case checkToken.TRIGGER:
    case register.TRIGGER:
    case login.TRIGGER:
    case setBasic.TRIGGER:
    case setPersonal.TRIGGER:
    case setNSW.TRIGGER:
    case setVic.TRIGGER:
    case setQld.TRIGGER:
    case setWA.TRIGGER:
    case setSA.TRIGGER:
    case setTas.TRIGGER:
    case setACT.TRIGGER:
    case setNT.TRIGGER:
    case setEmployment.TRIGGER:
    case setDiscipline.TRIGGER:
    case setExperience.TRIGGER:
    case registerAndCheckout.TRIGGER:
    case registerAndEoI.TRIGGER:
    case resetPasswordRequest.TRIGGER:
    case resetPassword.TRIGGER:
    case updatePassword.TRIGGER:
      state.isLoading = true;
      state.error = '';
      break;
    case assertTokensAreValid.SUCCESS:
    case login.SUCCESS:
    case activate.SUCCESS:
      state.id = payload.sub;
      state.email = payload.email;
      state.role = payload.role;
      state.isAuthenticated = Boolean(payload.sub);
      state.isActive = payload.isActive;
      state.verificationIsInProgress = false;
      state.isLoading = false;
      state.error = undefined;
      break;
    case register.SUCCESS:
    case registerAndEoI.SUCCESS:
      state.isLoading = false;
      state.id = payload.id;
      state.email = payload.email;
      state.isAuthenticated = false;
      state.isActive = false;
      break;
    case logout.SUCCESS:
    case assertTokensAreValid.FAILURE:
      state.id = undefined;
      state.email = undefined;
      state.role = UserRoles.GUEST;
      state.isAuthenticated = false;
      state.isActive = false;
      state.verificationIsInProgress = false;
      break;
    case restartAccountActivation.SUCCESS:
      state.isLoading = false;
      break;
    case fetchUserInfo.SUCCESS:
      Object.assign(state, payload);
      state.isLoading = false;
      break;
    case checkToken.SUCCESS:
      state.isLoading = false;
      break;
    case setBasic.SUCCESS:
      state.isLoading = false;
      state.basic = payload;
      break;
    case setNSW.SUCCESS:
      state.isLoading = false;
      state.nsw = payload;
      break;
    case setVic.SUCCESS:
      state.isLoading = false;
      state.vic = payload;
      break;
    case setQld.SUCCESS:
      state.isLoading = false;
      state.qld = payload;
      break;
    case setWA.SUCCESS:
      state.isLoading = false;
      state.wa = payload;
      break;
    case setSA.SUCCESS:
      state.isLoading = false;
      state.sa = payload;
      break;
    case setTas.SUCCESS:
      state.isLoading = false;
      state.tas = payload;
      break;
    case setACT.SUCCESS:
      state.isLoading = false;
      state.act = payload;
      break;
    case setNT.SUCCESS:
      state.isLoading = false;
      state.nt = payload;
      break;
    case setEmployment.SUCCESS:
      state.isLoading = false;
      if (payload.isPrimary) state.primaryEmployment = payload;
      else state.secondaryEmployment = payload;
      break;
    case setDiscipline.SUCCESS:
      state.isLoading = false;
      state.discipline = payload;
      break;
    case setExperience.SUCCESS:
      state.isLoading = false;
      state.experience = payload;
      break;
    case setPersonal.SUCCESS:
      state.isLoading = false;
      state.personal = payload;
      break;
    case resetPassword.SUCCESS:
    case resetPasswordRequest.SUCCESS:
    case updatePassword.SUCCESS:
      state.isLoading = false;
      break;
    case login.FAILURE:
    case register.FAILURE:
    case setBasic.FAILURE:
    case setPersonal.FAILURE:
    case setNSW.FAILURE:
    case setVic.FAILURE:
    case setQld.FAILURE:
    case setWA.FAILURE:
    case setSA.FAILURE:
    case setTas.FAILURE:
    case setACT.FAILURE:
    case setNT.FAILURE:
    case setEmployment.FAILURE:
    case setDiscipline.FAILURE:
    case setExperience.FAILURE:
    case activate.FAILURE:
    case checkToken.FAILURE:
    case restartAccountActivation.FAILURE:
    case fetchUserInfo.FAILURE:
    case registerAndCheckout.FAILURE:
    case registerAndEoI.FAILURE:
    case resetPasswordRequest.FAILURE:
    case resetPassword.FAILURE:
    case updatePassword.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
