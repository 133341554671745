import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FileIndex, RootState } from 'types/state';
import { Title, Table, Alert, LoadingOverlay } from '@mantine/core';
import { fetchFiles as fetchFilesRoutine } from 'store/file/routines';

type Props = {
  files: FileIndex;
  isLoading: boolean;
  error?: string;
  fetchFiles: () => void;
};

const FileListComponent: React.FC<Props> = ({
  files,
  isLoading,
  error,
  fetchFiles
}) => {
  useEffect(() => {
    fetchFiles();
  }, []);

  const fileList = Object.values(files);

  return (
    <div>
      <Title order={2}>File List</Title>
      {error && (
        <Alert title="Error fetching files" color="red">
          {error}
        </Alert>
      )}
      <Table>
        <thead>
          <tr>
            <th>Original Filename</th>
          </tr>
        </thead>
        <tbody>
          {fileList.map(file => (
            <tr key={file.id}>
              <td>{file.originalFilename}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LoadingOverlay visible={isLoading} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  files: state.file.files,
  isLoading: state.file.isLoading,
  error: state.file.error
});

const mapDispatchToProps = {
  fetchFiles: fetchFilesRoutine
};

export const FileList = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileListComponent);
