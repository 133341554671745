/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { Routine } from 'redux-saga-routines';
import { produce } from 'immer';
import { fetchF2FType, fetchF2FTypes, createF2FType, upsertEmail, deleteEmail, createTask, reorderTask, deleteTask, updateTask, updateF2FType, uploadSplash } from './routines';
import { F2FTypeIndex, FaceToFaceState } from 'types/state';
import { F2FTaskDto, F2FTypeDto } from 'types/api/face-to-face';
import { uploadFileForF2F } from 'store/file/routines';
import { answerSurvey } from 'store/survey/routines';
import { setScormData } from 'store/scorm/routines';
import { createUserUpload } from 'store/user-upload/routines';
import { F2FTaskWhen } from 'enums/training';
import { LessonStatusType, ScormDataType } from 'enums/scorm';

const initialState: FaceToFaceState = {
  error: undefined,
  isLoading: false,
  types: {}
};

export const faceToFaceReducer = produce((state: FaceToFaceState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case fetchF2FTypes.TRIGGER:
    case fetchF2FType.TRIGGER:
    case createF2FType.TRIGGER:
    case updateF2FType.TRIGGER:
    case upsertEmail.TRIGGER:
    case deleteEmail.TRIGGER:
    case createTask.TRIGGER:
    case updateTask.TRIGGER:
    case reorderTask.TRIGGER:
    case deleteTask.TRIGGER:
    case uploadSplash.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchF2FTypes.SUCCESS:
      state.isLoading = false;
      state.types = payload.reduce(
        (acc: F2FTypeIndex, f2f: F2FTypeDto) => ({
          ...acc,
          [f2f.id]: f2f
        }),
        {}
      );
      break;
    case createF2FType.SUCCESS:
    case fetchF2FType.SUCCESS:
    case updateF2FType.SUCCESS:
      state.isLoading = false;
      state.types[payload.id] = { ...state.types[payload.id], ...payload };
      break;
    case upsertEmail.SUCCESS:
      state.isLoading = false;
      if (payload.insert) state.types[payload.typeId].emails.push(payload.data);
      else
        state.types[payload.typeId].emails.forEach(email => {
          if (email.id === payload.data.id) Object.assign(email, payload.data);
        });
      break;
    case deleteEmail.SUCCESS:
      state.isLoading = false;
      state.types[payload.typeId].emails = state.types[payload.typeId].emails.filter(email => email.id !== payload.emailId);
      break;
    case createTask.SUCCESS:
      state.isLoading = false;
      state.types[payload.typeId][payload.task.when].push(payload.task);
      break;
    case updateTask.SUCCESS:
      state.isLoading = false;
      Object.assign(
        state.types[payload.typeId][payload.task.when].find((t: F2FTaskDto) => t.id === payload.task.id),
        payload.task
      );
      break;
    case reorderTask.SUCCESS:
      state.isLoading = false;
      state.types[payload.typeId].BEFORE = payload.BEFORE;
      state.types[payload.typeId].IMMEDIATELY_BEFORE = payload.IMMEDIATELY_BEFORE;
      state.types[payload.typeId].AFTER = payload.AFTER;
      break;
    case deleteTask.SUCCESS:
      state.isLoading = false;
      state.types[payload.typeId].BEFORE = state.types[payload.typeId].BEFORE.filter(task => task.id !== payload.taskId);
      state.types[payload.typeId].IMMEDIATELY_BEFORE = state.types[payload.typeId].IMMEDIATELY_BEFORE.filter(task => task.id !== payload.taskId);
      state.types[payload.typeId].AFTER = state.types[payload.typeId].AFTER.filter(task => task.id !== payload.taskId);
      break;
    case uploadSplash.SUCCESS:
      state.isLoading = false;
      state.types[payload.f2fId].splash = payload.splash;
      break;
    case answerSurvey.SUCCESS:
      if (payload.taskType === 'F2F') {
        const f2f = state.types[payload.parentId];
        if (f2f) {
          Object.keys(F2FTaskWhen).forEach(when => {
            let canUnlockTask = false;
            f2f[when].forEach((task: F2FTaskDto) => {
              if (payload.data.f2fTaskId === task.id) {
                task.status = 'COMPLETE';
                canUnlockTask = true;
              } else if (canUnlockTask && task.status === 'LOCKED') {
                task.status = 'INCOMPLETE';
                canUnlockTask = false;
              }
            });
          });
        }
      }
      break;
    case setScormData.TRIGGER:
      if (payload.key !== ScormDataType.LESSON_STATUS) break;
      if (payload.value !== LessonStatusType.COMPLETED && payload.value !== LessonStatusType.PASSED) break;
      Object.values(state.types).forEach(f2f => {
        Object.keys(F2FTaskWhen).forEach(when => {
          let canUnlockTask = false;
          f2f[when].forEach((task: F2FTaskDto) => {
            if (payload.id === task.scormId) {
              task.status = 'COMPLETE';
              canUnlockTask = true;
            } else if (canUnlockTask && task.status === 'LOCKED') {
              task.status = 'INCOMPLETE';
              canUnlockTask = false;
            }
          });
        });
      });
      break;
    case createUserUpload.SUCCESS:
      Object.values(state.types).forEach(f2f => {
        Object.keys(F2FTaskWhen).forEach(when => {
          let canUnlockTask = false;
          f2f[when].forEach((task: F2FTaskDto) => {
            if (payload.taskId === task.id) {
              task.status = 'COMPLETE';
              canUnlockTask = true;
            } else if (canUnlockTask && task.status === 'LOCKED') {
              task.status = 'INCOMPLETE';
              canUnlockTask = false;
            }
          });
        });
      });
      break;
    // FIXME one for courses as well?
    case fetchF2FTypes.FAILURE:
    case fetchF2FType.FAILURE:
    case createF2FType.FAILURE:
    case updateF2FType.FAILURE:
    case upsertEmail.FAILURE:
    case deleteEmail.FAILURE:
    case createTask.FAILURE:
    case updateTask.FAILURE:
    case reorderTask.FAILURE:
    case deleteTask.FAILURE:
    case uploadSplash.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    case uploadFileForF2F.SUCCESS:
      state.types[payload.typeId].files.push(payload.file);
      break;
    // no default
  }
}, initialState);
