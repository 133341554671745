import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Container,
  TextInput,
  Button,
  Group,
  Title,
  Text,
  Card,
  Anchor
} from '@mantine/core';
import { useForm, isEmail } from '@mantine/form';
import { RootState, UserState } from 'types/state';
import { register as registerRoutine } from 'store/user/routines';
import { Routes } from 'enums/routing/routes';

type Props = {
  user: UserState;
  register: (arg0: { email: string }) => void;
};

const RegistrationComponent: React.FC<Props> = ({ user, register }) => {
  const [searchParams] = useSearchParams();
  const msg = searchParams.get('msg');
  const email = searchParams.get('email');

  const form = useForm({
    initialValues: {
      email: email || ''
    },
    validate: {
      email: isEmail('Please enter a valid email address')
    }
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (user.id) {
      if (user.isActive) {
        navigate('/');
      } else {
        navigate(Routes.AwaitingActivation, { state: { email: user.email } });
      }
    }
  }, [user]);

  return (
    <Container size="xs">
      <Card withBorder my="xl">
        <form onSubmit={form.onSubmit(register)}>
          <Title order={1} mb="lg">
            Create InsideOut eLearning Hub Account
          </Title>
          {msg && <Alert color="green">{msg}</Alert>}
          <Text my="md">
            To begin creating your account please enter your email address.
          </Text>
          {user.error && (
            <Alert title="Error creating account" color="red" my="md">
              {user.error}
              {user.error ===
                'An account with this email address already exists.' && (
                <Text>
                  <Anchor component={Link} to={Routes.Login}>
                    Log In
                  </Anchor>{' '}
                  or{' '}
                  <Anchor component={Link} to={Routes.ResetPasswordRequest}>
                    Reset your Password
                  </Anchor>
                </Text>
              )}
            </Alert>
          )}
          <TextInput
            my="md"
            label="Email address"
            {...form.getInputProps('email')}
          />
          <Group position="center" my="md">
            <Button type="submit" uppercase loading={user.isLoading}>
              Create Account
            </Button>
          </Group>
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (rootState: RootState) => ({
  user: rootState.user
});

const mapDispatchToProps = {
  register: registerRoutine
};

export const Registration = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationComponent);
