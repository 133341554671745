import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Title, Container, Alert, LoadingOverlay, Anchor, Text, List, Box } from '@mantine/core';
import { IconArrowLeft, IconPdf } from '@tabler/icons-react';
import { CourseIndex, RootState } from 'types/state';
import { fetchCourse as fetchCourseRoutine } from 'store/course/routines';
import { Routes } from 'enums';

type Props = {
  courses: CourseIndex;
  fetchCourse: (id: string) => void;
  error?: string;
  isLoading: boolean;
};

const CourseResourcesComponent: React.FC<Props> = ({ courses, fetchCourse, error, isLoading }) => {
  const { id } = useParams();

  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  const course = courses[id];

  if (!course || isLoading) return <LoadingOverlay visible />;

  return (
    <Container size="lg" mt="4rem">
      <Anchor component={Link} to={`${Routes.courseEnrolment}/${course.id}`} display="flex">
        <IconArrowLeft />
        <Text ml="xs">Back to Course</Text>
      </Anchor>
      <Title order={2}>{course.title} Resources</Title>

      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      {course.resources.map(page => (
        <Box key={page.id}>
          <Title order={4}>{page.title}</Title>
          <List icon={<IconPdf />}>
            {page.resources.map(resource => (
              <List.Item key={resource.id}>
                <Anchor href={resource.link}>{resource.text}</Anchor>
              </List.Item>
            ))}
          </List>
        </Box>
      ))}
      {course.resources.length === 0 && <Text>This course has no associated resources.</Text>}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses,
  error: state.course.error,
  isLoading: state.course.isLoading
});

const mapDispatchToProps = {
  fetchCourse: fetchCourseRoutine
};

export const CourseResources = connect(mapStateToProps, mapDispatchToProps)(CourseResourcesComponent);
