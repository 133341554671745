export enum Headspace {
  ADELAIDE = 'Adelaide',
  ALBANY = 'Albany',
  ALBURY_WODONGA = 'Albury-Wodonga',
  ALICE_SPRINGS = 'Alice Springs',
  ARMADALE = 'Armadale',
  ARMIDALE = 'Armidale',
  ASHFIELD = 'Ashfield',
  BAIRNSDALE = 'Bairnsdale',
  BALLARAT = 'Ballarat',
  BANKSTOWN = 'Bankstown',
  BATEMANS_BAY = 'Batemans Bay',
  BATHURST = 'Bathurst',
  BEAUDESERT = 'Beaudesert',
  BEGA = 'Bega',
  BENDIGO = 'Bendigo',
  BENTLEIGH = 'Bentleigh',
  BERRI = 'Berri',
  BONDI_JUNCTION = 'Bondi Junction',
  BROKEN_HILL = 'Broken Hill',
  BROOKVALE = 'Brookvale',
  BROOME = 'Broome',
  BUNBURY = 'Bunbury',
  BUNDABERG = 'Bundaberg',
  BURNIE = 'Burnie',
  BUSSELTON = 'Busselton',
  CABOOLTURE = 'Caboolture',
  CAMPBELLTOWN = 'Campbelltown',
  CAMPERDOWN = 'Camperdown',
  CANBERRA = 'Canberra',
  CANNINGTON = 'Cannington',
  CAPALABA = 'Capalaba',
  CASTLE_HILL = 'Castle Hill',
  CESSNOCK = 'Cessnock',
  CHATSWOOD = 'Chatswood',
  COFFS_HARBOUR = 'Coffs Harbour',
  COLAC = 'Colac',
  COLLINGWOOD = 'Collingwood',
  COWRA = 'Cowra',
  CRAIGIEBURN = 'Craigieburn',
  DANDENONG = 'Dandenong',
  DARWIN = 'Darwin',
  DEVONPORT = 'Devonport',
  DRUITT = 'Druitt',
  DUBBO = 'Dubbo',
  ECHUCA = 'Echuca',
  EDINBURGH_NORTH = 'Edinburgh North',
  ELSTERNWICK = 'Elsternwick',
  EMERALD = 'Emerald',
  ESPERANCE = 'Esperance',
  FRANKSTON = 'Frankston',
  FREMANTLE = 'Fremantle',
  GEELONG = 'Geelong',
  GERALDTON = 'Geraldton',
  GLADSTONE = 'Gladstone',
  GLENROY = 'Glenroy',
  GOSFORD = 'Gosford',
  GOULBURN = 'Goulburn',
  GRAFTON = 'Grafton',
  GREENSBOROUGH = 'Greensborough',
  GRIFFITH = 'Griffith',
  GYMPIE = 'Gympie',
  HAWTHORN = 'Hawthorn',
  HEDLAND = 'Hedland',
  HERVEY_BAY = 'Hervey Bay',
  HOBART = 'Hobart',
  HORSHAM = 'Horsham',
  HURSTVILLE = 'Hurstville',
  INALA = 'Inala',
  IPSWICH = 'Ipswich',
  JOONDALUP = 'Joondalup',
  KALGOORLIE = 'Kalgoorlie',
  KARRATHA = 'Karratha',
  KATHERINE = 'Katherine',
  KATOOMBA = 'Katoomba',
  KEMPSEY = 'Kempsey',
  KNOX = 'Knox',
  KUNUNURRA = 'Kununurra',
  LAKE_HAVEN = 'Lake Haven',
  LAUNCESTON = 'Launceston',
  LILYDALE = 'Lilydale',
  LISMORE = 'Lismore',
  LITHGOW = 'Lithgow',
  LIVERPOOL = 'Liverpool',
  MACKAY = 'Mackay',
  MAITLAND = 'Maitland',
  MALVERN = 'Malvern',
  MANDURAH = 'Mandurah',
  MARGARET_RIVER = 'Margaret River',
  MARION = 'Marion',
  MAROOCHYDORE = 'Maroochydore',
  MARYBOROUGH = 'Maryborough',
  MEADOWBROOK = 'Meadowbrook',
  MELTON = 'Melton',
  MIDLAND = 'Midland',
  MILDURA = 'Mildura',
  MIRANDA = 'Miranda',
  MORWELL = 'Morwell',
  MOUNT_BARKER = 'Mount Barker',
  MOUNT_DRUITT = 'Mount Druitt',
  MOUNT_GAMBIER = 'Mount Gambier',
  MOUNT_ISA = 'Mount Isa',
  MURRAY_BRIDGE = 'Murray Bridge',
  NARRE_WARREN = 'Narre Warren',
  NEW_ENGLAND = 'New England',
  NORTHAM = 'Northam',
  NOWRA = 'Nowra',
  NUNDAH = 'Nundah',
  OCEAN_GROVE = 'Ocean Grove',
  ONKAPARINGA = 'Onkaparinga',
  ORANGE = 'Orange',
  OSBORNE = 'Osborne',
  PAKENHAM = 'Pakenham',
  PALMERSTON = 'Palmerston',
  PARK = 'Park',
  PARRAMATTA = 'Parramatta',
  PENRITH = 'Penrith',
  PILBARA = 'Pilbara',
  PLENTY_VALLEY = 'Plenty Valley',
  PORT_ADELAIDE = 'Port Adelaide',
  PORT_AUGUSTA = 'Port Augusta',
  PORT_LINCOLN = 'Port Lincoln',
  PORT_MACQUARIE = 'Port Macquarie',
  PORTLAND = 'Portland',
  QUEANBEYAN = 'Queanbeyan',
  REDCLIFFE = 'Redcliffe',
  ROCKHAMPTON = 'Rockhampton',
  ROCKINGHAM = 'Rockingham',
  ROMA = 'Roma',
  ROSEBUD = 'Rosebud',
  SALE = 'Sale',
  SARINA = 'Sarina',
  SHEPPARTON = 'Shepparton',
  SOUTHPORT = 'Southport',
  STRATHPINE = 'Strathpine',
  SUNSHINE = 'Sunshine',
  SWAN_HILL = 'Swan Hill',
  SYDNEY = 'Sydney',
  SYNDAL = 'Syndal',
  TAMWORTH = 'Tamworth',
  TAREE = 'Taree',
  TARINGA = 'Taringa',
  TOOWOOMBA = 'Toowoomba',
  TOWNSVILLE = 'Townsville',
  TUGGERANONG = 'Tuggeranong',
  TWEED = 'Tweed',
  TWEED_HEADS = 'Tweed Heads',
  UPPER_COOMERA = 'Upper Coomera',
  VICTOR_HARBOR = 'Victor Harbor',
  WAGGA_WAGGA = 'Wagga Wagga',
  WANGARATTA = 'Wangaratta',
  WARRNAMBOOL = 'Warrnambool',
  WARWICK = 'Warwick',
  WERRIBEE = 'Werribee',
  WHITSUNDAYS = 'Whitsundays',
  WHYALLA = 'Whyalla',
  WOLLONGONG = 'Wollongong',
  WONTHAGGI = 'Wonthaggi',
  WOOLLOONGABBA = 'Woolloongabba',
  WYONG = 'Wyong',
  YASS = 'Yass',
  OTHER = 'Other'
}
