import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { AppResponse } from 'types/api/response';
import { CourseEnrolment } from 'types/backend';

export const createCourseEnrolment = ({
  courseId,
  data
}: {
  courseId: string;
  data: { userEmail?: string; userId?: string };
}): Promise<AppResponse<CourseEnrolment>> => {
  return api.post(`/course-new/${courseId}/enrolments`, data);
};

type UseCreateCourseEnrolmentOptions = {
  mutationConfig?: MutationConfig<typeof createCourseEnrolment>;
};

export const useCreateCourseEnrolment = ({
  mutationConfig
}: UseCreateCourseEnrolmentOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: createCourseEnrolment
  });
};
