import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { UserDto } from 'types/api/users';
import { UserRoles } from 'enums';
import { NSWHealthLHD } from 'enums/user-info';

class UsersService {
  async getAll(): Promise<UserDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<UserDto[]>>({
      url: '/users'
    });
    return data.data;
  }

  async getByRole(role: UserRoles): Promise<UserDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<UserDto[]>>({
      url: `/users/by-role/${role}`
    });
    return data.data;
  }

  async create(
    email: string,
    role: keyof typeof UserRoles,
    coordinatorFor: keyof typeof NSWHealthLHD
  ): Promise<UserDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserDto>>({
      url: '/users/create',
      payload: { email, role, coordinatorFor }
    });
    return data.data;
  }
}

export const usersService = new UsersService();
