import {
  ActionIcon,
  Alert,
  Button,
  Divider,
  Drawer,
  Group,
  Select,
  Stack,
  TextInput,
  Title
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useCreateCouponValidationRule } from '../api/create-coupon-validation-rule';
import { useCouponValidationRule } from '../api/get-coupon-validation-rule';
import { useUpdateCouponValidationRule } from '../api/update-coupon-validation-rule';

interface CouponValidationRuleDrawerProps {
  open: boolean;
  onClose: () => void;
  coupons: Array<{ label: string; value: string }>;
  editId?: string;
}

interface CouponValidationRuleFormData {
  coupon: string;
  domains: Array<{ domain: string; key: string }>;
}

export const CouponValidationRuleDrawer = ({
  open,
  onClose,
  coupons,
  editId
}: CouponValidationRuleDrawerProps) => {
  const form = useForm<CouponValidationRuleFormData>({
    initialValues: {
      coupon: '',
      domains: [{ domain: '', key: randomId() }]
    },
    validate: {
      coupon: isNotEmpty('Please select a coupon'),
      domains: {
        domain: value =>
          /[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?/.test(value)
            ? null
            : 'Invalid domain name'
      }
    }
  });

  const couponValidationRuleQuery = useCouponValidationRule({
    id: editId,
    queryConfig: {
      enabled: !!editId
    }
  });

  const createCouponValidationRuleMutation = useCreateCouponValidationRule({
    mutationConfig: {
      onSuccess: data => {
        notifications.show({
          id: `create-coupon-validation-rule-${data.data?.id}`,
          autoClose: 3000,
          color: 'teal',
          icon: <IconCheck />,
          title: 'Coupon validation rule created',
          message: `Validation rule for coupon ${data.data.stripe_coupon_name} successfully created`
        });

        onClose();
        form.reset();
      }
    }
  });

  const updateCouponValidationRuleMutation = useUpdateCouponValidationRule({
    mutationConfig: {
      onSuccess: data => {
        notifications.show({
          id: `update-coupon-validation-rule-${data.data?.id}`,
          autoClose: 3000,
          color: 'teal',
          icon: <IconCheck />,
          title: 'Coupon validation rule updated',
          message: `Validation rule for coupon ${data.data.stripe_coupon_name} successfully updated`
        });

        onClose();
        form.reset();
      }
    }
  });

  useEffect(() => {
    if (couponValidationRuleQuery.data) {
      const { data } = couponValidationRuleQuery.data;

      form.setValues({
        coupon: data.stripe_coupon_id,
        domains: data.email_domains.map(domain => ({
          domain,
          key: randomId()
        }))
      });
    }
  }, [couponValidationRuleQuery.data]);

  const handleAddAnotherDomainClick = () => {
    form.insertListItem('domains', { domain: '', key: randomId() });
  };

  const onDrawerClose = () => {
    onClose();
    form.reset();
  };

  const isUpdating = !!editId;

  return (
    <Drawer
      position="right"
      opened={open}
      onClose={onDrawerClose}
      keepMounted={false}
    >
      <form
        onSubmit={form.onSubmit(values => {
          const data = {
            stripeCouponId: values.coupon,
            domains: values.domains.map(item => item.domain)
          };

          if (isUpdating) {
            updateCouponValidationRuleMutation.mutate({
              id: editId,
              stripeCouponId: data.stripeCouponId,
              domains: data.domains
            });
          } else {
            createCouponValidationRuleMutation.mutate({ data });
          }
        })}
      >
        <Title mb="lg" order={4}>
          {isUpdating ? 'Edit Rule' : 'Create New Rule'}
        </Title>

        {createCouponValidationRuleMutation.isError && (
          <Alert
            my="md"
            color="red"
            variant="light"
            title="Oops! Looks like there was a problem"
          >
            {createCouponValidationRuleMutation.error.message}
          </Alert>
        )}

        {updateCouponValidationRuleMutation.isError && (
          <Alert
            my="md"
            color="red"
            variant="light"
            title="Oops! Looks like there was a problem"
          >
            {updateCouponValidationRuleMutation.error.message}
          </Alert>
        )}

        <Stack>
          <Select
            label="Choose a coupon"
            data={coupons}
            limit={100}
            searchable
            withAsterisk
            {...form.getInputProps('coupon')}
          />
          <Stack spacing="xs">
            {form.values.domains.map((item, index) => (
              <Stack spacing="xs" key={item.key}>
                {index !== 0 && (
                  <Divider
                    label="AND"
                    labelPosition="center"
                    variant="dashed"
                  />
                )}
                <Group
                  align={
                    form.errors[`domains.${index}.domain`] ? 'center' : 'end'
                  }
                >
                  <TextInput
                    label="Email domain"
                    placeholder="example.com"
                    sx={{ flexGrow: 1 }}
                    withAsterisk
                    {...form.getInputProps(`domains.${index}.domain`)}
                  />
                  <ActionIcon
                    variant="light"
                    color="red"
                    size="lg"
                    onClick={() => form.removeListItem('domains', index)}
                    disabled={form.values.domains.length <= 1}
                  >
                    <IconTrash size="20" />
                  </ActionIcon>
                </Group>
              </Stack>
            ))}
          </Stack>
          <Button
            variant="white"
            leftIcon={<IconPlus size="20" />}
            onClick={handleAddAnotherDomainClick}
          >
            Add another domain
          </Button>
          <Button
            color="dark"
            type="submit"
            loading={createCouponValidationRuleMutation.isPending}
          >
            {isUpdating ? 'Save' : 'Create validation rule'}
          </Button>
        </Stack>
      </form>
    </Drawer>
  );
};
