import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getBaseUrlByRole, Routes } from 'enums/routing/routes';
import { UserState } from 'types/state';
import { UserRoles } from 'enums/user-roles';
import { Onboarding } from 'pages/Learner/Onboarding';

type Props = {
  user?: UserState;
  rolesAllowedToVisit?: UserRoles[];
  canBeInactive?: boolean;
};

const PrivateRoute: React.FC<React.PropsWithChildren<Props>> = ({ user, rolesAllowedToVisit = [], canBeInactive = false, children }) => {
  const location = useLocation();
  const { role, isAuthenticated, isActive } = user;

  if (!isAuthenticated) return <Navigate to={Routes.Login} state={{ from: location }} />;

  if (!(isActive || canBeInactive)) return <Navigate to={Routes.Profile} />;

  if (!rolesAllowedToVisit?.includes(role)) return <Navigate to={getBaseUrlByRole(role)} />;

  return <Onboarding>{children}</Onboarding>;
};

export default PrivateRoute;
