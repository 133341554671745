import React from 'react';
import { Text, Card, Button, Box } from '@mantine/core';
import { EventDto } from 'types/api/event';
import { EventType } from 'enums/event/event-type';
import dayjs from 'dayjs';

type Props = {
  event: EventDto;
};

export const EventDetails: React.FC<Props> = ({ event }) => {
  return (
    <>
      <Text my="md">Your attendance at training is manually marked by the facilitator. There may be a small delay in your attendance being reflected for this reason.</Text>
      {event.instances.map((instance, index) => (
        <Card key={instance.id} withBorder shadow="sm" my="lg">
          <Text fw="bold">
            Day {index + 1}: {EventType[instance.type]}
          </Text>
          <Text>
            {dayjs(instance.startsAt).format('DD MMM YYYY hh:mm a')}
            {dayjs(instance.endsAt).format(' - hh:mm a')}
          </Text>
          {instance.type === 'ONLINE' && (
            <Button component="a" href={instance.link} variant="light" mt="lg">
              Open Meeting
            </Button>
          )}
          {instance.type === 'IN_PERSON' && (
            <Box mt="lg">
              <Text>{instance.address}</Text>
              <Text>{instance.city}</Text>
              <Text>{instance.state}</Text>
            </Box>
          )}
        </Card>
      ))}
    </>
  );
};
