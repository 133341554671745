import { all } from 'redux-saga/effects';
import userSaga from './user/sagas';
import eventSaga from './event/sagas';
import faceToFaceSaga from './face-to-face/sagas';
import { scormSaga } from './scorm/sagas';
import { courseSaga } from './course/sagas';
import { usersSaga } from './users/sagas';
import { surveySaga } from './survey/sagas';
import { fileSaga } from './file/sagas';
import { userUploadSaga } from './user-upload/sagas';
import { certificateSaga } from './certificate/sagas';
import { courseEnrolmentSaga } from './course-enrolment/sagas';
import { eventEnrolmentSaga } from './event-enrolment/sagas';
import { dataExportSaga } from './data-export/sagas';
import { surveyAnswerSaga } from './survey-answer/sagas';

export default function* rootSaga() {
  yield all([
    userSaga(),
    eventSaga(),
    eventEnrolmentSaga(),
    faceToFaceSaga(),
    scormSaga(),
    courseSaga(),
    courseEnrolmentSaga(),
    usersSaga(),
    surveySaga(),
    surveyAnswerSaga(),
    fileSaga(),
    userUploadSaga(),
    certificateSaga(),
    dataExportSaga()
  ]);
}
