import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SimpleGrid, Alert, Skeleton, Title } from '@mantine/core';
import { RootState, EventIndex, F2FTypeIndex } from 'types/state';
import { fetchEvents as fetchEventsRoutine } from 'store/event/routines';
import { fetchF2FTypes as fetchF2FTypesRoutine } from 'store/face-to-face/routines';
import { PendingCard } from './PendingCard';
import { ActiveCard } from './ActiveCard';
import { CompleteCard } from './CompleteCard';

type Props = {
  events: EventIndex;
  isLoading: boolean;
  error?: string;
  fetchEvents: () => void;
  f2fs: F2FTypeIndex;
  fetchF2FTypes: () => void;
};

const TrainingsComponent: React.FC<Props> = ({ events, isLoading, error, fetchEvents, f2fs, fetchF2FTypes }) => {
  useEffect(() => {
    fetchEvents();
    fetchF2FTypes();
  }, []);

  if (error) {
    return (
      <Alert color="red" title="Error loading your events.">
        {error}
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <SimpleGrid cols={4} mt="md" mb="xl">
        <Skeleton height={150} />
        <Skeleton height={150} />
        <Skeleton height={150} />
        <Skeleton height={150} />
      </SimpleGrid>
    );
  }

  return (
    <>
      {Object.values(events).length > 0 && (
        <Title order={2} my="md">
          Training Events
        </Title>
      )}

      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: '62rem', cols: 3, spacing: 'md' },
          { maxWidth: '48rem', cols: 2, spacing: 'sm' },
          { maxWidth: '28rem', cols: 1, spacing: 'sm' }
        ]}
        mb="xl"
      >
        {Object.values(events).map(event => {
          if (event.enrolment?.status === 'PENDING') return <PendingCard key={event.id} event={event} />;
          if (event.enrolment?.status === 'APPROVED') return <ActiveCard key={event.id} event={event} f2f={f2fs[event.f2fId]} />;
          if (event.enrolment?.status === 'COMPLETE') return <CompleteCard key={event.id} event={event} f2f={f2fs[event.f2fId]} />;
          return false;
        })}
      </SimpleGrid>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  isLoading: state.event.isLoading,
  error: state.event.error,
  f2fs: state.faceToFace.types
});

const mapDispatchToProps = {
  fetchEvents: fetchEventsRoutine,
  fetchF2FTypes: fetchF2FTypesRoutine
};

export const Trainings = connect(mapStateToProps, mapDispatchToProps)(TrainingsComponent);
