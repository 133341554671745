import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { F2FEmailDto } from 'types/api/face-to-face';

class F2FEmailService {
  constructor() {
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  async create(typeId: string, dto: F2FEmailDto): Promise<F2FEmailDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<F2FEmailDto>>({
      url: `/f2f-training-email/new/${typeId}`,
      payload: dto
    });
    return data.data;
  }

  async update(id: string, dto: F2FEmailDto): Promise<F2FEmailDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<F2FEmailDto>>({
      url: `/f2f-training-email/${id}`,
      payload: dto
    });
    return data.data;
  }

  async delete(id: string) {
    await axiosAdapter.doDelete({
      url: `/f2f-training-email/${id}`
    });
  }
}

export const f2fEmailService = new F2FEmailService();
