import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'types/state';

import { TextInput, Title, Stack, Group, Button, LoadingOverlay, Alert, Text, Divider, ActionIcon } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';

import { createSurvey as createSurveyRoutine } from 'store/survey/routines';
import { SurveyDto } from 'types/api/survey';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { QuestionForm } from '../components/QuestionForm';

type Props = {
  isLoading: boolean;
  error?: string;
  createSurvey: (arg0: { dto: SurveyDto; navigate: NavigateFunction }) => void;
};

const SurveyNewComponent: React.FC<Props> = ({ isLoading, error, createSurvey }) => {
  const navigate = useNavigate();

  const form = useForm<SurveyDto>({
    initialValues: {
      title: '',
      questions: [
        {
          id: randomId(),
          type: 'TEXT',
          text: '',
          options: [
            {
              id: randomId(),
              text: '',
              value: undefined
            }
          ],
          isOther: false
        }
      ]
    }
  });

  return (
    <div>
      <Title order={3}>New Survey</Title>
      {error && (
        <Alert title="Error Creating Survey" color="red">
          {error}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(values => createSurvey({ dto: values, navigate }))}>
        <Stack>
          <TextInput label="Survey title" {...form.getInputProps('title')} />

          <Divider />

          {form.values.questions.map((question, index) => (
            <Stack key={question.id}>
              <Group>
                <Text fw="bold" sx={{ flex: 1 }}>
                  Question {index + 1}
                </Text>
                <ActionIcon size="lg" onClick={() => form.removeListItem('questions', index)}>
                  <IconX />
                </ActionIcon>
              </Group>
              <QuestionForm form={form} values={question} prefix={`questions.${index}.`} />
            </Stack>
          ))}

          {form.values.questions.length === 0 && <Text>No Questions</Text>}

          <Group position="right">
            <Button
              onClick={() =>
                form.insertListItem('questions', {
                  id: randomId(),
                  type: 'TEXT',
                  text: '',
                  options: [
                    {
                      id: randomId(),
                      text: '',
                      value: undefined
                    }
                  ]
                })
              }
            >
              Add Question
            </Button>

            <Button type="submit">Create</Button>
          </Group>
        </Stack>
      </form>
      <LoadingOverlay visible={isLoading} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.survey.isLoading,
  error: state.survey.error
});

const mapDispatchToProps = {
  createSurvey: createSurveyRoutine
};

export const SurveyNew = connect(mapStateToProps, mapDispatchToProps)(SurveyNewComponent);
