import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { UserUploadDto } from 'types/api/user-upload';

class UserUploadService {
  async getAll(): Promise<UserUploadDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<UserUploadDto[]>>({
      url: '/user-upload'
    });
    return data.data;
  }

  async create(taskId: string, file: File): Promise<UserUploadDto> {
    const payload = new FormData();
    payload.append('file', file, file.name);
    const { data } = await axiosAdapter.doPost<AppResponse<UserUploadDto>>({
      url: `/user-upload/for-task/${taskId}`,
      payload
    });
    return data.data;
  }
}

export const userUploadService = new UserUploadService();
