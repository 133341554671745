import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import { CreateSurveyQuestionAnswerIndex, SurveyQuestionDto } from 'types/api/survey';
import { Title, Box, Checkbox, TextInput } from '@mantine/core';

type Props = {
  form: UseFormReturnType<CreateSurveyQuestionAnswerIndex>;
  question: SurveyQuestionDto;
};

export const QuestionCheckbox: React.FC<Props> = ({ form, question }) => {
  return (
    <Box mt="lg" mb="4rem">
      <Title order={4} mt="lg" mb="md">
        {question.text}
      </Title>

      <Checkbox.Group {...form.getInputProps(`${question.id}.valueArray`)}>
        {question.options.map(option => (
          <Checkbox fw="bold" label={option.text} value={option.id} key={option.id} my="md" />
        ))}
      </Checkbox.Group>
      {question.isOther && <Checkbox my="md" fw="bold" label="Other" {...form.getInputProps(`${question.id}.isOther`, { type: 'checkbox' })} />}
      {form.values[question.id].isOther && <TextInput maw="80%" placeholder="Please Specify" {...form.getInputProps(`${question.id}.valueString`)} />}
    </Box>
  );
};
