import React, { useState } from 'react';
import { ScormDto } from 'types/api/scorm';
import { Container, Text, Group, FileButton, Button } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { RichTextEditor } from 'components/RichTextEditor';
import { UpdateScormMetadataDto } from 'types/api/scorm/update.scorm.metadata.dto';

type Props = {
  scorm: ScormDto;
  isLoading: boolean;
  uploadSplash: (arg0: { scormId: string; file: File }) => void;
  updateMetadata: (arg0: UpdateScormMetadataDto) => void;
};

export const ScormEditMetadata: React.FC<Props> = ({ scorm, isLoading, uploadSplash, updateMetadata }) => {
  const [file, setFile] = useState<File>(null);
  const form = useForm<UpdateScormMetadataDto>({
    initialValues: {
      id: scorm.id,
      description: scorm.description
    },
    validate: {
      description: isNotEmpty('This field is required.')
    }
  });
  return (
    <Container size="md">
      <form onSubmit={form.onSubmit(updateMetadata)}>
        <RichTextEditor my="lg" label="Description" form={form} field="description" />
        <Button type="submit" my="lg" loading={isLoading}>
          Save Changes
        </Button>
      </form>

      <Group my="xl">
        <Text sx={{ flex: '1 !important' }}>{file ? file.name : 'Select a file to upload'}</Text>
        <FileButton onChange={setFile} accept="image/png,image/jpeg">
          {props => <Button {...props}>Choose New Splash Image</Button>}
        </FileButton>
        <Button
          loading={isLoading}
          disabled={file === null}
          onClick={() => {
            uploadSplash({ scormId: scorm.id, file });
            setFile(null);
          }}
        >
          Upload
        </Button>
      </Group>
    </Container>
  );
};
