export const EventEnrolmentStatusColors = Object.freeze({
  PENDING: 'yellow',
  APPROVED: 'green',
  REJECTED: 'red',
  COMPLETE: 'blue'
});

export enum EventEnrolmentStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  COMPLETE = 'Complete'
}
