import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Container, Group, Text, Divider, BackgroundImage, Button } from '@mantine/core';
import logo from 'assets/images/logo.svg';
import logoStaging from 'assets/images/logo-staging.svg';

type Props = {
  email: string;
  staging?: boolean;
  logout: () => void;
};

export const AdminHeader: React.FC<Props> = ({ email, staging, logout }) => {
  return (
    <Header height={72}>
      <Container display="flex" size="xl" sx={{ height: '100%', alignItems: 'center' }}>
        <BackgroundImage src={staging ? logoStaging : logo} sx={{ height: '30px', width: '150px' }} component={Link} to="/" mb="6px" />
        <Divider orientation="vertical" mx="xl" my="xl" />

        <Text fw="bold" color="gray" size="lg">
          eLearning
        </Text>

        <Group position="right" sx={{ flex: '1 !important' }} noWrap>
          <Text>{email}</Text>
          <Button onClick={() => logout()} color="cyan" uppercase>
            Log Out
          </Button>
        </Group>
      </Container>
    </Header>
  );
};
