export enum HeadToHealth {
  ADULT_CANBERRA = 'Adult Mental Health Centre - Australian Capital Territory (Canberra)',
  ADULT_PENRITH = 'Adult Mental Health Centre - New South Wales (Penrith)',
  ADULT_DARWIN = 'Adult Mental Health Centre - Northern Territory (Darwin)',
  ADULT_TOWNSVILLE = 'Adult Mental Health Centre - Queensland (Townsville)',
  ADULT_ADELAIDE = 'Adult Mental Health Centre - South Australia (Adelaide)',
  ADULT_LAUNCESTON = 'Adult Mental Health Centre - Tasmania (Launceston)',
  ADULT_MIDLAND = 'Adult Mental Health Centre - Western Australia (Midland)',
  ADULT_GEELONG = 'Adult Mental Health Centre - Victoria (Geelong)',
  POPUP_CENTRAL_EASTERN_SYDNEY = 'Pop Up Clinic - Central Eastern Sydney (Hurstville and Lakemba)',
  POPUP_SOUTH_WESTERN_SYDNEY = 'Pop Up Clinic - South Western Sydney (Bankstown and Liverpool)',
  POPUP_WESTERN_SYDNEY = 'Pop Up Clinic - Western Sydney (Seven Hills and Parramatta)',
  POPUP_NORTHERN_SYDNEY = 'Pop Up Clinic - Northern Sydney (Thornleigh)',
  POPUP_NEPEAN_BLUE_MOUNTAINS = 'Pop Up Clinic - Nepean Blue Mountains (Richmond)',
  POPUP_HUNTER_NEW_ENGLAND_CENTRAL_COAST = 'Pop Up Clinic - Hunter New England Central Coast (Tuggerah, Warners Bay and Cessnock)',
  POPUP_SOUTH_EASTERN_NSW = 'Pop Up Clinic - South Eastern NSW (Shellharbour)',
  POPUP_GIPPSLAND = 'Pop Up Clinic - Gippsland (Warragul and Sale)',
  POPUP_MURRAY = 'Pop Up Clinic - Murray (Shepparton, Seymour, Kangaroo Flat, Wodonga and Mildura)',
  POPUP_WESTERN_VICTORIA = 'Pop Up Clinic - Western Victoria (Sebastopol)',
  POPUP_EASTERN_MELBOURNE = 'Pop Up Clinic - Eastern Melbourne (Hawthorn, West Heidelberg, Box Hill, Boronia, Belgrave, Yarra Junction and Wallan)',
  POPUP_NORTH_WESTERN_MELBOURNE = 'Pop Up Clinic - North Western Melbourne (Brunswick East, Broadmeadows and Wyndham Vale)',
  POPUP_SOUTH_EASTERN_MELBOURNE = 'Pop Up Clinic - South Eastern Melbourne (Berwick, Officer and Frankston)'
}
