import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserVic } from 'types/state';
import { VicHealthRegion, VicHealthType } from 'enums/user-info';

type Props = {
  vic: UserVic;
};

export const DisplayVic: React.FC<Props> = ({ vic }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {vic.isVicHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for Vic Health.
      </Text>
      {vic.isVicHealth && (
        <Text>
          <Text span fw="bold">
            Where in Vic Health:{' '}
          </Text>
          {VicHealthType[vic.type]}
        </Text>
      )}
      {vic.type === 'REGION' && (
        <Text>
          <Text span fw="bold">
            Which Region:{' '}
          </Text>
          {VicHealthRegion[vic.region]}
        </Text>
      )}
    </Box>
  );
};
