import React from 'react';
import { connect } from 'react-redux';
import { Container, Tabs, Title } from '@mantine/core';
import { RootState, UserState } from 'types/state';
import { OnboardingBasic } from '../Onboarding/OnboardingBasic';
import { OnboardingNSW } from '../Onboarding/OnboardingNSW';
import { OnboardingVic } from '../Onboarding/OnboardingVic';
import { OnboardingQld } from '../Onboarding/OnboardingQld';
import { OnboardingWA } from '../Onboarding/OnboardingWA';
import { OnboardingSA } from '../Onboarding/OnboardingSA';
import { OnboardingTas } from '../Onboarding/OnboardingTas';
import { OnboardingNT } from '../Onboarding/OnboardingNT';
import { OnboardingEmployment } from '../Onboarding/OnboardingEmployment';
import { OnboardingDiscipline } from '../Onboarding/OnboardingDiscipline';
import { OnboardingExperience } from '../Onboarding/OnboardingExperience';
import { OnboardingPersonal } from '../Onboarding/OnboardingPersonal';
import { OnboardingACT } from '../Onboarding/OnboardingACT';
import { Settings } from './Settings';

type Props = {
  user: UserState;
};

const AccountSettingsComponent: React.FC<Props> = ({ user }) => {
  return (
    <Container size="xl" bg="white" py="lg" my="xl">
      <Title order={3} mb="lg">
        Account Settings
      </Title>
      <Tabs orientation="vertical" defaultValue="settings">
        <Tabs.List>
          <Tabs.Tab value="settings">Account Settings</Tabs.Tab>
          <Tabs.Tab value="basic">Basic Information</Tabs.Tab>
          <Tabs.Tab value="nsw" disabled={user.basic?.state !== 'NSW'}>
            New South Wales
          </Tabs.Tab>
          <Tabs.Tab value="vic" disabled={user.basic?.state !== 'VIC'}>
            Victoria
          </Tabs.Tab>
          <Tabs.Tab value="qld" disabled={user.basic?.state !== 'QLD'}>
            Queensland
          </Tabs.Tab>
          <Tabs.Tab value="wa" disabled={user.basic?.state !== 'WA'}>
            Western Australia
          </Tabs.Tab>
          <Tabs.Tab value="sa" disabled={user.basic?.state !== 'SA'}>
            South Australia
          </Tabs.Tab>
          <Tabs.Tab value="tas" disabled={user.basic?.state !== 'TAS'}>
            Tasmania
          </Tabs.Tab>
          <Tabs.Tab value="act" disabled={user.basic?.state !== 'ACT'}>
            Australian Capital Territory
          </Tabs.Tab>
          <Tabs.Tab value="nt" disabled={user.basic?.state !== 'NT'}>
            Northern Territory
          </Tabs.Tab>
          <Tabs.Tab value="employment">Employment</Tabs.Tab>
          <Tabs.Tab value="discipline">Discipline</Tabs.Tab>
          <Tabs.Tab value="experience">Experience</Tabs.Tab>
          <Tabs.Tab value="personal">Personal</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="settings" pl="lg">
          <Settings />
        </Tabs.Panel>
        <Tabs.Panel value="basic" pl="lg">
          <OnboardingBasic />
        </Tabs.Panel>
        <Tabs.Panel value="nsw" pl="lg">
          <OnboardingNSW />
        </Tabs.Panel>
        <Tabs.Panel value="vic" pl="lg">
          <OnboardingVic />
        </Tabs.Panel>
        <Tabs.Panel value="qld" pl="lg">
          <OnboardingQld />
        </Tabs.Panel>
        <Tabs.Panel value="wa" pl="lg">
          <OnboardingWA />
        </Tabs.Panel>
        <Tabs.Panel value="sa" pl="lg">
          <OnboardingSA />
        </Tabs.Panel>
        <Tabs.Panel value="tas" pl="lg">
          <OnboardingTas />
        </Tabs.Panel>
        <Tabs.Panel value="act" pl="lg">
          <OnboardingACT />
        </Tabs.Panel>
        <Tabs.Panel value="nt" pl="lg">
          <OnboardingNT />
        </Tabs.Panel>
        <Tabs.Panel value="employment" pl="lg">
          <OnboardingEmployment primary state={user.basic.state} />
          {user.primaryEmployment.hasSecondary && <OnboardingEmployment state={user.basic.state} />}
        </Tabs.Panel>
        <Tabs.Panel value="discipline" pl="lg">
          <OnboardingDiscipline />
        </Tabs.Panel>
        <Tabs.Panel value="experience" pl="lg">
          <OnboardingExperience />
        </Tabs.Panel>
        <Tabs.Panel value="personal" pl="lg">
          <OnboardingPersonal />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user
});

const mapDispatchToProps = {};

export const AccountSettings = connect(mapStateToProps, mapDispatchToProps)(AccountSettingsComponent);
