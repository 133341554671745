import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import {
  UserACT,
  UserBasic,
  UserDiscipline,
  UserEmployment,
  UserExperience,
  UserNSW,
  UserNT,
  UserPersonal,
  UserQld,
  UserSA,
  UserTas,
  UserVic,
  UserWA
} from 'types/state';

class UserService {
  async doesUserExist(email: string): Promise<boolean> {
    const { data } = await axiosAdapter.doGet<AppResponse<boolean>>({
      url: `users/does-user-exist?email=${encodeURIComponent(email)}`
    });
    return data.data;
  }

  async fetchUserInfo() {
    const { data } = await axiosAdapter.doGet<AppResponse<any>>({
      url: 'users/user-info'
    });
    return data.data;
  }

  async setBasic(dto: UserBasic): Promise<UserBasic> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserBasic>>({
      url: 'users/set-basic',
      payload: dto
    });
    return data.data;
  }

  async setNSW(dto: UserNSW): Promise<UserNSW> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserNSW>>({
      url: 'users/set-nsw',
      payload: dto
    });
    return data.data;
  }

  async setVic(dto: UserVic): Promise<UserVic> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserVic>>({
      url: 'users/set-vic',
      payload: dto
    });
    return data.data;
  }

  async setQld(dto: UserQld): Promise<UserQld> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserQld>>({
      url: 'users/set-qld',
      payload: dto
    });
    return data.data;
  }

  async setWA(dto: UserWA): Promise<UserWA> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserWA>>({
      url: 'users/set-wa',
      payload: dto
    });
    return data.data;
  }

  async setSA(dto: UserSA): Promise<UserSA> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserSA>>({
      url: 'users/set-sa',
      payload: dto
    });
    return data.data;
  }

  async setTas(dto: UserTas): Promise<UserTas> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserTas>>({
      url: 'users/set-tas',
      payload: dto
    });
    return data.data;
  }

  async setACT(dto: UserACT): Promise<UserACT> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserACT>>({
      url: 'users/set-act',
      payload: dto
    });
    return data.data;
  }

  async setNT(dto: UserNT): Promise<UserNT> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserNT>>({
      url: 'users/set-nt',
      payload: dto
    });
    return data.data;
  }

  async setEmployment(dto: UserEmployment): Promise<UserEmployment> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserEmployment>>({
      url: 'users/set-employment',
      payload: dto
    });
    return data.data;
  }

  async setDiscipline(dto: UserDiscipline): Promise<UserDiscipline> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserDiscipline>>({
      url: 'users/set-discipline',
      payload: dto
    });
    return data.data;
  }

  async setExperience(dto: UserExperience): Promise<UserExperience> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserExperience>>({
      url: 'users/set-experience',
      payload: dto
    });
    return data.data;
  }

  async setPersonal(dto: UserPersonal): Promise<UserPersonal> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserPersonal>>({
      url: 'users/set-personal',
      payload: dto
    });
    return data.data;
  }
}

export const userService = new UserService();
