import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Card, Title, Text, TextInput, Alert, Button, Group } from '@mantine/core';
import { useForm, isEmail } from '@mantine/form';
import { resetPasswordRequest as resetPasswordRequestRoutine } from 'store/user/routines';
import { RootState } from 'types/state';

type Props = {
  isLoading: boolean;
  error?: string;
  resetPasswordRequest: (email: string) => void;
};

const ResetPasswordRequestComponent: React.FC<Props> = ({ isLoading, error, resetPasswordRequest }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: isEmail('Please enter a valid email address')
    }
  });

  return (
    <Container size="xs">
      <Card withBorder my="xl">
        <form
          onSubmit={form.onSubmit(values => {
            resetPasswordRequest(values.email);
            setSubmitted(true);
          })}
        >
          <Title order={1}>Reset Password</Title>
          <Text my="md">To request a password reset please enter your email below.</Text>
          {error && (
            <Alert title="Something went wrong" color="red" my="md">
              {error}
            </Alert>
          )}
          {submitted && !isLoading && !error && (
            <Alert title="Password Reset Requested" color="green">
              You will receive an email to {form.values.email} with instructions on how to reset your password.
            </Alert>
          )}
          {(!submitted || isLoading) && (
            <>
              <TextInput my="md" label="Email address" {...form.getInputProps('email')} />
              <Group position="right" my="md">
                <Button type="submit" loading={isLoading}>
                  Submit
                </Button>
              </Group>
            </>
          )}
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error
});

const mapDispatchToProps = {
  resetPasswordRequest: resetPasswordRequestRoutine
};

export const ResetPasswordRequest = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRequestComponent);
