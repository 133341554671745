import React from 'react';
import { connect } from 'react-redux';
import { createScorm as createScormRoutine } from 'store/scorm/routines';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { IconFileZip, IconUpload, IconX } from '@tabler/icons-react';
import { Title, Group, Space, Text, Alert } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { RootState } from 'types/state';

type Props = {
  isLoading: boolean;
  error?: string;
  createScorm: (arg0: { file: File; navigate: NavigateFunction }) => void;
  triggerError: (err: string) => void;
};

const ScormNewComponent: React.FC<Props> = ({ isLoading, error, createScorm, triggerError }) => {
  const navigate = useNavigate();

  return (
    <>
      <Title order={2}>Upload New Scorm Module</Title>
      {error && (
        <Alert title="Error Uploading Module" color="red">
          {error}
        </Alert>
      )}
      <Space h="lg" />
      <Dropzone onDrop={files => createScorm({ file: files[0], navigate })} onReject={files => triggerError(files[0].errors[0].message)} maxSize={1024 ** 3} multiple={false} loading={isLoading}>
        <Group position="center">
          <Dropzone.Accept>
            <IconUpload size="3rem" />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size="3rem" />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileZip size="3rem" />
          </Dropzone.Idle>
          <div>
            <Text fw="bold">Drag Scorm ZIP to Upload</Text>
            <Text size="sm">Maximum file size of 300 mb</Text>
          </div>
        </Group>
      </Dropzone>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.scorm.isLoading,
  error: state.scorm.error
});

const mapDispatchToProps = {
  createScorm: createScormRoutine,
  triggerError: createScormRoutine.failure
};

export const ScormNew = connect(mapStateToProps, mapDispatchToProps)(ScormNewComponent);
