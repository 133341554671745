import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ScormDto } from 'types/api/scorm';
import { Container, Title, Table, Skeleton } from '@mantine/core';
import { RootState, UserIndex } from 'types/state';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { UserRoles } from 'enums';

type Props = {
  scorm: ScormDto;
  users: UserIndex;
  fetchUsersByRole: (role: UserRoles) => void;
};

const ScormEnrolmentsComponent: React.FC<Props> = ({
  scorm,
  users,
  fetchUsersByRole
}) => {
  useEffect(() => {
    fetchUsersByRole(UserRoles.LEARNER);
  }, []);

  return (
    <Container size="md">
      <Title order={2}>Enrolments</Title>
      <Table>
        <thead>
          <tr>
            <th>User</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {scorm?.enrolments?.map(enrolment => (
            <tr key={enrolment.id}>
              <td>
                {users[enrolment.userId] ? (
                  users[enrolment.userId].email
                ) : (
                  <Skeleton h="1rem" />
                )}
              </td>
              <td>{enrolment.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.LEARNER
});

const mapDispatchToProps = {
  fetchUsersByRole: fetchUsersByRoleRoutine
};

export const ScormEnrolments = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScormEnrolmentsComponent);
