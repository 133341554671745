import { css } from 'styled-components';

export const maxWidth = '1200px';

export const devices = {
  desktopLg: 1280,
  desktopMd: 1200,
  desktop: 1024,
  tabletLg: 992,
  tablet: 768,
  phoneLg: 568,
  phoneMd: 480,
  phoneSm: 360,
  phoneXs: 320
};

interface IMedia {
  desktopLg: Function;
  desktopMd: Function;
  desktop: Function;
  tabletLg: Function;
  tablet: Function;
  phoneLg: Function;
  phoneMd: Function;
  phoneSm: Function;
  phoneXs: Function;
  untilDesktopLg: Function;
  untilDesktopMd: Function;
  untilDesktop: Function;
  untilTabletLg: Function;
  untilTablet: Function;
  untilPhoneLg: Function;
  untilPhoneMd: Function;
  untilPhoneSm: Function;
  untilPhoneXs: Function;
  fromDesktopLg: Function;
  fromDesktopMd: Function;
  fromDesktop: Function;
  fromTabletLg: Function;
  fromTablet: Function;
  fromPhoneLg: Function;
  fromPhoneMd: Function;
  fromPhoneSm: Function;
  fromPhoneXs: Function;
}

export const media = Object.keys(devices).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...args: string[]) => css`
    @media (max-width: ${devices[label] - 1}px) {
      ${css(literals, ...args)}
    }
  `;

  const fromLabel = `from${label.substring(0, 1).toUpperCase()}${label.substring(1)}`;

  acc[fromLabel] = (literals: TemplateStringsArray, ...args: string[]) => css`
    @media (min-width: ${devices[label] / 16}em) {
      ${css(literals, ...args)}
    }
  `;

  const untilLabel = `until${label.substring(0, 1).toUpperCase()}${label.substring(1)}`;
  acc[untilLabel] = (literals: TemplateStringsArray, ...args: string[]) => css`
    @media (max-width: ${devices[label] - 1}px) {
      ${css(literals, ...args)}
    }
  `;

  return acc;
}, {} as IMedia);

// for srcset image selection
// returns a list ['(max-width: ..) ..', ...]
export const srcSetSizes = Object.keys(devices).reduce((acc, label) => {
  // not sure why we're converting to em here, but cargo-culting the above!
  const sz = devices[label];
  acc.push(`(max-width: ${sz}px) ${sz}px`);
  return acc;
}, []);

// return the pixel widths, as they're needed to calculate apparent pixel width
export const pixSizes = Object.keys(devices).reduce((acc, label) => {
  acc.push(devices[label]);
  return acc;
}, []);
