import React from 'react';
import { TextInput, Button, Group, Text } from '@mantine/core';
import { useForm, isEmail } from '@mantine/form';

type Props = {
  eventId: string;
  onSubmit: (arg0: { email: string; eventId: string }) => void;
  coordinatorEmail: string;
};

export const AddParticipantForm: React.FC<Props> = ({ eventId, onSubmit, coordinatorEmail }) => {
  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: isEmail('Please enter a valid email address.')
    },
    transformValues: values => ({
      eventId,
      email: values.email
    })
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Text>Override and add participant as {coordinatorEmail}. Please only use this feature in exceptional circumstances.</Text>
      <Group align="start">
        <TextInput sx={{ flex: '1 !important' }} placeholder="Participant email" {...form.getInputProps('email')} />
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
};
