import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import {
  CreateF2FTypeDto,
  F2FTypeDto,
  UpdateF2FTypeDto
} from 'types/api/face-to-face';

class TrainingService {
  async createF2FType(payload: CreateF2FTypeDto) {
    const { data } = await axiosAdapter.doPost<AppResponse<F2FTypeDto>>({
      url: '/f2f',
      payload
    });
    return data.data;
  }

  async updateF2FTraining(payload: UpdateF2FTypeDto) {
    const { data } = await axiosAdapter.doPut<AppResponse<F2FTypeDto>>({
      url: `/f2f/${payload.id}`,
      payload
    });
    return data.data;
  }

  async fetchF2FTypes() {
    const { data } = await axiosAdapter.doGet<AppResponse<F2FTypeDto[]>>({
      url: '/f2f'
    });
    return data.data;
  }

  async fetchF2FType(id: string) {
    const { data } = await axiosAdapter.doGet<AppResponse<F2FTypeDto>>({
      url: `/f2f/${id}`
    });
    return data.data;
  }

  async uploadSplash(id: string, file: File): Promise<F2FTypeDto> {
    const payload = new FormData();
    payload.append('file', file, file.name);
    const { data } = await axiosAdapter.doPut<AppResponse<F2FTypeDto>>({
      url: `/f2f/${id}/splash`,
      payload
    });
    return data.data;
  }
}

export const trainingService = new TrainingService();
