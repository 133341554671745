import React from 'react';
import { connect } from 'react-redux';

import { createF2FType as createF2FTypeRoutine } from 'store/face-to-face/routines';
import { CreateF2FTypeDto } from 'types/api/face-to-face';

import { Alert, Title, TextInput, Textarea, Stack, Button, Group, LoadingOverlay } from '@mantine/core';

import { useForm } from '@mantine/form';
import { RootState } from 'types/state';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type Props = {
  error?: string;
  isLoading: boolean;
  createF2FType: (arg0: { values: CreateF2FTypeDto; navigate: NavigateFunction }) => void;
};

const F2FTypeNewComponent: React.FC<Props> = ({ error, isLoading, createF2FType }) => {
  const form = useForm({
    initialValues: {
      name: '',
      description: ''
    },
    validate: {
      name: value => (value ? null : 'This field is required.'),
      description: value => (value ? null : 'This field is required.')
    }
  });

  const navigate = useNavigate();

  return (
    <form onSubmit={form.onSubmit(values => createF2FType({ values, navigate }))}>
      <Stack>
        <Title order={3}>New Face to Face Type</Title>
        {error && (
          <Alert title="Error creating face to face type" color="red">
            {error}
          </Alert>
        )}
        <TextInput label="Name" placeholder="Training name" {...form.getInputProps('name')} />
        <Textarea label="Description" placeholder="Description" {...form.getInputProps('description')} />
        <Group position="right">
          <Button type="submit">Create</Button>
        </Group>
      </Stack>
      <LoadingOverlay visible={isLoading} />
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: state.faceToFace.error,
  isLoading: state.faceToFace.isLoading
});

const mapDispatchToProps = {
  createF2FType: createF2FTypeRoutine
};

export const F2FTypeNew = connect(mapStateToProps, mapDispatchToProps)(F2FTypeNewComponent);
