import { all, call, put, takeEvery } from 'redux-saga/effects';
import { dataExportService } from 'service/data-export';
import { getCourseExport, getEventParticipantsExport, getEventPreferencesExport, getF2FExport, getScormExport, getUserExport } from './routines';
import { Action } from 'redux-actions';

function* getCourseExportHandler() {
  try {
    const blob = yield call(dataExportService.getCourseExport);
    yield put(getCourseExport.success());
    window.open(URL.createObjectURL(blob));
  } catch (e: any) {
    if (e?.message) yield put(getCourseExport.failure(e.message));
    else yield put(getCourseExport.failure('Unknown error'));
  }
}

function* getF2FExportHandler() {
  try {
    const blob = yield call(dataExportService.getF2FExport);
    yield put(getF2FExport.success());
    window.open(URL.createObjectURL(blob));
  } catch (e: any) {
    if (e?.message) yield put(getF2FExport.failure(e.message));
    else yield put(getF2FExport.failure('Unknown error'));
  }
}

function* getScormExportHandler() {
  try {
    const blob = yield call(dataExportService.getScormExport);
    yield put(getF2FExport.success());
    window.open(URL.createObjectURL(blob));
  } catch (e: any) {
    if (e?.message) yield put(getF2FExport.failure(e.message));
    else yield put(getF2FExport.failure('Unknown error'));
  }
}

function* getUserExportHandler() {
  try {
    const blob = yield call(dataExportService.getUserExport);
    yield put(getUserExport.success());
    window.open(URL.createObjectURL(blob));
  } catch (e: any) {
    if (e?.message) yield put(getUserExport.failure(e.message));
    else yield put(getUserExport.failure('Unknown error'));
  }
}

function* getEventParticipantsExportHandler({ payload: id }: Action<string>) {
  try {
    const blob = yield call(dataExportService.getEventParticipantsExport, id);
    yield put(getEventParticipantsExport.success());

    const dataStr = URL.createObjectURL(blob);
    const anchorEl = document.createElement('a');
    anchorEl.href = dataStr;
    anchorEl.download = 'participants.csv';
    anchorEl.click();
    anchorEl.remove();

    URL.revokeObjectURL(dataStr);
  } catch (e: any) {
    if (e?.message) yield put(getEventParticipantsExport.failure(e.message));
    else yield put(getEventParticipantsExport.failure('Unknown error'));
  }
}

function* getEventPreferencesExportHandler({ payload: id }: Action<string>) {
  try {
    const blob = yield call(dataExportService.getEventPreferencesExport, id);
    yield put(getEventPreferencesExport.success());

    const dataStr = URL.createObjectURL(blob);
    const anchorEl = document.createElement('a');
    anchorEl.href = dataStr;
    anchorEl.download = 'preferences.csv';
    anchorEl.click();
    anchorEl.remove();

    URL.revokeObjectURL(dataStr);
  } catch (e: any) {
    if (e?.message) yield put(getEventPreferencesExport.failure(e.message));
    else yield put(getEventPreferencesExport.failure('Unknown error'));
  }
}

export function* dataExportSaga() {
  yield all([
    takeEvery(getCourseExport.TRIGGER, getCourseExportHandler),
    takeEvery(getF2FExport.TRIGGER, getF2FExportHandler),
    takeEvery(getScormExport.TRIGGER, getScormExportHandler),
    takeEvery(getUserExport.TRIGGER, getUserExportHandler),
    takeEvery(getEventParticipantsExport.TRIGGER, getEventParticipantsExportHandler),
    takeEvery(getEventPreferencesExport.TRIGGER, getEventPreferencesExportHandler)
  ]);
}
