import React from 'react';
import { connect } from 'react-redux';
import {
  getCourseExport as getCourseExportRoutine,
  getF2FExport as getF2FExportRoutine,
  getScormExport as getScormExportRoutine,
  getUserExport as getUserExportRoutine
} from 'store/data-export/routines';
import { Alert, Title, Stack, Button, Center } from '@mantine/core';
import { RootState } from 'types/state';

type Props = {
  isLoading: boolean;
  error?: string;
  getCourseExport: () => void;
  getF2FExport: () => void;
  getScormExport: () => void;
  getUserExport: () => void;
};

const DataExportComponent: React.FC<Props> = ({
  isLoading,
  error,
  getCourseExport,
  getF2FExport,
  getScormExport,
  getUserExport
}) => {
  return (
    <Center maw={960} mx="auto">
      {error && (
        <Alert my="lg" color="red" title="Something went wrong">
          {error}
        </Alert>
      )}
      <Stack w="200px">
        <Button loading={isLoading} onClick={() => getCourseExport()}>
          eLearning Report
        </Button>
        <Button loading={isLoading} onClick={() => getF2FExport()}>
          Face to Face Report
        </Button>
        <Button loading={isLoading} onClick={() => getScormExport()}>
          Module Report
        </Button>
        <Button loading={isLoading} onClick={() => getUserExport()}>
          User Report
        </Button>
      </Stack>
    </Center>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.dataExport.isLoading,
  error: state.dataExport.error
});

const mapDispatchToProps = {
  getCourseExport: getCourseExportRoutine,
  getF2FExport: getF2FExportRoutine,
  getScormExport: getScormExportRoutine,
  getUserExport: getUserExportRoutine
};

export const DataExport = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataExportComponent);
