import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { ReorderQuestionDto, SurveyDto, SurveyQuestionDto } from 'types/api/survey';
import { createQuestion as createQuestionRoutine, reorderQuestion as reorderQuestionRoutine, updateQuestion as updateQuestionRoutine, removeQuestion as removeQuestionRoutine } from 'store/survey/routines';
import { Text, Drawer, Group, Button, Space } from '@mantine/core';
import { QuestionCard } from './QuestionCard';
import { QuestionEditor } from './QuestionEditor';

type Props = {
  survey: SurveyDto;
  createQuestion?: (arg0: { surveyId: string; dto: SurveyQuestionDto }) => void;
  updateQuestion?: (arg0: { surveyId: string; dto: SurveyQuestionDto }) => void;
  reorderQuestion?: (arg0: { surveyId: string; dto: ReorderQuestionDto }) => void;
  removeQuestion?: (arg0: { surveyId: string; questionId: string }) => void;
};

const QuestionsComponent: React.FC<Props> = ({ survey, createQuestion, updateQuestion, reorderQuestion, removeQuestion }) => {
  const [editQuestion, setEditQuestion] = useState<SurveyQuestionDto>(null);

  const onDragEnd = ({ reason, source, destination }) => {
    if (reason === 'CANCEL') return;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
    const moving = survey.questions.find(q => q.sortIndex === source.index);
    reorderQuestion({
      surveyId: survey.id,
      dto: {
        questionId: moving.id,
        targetIndex: destination.index
      }
    });
  };

  const create = (dto: SurveyQuestionDto) => {
    createQuestion({ surveyId: survey.id, dto });
    setEditQuestion(null);
  };

  const update = (dto: SurveyQuestionDto) => {
    updateQuestion({ surveyId: survey.id, dto });
    setEditQuestion(null);
  };

  const remove = (questionId: string) => {
    setEditQuestion(null);
    removeQuestion({ surveyId: survey.id, questionId });
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="0">
          {(provided: DroppableProvided) => (
            <div {...provided.dropppableProps} ref={provided.innerRef}>
              {survey.questions.length === 0 && <Text>No questions.</Text>}
              {survey.questions.map(question => (
                <QuestionCard question={question} key={question.id} onClick={() => setEditQuestion(question)} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Space h="lg" />

      <Group position="right">
        <Button
          onClick={() =>
            setEditQuestion({
              type: 'TEXT',
              text: '',
              options: [],
              isOther: false
            })
          }
        >
          Add Question
        </Button>
      </Group>

      <Drawer title={`${editQuestion?.id ? 'Edit' : 'Add'} Question`} size="lg" position="right" opened={Boolean(editQuestion)} onClose={() => setEditQuestion(null)}>
        <QuestionEditor question={editQuestion} create={create} update={update} remove={remove} />
      </Drawer>
    </div>
  );
};

const mapDispatchToProps = {
  createQuestion: createQuestionRoutine,
  updateQuestion: updateQuestionRoutine,
  reorderQuestion: reorderQuestionRoutine,
  removeQuestion: removeQuestionRoutine
};

export const Questions = connect(null, mapDispatchToProps)(QuestionsComponent);
