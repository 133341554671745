import React from 'react';
import { NumberInput, TextInput, Select, Group, Button } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { RichTextEditor } from 'components/RichTextEditor';
import { CourseEmailCondition } from 'enums/course/course.email.condition.enum';
import { CreateCourseEmailDto } from 'types/api/course/create.course.email.dto';
import { UpdateCourseEmailDto } from 'types/api/course/update.course.email.dto';

type Props = {
  email: CreateCourseEmailDto | UpdateCourseEmailDto;
  courseId: string;
  createCourseEmail: (dto: CreateCourseEmailDto) => void;
  updateCourseEmail: (arg0: { id: string; dto: UpdateCourseEmailDto; courseId: string }) => void;
  deleteCourseEmail: (arg0: { courseId: string; emailId: string }) => void;
};

export const CourseEmailEditor: React.FC<Props> = ({ email, courseId, createCourseEmail, updateCourseEmail, deleteCourseEmail }) => {
  const form = useForm<CreateCourseEmailDto | UpdateCourseEmailDto>({
    initialValues: email,
    validate: {
      subject: isNotEmpty('This field is required'),
      body: isNotEmpty('This field is required'),
      delay: isNotEmpty('This field is required'),
      condition: isNotEmpty('This field is required')
    }
  });

  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (values.action === 'create') createCourseEmail(values);
        else updateCourseEmail({ id: values.id, dto: values, courseId });
      })}
    >
      <TextInput mb="md" label="Email subject" {...form.getInputProps('subject')} />

      <RichTextEditor
        my="md"
        label="Email body"
        field="body"
        form={form}
        tokens={[
          { name: 'User Name', value: '{{ user.firstName }}' },
          { name: 'Expiry Date', value: '{{ enrolment.expiresAt }}' }
        ]}
      />

      <NumberInput my="md" label="Days before expiration to send" min={0} max={365} {...form.getInputProps('delay')} hideControls />

      <Select my="md" label="Send this email when" data={Object.entries(CourseEmailCondition).map(([value, label]) => ({ label, value }))} {...form.getInputProps('condition')} />
      <Group position="right" my="md">
        {email?.action === 'update' && (
          <Button color="red" onClick={() => deleteCourseEmail({ emailId: email.id, courseId })}>
            Delete Email
          </Button>
        )}
        <Button type="submit">{email?.action === 'create' ? 'Create Email' : 'Save Changes'}</Button>
      </Group>
    </form>
  );
};
