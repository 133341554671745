import React, { useMemo } from 'react';
import { CourseDto, CourseTaskDto, CourseTaskPageDto } from 'types/api/course';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import {
  Button,
  Card,
  Text,
  Group,
  ActionIcon,
  TextInput,
  Space
} from '@mantine/core';
import { IconPencil, IconX } from '@tabler/icons-react';
import { TaskCard } from './TaskCard';
import { SurveyIndex } from 'types/state';
import { UseFormReturnType } from '@mantine/form';
import { useScorms } from 'features/scorms/api/get-scorms';

type Props = {
  page: CourseTaskPageDto;
  setEditTask: (task: CourseTaskDto) => void;
  surveys: SurveyIndex;
  form: UseFormReturnType<Partial<CourseDto>>;
  editingTitle: string;
  setEditingTitle: (id: string) => void;
};

export const CourseTaskPage: React.FC<Props> = ({
  page,
  setEditTask,
  surveys,
  form,
  editingTitle,
  setEditingTitle
}) => {
  const scormsQuery = useScorms();
  const scorms = scormsQuery.data?.data;

  const scormsIndex = useMemo(() => {
    if (!scorms) return {};

    return scorms.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  }, [scorms]);

  if (scormsQuery.isLoading || scormsQuery.isError) {
    return <div />;
  }

  const tasks = page.tasks.map(task => {
    return {
      id: task.id,
      task,
      scorm: task.type === 'SCORM' ? scormsIndex[task.scormId] : undefined,
      survey: task.type === 'SURVEY' ? surveys[task.surveyId] : undefined
    };
  });

  return (
    <Droppable droppableId={page.id}>
      {(provided: DroppableProvided) => (
        <Card
          withBorder
          shadow="sm"
          my="lg"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Group mb="lg">
            {editingTitle === page.id && (
              <Group>
                <ActionIcon onClick={() => setEditingTitle(null)}>
                  <IconX />
                </ActionIcon>
                <TextInput {...form.getInputProps(`pageTitles.${page.id}`)} />
                <Button type="submit">Save</Button>
              </Group>
            )}
            {editingTitle !== page.id && (
              <Group>
                <ActionIcon onClick={() => setEditingTitle(page.id)}>
                  <IconPencil />
                </ActionIcon>
                <Text>{page.name}</Text>
              </Group>
            )}

            <Space sx={{ flex: 1 }} />

            <Button
              onClick={() =>
                setEditTask({
                  pageIndex: page.id,
                  title: '',
                  type: 'SCORM'
                })
              }
            >
              Add Module
            </Button>

            <Button
              onClick={() =>
                setEditTask({
                  pageIndex: page.id,
                  title: '',
                  type: 'SURVEY'
                })
              }
            >
              Add Survey
            </Button>
          </Group>

          {tasks.map(task => (
            <TaskCard
              key={task.id}
              onClick={() => setEditTask(task.task)}
              task={task.task}
              scorm={task.scorm}
              survey={task.survey}
            />
          ))}

          {provided.placeholder}
        </Card>
      )}
    </Droppable>
  );
};
