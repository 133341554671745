import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `events/${opName}`;

export const createEvent = createRoutine(getPrefix('create'));

export const updateEvent = createRoutine(getPrefix('update'));

export const fetchEvents = createRoutine(getPrefix('fetch-all'));

export const fetchEvent = createRoutine(getPrefix('fetch-one'));

export const fetchEventForUser = createRoutine(getPrefix('fet-one-for-user'));

export const createInstance = createRoutine(getPrefix('create-instance'));

export const updateInstance = createRoutine(getPrefix('update-instance'));

export const removeInstance = createRoutine(getPrefix('remove-instance'));

export const createEnrolment = createRoutine(getPrefix('create-enrolment'));

export const reorderEnrolment = createRoutine(getPrefix('reorder-enrolment'));

export const signoffEvent = createRoutine(getPrefix('signoff'));
