import React from 'react';
import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import { AppShell } from '@mantine/core';

export const AdminLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AppShell header={<Header />} navbar={<Sidebar />}>
      {children}
    </AppShell>
  );
};
