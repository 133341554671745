export enum EmploymentType {
  COMMUNITY_HEALTH_CENTRE = 'Community Health Centre',
  COMMUNITY_MENTAL_HEALTH_CENTRE = 'Community Mental Health Centre',
  DRUG_AND_ALCOHOL_SERVICE = 'Drug and Alcohol Service',
  EATING_DISORDER_TREATMENT_SERVICE = 'Eating Disorder Treatment Service',
  EDUCATION_TEACHING_ENVIRONMENT = 'Education / Teaching Environment',
  EMERGENCY_DEPARTMENT = 'Emergency Department',
  HEADSPACE = 'Headspace',
  HEAD_TO_HEALTH = 'Head to Health',
  GENERAL_PRACTICE = 'General Practice',
  OTHER_PRIMARY_CARE = 'Other Primary Care',
  HOSPITAL_SETTING = 'Hospital Setting',
  PRIVATE_PRACTICE = 'Private Practice',
  PRIMARY_HEALTH_NETWORK = 'Primary Health Network (PHN)',
  UNIVERSITY = 'University',
  NGO = 'NGO',
  OTHER_GOVERNMENT_AGENCIES = 'Other Government Agencies apart from Health or Education',
  NOT_CURRENTLY_EMPLOYED = 'Not currently employed',
  OTHER = 'Other'
}
