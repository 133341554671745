import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState, ScormIndex, UserIndex } from 'types/state';
import {
  fetchScorm as fetchScormRoutine,
  reuploadScorm as reuploadScormRoutine,
  uploadSplash as uploadSplashRoutine,
  updateScormMetadata as updateScormMetadataRoutine
} from 'store/scorm/routines';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { connect } from 'react-redux';
import { Title, Button, Image, Group, Alert, Tabs } from '@mantine/core';
import { scormAPIService } from 'service/scorm-api';
import { ReuploadScormZip } from './ReuploadScormZip';
import { ScormEditHistory } from './ScormEditHistory';
import { ScormUsedIn } from './ScormUsedIn';
import { ScormEditMetadata } from './ScormEditMetadata';
import { UpdateScormMetadataDto } from 'types/api/scorm/update.scorm.metadata.dto';
import { UserRoles } from 'enums';
import { ScormEnrolments } from './ScormEnrolments';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  scorms: ScormIndex;
  isLoading: boolean;
  error?: string;
  fetchScorm: (id: string) => void;
  reuploadScorm: (arg0: { file: File; id: string }) => void;
  triggerError: (err: string) => void;
  uploadSplash: (arg0: { scormId: string; file: File }) => void;
  updateMetadata: (arg0: UpdateScormMetadataDto) => void;
  users: UserIndex;
  fetchUsersByRole: (role: UserRoles) => void;
};

export const ScormViewComponent: React.FC<Props> = ({
  scorms,
  isLoading,
  error,
  fetchScorm,
  reuploadScorm,
  triggerError,
  uploadSplash,
  updateMetadata,
  users,
  fetchUsersByRole
}) => {
  const { id } = useParams();
  const client = useQueryClient();

  useEffect(() => {
    scormAPIService.loadDummyAPI();
    fetchUsersByRole(UserRoles.IOI_ADMIN);
  }, []);

  useEffect(() => {
    fetchScorm(id);
  }, [id]);

  const scorm = scorms[id];

  if (!scorm) return null;

  const handleUpdateMetadata = (arg0: UpdateScormMetadataDto) => {
    updateMetadata(arg0);
    client.invalidateQueries({ queryKey: ['scorms'] });
  };

  const handleUploadSplash = (arg0: { scormId: string; file: File }) => {
    uploadSplash(arg0);
    client.invalidateQueries({ queryKey: ['scorms'] });
  };

  const handleReuploadScorm = (arg0: { file: File; id: string }) => {
    reuploadScorm(arg0);
    client.invalidateQueries({ queryKey: ['scorms'] });
  };

  return (
    <>
      <Group my="md">
        <Image src={scorm.splash} width="250px" height="150px" />
        <div>
          <Title order={3}>{scorm?.title}</Title>
          <Button
            my="md"
            onClick={() => scormAPIService.openPopup(id)}
            variant="light"
          >
            Preview Module
          </Button>
        </div>
      </Group>

      {error && (
        <Alert color="red" title="Something went wrong">
          {error}
        </Alert>
      )}

      <Tabs defaultValue="metadata">
        <Tabs.List>
          <Tabs.Tab value="metadata">Metadata</Tabs.Tab>
          <Tabs.Tab value="reupload">Reupload</Tabs.Tab>
          <Tabs.Tab value="used-in">Used In</Tabs.Tab>
          <Tabs.Tab value="enrolments">Enrolments</Tabs.Tab>
          <Tabs.Tab value="history">Edit History</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="metadata" my="xl">
          <ScormEditMetadata
            isLoading={isLoading}
            scorm={scorm}
            uploadSplash={handleUploadSplash}
            updateMetadata={handleUpdateMetadata}
          />
        </Tabs.Panel>

        <Tabs.Panel value="reupload" my="xl">
          <ReuploadScormZip
            id={id}
            reuploadScorm={handleReuploadScorm}
            triggerError={triggerError}
            isLoading={isLoading}
          />
        </Tabs.Panel>

        <Tabs.Panel value="used-in" my="xl">
          <ScormUsedIn scorm={scorm} />
        </Tabs.Panel>

        <Tabs.Panel value="enrolments" my="xl">
          <ScormEnrolments scorm={scorm} />
        </Tabs.Panel>

        <Tabs.Panel value="history" my="xl">
          <ScormEditHistory users={users} scorm={scorm} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  scorms: state.scorm.scorms,
  users: state.users.IOI_ADMIN,
  isLoading: state.scorm.isLoading,
  error: state.scorm.error
});

const mapDispatchToProps = {
  fetchScorm: fetchScormRoutine,
  reuploadScorm: reuploadScormRoutine,
  uploadSplash: uploadSplashRoutine,
  triggerError: reuploadScormRoutine.failure,
  updateMetadata: updateScormMetadataRoutine,
  fetchUsersByRole: fetchUsersByRoleRoutine
};

export const ScormView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScormViewComponent);
