import { Action } from 'redux-actions';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import { uploadService } from 'service/upload';
import { scormService } from 'service/scorm';
import { NavigateFunction } from 'react-router-dom';
import { ScormDto } from 'types/api/scorm';
import { createScorm, reuploadScorm, fetchScorm, fetchScorms, setScormData, openScorm, uploadSplash, updateScormMetadata } from './routines';
import { AdminRoutes } from 'enums';
import { AxiosError } from 'axios';
import { scormAPIService } from 'service/scorm-api';
import { UpdateScormMetadataDto } from 'types/api/scorm/update.scorm.metadata.dto';

// More dodgy passing NavigateFunction with the action
// if there's a better way I'd like to hear about it
function* createScormHandler({
  payload: { file, navigate }
}: Action<{
  file: File;
  navigate: NavigateFunction;
}>) {
  try {
    const newUpload = yield call(uploadService.uploadScormPackage, file);
    yield put(createScorm.success(newUpload));
    navigate(`${AdminRoutes.scormView}/${newUpload.id}`);
  } catch (e) {
    if (e instanceof AxiosError) yield put(createScorm.failure(e.message));
    else yield put(createScorm.failure('Unknown error.'));
  }
}

function* reuploadScormHandler({
  payload: { file, id }
}: Action<{
  file: File;
  id: string;
}>) {
  try {
    const newUpload = yield call(uploadService.reuploadScorm, { file, id });
    yield put(reuploadScorm.success(newUpload));
  } catch (e) {
    if (e instanceof AxiosError) yield put(reuploadScorm.failure(e.message));
    else yield put(reuploadScorm.failure('Unknown error'));
  }
}

function* fetchScormsHandler() {
  try {
    const data = yield call(scormService.getAll);
    yield put(fetchScorms.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchScorms.failure(e.message));
    else yield put(fetchScorms.failure('Unknown error'));
  }
}

function* fetchScormHandler({ payload: id }: Action<string>) {
  try {
    const data = yield call(scormService.getOne, id);
    yield put(fetchScorm.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchScorm.failure(e.message));
    else yield put(fetchScorm.failure('Unknown error'));
  }
}

// XXX passing the action like this is strange but is there another way?
function* openScormHandler({
  payload
}: Action<{
  scorm: ScormDto;
  setScormData: (arg0: { key: string; value: string; id: string }) => void;
}>) {
  const data = yield call(scormService.getOne, payload.scorm.id);
  yield put(openScorm.success(data));

  scormAPIService.loadAPI(data, payload.setScormData);
  scormAPIService.openPopup(data.id);
}

function* setScormDataHandler({
  payload: { id, key, value }
}: Action<{
  id: string;
  key: string;
  value: string;
}>) {
  try {
    yield call(scormService.setData, id, key, value);
    yield put(setScormData.success());
  } catch (e) {
    if (e instanceof AxiosError) yield put(setScormData.failure(e.message));
    else yield put(setScormData.failure('Unknown error.'));
  }
}

function* uploadSplashHandler({
  payload: { scormId, file }
}: Action<{
  scormId: string;
  file: File;
}>) {
  try {
    const data = yield call(scormService.uploadSplash, scormId, file);
    yield put(uploadSplash.success({ scormId, splash: data.splash }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(uploadSplash.failure(e.message));
    else yield put(uploadSplash.failure('Unknown error.'));
  }
}

function* updateScormMetadataHandler({ payload: dto }: Action<UpdateScormMetadataDto>) {
  try {
    const data = yield call(scormService.updateMetadata, dto);
    yield put(updateScormMetadata.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateScormMetadata.failure(e.message));
    else yield put(updateScormMetadata.failure('Unknown error.'));
  }
}

export function* scormSaga() {
  yield all([
    takeEvery(createScorm.TRIGGER, createScormHandler),
    takeEvery(reuploadScorm.TRIGGER, reuploadScormHandler),
    takeEvery(fetchScorms.TRIGGER, fetchScormsHandler),
    takeEvery(fetchScorm.TRIGGER, fetchScormHandler),
    takeEvery(openScorm.TRIGGER, openScormHandler),
    takeEvery(setScormData.TRIGGER, setScormDataHandler),
    takeEvery(uploadSplash.TRIGGER, uploadSplashHandler),
    takeEvery(updateScormMetadata.TRIGGER, updateScormMetadataHandler)
  ]);
}
