import React from 'react';
import { ScormDto } from 'types/api/scorm';
import { Container, Title, Table } from '@mantine/core';
import dayjs from 'dayjs';
import { UserIndex } from 'types/state';

type Props = {
  scorm: ScormDto;
  users: UserIndex;
};

export const ScormEditHistory: React.FC<Props> = ({ scorm, users }) => {
  return (
    <Container size="md">
      <Title order={3}>Edit History</Title>
      <Table>
        <thead>
          <tr>
            <th>When</th>
            <th>Who</th>
            <th>What</th>
          </tr>
        </thead>
        <tbody>
          {scorm.editHistory.map(each => (
            <tr key={dayjs(each.when).unix()}>
              <td>{dayjs(each.when).toISOString()}</td>
              <td>{users[each.userId] ? users[each.userId].email : 'Unknown User'}</td>
              <td>{each.msg}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
