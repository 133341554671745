import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Header, Text, Button, BackgroundImage, Divider, Container, Group } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { Routes } from 'enums';
import logo from 'assets/images/logo.svg';
import logoStaging from 'assets/images/logo-staging.svg';

type Props = {
  staging?: boolean;
};

export const GuestHeader: React.FC<Props> = ({ staging }) => {
  const [scroll] = useWindowScroll();
  const { pathname } = useLocation();

  return (
    <Header height={72} sx={{ border: '0' }} bg={scroll.y < 60 ? 'none' : 'white'}>
      <Container display="flex" size="xl" sx={{ height: '100%', alignItems: 'center' }}>
        <BackgroundImage src={staging ? logoStaging : logo} sx={{ height: '30px', width: '150px' }} component={Link} to="/" mb="6px" />

        <Divider orientation="vertical" mx="xl" my="xl" />

        <Text fw="bold" color="gray" size="lg">
          eLearning
        </Text>

        {pathname === Routes.Login ? (
          <Group position="right" sx={{ flex: '1 !important' }} noWrap>
            <Text>Don&apos;t have an account?</Text>
            <Button component={Link} to={Routes.Registration} color="cyan" uppercase>
              Sign Up
            </Button>
          </Group>
        ) : (
          <Group position="right" sx={{ flex: '1 !important' }} noWrap>
            <Text>Have an account?</Text>
            <Button component={Link} to={Routes.Login} color="cyan" uppercase>
              Log In
            </Button>
          </Group>
        )}
      </Container>
    </Header>
  );
};
