export enum ScormDataType {
  LESSON_MODE = 'cmi.core.lesson_mode',
  LESSON_STATUS = 'cmi.core.lesson_status',
  EXIT = 'cmi.core.exit',
  SUSPEND_DATA = 'cmi.suspend_data',
  LAUNCH_DATA = 'cmi.launch_data',
  LOCATION = 'cmi.core.lesson_location',
  SESSION_TIME = 'cmi.core.session_time',
  // these ones have a kinda broken implementation by rise
  // every single question is saved as interactions.0
  INTERACTIONS0_ID = 'cmi.interactions.0.id',
  INTERACTIONS0_TYPE = 'cmi.interactions.0.type',
  INTERACTIONS0_STUDENT_RESPONSE = 'cmi.interactions.0.student_response',
  INTERACTIONS0_PATTERN = 'cmi.interactions.0.correct_responses.0.pattern',
  INTERACTIONS0_RESULT = 'cmi.interactions.0.result',
  INTERACTIONS0_WEIGHTING = 'cmi.interactions.0.weighting',
  INTERACTIONS0_LATENCY = 'cmi.interactions.0.latency',
  INTERACTIONS0_OBJECTIVES = 'cmi.interactions.0.objectives.0.id',
  INTERACTIONS0_TIME = 'cmi.interactions.0.time'
}

export enum LessonModeType {
  BROWSE = 'browse',
  NORMAL = 'normal',
  REVIEW = 'review'
}

export enum LessonStatusType {
  PASSED = 'passed',
  COMPLETED = 'completed',
  FAILED = 'failed',
  INCOMPLETE = 'incomplete',
  BROWSED = 'browsed',
  NOT_ATTEMPTED = 'not attempted'
}

export enum ExitType {
  TIME_OUT = 'time-out',
  SUSPEND = 'suspend',
  LOGOUT = 'logout'
}
