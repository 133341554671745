import { Button, Group, Stack, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import { useDeleteCouponValidationRule } from 'features/coupon-validation-rule/api/delete-coupon-validation-rule';
import { CouponValidationRuleList } from 'features/coupon-validation-rule/components/coupon-validation-rule-list';
import { CouponValidationRuleDrawer } from 'features/coupon-validation-rule/components/create-coupon-validation-rule-drawer';
import { useCoupons } from 'features/coupons/api/get-coupons';
import React, { useCallback, useState } from 'react';

export const CouponPage = () => {
  const couponsQuery = useCoupons();

  const deleteCouponValidationRuleMutation = useDeleteCouponValidationRule({
    mutationConfig: {
      onSuccess: (_, variables) => {
        notifications.show({
          id: `delte-coupon-validation-rule-${variables.id}`,
          autoClose: 3000,
          color: 'teal',
          icon: <IconCheck />,
          title: 'Coupon validation rule deleted',
          message: `Validation rule successfully deleted`
        });
      }
    }
  });

  const [showCreateRuleDrawer, setShowCreateRuleDrawer] = useState(false);
  const [editRuleId, setEditRuleId] = useState<string>(null);

  const coupons =
    couponsQuery.data?.data?.map(coupon => ({
      label: coupon.name,
      value: coupon.id
    })) ?? [];

  const onDrawerClose = useCallback(() => {
    setShowCreateRuleDrawer(false);
    setEditRuleId(null);
  }, []);

  const onEditClick = useCallback((id: string) => {
    setShowCreateRuleDrawer(true);
    setEditRuleId(id);
  }, []);

  const onDeleteClick = useCallback((id: string) => {
    deleteCouponValidationRuleMutation.mutate({ id });
  }, []);

  return (
    <Stack maw={1280} mx="auto">
      <Title order={2}>Validation Rules</Title>
      <Group position="right">
        <Button
          color="dark"
          leftIcon={<IconPlus size="20" />}
          onClick={() => setShowCreateRuleDrawer(true)}
        >
          New Rule
        </Button>
      </Group>
      <CouponValidationRuleList
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
      <CouponValidationRuleDrawer
        coupons={coupons}
        open={showCreateRuleDrawer}
        editId={editRuleId}
        onClose={onDrawerClose}
      />
    </Stack>
  );
};
