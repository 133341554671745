export enum F2FEmailWhen {
  PRE_EVENT = 'Before Event',
  POST_EVENT = 'After Event'
}

export enum F2FEmailCondition {
  ALWAYS = 'Always',
  ANY_UNFINISHED = 'User has any unfinished tasks',
  ALL_FINISHED = 'User has completed all tasks',
  NO_PRESURVEY_NO_PREREQUISITE = 'No pre-event survey & no pre-requisite eLearning',
  YES_PRESURVEY_NO_PREREQUISITE = 'Yes pre-event survey & no pre-requisite eLearning',
  NO_PRESURVEY_YES_PREREQUISITE = 'No pre-event survey & yes pre-requisite eLearning',
  YES_PRESURVEY_YES_PREREQUISITE = 'Yes pre-event survey & yes pre-requisite eLearning',
  NO_POSTSURVEY_NO_PREREQUISITE = 'No post-event survey & no pre-requisite eLearning',
  YES_POSTSURVEY_NO_PREREQUISITE = 'Yes post-event survey & no pre-requisite eLearning',
  NO_POSTSURVEY_YES_PREREQUISITE = 'No post-event survey & yes pre-requisite eLearning',
  YES_POSTSURVEY_YES_PREREQUISITE = 'Yes post-event survey & yes pre-requisite eLearning'
}

export enum F2FTaskType {
  SURVEY = 'Survey',
  SCORM = 'Module',
  COURSE = 'Course',
  UPLOAD = 'File Upload'
}

export enum F2FTaskWhen {
  BEFORE = 'Pre-Requisite Learning',
  IMMEDIATELY_BEFORE = 'Pre-Event Surveys',
  AFTER = 'Post-Event Surveys'
}
