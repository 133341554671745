import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Group, TextInput, Select, Alert, Collapse } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { useForm, isNotEmpty } from '@mantine/form';
import { setBasic as setBasicRoutine } from 'store/user/routines';
import { googlemapsService } from 'service/googlemaps';
import { UserBasic, RootState } from 'types/state';
import { PredictionDto, AustraliaPredictionDto } from 'types/api/google-maps';
import { State } from 'enums/user-info/state.enum';
import { countryList } from 'enums/country-list';

type Props = {
  isLoading: boolean;
  error?: string;
  basic: UserBasic;
  setBasic: (dto: UserBasic) => void;
};

const OnboardingBasicComponent: React.FC<Props> = ({ isLoading, error, basic, setBasic }) => {
  const form = useForm<UserBasic>({
    initialValues: basic
      ? {
          firstName: basic.firstName,
          lastName: basic.lastName,
          address: basic.address,
          state: basic.state,
          city: basic.city,
          country: basic.country,
          postcode: basic.postcode
        }
      : {
          firstName: '',
          lastName: '',
          address: '',
          state: undefined,
          city: undefined,
          country: '',
          postcode: ''
        },
    validate: {
      firstName: isNotEmpty('This field is required.'),
      lastName: isNotEmpty('This field is required.'),
      address: isNotEmpty('This field is required.'),
      city: (value, values) => (!value && values.country === 'Australia' ? 'This field is required' : null),
      state: (value, values) => (!value && values.country === 'Australia' ? 'This field is required' : null),
      postcode: (value, values) => (!value && values.country === 'Australia' ? 'This field is required' : null),
      country: isNotEmpty('This field is required.')
    }
  });

  const [addressSearch, setAddressSearch] = useDebouncedState<string>('', 200);
  const [suggestions, setSuggestions] = useState<PredictionDto[]>([]);

  useEffect(() => {
    const search = async (q: string) => {
      const predictions = await googlemapsService.getPredictions(q);
      setSuggestions(predictions);
    };

    if (addressSearch && addressSearch.length > 3) {
      search(addressSearch);
    }
  }, [addressSearch]);

  const chooseAddress = (id: string) => {
    suggestions.forEach(each => {
      if (each.id === id) {
        if (each.country === 'Australia') {
          form.setValues({
            address: each.address,
            city: (each as AustraliaPredictionDto).city,
            state: (each as AustraliaPredictionDto).state as keyof typeof State,
            postcode: (each as AustraliaPredictionDto).postcode,
            country: 'Australia'
          });
        } else {
          form.setValues({
            address: each.address,
            country: each.country
          });
        }
      }
    });
  };

  return (
    <form onSubmit={form.onSubmit(values => setBasic(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      <TextInput my="lg" label="First name" placeholder="First name" {...form.getInputProps('firstName')} />

      <TextInput my="lg" label="Last name" placeholder="Last name" {...form.getInputProps('lastName')} />

      <Select
        my="lg"
        label="Work Address Quick Lookup"
        placeholder="Pick one"
        searchable
        onSearchChange={search => setAddressSearch(search)}
        data={suggestions.map(each => ({
          label: each.description,
          value: each.id
        }))}
        onChange={chooseAddress}
      />

      <TextInput my="lg" label="Work Address" {...form.getInputProps('address')} />

      <Collapse in={form.values.country === 'Australia'}>
        <TextInput my="lg" label="City" {...form.getInputProps('city')} />

        <Select
          my="lg"
          label="State"
          data={Object.entries(State).map(([value, label]) => ({
            label,
            value
          }))}
          {...form.getInputProps('state')}
        />

        <TextInput my="lg" label="Postcode" {...form.getInputProps('postcode')} />
      </Collapse>

      <Select my="lg" label="Country" searchable data={countryList} {...form.getInputProps('country')} />

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Basic Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  basic: state.user.basic
});

const mapDispatchToProps = {
  setBasic: setBasicRoutine
};

export const OnboardingBasic = connect(mapStateToProps, mapDispatchToProps)(OnboardingBasicComponent);
