import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { F2F } from 'types/backend';

export const getF2Fs = (): Promise<{ data: F2F[] }> => {
  return api.get('/f2f-new');
};

export const getF2FsQueryOptions = () => {
  return queryOptions({
    queryKey: ['f2fs'],
    queryFn: getF2Fs
  });
};

type UseF2FsOptions = {
  queryConfig?: QueryConfig<typeof getF2FsQueryOptions>;
};

export const useF2Fs = ({ queryConfig }: UseF2FsOptions = {}) => {
  return useQuery({
    ...getF2FsQueryOptions(),
    ...queryConfig
  });
};
