import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { MutationConfig } from 'lib/react-query';
import { z } from 'zod';

export const exportCourseReportSchema = z.object({
  courses: z.string().array().optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  completionPerModule: z.boolean().optional()
});

export type ExportCourseReportBody = z.infer<typeof exportCourseReportSchema>;

export const exportCourseReport = ({
  data
}: {
  data: ExportCourseReportBody;
}): Promise<Blob> => {
  return api.post('/report/course', data, {
    headers: { 'Accept': 'text/csv' },
    responseType: 'blob'
  });
};

type UseExportCourseReportOptions = {
  mutationConfig?: MutationConfig<typeof exportCourseReport>;
};

export const useExportCourseReport = ({
  mutationConfig
}: UseExportCourseReportOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: exportCourseReport
  });
};
