import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { env } from '../env';
import { StartupMode } from '../enums';
import { RootState } from '../types/state';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore<RootState, AnyAction, any>({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(sagaMiddleware),
  devTools: env.nodeEnv !== StartupMode.PRODUCTION
});

sagaMiddleware.run(rootSaga);
