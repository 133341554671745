import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchUsers, fetchUsersByRole, createUser } from './routines';
import { usersService } from 'service/users';
import { UserRoles } from 'enums';
import { AxiosError } from 'axios';
import { NSWHealthLHD } from 'enums/user-info';

function* fetchUsersHandler() {
  try {
    const data = yield call(usersService.getAll);
    yield put(fetchUsers.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchUsers.failure(e.message));
    else yield put(fetchUsers.failure('Unknown error occurred.'));
  }
}

function* fetchUsersByRoleHandler({ payload: role }: Action<UserRoles>) {
  try {
    const data = yield call(usersService.getByRole, role);
    yield put(fetchUsersByRole.success({ role, data }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchUsersByRole.failure(e.message));
    else yield put(fetchUsersByRole.failure('Unknown error occurred.'));
  }
}

function* createUserHandler({
  payload: { email, role, coordinatorFor }
}: Action<{
  email: string;
  role: keyof typeof UserRoles;
  coordinatorFor?: keyof typeof NSWHealthLHD;
}>) {
  try {
    const data = yield call(usersService.create, email, role, coordinatorFor);
    yield put(createUser.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createUser.failure(e.message));
    else yield put(createUser.failure('Unknown error occurred.'));
  }
}

export function* usersSaga() {
  yield all([takeEvery(fetchUsers.TRIGGER, fetchUsersHandler), takeEvery(fetchUsersByRole.TRIGGER, fetchUsersByRoleHandler), takeEvery(createUser.TRIGGER, createUserHandler)]);
}
