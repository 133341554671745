import { localStorageService } from 'service/local-storage-service';
import { AppError, AppResponse } from 'types/api';
import { Tokens, JwtPayloadDto } from 'types/api/auth';
import { axiosAdapter } from 'service/axios-adapter';
import { Routes } from 'enums';
import { PublicRoutes } from 'enums/routing/routes';
import { authInfoManagerHolder } from 'service/auth-info-manager-holder';
import { AxiosError, AxiosRequestHeaders } from 'axios';
import { UserState } from 'types/state';

class AuthService {
  constructor() {
    this.assertTokensAreValid = this.assertTokensAreValid.bind(this);
    this.login = this.login.bind(this);
    this.checkToken = this.checkToken.bind(this);
    this.activateAccount = this.activateAccount.bind(this);
    this.restartAccountActivation = this.restartAccountActivation.bind(this);
    this.parseJwtPayload = this.parseJwtPayload.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.logout = this.logout.bind(this);
    this.register = this.register.bind(this);
    this.registerAndCheckout = this.registerAndCheckout.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.resetPasswordRequest = this.resetPasswordRequest.bind(this);
  }

  async assertTokensAreValid(): Promise<UserState> {
    const authManager = authInfoManagerHolder.getAuthInfoManager();
    if (!this.isAuthenticated()) {
      authManager.logout();
      // XXX odd workaround for :id routes; need to _start_ with a public route
      // would like a better fix sometime in the future. This does not enforce any actual security though
      if (
        PublicRoutes.every(route => !window.location.pathname.startsWith(route))
      ) {
        window.location.href = `${window.location.origin}${Routes.Login}`;
      }
      return null;
    }
    try {
      const resp = await axiosAdapter.doGet<AppResponse<Tokens>>({
        url: '/auth/refresh',
        headers: {
          'X-Refresh-Token': authManager.getTokens().refreshToken as string
        } as unknown as AxiosRequestHeaders
      });
      const tokens: Tokens = {
        accessToken: resp.data.data.accessToken,
        refreshToken: resp.data.data.refreshToken
      };
      authManager.setTokens(tokens);
      return resp.data.data.user;
    } catch (e) {
      if (e instanceof AxiosError<AppResponse<Tokens>>) {
        const respError: AppError = e.response.data.error;
        throw new Error(respError.data.message);
      }
      throw e;
    }
  }

  async login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<UserState> {
    const { data } = await axiosAdapter.doPost<AppResponse<Tokens>>({
      url: '/auth/login',
      payload: { email, password }
    });

    if (data.data) {
      if (rememberMe) {
        authInfoManagerHolder.setAuthInfoManager(localStorageService);
      }
      const tokens: Tokens = {
        accessToken: data.data.accessToken,
        refreshToken: data.data.refreshToken
      };
      const authManager = authInfoManagerHolder.getAuthInfoManager();
      authManager.setTokens(tokens);
      return data.data.user;
    }
    return null;
  }

  async checkToken(token: string): Promise<string> {
    const res = await axiosAdapter.doPost<AppResponse<string>>({
      url: '/auth/check-token',
      payload: { token }
    });
    return res.data.data;
  }

  async activateAccount(token: string, password: string) {
    try {
      const resp = await axiosAdapter.doPost<AppResponse<Tokens>>({
        url: '/auth/activate-account',
        payload: { token, password }
      });
      if (resp.data.data) {
        const tokens: Tokens = {
          accessToken: resp.data.data.accessToken,
          refreshToken: resp.data.data.refreshToken
        };
        const authManager = authInfoManagerHolder.getAuthInfoManager();
        authManager.setTokens(tokens);
      } else {
        throw new Error(resp.data.error.data.message);
      }
    } catch (e) {
      if (e instanceof AxiosError<AppResponse<Tokens>>) {
        const respError: AppError = e.response.data.error;
        throw new Error(respError.data.message);
      }
      throw e;
    }
  }

  async restartAccountActivation() {
    try {
      await axiosAdapter.doGet<AppResponse<{ status: string }>>({
        url: '/auth/restart-account-activation'
      });
    } catch (e) {
      if (e instanceof AxiosError<AppResponse<string>>) {
        const respError: AppError = e.response.data.error;
        throw new Error(respError.data.message);
      }
      throw e;
    }
  }

  parseJwtPayload(): JwtPayloadDto | undefined {
    const authManager = authInfoManagerHolder.getAuthInfoManager();
    const token = authManager.getTokens().accessToken;
    if (!token) {
      return undefined;
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid jwt');
    }
    return JSON.parse(atob(parts[1]));
  }

  isAuthenticated() {
    const authManager = authInfoManagerHolder.getAuthInfoManager();
    return authManager.isAuthenticated();
  }

  logout() {
    const authManager = authInfoManagerHolder.getAuthInfoManager();
    authManager.logout();
  }

  async register(email: string, target: string): Promise<UserState> {
    const { data } = await axiosAdapter.doPost<AppResponse<UserState>>({
      url: '/auth/new',
      payload: {
        email,
        target
      }
    });
    return data.data;
  }

  async registerAndCheckout(
    courseId: string,
    email: string,
    promoCode: string
  ) {
    const { data } = await axiosAdapter.doPost<AppResponse<string>>({
      url: '/stripe/register-and-purchase',
      payload: {
        courseId,
        email,
        promoCode
      }
    });
    return data.data;
  }

  async resetPassword(token: string, password: string) {
    await axiosAdapter.doPost({
      url: '/auth/reset-password',
      payload: { token, password }
    });
  }

  async resetPasswordRequest(email: string) {
    await axiosAdapter.doPost({
      url: '/auth/request-password-reset',
      payload: { email }
    });
  }

  async updatePassword(password: string) {
    await axiosAdapter.doPut({
      url: '/auth/update-password',
      payload: { password }
    });
  }
}

export const authService = new AuthService();
