import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { User } from 'types/backend';

export const getCurrentUser = (): Promise<{ data: User }> => {
  return api.get('/user-new/me');
};

export const getCurrentUserQueryOptions = () => {
  return queryOptions({
    queryKey: ['user', 'me'],
    queryFn: getCurrentUser
  });
};

type UseCurrentUserOptions = {
  queryConfig?: QueryConfig<typeof getCurrentUserQueryOptions>;
};

export const useCurrentUser = ({ queryConfig }: UseCurrentUserOptions = {}) => {
  return useQuery({
    ...getCurrentUserQueryOptions(),
    ...queryConfig
  });
};
