/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { Routine } from 'redux-saga-routines';
import { SurveyDto } from 'types/api/survey';
import { SurveyState } from 'types/state';
import { answerPublicSurvey, answerSurvey, createQuestion, createSurvey, fetchSurvey, fetchSurveys, fetchSurveysForEvent, removeQuestion, reorderQuestion, updateQuestion, updateSurvey } from './routines';

const initialState: SurveyState = {
  surveys: {},
  isLoading: false,
  error: undefined
};

export const surveyReducer = produce((state: SurveyState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case createSurvey.TRIGGER:
    case updateSurvey.TRIGGER:
    case fetchSurveys.TRIGGER:
    case fetchSurveysForEvent.TRIGGER:
    case fetchSurvey.TRIGGER:
    case createQuestion.TRIGGER:
    case updateQuestion.TRIGGER:
    case reorderQuestion.TRIGGER:
    case removeQuestion.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case answerSurvey.TRIGGER:
      state.error = undefined;
      state.surveys[payload.surveyId].isLoading = true;
      break;
    case answerPublicSurvey.TRIGGER:
      state.error = undefined;
      state.surveys[payload.dto.surveyId].isLoading = true;
      break;
    case fetchSurveys.SUCCESS:
    case fetchSurveysForEvent.SUCCESS:
      state.isLoading = false;
      state.surveys = {};
      payload.forEach((survey: SurveyDto) => {
        state.surveys[survey.id] = survey;
      });
      break;
    // case fetchSurveysForEvent.SUCCESS:
    //   state.isLoading = false;
    //   payload.forEach((survey: SurveyDto) => {
    //     state.surveys[survey.id] = survey;
    //   });
    //   break;
    case createSurvey.SUCCESS:
    case updateSurvey.SUCCESS:
    case fetchSurvey.SUCCESS:
    case removeQuestion.SUCCESS:
      state.isLoading = false;
      state.surveys[payload.id] = payload;
      break;
    case createQuestion.SUCCESS:
    case updateQuestion.SUCCESS:
      state.isLoading = false;
      state.surveys[payload.surveyId].questions = payload.data.questions;
      break;
    case reorderQuestion.SUCCESS:
      state.isLoading = false;
      state.surveys[payload.surveyId] = payload.data;
      break;
    case answerSurvey.SUCCESS:
    case answerPublicSurvey.SUCCESS:
      state.surveys[payload.surveyId].isLoading = false;
      if (payload.data.f2fTaskId) state.surveys[payload.surveyId].answers[payload.data.f2fTaskId] = payload.data;
      if (payload.data.courseTaskId) state.surveys[payload.surveyId].answers[payload.data.courseTaskId] = payload.data;
      break;
    case createSurvey.FAILURE:
    case updateSurvey.FAILURE:
    case fetchSurveys.FAILURE:
    case fetchSurveysForEvent.FAILURE:
    case fetchSurvey.FAILURE:
    case createQuestion.FAILURE:
    case updateQuestion.FAILURE:
    case reorderQuestion.FAILURE:
    case removeQuestion.FAILURE:
    case answerSurvey.FAILURE:
    case answerPublicSurvey.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
