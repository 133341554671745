import React from 'react';
import { Link } from 'react-router-dom';
import { Title, Text, Grid, Button, CopyButton, Anchor } from '@mantine/core';
import { IconDownload, IconClipboard, IconClipboardCheck } from '@tabler/icons-react';
import { EventDto } from 'types/api/event';
import { Routes } from 'enums';

type Props = {
  event: EventDto;
};

export const Promotion: React.FC<Props> = ({ event }) => {
  return (
    <Grid my="lg">
      <Grid.Col span={6}>
        <Title order={3} mb="lg">
          Promotion Flyer
        </Title>
        <Text my="lg">Download the flyer for this event. The flyer can be printed or forwarded to interested parties.</Text>
        <Button color="cyan" uppercase size="lg" leftIcon={<IconDownload />}>
          Coming Soon...
        </Button>
      </Grid.Col>
      <Grid.Col span={6}>
        <Title order={3} mb="lg">
          EOI Form Link
        </Title>
        <Text my="lg">
          Copy and share the link to the{' '}
          <Anchor component={Link} to={`${Routes.ExpressionOfInterest}/${event.id}`}>
            EOI Form
          </Anchor>{' '}
          with anyone you would like to invite to express their interest in attending this training.
        </Text>
        <CopyButton value={`${window.location.origin}${Routes.ExpressionOfInterest}/${event.id}`}>
          {({ copy, copied }) => (
            <Button color={copied ? 'green' : 'red'} onClick={copy} uppercase size="lg" leftIcon={copied ? <IconClipboardCheck /> : <IconClipboard />}>
              {copied ? 'Link copied to share EOI form' : 'Copy link to share EOI form'}
            </Button>
          )}
        </CopyButton>
      </Grid.Col>
    </Grid>
  );
};
