import React from 'react';
import { SurveyDto, UpdateSurveyDto } from 'types/api/survey';
import { TextInput, Group, Button, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';

type Props = {
  survey: SurveyDto;
  updateSurvey: (dto: UpdateSurveyDto) => void;
};

export const SurveyMetadata: React.FC<Props> = ({ survey, updateSurvey }) => {
  const form = useForm({
    initialValues: {
      title: survey.title
    },
    transformValues: values => ({
      id: survey.id,
      title: values.title
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => updateSurvey(values))}>
      <Stack>
        <TextInput label="Survey title" {...form.getInputProps('title')} />

        <Group position="right">
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </form>
  );
};
