import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, F2FTypeIndex, EventIndex } from 'types/state';
import { Link, useParams } from 'react-router-dom';
import {
  Container,
  Title,
  Text,
  Badge,
  Box,
  SimpleGrid,
  Card,
  ThemeIcon,
  Group,
  Button,
  Notification
} from '@mantine/core';
import {
  IconCircle,
  IconCircleCheck,
  IconLock,
  IconChalkboard,
  IconCheck
} from '@tabler/icons-react';
import { fetchF2FTypes as fetchF2FTypesRoutine } from 'store/face-to-face/routines';
import { fetchEvent as fetchEventRoutine } from 'store/event/routines';
import { fetchSurveys as fetchSurveysRoutine } from 'store/survey/routines';
import { fetchScorms as fetchScormsRoutine } from 'store/scorm/routines';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { fetchUserUploads as fetchUserUploadsRoutine } from 'store/user-upload/routines';
import { NSWHealthLHD } from 'enums/user-info';
import { F2FTaskWhen } from 'enums/training';
import { TaskList } from './TaskList';
import { EventDetails } from './EventDetails';
import { F2FTaskDto } from 'types/api/face-to-face';
import { TaskStatus } from 'enums/task';
import { Routes } from 'enums';
import { sanitize } from 'dompurify';

type Props = {
  events: EventIndex;
  fetchEvent: (id: string) => void;
  f2fs: F2FTypeIndex;
  fetchF2FTypes: () => void;
  fetchScorms: () => void;
  fetchSurveys: () => void;
  fetchCourses: () => void;
  fetchUserUploads: () => void;
};

const TrainingsDetailComponent: React.FC<Props> = ({
  events,
  fetchEvent,
  f2fs,
  fetchF2FTypes,
  fetchScorms,
  fetchSurveys,
  fetchCourses,
  fetchUserUploads
}) => {
  const { id } = useParams();
  const [page, setPage] = useState<keyof typeof F2FTaskWhen | 'EVENT'>(
    'BEFORE'
  );

  useEffect(() => {
    fetchEvent(id);
  }, [id]);

  useEffect(() => {
    fetchF2FTypes();
    fetchScorms();
    fetchSurveys();
    fetchCourses();
    fetchUserUploads();
  }, []);

  const event = events[id];
  if (!event) return null;

  const f2f = f2fs[event.f2fId];
  if (!f2f) return null;

  const pageStatus: Record<
    keyof typeof F2FTaskWhen,
    keyof typeof TaskStatus | 'NONE'
  > = {
    BEFORE: 'NONE',
    IMMEDIATELY_BEFORE: 'NONE',
    AFTER: 'NONE'
  };

  Object.keys(F2FTaskWhen).forEach(when => {
    f2f[when].forEach((task: F2FTaskDto) => {
      if (pageStatus[when] === 'NONE') pageStatus[when] = task.status;
      else if (
        pageStatus[when] === 'COMPLETE' &&
        task.status !== 'COMPLETE' &&
        task.status !== 'EXPIRED'
      )
        pageStatus[when] = 'INCOMPLETE';
    });
  });

  const isComplete =
    page === 'EVENT'
      ? false
      : f2f[page].every(task => task.status === 'COMPLETE');

  let hasNextPage = false;
  if (page === 'BEFORE' && pageStatus.IMMEDIATELY_BEFORE !== 'LOCKED')
    hasNextPage = true;
  else if (page === 'IMMEDIATELY_BEFORE') hasNextPage = true;
  else if (
    page === 'EVENT' &&
    !(pageStatus.AFTER === 'LOCKED' || pageStatus.AFTER === 'NONE')
  )
    hasNextPage = true;

  return (
    <div>
      <Box bg="blue" py="4rem" c="white">
        <Container size="lg">
          <Group position="right">
            <Button
              color="blue"
              variant="light"
              uppercase
              component={Link}
              to={`${Routes.F2FResources}/${event.id}`}
            >
              Training Resources
            </Button>
          </Group>

          <Title order={2}>{event.name}</Title>
          <Text
            mb="md"
            dangerouslySetInnerHTML={{ __html: sanitize(event.details) }}
          />

          {event.lhds.map(lhd => (
            <Badge key={lhd}>{NSWHealthLHD[lhd]}</Badge>
          ))}
        </Container>
      </Box>

      <Container size="lg">
        <SimpleGrid
          cols={4}
          breakpoints={[{ maxWidth: '28rem', cols: 1, spacing: 'sm' }]}
          mt="-2rem"
          mb="lg"
        >
          {pageStatus.BEFORE !== 'NONE' && (
            <Card
              withBorder
              shadow="sm"
              display="flex"
              c={page === 'BEFORE' ? 'blue' : 'black'}
              onClick={() => {
                if (pageStatus.BEFORE !== 'LOCKED') setPage('BEFORE');
              }}
            >
              <ThemeIcon
                radius="xl"
                variant={page === 'BEFORE' ? 'outline' : 'default'}
              >
                {pageStatus.BEFORE === 'LOCKED' && <IconLock />}
                {pageStatus.BEFORE === 'INCOMPLETE' && <IconCircle />}
                {pageStatus.BEFORE === 'COMPLETE' && <IconCircleCheck />}
              </ThemeIcon>
              <Text ml="md">{F2FTaskWhen.BEFORE}</Text>
            </Card>
          )}
          {pageStatus.IMMEDIATELY_BEFORE !== 'NONE' && (
            <Card
              withBorder
              shadow="sm"
              display="flex"
              c={page === 'IMMEDIATELY_BEFORE' ? 'blue' : 'black'}
              onClick={() => {
                if (pageStatus.IMMEDIATELY_BEFORE !== 'LOCKED')
                  setPage('IMMEDIATELY_BEFORE');
              }}
            >
              <ThemeIcon
                radius="xl"
                variant={page === 'IMMEDIATELY_BEFORE' ? 'outline' : 'default'}
              >
                {pageStatus.IMMEDIATELY_BEFORE === 'LOCKED' && <IconLock />}
                {pageStatus.IMMEDIATELY_BEFORE === 'INCOMPLETE' && (
                  <IconCircle />
                )}
                {pageStatus.IMMEDIATELY_BEFORE === 'COMPLETE' && (
                  <IconCircleCheck />
                )}
              </ThemeIcon>
              <Text ml="md">{F2FTaskWhen.IMMEDIATELY_BEFORE}</Text>
            </Card>
          )}
          <Card
            withBorder
            shadow="sm"
            display="flex"
            c={page === 'EVENT' ? 'blue' : 'black'}
            onClick={() => setPage('EVENT')}
          >
            <ThemeIcon
              radius="xl"
              variant={page === 'EVENT' ? 'outline' : 'default'}
            >
              <IconChalkboard />
            </ThemeIcon>
            <Text ml="md">Event Details</Text>
          </Card>
          {pageStatus.AFTER !== 'NONE' && (
            <Card
              withBorder
              shadow="sm"
              display="flex"
              c={page === 'AFTER' ? 'blue' : 'black'}
              onClick={() => {
                if (pageStatus.AFTER !== 'LOCKED') setPage('AFTER');
              }}
            >
              <ThemeIcon
                radius="xl"
                variant={page === 'AFTER' ? 'outline' : 'default'}
              >
                {pageStatus.AFTER === 'LOCKED' && <IconLock />}
                {pageStatus.AFTER === 'INCOMPLETE' && <IconCircle />}
                {pageStatus.AFTER === 'COMPLETE' && <IconCircleCheck />}
              </ThemeIcon>
              <Text ml="md">{F2FTaskWhen.AFTER}</Text>
            </Card>
          )}
        </SimpleGrid>

        {isComplete && (
          <Notification
            icon={<IconCheck size="1rem" />}
            color="green"
            py="md"
            fw="bold"
          >
            {hasNextPage
              ? 'Well done, you have completed this page, you can now proceed to the next page'
              : 'Well done, you have completed this page'}
          </Notification>
        )}

        {page !== 'EVENT' && <TaskList f2fId={f2f.id} tasks={f2f[page]} />}
        {page === 'EVENT' && <EventDetails event={event} />}

        {page === 'BEFORE' && (
          <Group position="right">
            <Button disabled>Prev</Button>
            <Button
              disabled={pageStatus.IMMEDIATELY_BEFORE === 'LOCKED'}
              onClick={() => {
                if (pageStatus.IMMEDIATELY_BEFORE === 'NONE') setPage('EVENT');
                else setPage('IMMEDIATELY_BEFORE');
              }}
            >
              Next
            </Button>
          </Group>
        )}
        {page === 'IMMEDIATELY_BEFORE' && (
          <Group position="right">
            <Button disabled={pageStatus.BEFORE === 'NONE'}>Prev</Button>
            <Button onClick={() => setPage('EVENT')}>Next</Button>
          </Group>
        )}
        {page === 'EVENT' && (
          <Group position="right">
            <Button
              disabled={
                pageStatus.BEFORE === 'NONE' &&
                pageStatus.IMMEDIATELY_BEFORE === 'NONE'
              }
              onClick={() => {
                if (
                  pageStatus.IMMEDIATELY_BEFORE === 'NONE' ||
                  pageStatus.IMMEDIATELY_BEFORE === 'LOCKED'
                )
                  setPage('BEFORE');
                else setPage('IMMEDIATELY_BEFORE');
              }}
            >
              Prev
            </Button>
            <Button
              disabled={
                pageStatus.AFTER === 'LOCKED' || pageStatus.AFTER === 'NONE'
              }
              onClick={() => setPage('AFTER')}
            >
              Next
            </Button>
          </Group>
        )}
        {page === 'AFTER' && (
          <Group position="right">
            <Button onClick={() => setPage('EVENT')}>Prev</Button>
            <Button disabled>Next</Button>
          </Group>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  f2fs: state.faceToFace.types
});

const mapDispatchToProps = {
  fetchEvent: fetchEventRoutine,
  fetchF2FTypes: fetchF2FTypesRoutine,
  fetchScorms: fetchScormsRoutine,
  fetchSurveys: fetchSurveysRoutine,
  fetchCourses: fetchCoursesRoutine,
  fetchUserUploads: fetchUserUploadsRoutine
};

export const TrainingsDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingsDetailComponent);
