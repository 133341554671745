import { Divider, Drawer, Flex, Text } from '@mantine/core';
import { IconCertificate, IconUser } from '@tabler/icons-react';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { fetchUserEnrolments as fetchUserEnrolmentsRoutine } from 'store/course-enrolment/routines';
import { CourseEnrolmentDto } from 'types/api/course';
import { UserDto } from 'types/api/users';
import { CourseState, RootState } from 'types/state';

interface StateProps {
  enrolments: CourseEnrolmentDto[];
  courses: CourseState['courses'];
}

interface DispatchProps {
  fetchUserEnrolments: (userId: string) => void;
}

interface OwnProps {
  isOpen: boolean;
  user: UserDto;
  onClose: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const UserDrawerComponent: React.FC<Props> = ({
  enrolments,
  courses,
  fetchUserEnrolments,
  isOpen,
  user,
  onClose
}: Props) => {
  const { id: userId } = user;

  const enrolledCourses = useMemo(
    () =>
      enrolments.map(enrolment => ({
        ...courses[enrolment.courseId],
        isActive: enrolment.isActive
      })),
    [enrolments, courses]
  );

  useEffect(() => {
    if (enrolments.length === 0) fetchUserEnrolments(userId);
  }, [userId]);

  return (
    <Drawer opened={isOpen} onClose={onClose} position="right">
      {user && user.basic && (
        <div>
          <Flex direction="column" gap="xl">
            <div>
              <Flex direction="row" align="center" gap="sm">
                <IconUser />
                <Text size="lg" weight="bold">
                  Basic Information
                </Text>
              </Flex>
              <Divider my="sm" />
              <Flex direction="column" gap="xs">
                <Flex gap="sm">
                  <Text weight="bold">Full Name:</Text>
                  <Text>{`${user.basic.firstName} ${user.basic.lastName}`}</Text>
                </Flex>
                <Flex gap="sm">
                  <Text weight="bold">Email:</Text>
                  <Text>{user.email}</Text>
                </Flex>
              </Flex>
            </div>
            <Flex direction="column">
              <Flex direction="row" align="center" gap="sm">
                <IconCertificate />
                <Text size="lg" weight="bold">
                  Courses
                </Text>
              </Flex>
              <Divider my="sm" />
              <Flex direction="column" gap="sm">
                {enrolledCourses.length > 0 &&
                  enrolledCourses.map(course => {
                    if (course.isActive) {
                      return (
                        <Text key={course.id} truncate="end">
                          {course.title}
                        </Text>
                      );
                    }

                    return (
                      <Text
                        key={course.id}
                        c="dimmed"
                        td="line-through"
                        truncate="end"
                      >
                        {course.title}
                      </Text>
                    );
                  })}
              </Flex>
            </Flex>
          </Flex>
        </div>
      )}
    </Drawer>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const enrolmentIds =
    state.courseEnrolment.userEnrolments[ownProps.user.id] ?? [];

  return {
    enrolments: enrolmentIds.map(
      enrolmentId => state.courseEnrolment.enrolments[enrolmentId]
    ),
    courses: state.course.courses
  };
};

const mapDispatchToProps = {
  fetchUserEnrolments: fetchUserEnrolmentsRoutine
};

export const UserDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDrawerComponent);
