import React, { useMemo } from 'react';

import { Droppable, DroppableProvided } from 'react-beautiful-dnd';

import { SimpleGrid, Divider, Title, Stack, Button, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { F2FTaskDto } from 'types/api/face-to-face';

import { TaskCard } from './TaskCard';
import { F2FTaskWhen } from 'enums/training';
import { CourseIndex, SurveyIndex } from 'types/state';
import { useScorms } from 'features/scorms/api/get-scorms';

type Props = {
  when: keyof typeof F2FTaskWhen;
  tasks: F2FTaskDto[];
  setTask: (task: F2FTaskDto) => void;
  courses: CourseIndex;
  surveys: SurveyIndex;
};

export const TaskList: React.FC<Props> = ({
  when,
  tasks,
  setTask,
  courses,
  surveys
}) => {
  const scormsQuery = useScorms();
  const scorms = scormsQuery.data?.data;

  const scormsIndex = useMemo(() => {
    if (!scorms) return {};

    return scorms.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  }, [scorms]);

  if (!tasks) return null;

  return (
    <Stack>
      <Title order={3}>{F2FTaskWhen[when]}</Title>

      <Divider />

      <Droppable droppableId={when}>
        {(provided: DroppableProvided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.length === 0 && <Text>No tasks.</Text>}
            {tasks.map(task => (
              <TaskCard
                key={task.id}
                task={task}
                scorms={scormsIndex}
                courses={courses}
                surveys={surveys}
                onClick={() => setTask(task)}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Divider />

      <SimpleGrid cols={4}>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'SURVEY',
              when
            })
          }
        >
          Survey
        </Button>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'COURSE',
              when
            })
          }
        >
          Course
        </Button>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'SCORM',
              when
            })
          }
        >
          Module
        </Button>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'UPLOAD',
              when
            })
          }
        >
          Upload
        </Button>
      </SimpleGrid>
    </Stack>
  );
};
