import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { CouponValidationRule } from 'types/backend';

export const getCouponValidationRules = ({
  expand
}: {
  expand?: Array<string>;
}): Promise<{
  data: Array<CouponValidationRule>;
}> => {
  return api.get('/coupon-validation-rule', { params: { 'expand[]': expand } });
};

export const getCouponValidationRulesQueryOptions = (
  expand?: Array<string>
) => {
  return queryOptions({
    queryKey: ['coupon-validation-rule'],
    queryFn: () => getCouponValidationRules({ expand })
  });
};

type UseCouponValidationRulesOptions = {
  expand?: Array<string>;
  queryConfig?: QueryConfig<typeof getCouponValidationRulesQueryOptions>;
};

export const useCouponValidationRules = ({
  expand,
  queryConfig
}: UseCouponValidationRulesOptions = {}) => {
  return useQuery({
    ...getCouponValidationRulesQueryOptions(expand),
    ...queryConfig
  });
};
