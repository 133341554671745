import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { CourseEnrolmentDto } from 'types/api/course';

class CourseEnrolmentService {
  async getOne(id: string): Promise<CourseEnrolmentDto> {
    const { data } = await axiosAdapter.doGet<AppResponse<CourseEnrolmentDto>>({
      url: `/course-enrolment/${id}`
    });
    return data.data;
  }

  async getUserEnrolments(userId: string): Promise<CourseEnrolmentDto[]> {
    const { data } = await axiosAdapter.doGet<
      AppResponse<CourseEnrolmentDto[]>
    >({
      url: `/course-enrolment/enrolments/${userId}`
    });

    return data.data;
  }

  async enrolUser(
    courseId: string,
    userId: string
  ): Promise<CourseEnrolmentDto[]> {
    const { data } = await axiosAdapter.doPost<
      AppResponse<CourseEnrolmentDto[]>
    >({
      url: `/course-enrolment/enrol/${courseId}/${userId}`
    });
    return data.data;
  }

  async update(
    id: string,
    payload: Partial<CourseEnrolmentDto>
  ): Promise<CourseEnrolmentDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<CourseEnrolmentDto>>({
      url: `/course-enrolment/update/${id}`,
      payload
    });
    return data.data;
  }

  async progress(userId: string, taskId: string) {
    await axiosAdapter.doPut({
      url: `/course/progress-task/${taskId}`,
      payload: { userId }
    });
  }
}

export const courseEnrolmentService = new CourseEnrolmentService();
