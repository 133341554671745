export enum HospitalSetting {
  PUBLIC_MEDICAL_ADULT = 'Public Medical Adult',
  PUBLIC_MEDICAL_PAEDIATRIC = 'Public Medical Paediatric',
  PUBLIC_PSYCHIATRIC_ADULT = 'Public Psychiatric Adult',
  PUBLIC_PSYCHIATRIC_PAEDIATRIC = 'Public Psychiatric Paediatric',
  PRIVATE_MEDICAL_ADULT = 'Private Medical Adult',
  PRIVATE_MEDICAL_PAEDIATRIC = 'Private Medical Paediatric',
  PRIVATE_PSYCHIATRIC_ADULT = 'Private Psychiatric Adult',
  PRIVATE_PSYCHIATRIC_PAEDIATRIC = 'Private Psychiatric Paediatric'
}
