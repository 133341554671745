import React, { useState } from 'react';
import { EventDto } from 'types/api/event';
import { Button, Text, Alert } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { EventEnrolmentStatus } from 'enums/event/event.enrolment.status.enum';
import dayjs from 'dayjs';

type Props = {
  event: EventDto;
  isLoading: boolean;
  createEnrolment: (eventId: string) => void;
  error?: string;
};

export const LoggedInExpressionOfInterest: React.FC<Props> = ({
  event,
  isLoading,
  createEnrolment,
  error
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  return (
    <div>
      {event.enrolment && !submitted && (
        <>
          <Text>
            You have already expressed your interest in this training.
          </Text>
          <Text>
            Your application&apos;s status is:{' '}
            <Text fw="bold" span>
              {EventEnrolmentStatus[event.enrolment.status]}
            </Text>
          </Text>
          <Text>
            You should expect to hear back about you application on{' '}
            {dayjs(event.preferencesDue).format('DD MMM YYYY')}.
          </Text>
        </>
      )}
      {event.enrolment && submitted && (
        <Alert color="green" icon={<IconCheck />}>
          Submitted, thanks! Your coordinator will review expressions of
          interest and notify you on{' '}
          {dayjs(event.preferencesDue).format('DD MMM YYYY')}.
        </Alert>
      )}

      {error && (
        <Alert title="Something went wrong" color="red">
          {error}
        </Alert>
      )}

      {!event.enrolment && (
        <Button
          color="cyan"
          uppercase
          loading={isLoading}
          onClick={() => {
            setSubmitted(true);
            createEnrolment(event.id);
          }}
        >
          Submit Expression of Interest
        </Button>
      )}
    </div>
  );
};
