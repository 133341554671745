import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Title, Text, Group, Badge, Alert } from '@mantine/core';
import { IconCalendar, IconClock } from '@tabler/icons-react';
import {
  fetchEvent as fetchEventRoutine,
  createEnrolment as createEnrolmentRoutine
} from 'store/event/routines';
import { registerAndEoI as registerAndEoIRoutine } from 'store/user/routines';
import { EventIndex, RootState } from 'types/state';
import { GuestExpressionOfInterest } from './GuestExpressionOfInterest';
import { NSWHealthLHD } from 'enums/user-info';
import { LoggedInExpressionOfInterest } from './LoggedInExpressionOfInterest';
import { sanitize } from 'dompurify';
import dayjs from 'dayjs';

type Props = {
  events: EventIndex;
  fetchEvent: (id: string) => void;
  isAuthenticated: boolean;
  createEnrolment: (eventId: string) => void;
  isLoading: boolean;
  error?: string;
  registerAndEoI: (arg0: { email: string; eventId: string }) => void;
};

const ExpressionOfInterestComponent: React.FC<Props> = ({
  events,
  fetchEvent,
  isAuthenticated,
  createEnrolment,
  isLoading,
  error,
  registerAndEoI
}) => {
  const { id } = useParams();

  useEffect(() => {
    fetchEvent(id);
  }, [id]);

  const event = events[id];
  if (!event) return null; // FIXME obviously have to handle this correctly!

  return (
    <Container size="lg" my="xl" py="4rem">
      <Text fw="bold">Thank you for your interest in</Text>
      <Title order={1}>{event.name}</Title>
      <Group mb="4rem">
        {event.lhds.map(lhd => (
          <Badge radius="xs" key={lhd}>
            {NSWHealthLHD[lhd]}
          </Badge>
        ))}
      </Group>

      <Text my="md">
        Expressions of interest are due on{' '}
        {dayjs(event.eoisDue).format('DD MMM YYYY')}
      </Text>

      <Title order={3} mb="xs">
        Date
      </Title>
      {event.instances.map(instance => (
        <Group key={instance.id} my="xs">
          <Group miw="200px">
            <Text color="cyan">
              <IconCalendar />
            </Text>
            <Text>{dayjs(instance.startsAt).format('DD MMM YYYY')}</Text>
          </Group>
          <Group miw="200px">
            <Text color="cyan">
              <IconClock />
            </Text>
            <Text>
              {dayjs(instance.startsAt).format('hh:mm a')} -{' '}
              {dayjs(instance.endsAt).format('hh:mm a')}
            </Text>
          </Group>
        </Group>
      ))}

      <Title order={3} mt="2rem" mb="xs">
        Description
      </Title>

      <Text
        mb="lg"
        dangerouslySetInnerHTML={{ __html: sanitize(event.details) }}
      />
      <Text mb="lg">
        If you do not have an existing account and experience any issues
        submitting your EOI, first <a href="/registration">Create an Account</a>{' '}
        then return to this page.
      </Text>

      {(event.status === 'OPEN' || event.status === 'WITH_COORDINATOR') && (
        <>
          {event.status === 'WITH_COORDINATOR' && (
            <Alert
              color="yellow"
              title="Expression of Interest Overdue"
              my="md"
            >
              Expressions of interest in this event were due on{' '}
              {dayjs(event.eoisDue).format('DD MMM YYYY')}. However, you can
              still submit an application now.
            </Alert>
          )}
          {isAuthenticated && (
            <LoggedInExpressionOfInterest
              event={event}
              isLoading={isLoading}
              createEnrolment={createEnrolment}
              error={error}
            />
          )}
          {!isAuthenticated && (
            <GuestExpressionOfInterest
              eventId={event.id}
              registerAndEoI={registerAndEoI}
              isLoading={isLoading}
            />
          )}
        </>
      )}

      {!(event.status === 'OPEN' || event.status === 'WITH_COORDINATOR') && (
        <Alert color="red" title="Expressions of interest are closed" my="md">
          Unfortunately expressions of interest are now closed for this event.
        </Alert>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  isAuthenticated: state.user.isAuthenticated,
  isLoading: state.event.isLoading || state.user.isLoading,
  error: state.event.error
});

const mapDispatchToProps = {
  fetchEvent: fetchEventRoutine,
  createEnrolment: createEnrolmentRoutine,
  registerAndEoI: registerAndEoIRoutine
};

export const ExpressionOfInterest = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionOfInterestComponent);
