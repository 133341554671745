/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { assertTokensAreValid as assertTokensAreValidRoutine } from 'store/user/routines';
import { RootState } from 'types/state';
import { Action } from '@reduxjs/toolkit';
import { UnifiedRoutine } from 'redux-saga-routines';

import { LoadingOverlay } from '@mantine/core';

type Props = {
  assertTokensAreValid?: UnifiedRoutine<(payload?: any) => Action<any>>;
  verificationIsInProgress?: boolean;
};

const TokenVerifier: React.FC<React.PropsWithChildren<Props>> = ({ children, verificationIsInProgress, assertTokensAreValid }) => {
  useEffect(() => {
    if (verificationIsInProgress) assertTokensAreValid();
  }, []);

  if (verificationIsInProgress) return <LoadingOverlay visible />;
  return <>{children}</>;
};

const mapStateToProps = (rootState: RootState) => ({
  verificationIsInProgress: rootState.user.verificationIsInProgress
});

const mapDispatchToProps = {
  assertTokensAreValid: assertTokensAreValidRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenVerifier);
