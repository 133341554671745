import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Text, Card, Skeleton } from '@mantine/core';
import { F2FTaskDto } from 'types/api/face-to-face';
import { CourseIndex, ScormIndex, SurveyIndex } from 'types/state';

type Props = {
  task: F2FTaskDto;
  onClick: () => void;
  scorms: ScormIndex;
  courses: CourseIndex;
  surveys: SurveyIndex;
};

export const TaskCard: React.FC<Props> = ({ task, onClick, scorms, courses, surveys }) => {
  return (
    <Draggable draggableId={task.id} index={task.sortIndex}>
      {(provided: DraggableProvided) => (
        <Card withBorder shadow="sm" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} onClick={onClick}>
          <Text fw="bold" my="md">
            {task.sortIndex}. {task.type}: {task.title}
          </Text>
          {task.type === 'SURVEY' && <Text>{surveys[task.surveyId] ? surveys[task.surveyId].title : <Skeleton h="2rem" />}</Text>}
          {task.type === 'COURSE' && <Text>{courses[task.courseId] ? courses[task.courseId].title : <Skeleton h="2rem" />}</Text>}
          {task.type === 'SCORM' && <Text>{scorms[task.scormId] ? scorms[task.scormId].title : <Skeleton h="2rem" />}</Text>}
          {task.type === 'UPLOAD' && <div dangerouslySetInnerHTML={{ __html: task.body }} />}
        </Card>
      )}
    </Draggable>
  );
};
