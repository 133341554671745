import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { CreateF2FTaskDto, F2FTaskDto, F2FTaskReorderDto, UpdateF2FTaskDto } from 'types/api/face-to-face';

class F2FTaskService {
  constructor() {
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.reorder = this.reorder.bind(this);
    this.delete = this.delete.bind(this);
  }

  async create(typeId: string, dto: CreateF2FTaskDto): Promise<F2FTaskDto> {
    const { data } = await axiosAdapter.doPost<AppResponse<F2FTaskDto>>({
      url: `/f2f-task/${typeId}`,
      payload: dto
    });
    return data.data;
  }

  async update(dto: UpdateF2FTaskDto): Promise<F2FTaskDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<F2FTaskDto>>({
      url: `/f2f-task/${dto.id}`,
      payload: dto
    });
    return data.data;
  }

  async reorder(typeId: string, dto: F2FTaskReorderDto): Promise<F2FTaskDto> {
    const { data } = await axiosAdapter.doPut<AppResponse<F2FTaskDto>>({
      url: `/f2f-task/${typeId}/reorder`,
      payload: dto
    });
    return data.data;
  }

  async delete(id: string) {
    await axiosAdapter.doDelete({
      url: `/f2f-task/${id}`
    });
  }
}

export const f2fTaskService = new F2FTaskService();
