import React from 'react';
import { connect } from 'react-redux';
import { useForm, isNotEmpty } from '@mantine/form';
import { Collapse, Button, Group, Radio, TextInput, Alert } from '@mantine/core';
import { setDiscipline as setDisciplineRoutine } from 'store/user/routines';
import { RootState, UserDiscipline } from 'types/state';
import { Discipline } from 'enums/user-info';

type DisciplineForm = {
  discipline: keyof typeof Discipline | 'STUDENT';
  studying: keyof typeof Discipline;
  other: string;
};

type Props = {
  isLoading: boolean;
  error?: string;
  discipline: UserDiscipline;
  setDiscipline: (dto: UserDiscipline) => void;
};

const OnboardingDisciplineComponent: React.FC<Props> = ({ isLoading, error, discipline, setDiscipline }) => {
  const form = useForm<DisciplineForm, (arg0: DisciplineForm) => UserDiscipline>({
    initialValues: discipline
      ? {
          discipline: discipline.isStudent ? 'STUDENT' : discipline.discipline,
          studying: discipline.isStudent ? discipline.discipline : undefined,
          other: discipline.other || ''
        }
      : {
          discipline: undefined,
          studying: undefined,
          other: ''
        },
    validate: {
      discipline: isNotEmpty('This field is required'),
      studying: (value, values) => (!value && values.discipline === 'STUDENT' ? 'This field is required' : null),
      other: (value, values) => (!value && (values.discipline === 'OTHER' || (values.discipline === 'STUDENT' && values.studying === 'OTHER')) ? 'Please specify' : null)
    },
    transformValues: values =>
      values.discipline === 'STUDENT'
        ? {
            isStudent: true,
            discipline: values.studying,
            other: values.other
          }
        : {
            isStudent: false,
            discipline: values.discipline,
            other: values.other
          }
  });

  return (
    <form onSubmit={form.onSubmit(values => setDiscipline(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="What is your primary professional discipline?" {...form.getInputProps('discipline')}>
        {Object.entries(Discipline).map(([value, label]) => (
          <Radio key={value} {...{ value, label }} />
        ))}
        <Radio value="STUDENT" label="Student" />
      </Radio.Group>

      <Collapse in={form.values.discipline === 'STUDENT'} my="lg">
        <Radio.Group label="What discipline are you studying for?" {...form.getInputProps('studying')}>
          {Object.entries(Discipline).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.discipline === 'OTHER' || form.values.studying === 'OTHER'} my="lg">
        <TextInput label="Please specify" {...form.getInputProps('other')} />
      </Collapse>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Discipline Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  discipline: state.user.discipline
});

const mapDispatchToProps = {
  setDiscipline: setDisciplineRoutine
};

export const OnboardingDiscipline = connect(mapStateToProps, mapDispatchToProps)(OnboardingDisciplineComponent);
