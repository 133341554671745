import React from 'react';
import { Header } from 'components/Header';
import { PageFooter } from 'components/PageFooter';
import { AppShell } from '@mantine/core';

export const UserLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AppShell
      padding={0}
      header={<Header />}
      footer={<PageFooter />}
      styles={theme => ({
        main: {
          backgroundColor: theme.colors.gray[0]
        }
      })}
    >
      {children}
    </AppShell>
  );
};
