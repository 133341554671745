import { combineReducers } from 'redux';
import { userReducer } from './user/reducer';
import { faceToFaceReducer } from './face-to-face/reducer';
import { eventReducer } from './event/reducer';
import { scormReducer } from 'store/scorm/reducer';
import { courseReducer } from 'store/course/reducer';
import { courseEnrolmentReducer } from './course-enrolment/reducer';
import { usersReducer } from './users/reducer';
import { surveyReducer } from './survey/reducer';
import { fileReducer } from './file/reducer';
import { userUploadReducer } from './user-upload/reducer';
import { certificateReducer } from './certificate/reducer';
import { eventEnrolmentReducer } from './event-enrolment/reducer';
import { dataExportReducer } from './data-export/reducer';
import { RootState } from 'types/state';
import { surveyAnswerReducer } from './survey-answer/reducer';

const rootReducer = combineReducers<RootState>({
  user: userReducer,
  faceToFace: faceToFaceReducer,
  event: eventReducer,
  eventEnrolment: eventEnrolmentReducer,
  scorm: scormReducer,
  course: courseReducer,
  courseEnrolment: courseEnrolmentReducer,
  users: usersReducer,
  survey: surveyReducer,
  surveyAnswer: surveyAnswerReducer,
  file: fileReducer,
  upload: userUploadReducer,
  certificate: certificateReducer,
  dataExport: dataExportReducer
});

export default rootReducer;
