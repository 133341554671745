import React from 'react';
import { connect } from 'react-redux';
import { useForm } from '@mantine/form';
import { Button, Group, Radio, Collapse, Alert } from '@mantine/core';
import { setNSW as setNSWRoutine } from 'store/user/routines';
import { RootState, UserNSW } from 'types/state';
import { NSWHealthType, NSWHealthLHD } from 'enums/user-info';

type NSWForm = {
  isNSWHealth: 'YES' | 'NO';
  type: keyof typeof NSWHealthType;
  lhd: keyof typeof NSWHealthLHD;
};

type Props = {
  isLoading: boolean;
  error?: string;
  nsw: UserNSW;
  setNSW: (dto: UserNSW) => void;
};

const OnboardingNSWComponent: React.FC<Props> = ({ isLoading, error, nsw, setNSW }) => {
  const form = useForm<NSWForm, (arg0: NSWForm) => UserNSW>({
    initialValues: nsw
      ? {
          isNSWHealth: nsw.isNSWHealth ? 'YES' : 'NO',
          type: nsw.type,
          lhd: nsw.lhd
        }
      : {
          isNSWHealth: undefined,
          type: undefined,
          lhd: undefined
        },
    validate: {
      isNSWHealth: value => (!value ? 'This value is required' : null),
      type: (value, values) => (!value && values.isNSWHealth === 'YES' ? 'This value is required' : null),
      lhd: (value, values) => (!value && values.isNSWHealth === 'YES' && values.type === 'LHD' ? 'This value is required' : null)
    },
    transformValues: values => ({
      type: values.type,
      lhd: values.lhd,
      isNSWHealth: values.isNSWHealth === 'YES'
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setNSW(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Do you work for NSW Health?" {...form.getInputProps('isNSWHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Collapse in={form.values.isNSWHealth === 'YES'} my="lg">
        <Radio.Group label="Where in NSW Health?" {...form.getInputProps('type')}>
          {Object.entries(NSWHealthType).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.isNSWHealth === 'YES' && form.values.type === 'LHD'} my="lg">
        <Radio.Group label="Local Health District / Specialty Health Network" {...form.getInputProps('lhd')}>
          {Object.entries(NSWHealthLHD).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Group position="right">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update NSW Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  nsw: state.user.nsw
});

const mapDispatchToProps = {
  setNSW: setNSWRoutine
};

export const OnboardingNSW = connect(mapStateToProps, mapDispatchToProps)(OnboardingNSWComponent);
