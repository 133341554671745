import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, SurveyIndex } from 'types/state';
import { fetchSurveys as fetchSurveysRoutine } from 'store/survey/routines';
import { Alert, LoadingOverlay, Table, Group, Button, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { AdminRoutes } from 'enums';

type Props = {
  surveys: SurveyIndex;
  isLoading: boolean;
  error?: string;
  fetchSurveys: () => void;
};

const SurveyListComponent: React.FC<Props> = ({ surveys, isLoading, error, fetchSurveys }) => {
  const navigate = useNavigate();

  const surveyList = Object.values(surveys).sort((a, b) => (a.title < b.title ? -1 : 1));

  useEffect(() => {
    fetchSurveys();
  }, []);

  return (
    <>
      <Group position="right" my="xl">
        <Title order={2} sx={{ flex: '1 !important' }}>
          All Surveys
        </Title>
        <Button component={Link} to={AdminRoutes.surveyNew} leftIcon={<IconPlus />}>
          Create New Survey
        </Button>
      </Group>

      {error && (
        <Alert title="Error Fetching Surveys" color="red" my="lg">
          {error}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>Title</th>
          </tr>
        </thead>
        <tbody>
          {surveyList.map(survey => (
            <tr key={survey.id} onClick={() => navigate(`${AdminRoutes.surveyView}/${survey.id}`)}>
              <td>{survey.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  surveys: state.survey.surveys,
  isLoading: state.survey.isLoading,
  error: state.survey.error
});

const mapDispatchToProps = {
  fetchSurveys: fetchSurveysRoutine
};

export const SurveyList = connect(mapStateToProps, mapDispatchToProps)(SurveyListComponent);
