import { createRoutine } from 'redux-saga-routines';

const getPrefix = (opName: string) => `course-enrolment/${opName}`;

export const fetchCourseEnrolment = createRoutine(getPrefix('fetch-one'));

export const fetchUserEnrolments = createRoutine(
  getPrefix('fetch-user-enrolments')
);

export const progressCourseTask = createRoutine(getPrefix('progress-task'));

export const editEnrolment = createRoutine(getPrefix('edit-enrolment'));
