export enum NSWHealthLHD {
  CENTRAL_COAST = 'Central Coast',
  ILLAWARRA_SHOALHAVEN = 'Illawarra Shoalhaven',
  NEPEAN_BLUE_MOUNTAINS = 'Nepean Blue Mountains',
  NORTHERN_SYDNEY = 'Northern Sydney',
  SOUTH_EASTERN_SYDNEY = 'South Eastern Sydney',
  SOUTH_WESTERN_SYDNEY = 'South Western Sydney',
  SYDNEY = 'Sydney',
  WESTERN_SYDNEY = 'Western Sydney',
  FAR_WEST = 'Far West',
  HUNTER_NEW_ENGLAND = 'Hunter New England',
  MID_NORTH_COAST = 'Mid North Coast',
  MURRUMBIDGEE = 'Murrumbidgee',
  NORTHERN_NSW = 'Northern NSW',
  SOUTHERN_NSW = 'Southern NSW',
  WESTERN_NSW = 'Western NSW',
  SYDNEY_CHILDRENS_HOSPITAL_NETWORK = "Sydney Children's Hospital Network",
  JUSTICE_HEALTH_AND_FORENSIC_MENTAL_HEALTH_NETWORK = 'Justice Health and Forensic Mental Health Network',
  ST_VINCENTS_HEALTH_NETWORK = "St Vincent's Health Network"
}
