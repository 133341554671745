export enum F2fSurveyType {
  PRE_SURVEY = 'PRE_SURVEY',
  POST_SURVEY = 'POST_SURVEY'
}

export enum SurveyQuestionType {
  TEXT = 'Text',
  NUMBER = 'Number',
  CHECKBOX = 'Multiple Choice',
  RADIO = 'Single Choice',
  LIKERT = 'Likert',
  COMMENT = 'Comment'
}
