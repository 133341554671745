import { AxiosError } from 'axios';
import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { courseEnrolmentService } from 'service/course-enrolment';
import { CourseEnrolmentDto } from 'types/api/course';
import {
  editEnrolment,
  fetchCourseEnrolment,
  fetchUserEnrolments,
  progressCourseTask
} from './routines';

function* fetchCourseEnrolmentHandler({ payload: id }: Action<string>) {
  try {
    const data = yield call(courseEnrolmentService.getOne, id);
    yield put(fetchCourseEnrolment.success(data));
  } catch (e: any) {
    if (e.message) yield put(fetchCourseEnrolment.failure(e.message));
    else yield put(fetchCourseEnrolment.failure(JSON.stringify(e)));
  }
}

function* fetchUserEnrolmentsHandler({ payload: userId }: Action<string>) {
  try {
    const data = yield call(courseEnrolmentService.getUserEnrolments, userId);
    yield put(fetchUserEnrolments.success({ id: userId, data }));
  } catch (e) {
    if (e instanceof AxiosError)
      yield put(fetchUserEnrolments.failure(e.message));
    else yield put(fetchUserEnrolments.failure('Unknown error occured.'));
  }
}

function* progressCourseTaskHandler({
  payload: { enrolmentId, userId, taskId }
}: Action<{
  enrolmentId: string;
  userId: string;
  taskId: string;
}>) {
  try {
    yield call(courseEnrolmentService.progress, userId, taskId);
    yield put(progressCourseTask.success({ enrolmentId, taskId }));
  } catch (e: any) {
    if (e.message) yield put(progressCourseTask.failure(e.message));
    else yield put(progressCourseTask.failure(JSON.stringify(e)));
  }
}

function* editEnrolmentHandler({
  payload: { id, data }
}: Action<{
  id: string;
  data: Partial<CourseEnrolmentDto>;
}>) {
  try {
    const resData = yield call(courseEnrolmentService.update, id, data);
    yield put(editEnrolment.success({ id, data: resData }));
  } catch (e: any) {
    if (e.message) yield put(editEnrolment.failure(e.message));
    else yield put(editEnrolment.failure(JSON.stringify(e)));
  }
}

export function* courseEnrolmentSaga() {
  yield all([
    takeEvery(fetchCourseEnrolment.TRIGGER, fetchCourseEnrolmentHandler),
    takeEvery(fetchUserEnrolments.TRIGGER, fetchUserEnrolmentsHandler),
    takeEvery(progressCourseTask.TRIGGER, progressCourseTaskHandler),
    takeEvery(editEnrolment.TRIGGER, editEnrolmentHandler)
  ]);
}
