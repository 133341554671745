import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Paper,
  Title,
  Text,
  Box,
  Group,
  RingProgress,
  Button,
  Progress,
  Center,
  Space,
  Skeleton,
  Alert
} from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import { NSWHealthLHD } from 'enums/user-info';
import dayjs from 'dayjs';
import { Routes, UserRoles } from 'enums';
import { RootState } from 'types/state';
import { EventExtended } from 'types/backend';
import { add, isAfter, sub } from 'date-fns';
import { useEventCompletion } from 'features/events/api/get-event-completion';

type Props = {
  event: EventExtended;
  role: UserRoles;
  isHistorical?: boolean;
};

const getEventStatus = (event: EventExtended) => {
  if (!event.instances) return 'OPEN';

  const now = new Date();
  const start = event.instances[0].starts_at;
  const end = event.instances[event.instances.length - 1].ends_at;

  if (isAfter(now, add(end, { weeks: 3 }))) return 'ARCHIVED';
  if (isAfter(now, end)) return 'FINISHED';
  if (isAfter(now, sub(start, { days: 3 }))) return 'STARTING_SOON';
  if (event.ioi_signoff) return 'READY';
  if (isAfter(now, event.preferences_due)) return 'WITH_ADMIN';
  if (isAfter(now, event.eois_due)) return 'WITH_COORDINATOR';

  return 'OPEN';
};

const EventCardComponent: React.FC<Props> = ({ event, role, isHistorical }) => {
  const navigate = useNavigate(); // workaround to avoid nesting <a>

  const status = getEventStatus(event);
  const completion = useEventCompletion({
    eventId: event.id,
    queryConfig: {
      enabled:
        !isHistorical &&
        (status === 'READY' ||
          status === 'STARTING_SOON' ||
          status === 'FINISHED')
    }
  });

  const approvedEnrolments = event.enrolments.filter(
    enrolment =>
      enrolment.status === 'APPROVED' || enrolment.status === 'COMPLETE'
  );
  const attendedEnrolments = approvedEnrolments.filter(
    enrolment =>
      enrolment.attendance &&
      Object.values(enrolment.attendance).every(val => val === true)
  );
  const classSizeLimit =
    role === UserRoles.IOI_ADMIN || role === UserRoles.F2F_FACILITATOR
      ? event.class_size_limit
      : Math.floor(event.class_size_limit / event.lhds.length && 1);

  if (!event.instances || !event.enrolments) return null;

  return (
    <Paper
      withBorder
      shadow="sm"
      p="1rem"
      h={isHistorical ? '12rem' : '19rem'}
      sx={{ display: 'flex', flexDirection: 'column' }}
      component={Link}
      to={`${Routes.LHD}/${event.id}`}
    >
      {status === 'WITH_COORDINATOR' && (
        <Button
          color="orange"
          variant="light"
          display="block"
          ml="auto"
          mt="-2rem"
          uppercase
          onClick={() => navigate(`${Routes.LHD}/${event.id}/preferences`)}
        >
          Submit Preferences
        </Button>
      )}
      {status === 'STARTING_SOON' && (
        <Button
          color="orange"
          variant="light"
          display="block"
          ml="auto"
          mt="-2rem"
          uppercase
          onClick={() => navigate(`${Routes.LHD}/${event.id}/attendance`)}
        >
          Print Attendance
        </Button>
      )}

      <Title order={4} display="flex" sx={{ gap: '0.5rem' }}>
        <IconCalendar />
        {dayjs(event.instances[0].starts_at).format('DD MMM')}
        {event.instances.length > 1 &&
          dayjs(event.instances[event.instances.length - 1].starts_at).format(
            ' - DD MMM'
          )}
      </Title>

      <Title order={4} mb="sm" lineClamp={2}>
        {event.f2f.name}
      </Title>

      <Text size="sm" color="gray" mb="lg" lineClamp={2}>
        {event.lhds.map(lhd => NSWHealthLHD[lhd]).join(', ')}
      </Text>

      <Space sx={{ flex: 1 }} />

      {(status === 'OPEN' ||
        status === 'WITH_COORDINATOR' ||
        status === 'WITH_ADMIN') && (
        <Box my="md">
          <Box display="flex">
            <Text sx={{ flex: 1 }}>EOI Applications</Text>
            <Text color="blue">
              {event.enrolments.length} / {classSizeLimit}
            </Text>
          </Box>
          <Progress value={(100 * event.enrolments.length) / classSizeLimit} />
          {status === 'OPEN' && (
            <Text>
              EOIs are due on {dayjs(event.eois_due).format('DD MMM YYYY')}
            </Text>
          )}
          {status === 'WITH_COORDINATOR' && (
            <Text color="orange">
              Preferences are due on{' '}
              {dayjs(event.preferences_due).format('DD MMM YYYY')}
            </Text>
          )}
          {status === 'WITH_ADMIN' && (
            <Text>
              Participants will be notified of their confirmation status by
              InsideOut by {dayjs(event.preferences_due).format('DD MMM YYYY')}
            </Text>
          )}
        </Box>
      )}

      {completion.isLoading && <Skeleton height={120} />}

      {completion.isError && (
        <Alert color="red" title="Someting went wrong">
          Please try again
        </Alert>
      )}

      {completion.isSuccess &&
        (status === 'READY' || status === 'STARTING_SOON') && (
          <Group>
            <RingProgress
              size={120}
              label={
                <Center>
                  <RingProgress
                    size={80}
                    sections={[
                      {
                        value:
                          (100 * completion.data.data.before) /
                          approvedEnrolments.length,
                        color: 'blue'
                      }
                    ]}
                  />
                </Center>
              }
              sections={[
                {
                  value:
                    (100 * completion.data.data.immediately_before) /
                    approvedEnrolments.length,
                  color: 'teal'
                }
              ]}
            />
            <Box>
              <Text color="blue" fw="bold" sx={{ lineHeight: '1' }}>
                {completion.data.data.before} / {approvedEnrolments.length}
              </Text>
              <Text color="blue" size="sm" mb="sm">
                Pre-requisite completed
              </Text>

              <Text color="teal" fw="bold" sx={{ lineHeight: '1' }}>
                {completion.data.data.immediately_before} /{' '}
                {approvedEnrolments.length}
              </Text>
              <Text color="teal" size="sm">
                Pre-survey completed
              </Text>
            </Box>
          </Group>
        )}

      {completion.isSuccess && status === 'FINISHED' && (
        <Group>
          <RingProgress
            size={120}
            sections={[
              {
                value:
                  (100 * completion.data.data.after) /
                  attendedEnrolments.length,
                color: 'blue'
              }
            ]}
          />
          <Box>
            <Text color="blue" fw="bold" sx={{ lineHeight: '1' }}>
              {completion.data.data.after} / {attendedEnrolments.length}
            </Text>
            <Text color="blue" size="sm" mb="sm">
              Post-requisite completed
            </Text>
          </Box>
        </Group>
      )}
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export const EventCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCardComponent);
