import React from 'react';
import { EventDto, UpdateEventDto } from 'types/api/event';
import {
  TextInput,
  Textarea,
  MultiSelect,
  Stack,
  Group,
  Button,
  NumberInput,
  SimpleGrid
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { NSWHealthLHD } from 'enums/user-info';
import { RichTextEditor } from 'components/RichTextEditor';

type Props = {
  event: EventDto;
  updateEvent: (dto: UpdateEventDto) => void;
};

export const EventMetadata: React.FC<Props> = ({ event, updateEvent }) => {
  const form = useForm<UpdateEventDto>({
    initialValues: {
      id: event.id,
      name: event.name,
      details: event.details,
      lhds: event.lhds,
      classSizeLimit: event.classSizeLimit,
      eoisDue: new Date(event.eoisDue),
      preferencesDue: new Date(event.preferencesDue)
    }
  });

  return (
    <form onSubmit={form.onSubmit(values => updateEvent(values))}>
      <Stack>
        <TextInput label="Event name" {...form.getInputProps('name')} />

        <RichTextEditor
          my="md"
          label="Event details"
          field="details"
          form={form}
        />

        {/* <Textarea label="Event details" {...form.getInputProps('details')} /> */}

        <MultiSelect
          label="Local health districts"
          data={Object.entries(NSWHealthLHD).map(([value, label]) => ({
            label,
            value
          }))}
          {...form.getInputProps('lhds')}
        />

        <SimpleGrid cols={2}>
          <DateInput
            label="Expressions of Interest Due (Inclusive)"
            {...form.getInputProps('eoisDue')}
          />
          <DateInput
            label="Preferences Due (Inclusive)"
            {...form.getInputProps('preferencesDue')}
          />
        </SimpleGrid>

        <NumberInput
          label="Class size limit"
          {...form.getInputProps('classSizeLimit')}
        />

        <Group position="right">
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </form>
  );
};
