import React from 'react';
import { UserBasic } from 'types/state';
import { Box, Text } from '@mantine/core';

type Props = {
  basic: UserBasic;
};

export const DisplayBasic: React.FC<Props> = ({ basic }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          Name:{' '}
        </Text>
        {basic.firstName} {basic.lastName}
      </Text>
      <Text>
        <Text span fw="bold">
          Address:{' '}
        </Text>
        {basic.address}, {basic.city}, {basic.state}, {basic.postcode}, {basic.country}
      </Text>
    </Box>
  );
};
