import { fetchF2FType, fetchF2FTypes, createF2FType, upsertEmail, deleteEmail, createTask, reorderTask, deleteTask, updateTask, updateF2FType, uploadSplash } from './routines';
import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { f2fEmailService } from 'service/f2f-training-emails';
import { trainingService } from 'service/f2f-training';
import { CreateF2FTaskDto, CreateF2FTypeDto, F2FEmailDto, F2FTaskReorderDto, UpdateF2FTaskDto, UpdateF2FTypeDto } from 'types/api/face-to-face';
import { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { AdminRoutes } from 'enums';
import { f2fTaskService } from 'service/f2f-task';

function* fetchF2FTypesHandler() {
  try {
    const data = yield call(trainingService.fetchF2FTypes);
    yield put(fetchF2FTypes.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchF2FTypes.failure(e.message));
    else yield put(fetchF2FTypes.failure('Unknown error.'));
  }
}

function* fetchF2FTypeHandler({ payload: { id } }: Action<{ id: string }>) {
  try {
    const data = yield call(trainingService.fetchF2FType, id);
    yield put(fetchF2FType.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchF2FType.failure(e.message));
    else yield put(fetchF2FType.failure('Unknown error.'));
  }
}

function* createF2FTypeHandler({
  payload: { values, navigate }
}: Action<{
  values: CreateF2FTypeDto;
  navigate: NavigateFunction;
}>) {
  try {
    const data = yield call(trainingService.createF2FType, values);
    navigate(`${AdminRoutes.faceToFaceView}/${data.id}`);
    yield put(createF2FType.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createF2FType.failure(e.message));
    else yield put(createF2FType.failure('Unknown error.'));
  }
}

function* updateF2FTypeHandler({ payload }: Action<UpdateF2FTypeDto>) {
  try {
    const data = yield call(trainingService.updateF2FTraining, payload);
    yield put(updateF2FType.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateF2FType.failure(e.message));
    else yield put(updateF2FType.failure('Unknown error.'));
  }
}

function* upsertEmailHandler({
  payload: { typeId, dto }
}: Action<{
  typeId: string;
  dto: F2FEmailDto;
}>) {
  try {
    if (dto.id) {
      const data = yield call(f2fEmailService.update, dto.id, dto);
      yield put(upsertEmail.success({ typeId, data, insert: false }));
    } else {
      const data = yield call(f2fEmailService.create, typeId, dto);
      yield put(upsertEmail.success({ typeId, data, insert: true }));
    }
  } catch (e) {
    if (e instanceof AxiosError) yield put(upsertEmail.failure(e.message));
    else yield put(upsertEmail.failure('Unknown error.'));
  }
}

function* deleteEmailHandler({
  payload: { typeId, emailId }
}: Action<{
  typeId: string;
  emailId: string;
}>) {
  try {
    yield call(f2fEmailService.delete, emailId);
    yield put(deleteEmail.success({ typeId, emailId }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(deleteEmail.failure(e.message));
    else yield put(deleteEmail.failure('Unknown error.'));
  }
}

function* createTaskHandler({
  payload: { typeId, dto }
}: Action<{
  typeId: string;
  dto: CreateF2FTaskDto;
}>) {
  try {
    const task = yield call(f2fTaskService.create, typeId, dto);
    yield put(createTask.success({ typeId, task }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createTask.failure(e.message));
    else yield put(createTask.failure('Unknown error.'));
  }
}

function* updateTaskHandler({
  payload: { typeId, dto }
}: Action<{
  typeId: string;
  dto: UpdateF2FTaskDto;
}>) {
  try {
    const task = yield call(f2fTaskService.update, dto);
    yield put(updateTask.success({ typeId, task }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createTask.failure(e.message));
    else yield put(createTask.failure('Unknown error.'));
  }
}

function* reorderTaskHandler({
  payload: { typeId, dto }
}: Action<{
  typeId: string;
  dto: F2FTaskReorderDto;
}>) {
  try {
    const data = yield call(f2fTaskService.reorder, typeId, dto);
    yield put(
      reorderTask.success({
        typeId,
        BEFORE: data.BEFORE,
        IMMEDIATELY_BEFORE: data.IMMEDIATELY_BEFORE,
        AFTER: data.AFTER
      })
    );
  } catch (e) {
    if (e instanceof AxiosError) yield put(reorderTask.failure(e.message));
    else yield put(reorderTask.failure('Unknown error.'));
  }
}

function* deleteTaskHandler({
  payload: { typeId, taskId }
}: Action<{
  typeId: string;
  taskId: string;
}>) {
  try {
    yield call(f2fTaskService.delete, taskId);
    yield put(deleteTask.success({ typeId, taskId }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(deleteTask.failure(e.message));
    else yield put(deleteTask.failure('Unknown error.'));
  }
}

function* uploadSplashHandler({
  payload: { f2fId, file }
}: Action<{
  f2fId: string;
  file: File;
}>) {
  try {
    const data = yield call(trainingService.uploadSplash, f2fId, file);
    yield put(uploadSplash.success({ f2fId, splash: data.splash }));
  } catch (e) {
    if (e instanceof AxiosError) yield put(uploadSplash.failure(e.message));
    else yield put(uploadSplash.failure('Unknown error.'));
  }
}

export default function* faceToFaceSaga() {
  yield all([
    takeEvery(fetchF2FTypes.TRIGGER, fetchF2FTypesHandler),
    takeEvery(fetchF2FType.TRIGGER, fetchF2FTypeHandler),
    takeEvery(createF2FType.TRIGGER, createF2FTypeHandler),
    takeEvery(updateF2FType.TRIGGER, updateF2FTypeHandler),
    takeEvery(upsertEmail.TRIGGER, upsertEmailHandler),
    takeEvery(deleteEmail.TRIGGER, deleteEmailHandler),
    takeEvery(createTask.TRIGGER, createTaskHandler),
    takeEvery(updateTask.TRIGGER, updateTaskHandler),
    takeEvery(reorderTask.TRIGGER, reorderTaskHandler),
    takeEvery(deleteTask.TRIGGER, deleteTaskHandler),
    takeEvery(uploadSplash.TRIGGER, uploadSplashHandler)
  ]);
}
