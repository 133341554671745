import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { EventExtended } from 'types/backend';

export const getEvents = (): Promise<{ data: EventExtended[] }> => {
  return api.get('/event');
};

export const getEventsQueryOptions = () => {
  return queryOptions({
    queryKey: ['events'],
    queryFn: getEvents
  });
};

type UseEventsOptions = {
  queryConfig?: QueryConfig<typeof getEventsQueryOptions>;
};

export const useEvents = ({ queryConfig }: UseEventsOptions = {}) => {
  return useQuery({
    ...getEventsQueryOptions(),
    ...queryConfig
  });
};
