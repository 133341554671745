import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';

export const getPromoCodeValidity = ({
  code,
  courseId
}: {
  code: string;
  courseId: string;
}): Promise<{ data: boolean }> => {
  return api.get('/stripe/check-promo-code', {
    params: {
      code,
      courseId
    }
  });
};

export const getPromoCodeValidityQueryOptions = (
  code: string,
  courseId: string
) => {
  return queryOptions({
    queryKey: ['check-promo-code', code, courseId],
    queryFn: () => getPromoCodeValidity({ code, courseId })
  });
};

type UsePromoCodeValidityOptions = {
  code: string;
  courseId: string;
  queryConfig?: QueryConfig<typeof getPromoCodeValidityQueryOptions>;
};

export const usePromoCodeValidity = ({
  code,
  courseId,
  queryConfig
}: UsePromoCodeValidityOptions) => {
  return useQuery({
    ...getPromoCodeValidityQueryOptions(code, courseId),
    ...queryConfig
  });
};
