import { axiosAdapter } from 'service/axios-adapter';
import { AppResponse } from 'types/api';
import { FileDto, UploadFileResponseDto } from 'types/api/file';
import { rethrowApiError } from '../../util';

class UploadService {
  constructor() {
    this.uploadTraining = this.uploadTraining.bind(this);
    this.uploadHtmlEditorImage = this.uploadHtmlEditorImage.bind(this);
    this.uploadFlyerFile = this.uploadFlyerFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.removeFiles = this.removeFiles.bind(this);
  }

  async getAll(): Promise<FileDto[]> {
    const { data } = await axiosAdapter.doGet<AppResponse<FileDto[]>>({
      url: '/uploads'
    });
    return data.data;
  }

  async uploadFileForF2F(typeId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const { data } = await axiosAdapter.doPost<AppResponse<UploadFileResponseDto>>({
      url: `/uploads/f2f-materials/${typeId}`,
      payload: formData
    });
    return data.data;
  }

  async uploadTraining(file: File): Promise<UploadFileResponseDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
      const response = await axiosAdapter.doPost({
        url: '/uploads/training-materials',
        payload: formData
      });
      const axiosData: AppResponse<UploadFileResponseDto> = response.data;
      const { data } = axiosData;
      return data;
    } catch (e) {
      return rethrowApiError(e);
    }
  }

  async uploadScormPackage(file: File): Promise<UploadFileResponseDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const { data } = await axiosAdapter.doPost<AppResponse<UploadFileResponseDto>>({
      url: '/scorm/upload',
      payload: formData
    });
    return data.data;
  }

  async reuploadScorm({ file, id }: { file: File; id: string }): Promise<UploadFileResponseDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const { data } = await axiosAdapter.doPut<AppResponse<UploadFileResponseDto>>({
      url: `/scorm/upload/${id}`,
      payload: formData
    });
    return data.data;
  }

  async uploadHtmlEditorImage(file: File): Promise<UploadFileResponseDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
      const response = await axiosAdapter.doPost({
        url: '/uploads/html-editor-image',
        payload: formData
      });
      const axiosData: AppResponse<UploadFileResponseDto> = response.data;
      const { data } = axiosData;
      return data;
    } catch (e) {
      return rethrowApiError(e);
    }
  }

  async uploadFlyerFile(file: File): Promise<UploadFileResponseDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
      const response = await axiosAdapter.doPost({
        url: '/uploads/flyer',
        payload: formData
      });
      const axiosData: AppResponse<UploadFileResponseDto> = response.data;
      const { data } = axiosData;
      return data;
    } catch (e) {
      return rethrowApiError(e);
    }
  }

  async removeFile(id: string) {
    try {
      const response = await axiosAdapter.doDelete({
        url: `/uploads/${id}`
      });
      const axiosData: AppResponse<UploadFileResponseDto> = response.data;
      const { data } = axiosData;
      return data;
    } catch (e) {
      return rethrowApiError(e);
    }
  }

  async removeFiles(fileIds: string[]) {
    try {
      const response = await axiosAdapter.doDelete({
        url: '/uploads',
        payload: {
          fileIds
        }
      });
      const axiosData: AppResponse<UploadFileResponseDto> = response.data;
      const { data } = axiosData;
      return data;
    } catch (e) {
      return rethrowApiError(e);
    }
  }
}

export const uploadService = new UploadService();
