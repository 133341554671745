import React from 'react';
import { connect } from 'react-redux';
import { useForm } from '@mantine/form';
import { Button, Group, Radio, Collapse, Alert } from '@mantine/core';
import { setVic as setVicRoutine } from 'store/user/routines';
import { RootState, UserVic } from 'types/state';
import { VicHealthType, VicHealthRegion } from 'enums/user-info';

type VicForm = {
  isVicHealth: 'YES' | 'NO';
  type: keyof typeof VicHealthType;
  region: keyof typeof VicHealthRegion;
};

type Props = {
  isLoading: boolean;
  error?: string;
  vic: UserVic;
  setVic: (dto: UserVic) => void;
};

const OnboardingVicComponent: React.FC<Props> = ({ isLoading, error, vic, setVic }) => {
  const form = useForm<VicForm, (arg0: VicForm) => UserVic>({
    initialValues: vic
      ? {
          isVicHealth: vic.isVicHealth ? 'YES' : 'NO',
          type: vic.type,
          region: vic.region
        }
      : {
          isVicHealth: undefined,
          type: undefined,
          region: undefined
        },
    validate: {
      isVicHealth: value => (!value ? 'This field is required' : null),
      type: (value, values) => (!value && values.isVicHealth === 'YES' ? 'This field is required' : null),
      region: (value, values) => (!value && values.isVicHealth === 'YES' && values.type === 'REGION' ? 'This field is required' : null)
    },
    transformValues: values => ({
      isVicHealth: values.isVicHealth === 'YES',
      type: values.type,
      region: values.region
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setVic(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group label="Do you work for Vic Health?" {...form.getInputProps('isVicHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Collapse in={form.values.isVicHealth === 'YES'}>
        <Radio.Group label="Where in Vic Health?" {...form.getInputProps('type')}>
          {Object.entries(VicHealthType).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.isVicHealth === 'YES' && form.values.type === 'REGION'}>
        <Radio.Group label="Health Regions" {...form.getInputProps('region')}>
          {Object.entries(VicHealthRegion).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Group position="right">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Victoria Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  vic: state.user.vic
});

const mapDispatchToProps = {
  setVic: setVicRoutine
};

export const OnboardingVic = connect(mapStateToProps, mapDispatchToProps)(OnboardingVicComponent);
