/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { produce } from 'immer';
import { Routine } from 'redux-saga-routines';
import { getCourseExport, getEventParticipantsExport, getEventPreferencesExport, getF2FExport, getScormExport, getUserExport } from './routines';
import { DataExportState } from 'types/state';

const initialState: DataExportState = {
  isLoading: false,
  error: null
};

export const dataExportReducer = produce((state: DataExportState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case getCourseExport.TRIGGER:
    case getF2FExport.TRIGGER:
    case getScormExport.TRIGGER:
    case getUserExport.TRIGGER:
    case getEventParticipantsExport.TRIGGER:
    case getEventPreferencesExport.TRIGGER:
      state.isLoading = true;
      state.error = null;
      break;
    case getCourseExport.SUCCESS:
    case getF2FExport.SUCCESS:
    case getScormExport.SUCCESS:
    case getUserExport.SUCCESS:
    case getEventParticipantsExport.SUCCESS:
    case getEventPreferencesExport.SUCCESS:
      state.isLoading = false;
      break;
    case getCourseExport.FAILURE:
    case getF2FExport.FAILURE:
    case getScormExport.FAILURE:
    case getUserExport.FAILURE:
    case getEventParticipantsExport.FAILURE:
    case getEventPreferencesExport.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
