import React from 'react';
import { connect } from 'react-redux';
import { useForm } from '@mantine/form';
import { Button, Group, Radio, Collapse, Alert } from '@mantine/core';
import { setWA as setWARoutine } from 'store/user/routines';
import { RootState, UserWA } from 'types/state';
import { WAHealthType, WAHealthRegion } from 'enums/user-info';

type WAForm = {
  isWAHealth: 'YES' | 'NO';
  type: keyof typeof WAHealthType;
  region: keyof typeof WAHealthRegion;
};

type Props = {
  isLoading: boolean;
  error?: string;
  wa: UserWA;
  setWA: (dto: UserWA) => void;
};

const OnboardingWAComponent: React.FC<Props> = ({ isLoading, error, wa, setWA }) => {
  const form = useForm<WAForm, (arg0: WAForm) => UserWA>({
    initialValues: wa
      ? {
          isWAHealth: wa.isWAHealth ? 'YES' : 'NO',
          type: wa.type,
          region: wa.region
        }
      : {
          isWAHealth: undefined,
          type: undefined,
          region: undefined
        },
    validate: {
      isWAHealth: value => (!value ? 'This field is required' : null),
      type: (value, values) => (!value && values.isWAHealth === 'YES' ? 'This field is required' : null),
      region: (value, values) => (!value && values.isWAHealth === 'YES' && values.type === 'REGION' ? 'This field is required' : null)
    },
    transformValues: values => ({
      isWAHealth: values.isWAHealth === 'YES',
      type: values.type,
      region: values.region
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setWA(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Do you work for WA Health?" required {...form.getInputProps('isWAHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Collapse in={form.values.isWAHealth === 'YES'} my="lg">
        <Radio.Group label="Where in WA Health?" {...form.getInputProps('type')}>
          {Object.entries(WAHealthType).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.isWAHealth === 'YES' && form.values.type === 'REGION'} my="lg">
        <Radio.Group label="Health Regions" {...form.getInputProps('region')}>
          {Object.entries(WAHealthRegion).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Western Australia Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  wa: state.user.wa
});

const mapDispatchToProps = {
  setWA: setWARoutine
};

export const OnboardingWA = connect(mapStateToProps, mapDispatchToProps)(OnboardingWAComponent);
