import React from 'react';
import { connect } from 'react-redux';
import { updateCourse as updateCourseRoutine } from 'store/course/routines';
import { Container, TextInput, Box, Grid, Button, Group } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { CourseDto } from 'types/api/course';
import { randomId } from '@mantine/hooks';

type Props = {
  course: CourseDto;
  updateCourse: (arg0: Partial<CourseDto>) => void;
};

const CourseResourcesComponent: React.FC<Props> = ({ course, updateCourse }) => {
  const form = useForm<Partial<CourseDto>>({
    initialValues: {
      id: course.id,
      resources: course.resources.map(page => ({
        id: page.id,
        title: page.title,
        resources: page.resources.map(resource => ({ ...resource }))
      }))
    }
  });
  return (
    <Container size="md" my="xl">
      <form onSubmit={form.onSubmit(updateCourse)}>
        <Button leftIcon={<IconPlus />} onClick={() => form.insertListItem('resources', { id: randomId(), title: '', resources: [] })}>
          Add Section
        </Button>
        {form.values.resources.map((page, index) => (
          <Box key={page.id} my="lg">
            <Grid align="end" mb="md">
              <Grid.Col span={1}>
                <Button color="red" variant="outline" fullWidth onClick={() => form.removeListItem('resources', index)}>
                  <IconTrash />
                </Button>
              </Grid.Col>
              <Grid.Col span={9}>
                <TextInput label="Section Heading" {...form.getInputProps(`resources.${index}.title`)} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Button leftIcon={<IconPlus />} fullWidth onClick={() => form.insertListItem(`resources.${index}.resources`, { id: randomId(), text: '', link: '' })}>
                  Add Resource
                </Button>
              </Grid.Col>
            </Grid>
            {page.resources.map((resource, jndex) => (
              <Grid key={resource.id} my="md">
                <Grid.Col span={5}>
                  <TextInput placeholder="Text" {...form.getInputProps(`resources.${index}.resources.${jndex}.text`)} />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput placeholder="Link" {...form.getInputProps(`resources.${index}.resources.${jndex}.link`)} />
                </Grid.Col>
                <Grid.Col span={1}>
                  <Button color="red" variant="outline" fullWidth onClick={() => form.removeListItem(`resources.${index}.resources`, jndex)}>
                    <IconTrash />
                  </Button>
                </Grid.Col>
              </Grid>
            ))}
          </Box>
        ))}
        <Group position="right" my="lg">
          <Button color="red" uppercase type="submit">
            Save Changes
          </Button>
        </Group>
      </form>
    </Container>
  );
};

const mapDispatchToProps = {
  updateCourse: updateCourseRoutine
};

export const CourseResources = connect(null, mapDispatchToProps)(CourseResourcesComponent);
