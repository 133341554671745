import { queryOptions, useQuery } from '@tanstack/react-query';
import { api } from 'lib/api-client';
import { QueryConfig } from 'lib/react-query';
import { Course } from 'types/backend';

export const getCourses = (): Promise<{ data: Array<Course> }> => {
  return api.get('/course-new');
};

export const getCoursesQueryOptions = () => {
  return queryOptions({
    queryKey: ['course'],
    queryFn: getCourses
  });
};

type UseCoursesOptions = {
  queryConfig?: QueryConfig<typeof getCoursesQueryOptions>;
};

export const useCourses = ({ queryConfig }: UseCoursesOptions = {}) => {
  return useQuery({
    ...getCoursesQueryOptions(),
    ...queryConfig
  });
};
