import React from 'react';
import { TextInput, Group, Button, Select, SegmentedControl, NumberInput } from '@mantine/core';
import { useForm, isNotEmpty, isInRange } from '@mantine/form';
import { F2FEmailCondition, F2FEmailWhen } from 'enums/training';
import { F2FEmailDto } from 'types/api/face-to-face';
import { RichTextEditor } from 'components/RichTextEditor';

type Props = {
  typeId: string;
  email: F2FEmailDto;
  upsertEmail: (arg0: { typeId: string; dto: F2FEmailDto }) => void;
  deleteEmail: (arg0: { typeId: string; emailId: string }) => void;
  close: () => void;
};

export const EmailEditor: React.FC<Props> = ({ typeId, email, upsertEmail, deleteEmail, close }) => {
  const form = useForm<F2FEmailDto>({
    initialValues: email
      ? {
          id: email.id,
          subject: email.subject,
          body: email.body,
          when: email.when,
          condition: email.condition,
          delay: email.delay
        }
      : {
          subject: '',
          body: '',
          when: 'PRE_EVENT',
          condition: 'ALWAYS',
          delay: 1
        },
    validate: {
      subject: isNotEmpty('This field is required.'),
      body: isNotEmpty('This field is required.'),
      delay: isInRange({ min: 1, max: 100 }, 'Must be between 1 and 100')
    }
  });

  return (
    <form
      onSubmit={form.onSubmit(values => {
        upsertEmail({ typeId, dto: values });
        close();
      })}
    >
      <TextInput mb="md" label="Email subject" {...form.getInputProps('subject')} />

      <RichTextEditor
        my="md"
        label="Email Body"
        field="body"
        form={form}
        tokens={[
          { name: 'User Name', value: '{{ user.firstName }}' },
          { name: 'Event Name', value: '{{ event.name }}' },
          { name: 'Event Types', value: '{{ eventTypes }}' },
          { name: 'F2F Name', value: '{{ f2f.name }}' },
          { name: 'Times & Locations', value: '{{ timesAndLocations }}' },
          { name: 'Pre-Event Link', value: '{{ preEventDSL }}' },
          { name: 'Post-Event Link', value: '{{ postEventDSL }}' }
        ]}
      />

      <SegmentedControl my="md" data={Object.entries(F2FEmailWhen).map(([value, label]) => ({ label, value }))} {...form.getInputProps('when')} />

      <NumberInput my="md" label={`Days ${form.values.when === 'PRE_EVENT' ? 'before' : 'after'} event to send email`} min={1} max={100} {...form.getInputProps('delay')} />

      <Select my="md" label="Send this email when" data={Object.entries(F2FEmailCondition).map(([value, label]) => ({ label, value }))} {...form.getInputProps('condition')} />

      <Group position="right" my="md">
        {email?.id && (
          <Button
            color="red"
            onClick={() => {
              deleteEmail({ typeId, emailId: email.id });
              close();
            }}
          >
            Delete Email
          </Button>
        )}
        <Button type="submit">{email?.id ? 'Save Changes' : 'Create Email'}</Button>
      </Group>
    </form>
  );
};
