import React from 'react';
import { connect } from 'react-redux';
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, Radio, Collapse, TextInput, Select, Checkbox, Alert } from '@mantine/core';
import { setEmployment as setEmploymentRoutine } from 'store/user/routines';
import { RootState, UserEmployment } from 'types/state';
import { CommunityMentalHealthCentre, EatingDisorderTreatmentService, EducationEnvironment, EmploymentType, Headspace, HeadToHealth, HospitalSetting, PrimaryHealthNetwork } from 'enums/user-info';
import { employmentTypeNeedsName } from 'enums/user-info/employment.type.needs.name';
import { phnsByState } from 'enums/user-info/phns.by.state';
import { State } from 'enums/user-info/state.enum';

type EmploymentForm = {
  type: keyof typeof EmploymentType;
  headToHealth: keyof typeof HeadToHealth;
  phn: keyof typeof PrimaryHealthNetwork;
  companyName: string;
  headspace: keyof typeof Headspace;
  hospitalSetting: (keyof typeof HospitalSetting)[];
  educationEnvironment: keyof typeof EducationEnvironment;
  communityMentalHealthCentre: keyof typeof CommunityMentalHealthCentre;
  eatingDisorderTreatmentService: (keyof typeof EatingDisorderTreatmentService)[];
  hasSecondary: 'YES' | 'NO';
};

type Props = {
  isLoading: boolean;
  error?: string;
  primary?: boolean;
  primaryEmployment: UserEmployment;
  secondaryEmployment: UserEmployment;
  setEmployment: (dto: UserEmployment) => void;
  state: keyof typeof State;
};

const OnboardingEmploymentComponent: React.FC<Props> = ({ isLoading, error, primary = false, primaryEmployment, secondaryEmployment, setEmployment, state }) => {
  const employment = primary ? primaryEmployment : secondaryEmployment;

  const form = useForm<EmploymentForm, (arg0: EmploymentForm) => UserEmployment>({
    initialValues: employment
      ? {
          type: employment.type,
          headToHealth: employment.headToHealth,
          phn: employment.phn,
          companyName: employment.companyName || undefined,
          headspace: employment.headspace,
          hospitalSetting: employment.hospitalSetting || [],
          educationEnvironment: employment.educationEnvironment,
          communityMentalHealthCentre: employment.communityMentalHealthCentre,
          eatingDisorderTreatmentService: employment.eatingDisorderTreatmentService || [],
          hasSecondary: employment.hasSecondary ? 'YES' : 'NO'
        }
      : {
          type: undefined,
          headToHealth: undefined,
          phn: undefined,
          companyName: undefined,
          headspace: undefined,
          hospitalSetting: [],
          educationEnvironment: undefined,
          communityMentalHealthCentre: undefined,
          eatingDisorderTreatmentService: [],
          hasSecondary: undefined
        },
    validate: {
      type: isNotEmpty('This field is required'),
      headToHealth: (value, values) => (!value && values.type === 'HEAD_TO_HEALTH' ? 'This field is required' : null),
      phn: (value, values) => (!value && values.type === 'PRIMARY_HEALTH_NETWORK' ? 'This field is required' : null),
      companyName: (value, values) => (!value && employmentTypeNeedsName.includes(values.type) ? 'This field is required' : null),
      headspace: (value, values) => (!value && values.type === 'HEADSPACE' ? 'This field is required' : null),
      hospitalSetting: (value, values) => (value?.length === 0 && values.type === 'HOSPITAL_SETTING' ? 'This field is required' : null),
      educationEnvironment: (value, values) => (!value && values.type === 'EDUCATION_TEACHING_ENVIRONMENT' ? 'This field is required' : null),
      communityMentalHealthCentre: (value, values) => (!value && values.type === 'COMMUNITY_MENTAL_HEALTH_CENTRE' ? 'This field is required' : null),
      eatingDisorderTreatmentService: (value, values) => (value?.length === 0 && values.type === 'EATING_DISORDER_TREATMENT_SERVICE' ? 'This field is required' : null),
      hasSecondary: value => (!value && primary ? 'This field is required' : null)
    },
    transformValues: values => ({
      ...values,
      isPrimary: primary,
      hasSecondary: values.hasSecondary === 'YES',
      hospitalSetting: values.hospitalSetting.length > 0 ? values.hospitalSetting : undefined,
      eatingDisorderTreatmentService: values.eatingDisorderTreatmentService.length > 0 ? values.eatingDisorderTreatmentService : undefined
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setEmployment(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label={primary ? 'Where do you primarily work?' : 'Where do you secondarily work?'} {...form.getInputProps('type')}>
        {Object.entries(EmploymentType).map(([value, label]) => (
          <Radio key={value} {...{ value, label }} />
        ))}
      </Radio.Group>

      <Collapse in={form.values.type === 'HEAD_TO_HEALTH'} my="lg">
        <Radio.Group label="Which Head to Health?" {...form.getInputProps('headToHealth')}>
          {Object.entries(HeadToHealth).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.type === 'PRIMARY_HEALTH_NETWORK'} my="lg">
        <Radio.Group label="Which PHN?" {...form.getInputProps('headToHealth')}>
          {Object.keys(PrimaryHealthNetwork)
            .filter(value => !state || phnsByState[state].includes(value as keyof typeof PrimaryHealthNetwork))
            .map(value => (
              <Radio key={value} value={value} label={PrimaryHealthNetwork[value]} />
            ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={employmentTypeNeedsName.includes(form.values.type)} my="lg">
        <TextInput label="Team / Organisation / Company Name" {...form.getInputProps('companyName')} />
      </Collapse>

      <Collapse in={form.values.type === 'HEADSPACE'} my="lg">
        <Select label="Which Headspace?" data={Object.entries(Headspace).map(([value, label]) => ({ value, label }))} searchable {...form.getInputProps('headspace')} />
      </Collapse>

      <Collapse in={form.values.type === 'HOSPITAL_SETTING'} my="lg">
        <Checkbox.Group label="Which hospital setting? (select all that apply)" {...form.getInputProps('hospitalSetting')}>
          {Object.entries(HospitalSetting).map(([value, label]) => (
            <Checkbox key={value} {...{ value, label }} />
          ))}
        </Checkbox.Group>
      </Collapse>

      <Collapse in={form.values.type === 'EDUCATION_TEACHING_ENVIRONMENT'} my="lg">
        <Radio.Group label="Education / Teaching Environment" {...form.getInputProps('educationEnvironment')}>
          {Object.entries(EducationEnvironment).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.type === 'COMMUNITY_MENTAL_HEALTH_CENTRE'} my="lg">
        <Radio.Group label="Which Community Mental Health Centre" {...form.getInputProps('communityMentalHealthCentre')}>
          {Object.entries(CommunityMentalHealthCentre).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.type === 'EATING_DISORDER_TREATMENT_SERVICE'} my="lg">
        <Checkbox.Group label="What type of Eating Disorder Treatment Service?" {...form.getInputProps('eatingDisorderTreatmentService')}>
          {Object.entries(EatingDisorderTreatmentService).map(([value, label]) => (
            <Checkbox key={value} {...{ value, label }} />
          ))}
        </Checkbox.Group>
      </Collapse>

      <Collapse in={primary} my="lg">
        <Radio.Group label="Do you have a secondary place of employment?" {...form.getInputProps('hasSecondary')}>
          <Radio value="YES" label="Yes" />
          <Radio value="NO" label="No" />
        </Radio.Group>
      </Collapse>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update {primary ? 'Primary' : 'Secondary'} Employment Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  primaryEmployment: state.user.primaryEmployment,
  secondaryEmployment: state.user.secondaryEmployment
});

const mapDispatchToProps = {
  setEmployment: setEmploymentRoutine
};

export const OnboardingEmployment = connect(mapStateToProps, mapDispatchToProps)(OnboardingEmploymentComponent);
