import { Alert, Button, Group, LoadingOverlay, Table, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { AdminRoutes, UserRoles } from 'enums';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchF2FTypes as fetchF2FTypesRoutine } from 'store/face-to-face/routines';
import { F2FTypeIndex, RootState } from 'types/state';

type Props = {
  role: UserRoles;
  types: F2FTypeIndex;
  error?: string;
  isLoading: boolean;
  fetchF2FTypes: () => void;
};

const F2FTypeListComponent: React.FC<Props> = ({ role, types, error, isLoading, fetchF2FTypes }) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchF2FTypes();
  }, []);

  const typeList = Object.values(types).sort((a, b) => (a.name < b.name ? -1 : 1));

  return (
    <>
      <Group my="xl">
        <Title order={2} sx={{ flex: '1 !important' }}>
          Face to Face Types
        </Title>
        {role === UserRoles.IOI_ADMIN && (
          <Button component={Link} to={AdminRoutes.faceToFaceNew} leftIcon={<IconPlus />}>
            Create New
          </Button>
        )}
      </Group>

      {error && (
        <Alert title="Error fetching face to face types" color="red" my="lg">
          {error}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {typeList.map(type => (
            <tr key={type.id} onClick={() => navigate(`${AdminRoutes.faceToFaceView}/${type.id}`)}>
              <td>{type.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  role: state.user.role,
  types: state.faceToFace.types,
  error: state.faceToFace.error,
  isLoading: state.faceToFace.isLoading
});

const mapDispatchToProps = {
  fetchF2FTypes: fetchF2FTypesRoutine
};

export const F2FTypeList = connect(mapStateToProps, mapDispatchToProps)(F2FTypeListComponent);
