export enum QldHealthHSD {
  TORRES_STRAIT_NORTHERN_PENINSULA = 'Torres Strait - Northern Peninsula',
  CAPE_YORK = 'Cape York',
  CAIRNS_AND_HINTERLAND = 'Cairns and Hinterland',
  MOUNT_ISA = 'Mount Isa',
  TOWNSVILLE = 'Townsville',
  CENTRAL_WEST = 'Central West',
  MACKAY = 'MacKay',
  CENTRAL_QLD = 'Central QLD',
  SOUTH_WEST = 'South West',
  WIDE_BAY = 'Wide Bay',
  DARLING_DOWNS_WEST_MORETON = 'Darling Downs - West Moreton',
  METRO_NORTH = 'Metro North',
  METRO_SOUTH = 'Metro South',
  GOLD_COAST = 'Gold Coast',
  SUNSHINE_COAST = 'Sunshine Coast'
}
