import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserWA } from 'types/state';
import { WAHealthRegion, WAHealthType } from 'enums/user-info';

type Props = {
  wa: UserWA;
};

export const DisplayWA: React.FC<Props> = ({ wa }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          {wa.isWAHealth ? 'Does' : 'Does not'}{' '}
        </Text>
        work for WA Health.
      </Text>
      {wa.isWAHealth && (
        <Text>
          <Text span fw="bold">
            Where in WA Health:{' '}
          </Text>
          {WAHealthType[wa.type]}
        </Text>
      )}
      {wa.type === 'REGION' && (
        <Text>
          <Text span fw="bold">
            Which Region:{' '}
          </Text>
          {WAHealthRegion[wa.region]}
        </Text>
      )}
    </Box>
  );
};
