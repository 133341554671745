import React from 'react';
import { connect } from 'react-redux';
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, Radio, Checkbox, TextInput, Select, Collapse, Alert } from '@mantine/core';
import { setPersonal as setPersonalRoutine } from 'store/user/routines';
import { RootState, UserPersonal } from 'types/state';
import { Aboriginal, Age, Gender, Identity, Language } from 'enums/user-info';

type Props = {
  isLoading: boolean;
  error?: string;
  personal: UserPersonal;
  setPersonal: (dto: UserPersonal) => void;
};

const OnboardingPersonalComponent: React.FC<Props> = ({ isLoading, error, personal, setPersonal }) => {
  const form = useForm<UserPersonal>({
    initialValues: personal
      ? {
          aboriginal: personal.aboriginal,
          gender: personal.gender,
          age: personal.age,
          identity: personal.identity || [],
          language: personal.language,
          languageOther: personal.languageOther || ''
        }
      : {
          aboriginal: undefined,
          gender: undefined,
          age: undefined,
          identity: [],
          language: undefined,
          languageOther: undefined
        },
    validate: {
      aboriginal: isNotEmpty('This field is required'),
      gender: isNotEmpty('This field is required'),
      age: isNotEmpty('This field is required'),
      identity: isNotEmpty('This field is required'),
      language: isNotEmpty('This field is required'),
      languageOther: (value, values) => (!value && values.language === 'OTHER' ? 'This field is required' : null)
    }
  });

  return (
    <form onSubmit={form.onSubmit(values => setPersonal(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Are you of Aboriginal and/or Torres Strait Islander descent?" {...form.getInputProps('aboriginal')}>
        {Object.entries(Aboriginal).map(([value, label]) => (
          <Radio key={value} value={value} label={label} />
        ))}
      </Radio.Group>

      <Radio.Group my="lg" label="Gender" {...form.getInputProps('gender')}>
        {Object.entries(Gender).map(([value, label]) => (
          <Radio key={value} value={value} label={label} />
        ))}
      </Radio.Group>

      <Select my="lg" label="Age" {...form.getInputProps('age')} data={Object.entries(Age).map(([value, label]) => ({ value, label }))} />

      <Checkbox.Group
        my="lg"
        label="Do you identify with any of the following?"
        value={form.values.identity}
        error={form.errors.identity}
        onChange={(value: (keyof typeof Identity)[]) => {
          // is there a less painful way of doing this?
          if (value.includes('PREFER_NOT_TO_SAY')) {
            if (form.values.identity.includes('PREFER_NOT_TO_SAY')) {
              form.setValues({ identity: value.filter(s => s !== 'PREFER_NOT_TO_SAY') });
            } else {
              form.setValues({ identity: ['PREFER_NOT_TO_SAY'] });
            }
          } else if (value.includes('NONE')) {
            if (form.values.identity.includes('NONE')) {
              form.setValues({ identity: value.filter(s => s !== 'NONE') });
            } else {
              form.setValues({ identity: ['NONE'] });
            }
          } else {
            form.setValues({ identity: value });
          }
        }}
      >
        {Object.entries(Identity).map(([value, label]) => (
          <Checkbox key={value} label={label} value={value} />
        ))}
      </Checkbox.Group>

      <Radio.Group my="lg" label="Do you speak a language other than English? (Please select your primary other language, if more than one)" {...form.getInputProps('language')}>
        {Object.entries(Language).map(([value, label]) => (
          <Radio key={value} value={value} label={label} />
        ))}
      </Radio.Group>

      <Collapse in={form.values.language === 'OTHER'} my="lg">
        <TextInput label="Please specify" {...form.getInputProps('languageOther')} />
      </Collapse>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Personal Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  personal: state.user.personal
});

const mapDispatchToProps = {
  setPersonal: setPersonalRoutine
};

export const OnboardingPersonal = connect(mapStateToProps, mapDispatchToProps)(OnboardingPersonalComponent);
