import React from 'react';
import { Link } from 'react-router-dom';
import { Title, Text, Container, Group, Button, Box } from '@mantine/core';
import { Routes } from 'enums';
import { GuestLayout } from 'components/GuestLayout';
import landingBackground from 'assets/images/background-landing.svg';

export const Landing: React.FC = () => {
  return (
    <GuestLayout
      styles={theme => ({
        root: {
          backgroundColor: theme.colors.gray[0]
        },
        main: {
          padding: 0
        }
      })}
    >
      <Box
        sx={{
          backgroundImage: `url(${landingBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionY: 'bottom'
        }}
      >
        <Container size="md" py="16rem">
          <Title order={1} my="xl">
            eLearning Hub for Eating Disorders
          </Title>

          <Text my="xl">InsideOut is a leader in developing and disseminating innovative training for health professionals in the identification, assessment and treatment of people with eating disorders.</Text>

          <Group>
            <Button component={Link} to={Routes.Login} size="lg" color="cyan">
              Log In
            </Button>
            <Button component={Link} to={Routes.CourseStore} variant="light" size="lg" color="cyan">
              Browse Courses
            </Button>
          </Group>
        </Container>
      </Box>
    </GuestLayout>
  );
};
