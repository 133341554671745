import React from 'react';
import { Box, Text } from '@mantine/core';
import { UserExperience } from 'types/state';
import { ExperienceWithEatingDisorder, YearsInPractice } from 'enums/user-info';

type Props = {
  experience: UserExperience;
};

export const DisplayExperience: React.FC<Props> = ({ experience }) => {
  return (
    <Box my="lg">
      <Text>
        <Text span fw="bold">
          How many years have you been in professional practice:{' '}
        </Text>
        {YearsInPractice[experience.yearsInPractice]}
      </Text>
      <Text>
        <Text span fw="bold">
          How many years have you been working with people with an eating disorder:{' '}
        </Text>
        {ExperienceWithEatingDisorder[experience.experienceWithEatingDisorder]}
      </Text>
    </Box>
  );
};
