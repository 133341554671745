import React from 'react';
import { connect } from 'react-redux';
import { useForm } from '@mantine/form';
import { Button, Group, Radio, Collapse, Alert } from '@mantine/core';
import { setQld as setQldRoutine } from 'store/user/routines';
import { RootState, UserQld } from 'types/state';
import { QldHealthType, QldHealthHSD } from 'enums/user-info';

type QldForm = {
  isQldHealth: 'YES' | 'NO';
  type: keyof typeof QldHealthType;
  hsd: keyof typeof QldHealthHSD;
};

type Props = {
  isLoading: boolean;
  error?: string;
  qld: UserQld;
  setQld: (dto: UserQld) => void;
};

const OnboardingQldComponent: React.FC<Props> = ({ isLoading, error, qld, setQld }) => {
  const form = useForm<QldForm, (arg0: QldForm) => UserQld>({
    initialValues: qld
      ? {
          isQldHealth: qld.isQldHealth ? 'YES' : 'NO',
          type: qld.type,
          hsd: qld.hsd
        }
      : {
          isQldHealth: undefined,
          type: undefined,
          hsd: undefined
        },
    validate: {
      isQldHealth: value => (!value ? 'This field is required' : null),
      type: (value, values) => (!value && values.isQldHealth === 'YES' ? 'This field is required' : null),
      hsd: (value, values) => (!value && values.isQldHealth === 'YES' && values.type === 'HSD' ? 'This field is required' : null)
    },
    transformValues: values => ({
      isQldHealth: values.isQldHealth === 'YES',
      type: values.type,
      hsd: values.hsd
    })
  });

  return (
    <form onSubmit={form.onSubmit(values => setQld(values))}>
      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}
      <Radio.Group my="lg" label="Do you work for Qld Health?" required {...form.getInputProps('isQldHealth')}>
        <Radio value="YES" label="Yes" />
        <Radio value="NO" label="No" />
      </Radio.Group>

      <Collapse in={form.values.isQldHealth === 'YES'} my="lg">
        <Radio.Group label="Where in Qld Health?" {...form.getInputProps('type')}>
          {Object.entries(QldHealthType).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Collapse in={form.values.isQldHealth === 'YES' && form.values.type === 'HSD'} my="lg">
        <Radio.Group label="Health Service Districts" {...form.getInputProps('hsd')}>
          {Object.entries(QldHealthHSD).map(([value, label]) => (
            <Radio key={value} {...{ value, label }} />
          ))}
        </Radio.Group>
      </Collapse>

      <Group position="right" my="lg">
        <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
          Update Queensland Information
        </Button>
      </Group>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  qld: state.user.qld
});

const mapDispatchToProps = {
  setQld: setQldRoutine
};

export const OnboardingQld = connect(mapStateToProps, mapDispatchToProps)(OnboardingQldComponent);
