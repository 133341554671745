import React from 'react';
import { Paper, Title, Text, Accordion, ThemeIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { SurveyAnswerDto, SurveyDto } from 'types/api/survey';

type Props = {
  title: string;
  survey: SurveyDto;
  answer: SurveyAnswerDto;
};

export const SurveyAnswerCard: React.FC<Props> = ({ title, survey, answer }) => {
  return (
    <Paper withBorder my="md">
      <Accordion
        variant="filled"
        styles={theme => ({
          item: {
            '&[data-active]': {
              background: theme.white
            }
          },
          label: {
            display: 'flex',
            alignItems: 'center'
          }
        })}
      >
        <Accordion.Item value={survey.id}>
          <Accordion.Control>
            <ThemeIcon color="green" radius="lg" size="xl" variant="filled">
              <IconCheck />
            </ThemeIcon>
            <Title order={2} ml="xl">
              {title}
            </Title>
          </Accordion.Control>

          <Accordion.Panel>
            {answer.answers.map(qa => {
              const question = survey.questions.find(q => q.id === qa.questionId);
              if (!question) return false;

              switch (question.type) {
                case 'TEXT':
                  return (
                    <div key={qa.id}>
                      <Text fw="bold">{question.text}</Text>
                      <Text>{qa.value.text}</Text>
                    </div>
                  );
                case 'NUMBER':
                  return (
                    <div key={qa.id}>
                      <Text fw="bold">{question.text}</Text>
                      <Text>{qa.value.value}</Text>
                    </div>
                  );
                case 'RADIO':
                  return (
                    <div key={qa.id}>
                      <Text fw="bold">{question.text}</Text>
                      <Text>
                        {qa.value.text}
                        {qa.value.other && ` (${qa.value.other})`}
                      </Text>
                    </div>
                  );
                case 'LIKERT':
                  return (
                    <div key={qa.id}>
                      <Text fw="bold">{question.text}</Text>
                      <Text>{qa.value.text}</Text>
                    </div>
                  );
                case 'CHECKBOX':
                  return (
                    <div key={qa.id}>
                      <Text fw="bold">{question.text}</Text>
                      {qa.value.multiple.map(v => (
                        <Text key={v.text}>{v.text}</Text>
                      ))}
                      {qa.value.other && <Text>OTHER ({qa.value.other})</Text>}
                    </div>
                  );
                default:
                  return false;
              }
            })}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Paper>
  );
};
