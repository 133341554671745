import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchF2FType as fetchF2FTypeRoutine, upsertEmail as upsertEmailRoutine, deleteEmail as deleteEmailRoutine } from 'store/face-to-face/routines';
import { F2FTypeIndex, RootState } from 'types/state';
import { F2FEmailDto } from 'types/api/face-to-face';
import { Alert, LoadingOverlay, Title, Text, Tabs, Image, Group } from '@mantine/core';
import { Builder } from './Builder';
import { Certificate } from './Certificate';
import { Emails } from './Emails';
import { Events } from './Events';
import { Metadata } from './Metadata';
import { F2FResources } from './F2FResources';
import { QRCodes } from './QRCodes';
import { SurveyAnswers } from './SurveyAnswers';

type Props = {
  types: F2FTypeIndex;
  isLoading: boolean;
  error?: string;
  fetchF2FType: (arg0: { id: string }) => void;
  upsertEmail: (arg0: { typeId: string; dto: F2FEmailDto }) => void;
  deleteEmail: (arg0: { typeId: string; emailId: string }) => void;
};

const F2FTypeViewComponent: React.FC<Props> = ({ types, isLoading, error, fetchF2FType, upsertEmail, deleteEmail }) => {
  const { id } = useParams();

  useEffect(() => {
    fetchF2FType({ id });
  }, [id]);

  const type = types[id];

  // FIXME we can do better than this
  if (!type) return <LoadingOverlay visible />;

  return (
    <>
      <Group>
        <Image src={type.splash} width="250px" height="150px" />
        <div>
          <Title order={2}>{type.name}</Title>
          <Text>{type.description}</Text>
        </div>
      </Group>

      {error && (
        <Alert title="Error loading face to face type" color="red" my="lg">
          {error}
        </Alert>
      )}

      <Tabs defaultValue="builder" my="lg" keepMounted={false}>
        <Tabs.List>
          <Tabs.Tab value="builder">Workflow Builder</Tabs.Tab>
          <Tabs.Tab value="certificate">Certificate</Tabs.Tab>
          <Tabs.Tab value="email">Email</Tabs.Tab>
          <Tabs.Tab value="resources">Resources</Tabs.Tab>
          <Tabs.Tab value="events">Events</Tabs.Tab>
          <Tabs.Tab value="metadata">Metadata</Tabs.Tab>
          <Tabs.Tab value="qr_codes">QR Codes</Tabs.Tab>
          <Tabs.Tab value="survey_answers">Survey Answers</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="builder" my="lg">
          <Builder id={id} />
        </Tabs.Panel>
        <Tabs.Panel value="certificate" my="lg">
          <Certificate type={type} />
        </Tabs.Panel>
        <Tabs.Panel value="email" my="lg">
          <Emails type={type} upsertEmail={upsertEmail} deleteEmail={deleteEmail} />
        </Tabs.Panel>
        <Tabs.Panel value="resources" my="lg">
          <F2FResources f2f={type} />
        </Tabs.Panel>
        <Tabs.Panel value="events" my="lg">
          <Events typeId={type.id} events={type.events} />
        </Tabs.Panel>
        <Tabs.Panel value="metadata" my="lg">
          <Metadata type={type} />
        </Tabs.Panel>
        <Tabs.Panel value="qr_codes" my="lg">
          <QRCodes id={type.id} />
        </Tabs.Panel>
        <Tabs.Panel value="survey_answers" my="lg">
          <SurveyAnswers events={type.events} immediatelyBefore={type.IMMEDIATELY_BEFORE} after={type.AFTER} />
        </Tabs.Panel>
      </Tabs>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  types: state.faceToFace.types,
  isLoading: state.faceToFace.isLoading,
  error: state.faceToFace.error
});

const mapDispatchToProps = {
  fetchF2FType: fetchF2FTypeRoutine,
  upsertEmail: upsertEmailRoutine,
  deleteEmail: deleteEmailRoutine
};

export const F2FTypeView = connect(mapStateToProps, mapDispatchToProps)(F2FTypeViewComponent);
