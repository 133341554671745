import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, UserIndex } from 'types/state';
import { Alert, Table, Skeleton } from '@mantine/core';
import { EventDto, EventEnrolmentDto } from 'types/api/event';
import { fetchEnrolmentsForEvent as fetchEnrolmentsForEventRoutine } from 'store/event-enrolment/routines';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { F2FTaskWhen } from 'enums/training';
import { UserRoles } from 'enums';

type Props = {
  event: EventDto;
  enrolmentLists: Record<string, string[]>;
  enrolments: Record<string, EventEnrolmentDto>;
  isLoading: boolean;
  error?: string;
  users: UserIndex;
  fetchEnrolmentsForEvent: (id: string) => void;
  fetchUsersByRole: (role: UserRoles) => void;
};

const EventEnrolmentTableComponent: React.FC<Props> = ({ event, enrolmentLists, enrolments, isLoading, error, users, fetchEnrolmentsForEvent, fetchUsersByRole }) => {
  useEffect(() => {
    fetchEnrolmentsForEvent(event.id);
  }, [event.id]);

  useEffect(() => {
    fetchUsersByRole(UserRoles.LEARNER);
  }, []);

  if (isLoading || !enrolmentLists[event.id]) return <Skeleton h="8rem" />;

  const list = enrolmentLists[event.id];

  return (
    <>
      {error && (
        <Alert color="red" title="Something went wrong">
          {error}
        </Alert>
      )}
      <Table>
        <thead>
          <tr>
            <th>Email</th>
            <th>{F2FTaskWhen.BEFORE}</th>
            <th>{F2FTaskWhen.IMMEDIATELY_BEFORE}</th>
            <th>{F2FTaskWhen.AFTER}</th>
          </tr>
        </thead>
        <tbody>
          {list.map(id => {
            const enrolment = enrolments[id];
            const user = users[enrolment.userId];
            return (
              <tr key={id}>
                <td>{user ? user.email : <Skeleton h="1rem" />}</td>
                <td>{enrolment.BEFORE}</td>
                <td>{enrolment.IMMEDIATELY_BEFORE}</td>
                <td>{enrolment.AFTER}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  enrolmentLists: state.eventEnrolment.enrolmentLists,
  enrolments: state.eventEnrolment.enrolments,
  isLoading: state.eventEnrolment.isLoading,
  error: state.eventEnrolment.error,
  users: state.users.LEARNER
});

const mapDispatchToProps = {
  fetchEnrolmentsForEvent: fetchEnrolmentsForEventRoutine,
  fetchUsersByRole: fetchUsersByRoleRoutine
};

export const EventEnrolmentTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventEnrolmentTableComponent);
