import { axiosAdapter } from 'service/axios-adapter';

class CertificateService {
  async eventCertificate(id: string): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: `/certificate/event/${id}`
    });
    return res.data;
  }

  async courseCertificate(id: string): Promise<Blob> {
    const res = await axiosAdapter.doGetPDF({
      url: `/certificate/course/${id}`
    });
    return res.data;
  }
}

export const certificateService = new CertificateService();
