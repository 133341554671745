import { ScormDto, CMIElement, CMIErrorCode } from 'types/api/scorm';
import { ScormDataType } from 'enums/scorm';
import { env } from 'env';

const dummyAPI = {
  Initialize: (): boolean => {
    console.log('SCORM: Initialize');
    return true;
  },
  Terminate: (): boolean => {
    console.log('SCORM: Terminate');
    return true;
  },
  GetValue: (element: CMIElement): string => {
    console.log('SCORM: GetValue', element);
    switch (element) {
      case ScormDataType.LESSON_MODE:
        return 'browse';
      case ScormDataType.LESSON_STATUS:
        return 'not attempted';
      case ScormDataType.SUSPEND_DATA:
        return '{}';
      default:
        return '';
    }
  },
  SetValue: (element: CMIElement, value: string): string => {
    console.log('SCORM: SetValue', element, value);
    return value;
  },
  Commit: (): boolean => {
    console.log('SCORM: Commit');
    return true;
  },
  GetLastError: (): CMIErrorCode => {
    console.log('SCORM: GetLastError');
    return '0';
  },
  GetErrorString: (errorCode: CMIErrorCode): string => {
    console.log('SCORM: GetErrorString', errorCode);
    return errorCode;
  },
  GetDiagnostic: (errorCode: CMIErrorCode): string => {
    console.log('SCORM: GetDiagnostic', errorCode);
    return errorCode;
  }
};

class ScormAPIService {
  constructor() {
    this.openPopup = this.openPopup.bind(this);
    this.#addScorm12 = this.#addScorm12.bind(this);
    this.loadDummyAPI = this.loadDummyAPI.bind(this);
    this.loadAPI = this.loadAPI.bind(this);
  }

  openPopup = (id: string) => {
    window.open(`/scorm/${id}/scormdriver/indexAPI.html`, null, 'height=200,width=400,status=yes,toolbar=no,menubar=no,location=no');
  };

  #addScorm12 = () => {
    const w = window as any;
    w.API = {
      LMSInitialize: w.API_1484_11.Initialize,
      LMSFinish: w.API_1484_11.Terinate,
      LMSGetValue: w.API_1484_11.GetValue,
      LMSSetValue: w.API_1484_11.SetValue,
      LMSCommit: w.API_1484_11.Commit,
      LMSGetLastError: w.API_1484_11.GetLastError,
      LMSGetErrorString: w.API_1484_11.GetErrorString,
      LMSGetDiagnostic: w.API_1484_11.GetDiagnostic
    };
  };

  loadDummyAPI = () => {
    const w = window as any;
    w.API_1484_11 = dummyAPI;
    this.#addScorm12();
  };

  loadAPI = (scorm: ScormDto, setValueAction: (arg0: { key: string; value: string; id: string }) => void) => {
    this.loadDummyAPI();
    if (!scorm.enrolment) return;

    const w = window as any;
    w.API_1484_11.GetValue = (key: string): string => {
      switch (key) {
        case ScormDataType.SUSPEND_DATA:
          return scorm.enrolment.data;
        case ScormDataType.LESSON_STATUS:
          return scorm.enrolment.status;
        case ScormDataType.LOCATION:
          return scorm.enrolment.location;
        default:
          return '';
      }
    };
    w.API_1484_11.SetValue = (key: string, value: string): string => {
      setValueAction({ key, value, id: scorm.id });
      return '';
    };
    this.#addScorm12();
  };
}

export const scormAPIService = new ScormAPIService();
