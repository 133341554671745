import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import QRCode from 'qrcode';
import { IconCopy } from '@tabler/icons-react';
import { Anchor, Button, Group, Stack, Title } from '@mantine/core';
import { env } from 'env';

type Props = {
  id: string;
}

export const QRCodes: React.FC<Props> = ({ id }) => {
  const preEventQRCanvasRef = useRef<HTMLCanvasElement>(null);
  const postEventQRCanvasRef = useRef<HTMLCanvasElement>(null);

  const preEventSurveyLink = useMemo(() => {
    if (id) {
      return `${env.urls.frontendOrigin}/direct-survey/pre/${id}`;
    }

    return '';
  }, [id]);

  const postEventSurveyLink = useMemo(() => {
    if (id) {
      return `${env.urls.frontendOrigin}/direct-survey/post/${id}`;
    }

    return '';
  }, [id]);

  useEffect(() => {
    if (!preEventQRCanvasRef.current && !postEventQRCanvasRef.current && !preEventSurveyLink && !postEventSurveyLink) return;

    QRCode.toCanvas(preEventQRCanvasRef.current, preEventSurveyLink, { scale: 10 });
    QRCode.toCanvas(postEventQRCanvasRef.current, postEventSurveyLink, { scale: 10 });
  }, [preEventQRCanvasRef, postEventQRCanvasRef, preEventSurveyLink, postEventSurveyLink]);

  const handlePreEventQRCodeCopy = useCallback(async () => {
    if (!preEventQRCanvasRef.current) return;

    const blob =  await new Promise<Blob>((resolve) => {
      preEventQRCanvasRef.current.toBlob((b) => resolve(b));
    });

    try {
      navigator.clipboard.write([
          new ClipboardItem({
              'image/png': blob
          })
      ]);
  } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
      }
  }
  }, [preEventQRCanvasRef])

  const handlePostEventQRCodeCopy = useCallback(async () => {
    if (!postEventQRCanvasRef.current) return;

    const blob =  await new Promise<Blob>((resolve) => {
      postEventQRCanvasRef.current.toBlob((b) => resolve(b));
    });

    try {
      navigator.clipboard.write([
          new ClipboardItem({
              'image/png': blob
          })
      ]);
  } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
      }
  }
  }, [postEventQRCanvasRef])

  return (
    <Group position='center' spacing="lg">
      <Stack spacing="xs">
        <Title order={2} ta="center">Pre-Event</Title>
        <Anchor href={preEventSurveyLink}>
          <canvas ref={preEventQRCanvasRef} />
        </Anchor>
        <Button leftIcon={<IconCopy />} onClick={handlePreEventQRCodeCopy}>
          Copy QR Code
        </Button>
      </Stack>
      <Stack spacing="xs">
        <Title order={2} ta="center">Post-Event</Title>
        <Anchor href={postEventSurveyLink}>
          <canvas ref={postEventQRCanvasRef} />
        </Anchor>
        <Button leftIcon={<IconCopy />} onClick={handlePostEventQRCodeCopy}>
          Copy QR Code
        </Button>
      </Stack>
    </Group>
  )
}