import React, { useState } from 'react';
import { Text, TextInput, Button, Group, Alert } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { useForm, isEmail } from '@mantine/form';

type Props = {
  eventId: string;
  isLoading: boolean;
  registerAndEoI: (arg0: { email: string; eventId: string }) => void;
};

export const GuestExpressionOfInterest: React.FC<Props> = ({
  eventId,
  isLoading,
  registerAndEoI
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: isEmail('Please enter a valid email address')
    },
    transformValues: values => ({
      eventId,
      email: values.email
    })
  });

  return (
    <form
      onSubmit={form.onSubmit(values => {
        setSubmitted(true);
        registerAndEoI(values);
      })}
    >
      {(!submitted || isLoading) && (
        <>
          <Text mt="xl" mb="xs">
            To get started please enter your{' '}
            <b>
              <u>work email</u>
            </b>{' '}
            address:
          </Text>
          <Group align="end">
            <TextInput
              miw={300}
              placeholder="Email address"
              {...form.getInputProps('email')}
            />
            <Button type="submit" color="cyan" loading={isLoading}>
              Get Started
            </Button>
          </Group>
        </>
      )}

      {submitted && !isLoading && (
        <Alert color="green" icon={<IconMail />}>
          Thanks! A link to complete your expression of interest has been sent
          to your email address.
        </Alert>
      )}
    </form>
  );
};
