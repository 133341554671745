import { AxiosError } from 'axios';
import { AdminRoutes } from 'enums';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { eventService } from 'service/event';
import { CreateEventDto, CreateEventInstanceDto, UpdateEventDto } from 'types/api/event';
import { createEnrolment, createEvent, createInstance, fetchEvent, fetchEventForUser, fetchEvents, removeInstance, reorderEnrolment, signoffEvent, updateEvent, updateInstance } from './routines';

function* fetchEventsHandler() {
  try {
    const data = yield call(eventService.getAll);
    yield put(fetchEvents.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchEvents.failure(e.message));
    else yield put(fetchEvents.failure('Unknown error.'));
  }
}

function* fetchEventHandler({ payload: id }: Action<string>) {
  try {
    const data = yield call(eventService.getOne, id);
    yield put(fetchEvent.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchEvent.failure(e.message));
    else yield put(fetchEvent.failure('Unknown error.'));
  }
}

function* fetchEventForUserHandler({ payload: { eventId, userId } }: Action<{ eventId: string; userId: string }>) {
  try {
    const data = yield call(eventService.getOneForUser, eventId, userId);
    yield put(fetchEvent.success(data));
  } catch (e) {
    if (e instanceof AxiosError) yield put(fetchEvent.failure(e.message));
    else yield put(fetchEvent.failure('Unknown error.'));
  }
}

function* createEventHandler({
  payload: { dto, navigate }
}: Action<{
  dto: CreateEventDto;
  navigate: NavigateFunction;
}>) {
  try {
    const event = yield call(eventService.create, dto);
    navigate(`${AdminRoutes.eventView}/${event.id}`);
    yield put(createEvent.success(event));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createEvent.failure(e.message));
    else yield put(createEvent.failure('Unknown error'));
  }
}

function* updateEventHandler({ payload }: Action<UpdateEventDto>) {
  try {
    const event = yield call(eventService.update, payload);
    yield put(updateEvent.success(event));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateEvent.failure(e.message));
    else yield put(updateEvent.failure('Unknown error'));
  }
}

function* createInstanceHandler({
  payload: { eventId, dto }
}: Action<{
  eventId: string;
  dto: CreateEventInstanceDto;
}>) {
  try {
    const event = yield call(eventService.createInstance, eventId, dto);
    yield put(createInstance.success(event));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createInstance.failure(e.message));
    else yield put(createInstance.failure('Unknown error'));
  }
}

function* updateInstanceHandler({
  payload: { eventId, dto }
}: Action<{
  eventId: string;
  dto: CreateEventInstanceDto;
}>) {
  try {
    const event = yield call(eventService.updateInstance, eventId, dto);
    yield put(updateInstance.success(event));
  } catch (e) {
    if (e instanceof AxiosError) yield put(updateInstance.failure(e.message));
    else yield put(updateInstance.failure('Unknown error'));
  }
}

function* removeInstanceHandler({
  payload: { eventId, instanceId }
}: Action<{
  eventId: string;
  instanceId: string;
}>) {
  try {
    const event = yield call(eventService.removeInstance, eventId, instanceId);
    yield put(removeInstance.success(event));
  } catch (e) {
    if (e instanceof AxiosError) yield put(removeInstance.failure(e.message));
    else yield put(removeInstance.failure('Unknown error'));
  }
}

function* createEnrolmentHandler({ payload: eventId }: Action<string>) {
  try {
    const enrolment = yield call(eventService.createEnrolment, eventId);
    yield put(createEnrolment.success(enrolment));
  } catch (e) {
    if (e instanceof AxiosError) yield put(createEnrolment.failure(e.message));
    else yield put(createEnrolment.failure('Unknown error'));
  }
}

function* reorderEnrolmentHandler({
  payload: { enrolmentId, eventId, targetIndex }
}: Action<{
  enrolmentId: string;
  eventId: string;
  targetIndex: number;
}>) {
  try {
    const event = yield call(eventService.reorderEnrolment, enrolmentId, { eventId, targetIndex });
    yield put(reorderEnrolment.success(event));
  } catch (e) {
    if (e instanceof AxiosError) yield put(reorderEnrolment.failure(e.message));
    else yield put(reorderEnrolment.failure('Unknown error'));
  }
}

function* signoffEventHandler({ payload: eventId }: Action<string>) {
  try {
    const event = yield call(eventService.signoff, eventId);
    yield put(signoffEvent.success(event));
  } catch (e) {
    if (e instanceof AxiosError) yield put(signoffEvent.failure(e.message));
    else yield put(signoffEvent.failure('Unknown error'));
  }
}

export default function* eventSaga() {
  yield all([
    takeEvery(fetchEvents.TRIGGER, fetchEventsHandler),
    takeEvery(fetchEvent.TRIGGER, fetchEventHandler),
    takeEvery(fetchEventForUser.TRIGGER, fetchEventForUserHandler),
    takeEvery(createEvent.TRIGGER, createEventHandler),
    takeEvery(updateEvent.TRIGGER, updateEventHandler),
    takeEvery(createInstance.TRIGGER, createInstanceHandler),
    takeEvery(updateInstance.TRIGGER, updateInstanceHandler),
    takeEvery(removeInstance.TRIGGER, removeInstanceHandler),
    takeEvery(createEnrolment.TRIGGER, createEnrolmentHandler),
    takeEvery(reorderEnrolment.TRIGGER, reorderEnrolmentHandler),
    takeEvery(signoffEvent.TRIGGER, signoffEventHandler)
  ]);
}
