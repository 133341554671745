import React from 'react';
import { SurveyQuestionDto } from 'types/api/survey';
import { SurveyQuestionType } from 'enums/survey/f2f-survey-type';

import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { Card, Text, List } from '@mantine/core';

type Props = {
  question: SurveyQuestionDto;
  onClick: () => void;
};

export const QuestionCard: React.FC<Props> = ({ question, onClick }) => {
  return (
    <Draggable draggableId={question.id} index={question.sortIndex}>
      {(provided: DraggableProvided) => (
        <Card withBorder shadow="sm" onClick={onClick} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          {question.type === 'COMMENT' ? (
            <div dangerouslySetInnerHTML={{ __html: question.text }} />
          ) : (
            <>
              <Text fw="bold">{question.text}</Text>
              <Text>Answer is {SurveyQuestionType[question.type]}.</Text>
              {['RADIO', 'CHECKBOX', 'LIKERT'].includes(question.type) && (
                <List>
                  {question.options.map(opt => (
                    <List.Item key={opt.id}>
                      {opt.text} {typeof opt.value === 'number' && `(${opt.value})`}
                    </List.Item>
                  ))}
                  {question.isOther && <List.Item>Other (Please Specify)</List.Item>}
                </List>
              )}
            </>
          )}
        </Card>
      )}
    </Draggable>
  );
};
