import { ActionIcon, Group } from '@mantine/core';
import {
  IconChalkboard,
  IconClipboardList,
  IconFileUpload,
  IconNotebook
} from '@tabler/icons-react';
import React from 'react';

interface EventEnrolmentTaskProps {
  type: 'COURSE' | 'SCORM' | 'SURVEY' | 'UPLOAD';
  title: string;
  completed: boolean;
}

export const EventEnrolmentTask = ({
  type,
  title,
  completed
}: EventEnrolmentTaskProps) => {
  return (
    <Group>
      {completed && (
        <ActionIcon variant="filled" radius="lg" color="green" size="lg">
          {type === 'COURSE' && <IconChalkboard />}
          {type === 'SCORM' && <IconNotebook />}
          {type === 'SURVEY' && <IconClipboardList />}
          {type === 'UPLOAD' && <IconFileUpload />}
        </ActionIcon>
      )}
      {!completed && (
        <ActionIcon variant="filled" radius="lg" color="gray" size="lg">
          {type === 'COURSE' && <IconChalkboard />}
          {type === 'SCORM' && <IconNotebook />}
          {type === 'SURVEY' && <IconClipboardList />}
          {type === 'UPLOAD' && <IconFileUpload />}
        </ActionIcon>
      )}
      <span>{title}</span>
    </Group>
  );
};
