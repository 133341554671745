import { Container, LoadingOverlay, Tabs } from '@mantine/core';
import { useCurrentUser } from 'features/user/api/get-current-user';
import React from 'react';
import { DataExport } from './data-export';
import { SurveyExportPanel } from './survey-export';

export const DataExportPage = () => {
  const currentUserQuery = useCurrentUser();

  if (currentUserQuery.isLoading) return <LoadingOverlay visible />;

  if (!currentUserQuery.data) return null;

  const currentUserRole = currentUserQuery.data.data.role;

  return (
    <Container size="lg" my="lg">
      <Tabs
        w="100%"
        defaultValue={currentUserRole === 'IOI_ADMIN' ? 'data' : 'survey'}
      >
        <Tabs.List grow>
          {currentUserRole === 'IOI_ADMIN' && (
            <Tabs.Tab value="data">Data</Tabs.Tab>
          )}

          <Tabs.Tab value="survey">Survey</Tabs.Tab>
        </Tabs.List>

        {currentUserRole === 'IOI_ADMIN' && (
          <Tabs.Panel value="data" my="xl">
            <DataExport />
          </Tabs.Panel>
        )}

        <Tabs.Panel value="survey" my="xl">
          <SurveyExportPanel />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
