import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  TextInput,
  Textarea,
  Text,
  Button,
  Group,
  FileButton
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { F2FTypeDto, UpdateF2FTypeDto } from 'types/api/face-to-face';
import {
  updateF2FType as updateF2FTypeRoutine,
  uploadSplash as uploadSplashRoutine
} from 'store/face-to-face/routines';

type Props = {
  type: F2FTypeDto;
  updateF2FType?: (dto: UpdateF2FTypeDto) => void;
  uploadSplash: (arg0: { f2fId: string; file: File }) => void;
};

const MetadataComponent: React.FC<Props> = ({
  type,
  updateF2FType,
  uploadSplash
}) => {
  const [file, setFile] = useState<File>(null);

  const form = useForm({
    initialValues: {
      name: type.name,
      description: type.description
    },
    transformValues: values => ({
      id: type.id,
      name: values.name,
      description: values.description
    })
  });

  return (
    <>
      <form onSubmit={form.onSubmit(values => updateF2FType(values))}>
        <TextInput
          my="md"
          label="Name"
          placeholder="Training name"
          required
          {...form.getInputProps('name')}
        />
        <Textarea
          my="md"
          label="Description"
          placeholder="Description"
          required
          {...form.getInputProps('description')}
        />
        <Group position="right" my="md">
          <Button type="submit">Save Changes</Button>
        </Group>
      </form>

      <Group my="xl">
        <Text sx={{ flex: '1 !important' }}>
          {file ? file.name : 'Select a file to upload'}
        </Text>
        <FileButton onChange={setFile} accept="image/png,image/jpeg">
          {props => <Button {...props}>Choose New Splash Image</Button>}
        </FileButton>
        <Button
          disabled={file === null}
          onClick={() => {
            uploadSplash({ f2fId: type.id, file });
            setFile(null);
          }}
        >
          Upload
        </Button>
      </Group>
    </>
  );
};

const mapDispatchToProps = {
  updateF2FType: updateF2FTypeRoutine,
  uploadSplash: uploadSplashRoutine
};

export const Metadata = connect(null, mapDispatchToProps)(MetadataComponent);
